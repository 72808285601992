<div class="container-fluid">
  <h3 class="my-3">Invoices</h3>
  <div class="mb-3">
    <searchbar #searchbar [title]="'Invoices'" [placeholder]="'Search Invoices'" [hasCollapsedFilters]="true" [searchTipText]="searchTipText"
               [sortFilterOptions]="sortFilter" [hasExport]="false"
               (searched)="search($event)" (applyClicked)="applyFilterSortOptions($event)"></searchbar>
  </div>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="totalElements === 0; else counter">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="no-results">
            <h5><b>Not finding what you're searching for?</b></h5>
            <ul>
              <li>Check your spelling, or try fewer terms and more generic texts.</li>
              <li><a href="https://www.jjkane.com/contact-us/" target="_blank" >Contact Us</a> for more assistance.</li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #counter>
      <div class="font-italic font-weight-light mb-3">
        Showing {{ totalElements > 10 ? (1 + (10 * page)) : 1 }} - {{ invoices.length >= 10  ? (10 + (10 * page)) : totalElements }} of {{totalElements}} Items
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loadingCell>
    <div class="loading-container d-flex justify-content-center">
      <div class="loading-text-field loading my-3 w-50"></div>
    </div>
  </ng-template>

  <mat-table [dataSource]="invoices" multiTemplateDataRows class="mat-elevation-z8" *ngIf="loading || totalElements > 0">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Invoice ID</mat-header-cell>
      <mat-cell *matCellDef="let invoice">
        <span class="mobile-label">Invoice ID:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell">
          <div class="spinner-border" *ngIf="downloadingInvoicePdf(invoice.id); else downloadLink">
            <span class="sr-only"></span>
          </div>
          <ng-template #downloadLink>
            <a class="mb-1" (click)="downloadInvoicePdf(invoice.accountId, invoice.id)">{{ invoice.id }} </a>
          </ng-template>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="auctionDate">
      <mat-header-cell *matHeaderCellDef>Auction Date</mat-header-cell>
      <mat-cell *matCellDef="let invoice">
        <span class="mobile-label">Auction Date:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell">
          {{invoice.sale.date | date: 'LL/d/yyyy'}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="auctionLocation">
      <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
      <mat-cell *matCellDef="let invoice">
        <span class="mobile-label">Location:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell" class="location-cell">
          {{invoice.sale.city}}, {{invoice.sale.state}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="bidderNumber">
      <mat-header-cell *matHeaderCellDef>Bidder #</mat-header-cell>
      <mat-cell *matCellDef="let invoice">
        <span class="mobile-label">Bidder #:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell">
          {{invoice.bidderNumber}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="invoiceTotal">
      <mat-header-cell *matHeaderCellDef>Invoice Total</mat-header-cell>
      <mat-cell *matCellDef="let invoice">
        <span class="mobile-label">Invoice Total:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell" >
          {{invoice.invoiceTotal | currency}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="invoiceBalance">
      <mat-header-cell *matHeaderCellDef>Invoice Balance</mat-header-cell>
      <mat-cell *matCellDef="let invoice">
        <span class="mobile-label">Invoice Balance:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell" >
          {{(invoice.invoiceBalance ? invoice.invoiceBalance : 0) | currency}}
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let invoice; columns: columnsToDisplay;" class="invoice-row">
    </mat-row>
  </mat-table>

  <div class="mt-3">
    <pagination #paginator [pageSize]="10" [numberOfItems]="totalElements" [index]="page"
                (paging)="pageChange($event)"></pagination>
  </div>
</div>


<!-- DESKTOP VIEW -->

<div class="container-fluid" *ngIf="isDesktop">

  <div class="row border-bottom">
    <div *ngFor="let tabChild of tabChildren; let i = index" (click)="changeTab(tabChild.id)" class="col-2 tab desktop py-3" [class.active]="tabChild.id === activeTabID" [class.mr-2]="i != tabChildren.length-1" [GA]="tabChild.gaTrigger ? {event: {category: tabChild.title + ' Tab', action: 'Click', label: 'Item Detail'}} : null">
      {{tabChild.title}}
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 px-0">
      <div *ngFor="let tabChild of tabChildren" id="{{tabChild.id}}">
        <div *ngIf="activeTabID === tabChild.id">
          <ng-container *ngTemplateOutlet="tabChild.template"></ng-container>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- TABLET VIEW -->

<div class="container-fluid py-3" *ngIf="isTablet">

  <div class="row border-bottom col-12">
    <ul class="nav nav-tabs">
      <li *ngFor="let tabChild of tabChildren; let i = index" (click)="changeTab(tabChild.id)" class="nav-item tab tablet p-3" [class.active]="tabChild.id === activeTabID" [class.mr-2]="i != tabChildren.length-1" [GA]="tabChild.gaTrigger ? {event: {category: tabChild.title + ' Tab', action: 'Click', label: 'Item Detail'}} : null">
        {{tabChild.title}}
      </li>
    </ul>
  </div>

  <div class="row mt-3">
    <div class="col-12 px-0">
      <div *ngFor="let tabChild of tabChildren" id="{{tabChild.id}}">
        <div *ngIf="activeTabID === tabChild.id">
          <ng-container *ngTemplateOutlet="tabChild.template"></ng-container>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- MOBILE VIEW -->

<div class="container-fluid" *ngIf="isMobile">

  <div class="row mb-2" *ngFor="let tabChild of tabChildren; let i = index">

    <div class="col-12 px-0">
      <div (click)="changeTab(tabChild.id)" class="tab py-3" [class.active]="tabChild.id === activeTabID" [GA]="tabChild.gaTrigger ? {event: {category: tabChild.title + ' Tab', action: 'Click', label: 'Item Detail'}} : null">
        {{tabChild.title}}
      </div>

      <div *ngIf="tabChild.id === activeTabID" class="border-bottom border-left border-right px-2 py-2">
        <ng-container *ngTemplateOutlet="tabChild.template"></ng-container>
      </div>

    </div>

  </div>



</div>

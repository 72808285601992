<div class="mt-4" *ngIf="showForm">
    <form [formGroup]="form">
        <div class="col-12">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
                        <label>First Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" formControlName="firstName" placeholder="First Name" />
                        <div *ngIf="form.controls.firstName.errors && form.controls.firstName.touched" class="error-text">
                            <div *ngIf="form.controls.firstName.errors.required">First Name is required.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
                        <label>Middle Name</label>
                        <input type="text" class="form-control" formControlName="middleName" placeholder="Middle Name" />
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <label>Last Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name" />
                        <div *ngIf="form.controls.lastName.errors && form.controls.lastName.touched" class="error-text">
                            <div *ngIf="form.controls.lastName.errors.required">Last Name is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <input class="form-control" formControlName="email" placeholder="Email" />
                        <div *ngIf="form.controls.email.errors && form.controls.email.touched" class="error-text">
                            <div *ngIf="form.controls.email.errors.required">Email is required.</div>
                            <div *ngIf="form.controls.email.errors.email">Invalid email address format.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Phone Number <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <input class="form-control" formControlName="personalPhoneNumber" (keyup)="formatPhone($event)" placeholder="10 Digit Phone Number" />
                        <div *ngIf="form.controls.personalPhoneNumber.errors && form.controls.personalPhoneNumber.touched" class="error-text">
                            <div *ngIf="form.controls.personalPhoneNumber.errors.required">Phone Number is required.</div>
                            <div *ngIf="form.controls.personalPhoneNumber.errors.minlength || form.controls.personalPhoneNumber.errors.maxlength">Phone Number must be 10 digits.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Phone Type <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <select class="form-control" formControlName="personalPhoneType">
                            <option selected value="">Select Phone Type</option>
                            <option value="Home">Home</option>
                            <option value="Mobile">Mobile</option>
                            <option value="Office">Office</option>
                        </select>
                        <div *ngIf="form.controls.personalPhoneType.errors && form.controls.personalPhoneType.touched" class="error-text">
                            <div *ngIf="form.controls.personalPhoneType.errors.required">Phone Type is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Address <span class="text-danger">*</span></label>
                <div class="row mb-3">
                    <div class="col-12">
                        <input class="form-control" formControlName="personalAddressLine1" placeholder="Address line 1" />
                        <div *ngIf="form.controls.personalAddressLine1.errors && form.controls.personalAddressLine1.touched" class="error-text">
                            <div *ngIf="form.controls.personalAddressLine1.errors.required">Address Line 1 is required.</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="form-control" formControlName="personalAddressLine2" placeholder="Address line 2" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <div class="row">
                    <div class="col-12">
                        <label>Zip Code<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" formControlName="personalZip" (keyup)="checkForValidZip()" placeholder="Zip Code" />
                        <div *ngIf="form.controls.personalZip.errors && form.controls.personalZip.touched" class="error-text">
                            <div *ngIf="form.controls.personalZip.errors.pattern">Physical zip format should be XXXXX</div>
                            <div *ngIf="form.controls.personalZip.errors.required">Zip Code is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-2" *ngIf="geocodeLoading || geocodeLocations.length > 0">
            <label>City / County / State Selection<span class="text-danger">*</span></label>
            <mat-table [dataSource]="geocodeLocations" multiTemplateDataRows class="mat-elevation-z8 cursor-pointer" *ngIf="geocodeLoading || geocodeLocations.length > 0">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>Select</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">Select:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        <mat-radio-button (click)="selectLocation(geocodeLocation)"
                        [checked]="selectedLocation && selectedLocation.city === geocodeLocation.city && selectedLocation.county === geocodeLocation.county" ></mat-radio-button>
                    </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">City:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        {{geocodeLocation.city}}
                    </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="county">
                    <mat-header-cell *matHeaderCellDef>County</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">County:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        {{geocodeLocation.county}}
                    </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">State:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        {{geocodeLocation.state}}
                    </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row *matRowDef="let geocodeLocation; columns: columnsToDisplay;"
                    class="location-row" [class.selected-row]="selectedLocation && selectedLocation.city === geocodeLocation.city && selectedLocation.county === geocodeLocation.county">
                </mat-row>
            </mat-table>
        </div>

        <div class="col-12">
            <div class="form-group">
                <label>Country <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <select class="form-control" formControlName="personalCountry" [class.loading]="loading">
                            <ng-container *ngIf="!loading">
                                <option selected value="">Select Country</option>
                                <option *ngFor="let country of countries" [ngValue]="country.description">{{country.description}}</option>
                            </ng-container>
                        </select>
                        <div *ngIf="form.controls.personalCountry.errors && form.controls.personalCountry.touched" class="error-text">
                            <div *ngIf="form.controls.personalCountry.errors.required">Country is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="container-fluid wrapper">
    <div class="mt-3">
      <h3>Submit an Item</h3>
      <div *ngIf="showTitledItemWizard || showMiscellaneousItemWizard" class="col-12">
          ( <span class="text-danger"> * </span> ) Required Information
      </div>
      <div class="row mb-3">
        <div *ngIf="!showTitledItemWizard && !showMiscellaneousItemWizard" class="offset-md-4 col-md-4 col-xs-12">
          <h4 class="text-center">What kind of item would you like to submit?</h4>
          <button class="btn text-light bg-altec btn-block" (click)="showTitledItemWizard = true">Titled Item</button>
          <h5 class="text-center">or</h5>
          <button class="btn text-light bg-altec btn-block" (click)="showMiscellaneousItemWizard = true">Misc Item</button>
          <hr>
          <button class="btn text-light bg-altec btn-block" (click)="goToItemDrafts()">View Item Drafts</button>
        </div>
      </div>
      <div *ngIf="showTitledItemWizard">
        <app-titled-item-step-wizard [isDraft]="isDraft" [draft]="draft"></app-titled-item-step-wizard>
      </div>
      <div *ngIf="showMiscellaneousItemWizard">
        <app-miscellaneous-item-step-wizard [isDraft]="isDraft" [draft]="draft"></app-miscellaneous-item-step-wizard>
      </div>
    </div>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.scss']
})
export class FailureComponent implements OnInit {

  @Input() public showForm: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

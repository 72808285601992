import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AuthResponseData, AuthUserService} from './auth-user.service';

export const AGGREGATE_COMPANY_NAME = 'All Accounts';

@Injectable({
  providedIn: 'root'
})
export class CompanySelectionService {
  public showCompanyDropdown = true;
  public userSub: Subscription;
  public user: AuthResponseData;

  public $userCompanies: BehaviorSubject<(UserCompany | UserAggregateCompany)[]> = new BehaviorSubject(undefined);
  public userCompanies: Array<(UserCompany | UserAggregateCompany)>;
  public $selectedCompany: BehaviorSubject<(UserCompany | UserAggregateCompany)> = new BehaviorSubject(undefined);
  public selectedCompany: (UserCompany | UserAggregateCompany);
  public showSeller = new BehaviorSubject<boolean>(false);
  public $showSeller = this.showSeller.asObservable();
  public temporarySelection: boolean;
  private SELECTED_COMPANY_KEY = 'jjkSelectedCompany';

  public getShowSeller():boolean {
    return this.showSeller.getValue();
  }

  constructor(private userAuth: AuthUserService) {
    this.userSub = this.userAuth.$currentUser.subscribe(user => {
      if (user) {
        this.user = user;
        let accounts;

        // If user has more than one account, add in an aggregate view company and stuff in all IDs of other accounts
        // Otherwise just show the one account
        if (this.user.accounts.length > 1) {
          const aggregateAccount: UserAggregateCompany = {
            id: [],
            name: AGGREGATE_COMPANY_NAME,
            type: 'Account',
            parentAccountId: null,
            defaultContact: null,
            sellerCode: this.userAuth.isSeller.toString()
          };
          user.accounts.forEach(el => {
            aggregateAccount.id.push(el.id);
          });
          accounts = [aggregateAccount, ...user.accounts];
        } else {
          accounts = user.accounts;
        }

        this.userCompanies = accounts;
        this.$userCompanies.next(accounts);
        const storedCompanySelection = sessionStorage.getItem(this.SELECTED_COMPANY_KEY);
        if (storedCompanySelection && this.userCompanies.find(x => x.name === storedCompanySelection)) {
          this.selectCompany(storedCompanySelection);
        } else {
          this.selectCompany(this.userCompanies[0].name);
        }
      }
    });
  }

  public selectCompany(name: string): void {
      const company = this.userCompanies.find(element => element.name === name);
      if(company.name === AGGREGATE_COMPANY_NAME){
        let showSeller = false;
        this.userCompanies.forEach(c => {
          if(c.sellerCode && c.sellerCode != 'false'){
            showSeller = true;
          }
        });
        this.showSeller.next(showSeller);
      } else {
        if(company.sellerCode){
          this.showSeller.next(true);
        } else {
          this.showSeller.next(false);
        }
      }
      this.selectedCompany = company;
      this.$selectedCompany.next(this.selectedCompany);
      sessionStorage.setItem(this.SELECTED_COMPANY_KEY, name);
  }

  public getSelectedAccountIds(): number[] {
    const account = this.$selectedCompany.value;
    if (account.name === AGGREGATE_COMPANY_NAME) {
      return account.id as number[];
    } else {
      return [account.id] as number[];
    }
  }
}

export interface UserCompany {
  id: number;
  name: string;
  type: string;
  parentAccountId: number;
  defaultContact: number;
  sellerCode: string;
}

export interface UserAggregateCompany {
  id: Array<number>;
  name: string;
  type: string;
  parentAccountId: number;
  defaultContact: number;
  sellerCode: string;
}

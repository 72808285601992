import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ErrorHandlerService} from '../services/error-handler.service';
import {Router, RouterState} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private errorHandler: ErrorHandlerService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.errors) {
            for (const error of event.body.errors) {
              if (error.message.indexOf('cookie has expired') !== -1) {
                return this.handleSessionExpired(error);
              }
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.log("error?");
        if(error.error === 'login_required') {
          return this.handleSessionExpired(error);
        } else {
          this.errorHandler.handleError(error);
          return throwError(error);
        }
      })
    );
  }

  // TODO: Should this instead direct them to a session expired page to explain why they
  // need to login again?
  handleSessionExpired(error: any): Observable<HttpEvent<unknown>> {
    this.router.navigate(['/login']);
    return throwError(error);
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-delete-item-modal',
  templateUrl: './confirm-delete-item-modal.component.html',
  styleUrls: ['./confirm-delete-item-modal.component.scss']
})
export class ConfirmDeleteItemModalComponent implements OnInit {

  public deleteButtonDisabled: boolean = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.deleteButtonDisabled = false;
    }, 5000);
  }

  public delete() {
    if(!this.deleteButtonDisabled){
      this.activeModal.close(true);
    }
  }

}

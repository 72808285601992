<div *ngIf="getStyleClass() != 'orange'; else orangeClass" class="wrapper cursor-pointer" [ngClass]="getStyleClass()" (click)="clicked.emit()">
  <div class="icon-column" [ngClass]="getStyleClass()">
    <fa-icon [icon]="icon" size="lg" class="icon mx-1"></fa-icon>
  </div>
  <div class="data-column" [ngClass]="getStyleClass()">
    <div class="h5">
      {{ title }}
    </div>
    <div *ngIf="data || data == '0'; else loading" class="h5">
      {{ data }}
    </div>
    <ng-template #loading>
      <div class="mt-3 p-3 loading">
      </div>
    </ng-template>
  </div>
</div>
<ng-template #orangeClass>
  <div class="wrapper" [ngClass]="getStyleClass()" (click)="clicked.emit()">
    <div class="icon-column" [ngClass]="getStyleClass()">
      <fa-icon [icon]="icon" size="lg" class="icon mx-1"></fa-icon>
    </div>
    <div class="data-column" [ngClass]="getStyleClass()">
      <div class="h5">
        {{ title }}
      </div>
      <div *ngIf="data || data == '0'; else loading" class="h5">
        {{ data }}
      </div>
      <ng-template #loading>
        <div class="mt-3 p-3 loading">
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<div class="wrapper">
  <div class="modal-header">
    <h5 class="mb-1">SELECT COMPANY</h5>
  </div>

  <div class="row mt-4 no-gutters">
    <div class="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-center px-1">
      <!-- Desktop Company Select -->
      <div class="w-100 border border-dark" display="dynamic" placement="bottom-left">
          <select class="form-control" (change)="companyChange($event)">
              <option *ngFor="let company of companies">{{ company?.name }}</option>
          </select>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center pr-1 mt-2 mt-lg-0 pl-lg-0 pl-1">
        <button class="w-100 btn text-light bg-altec" (click)="swapCompany()">Select</button>
    </div>
  </div>
</div>

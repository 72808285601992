
<div class="row">
    <div class="col-md-6 col-sm-12 d-flex align-items-center center-sm-left-md">
        <fa-icon *ngIf="message.level === 'INFO'" class="mr-2 text-secondary" [icon]="faInfoCircle" size='lg'></fa-icon>
        <fa-icon *ngIf="message.level === 'WARNING'" class="mr-2 text-warning" [icon]="faExclamationTriangle" size='lg'></fa-icon>
        <span><b>{{message.title}}</b></span>
    </div>
    <div class="col-md-6 col-sm-12 center-sm-right-md">
        <button class="btn btn-success" (click)="previewMessage()">
            <fa-icon [icon]="faEye"></fa-icon>
        </button>
        <button class="btn btn-warning text-light mx-2" (click)="editMessage()">
            <fa-icon [icon]="faEdit"></fa-icon>
        </button>
        <button *ngIf="showDelete" class="btn btn-danger" (click)="deleteMessage()">
            <fa-icon [icon]="faTrash"></fa-icon>
        </button>
    </div>
    <div class="col-md-8 col-sm-12 mt-3">
        <p>{{message.message}}</p>
    </div>
    <div class="col-12">
        <span>{{message.startDate | date:'short'}} </span> 
        <span class="mx-2">
            <fa-icon [icon]="faLongArrowAltRight"></fa-icon>
        </span>
        <span>{{message.endDate | date:'short'}}</span>
        <span class="mx-2">&middot;</span>
        <span class="text-lowercase"><i>{{ message.sitePlacement }}</i></span>
    </div>
</div>


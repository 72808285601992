import { Component, OnInit, Input } from '@angular/core';
import {GetFaIcon} from '../../../Shared/functions/icon-helpers';
import {InvoiceService} from '../../../Invoices/services/invoice.service';
import {download} from '../../../Shared/functions/download-helpers';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';
import { Invoice, Sale, Payout } from '../../../generated/graphql';

@Component({
  selector: 'recent-auctions-card',
  templateUrl: './recent-auctions-card.component.html',
  styleUrls: ['./recent-auctions-card.component.scss']
})
export class RecentAuctionsCardComponent implements OnInit {

  @Input()
  auction: Sale;

  @Input()
  payout: Payout;

  @Input()
  invoice: Invoice;

  @Input()
  odd: boolean;

  public faCreditCard = GetFaIcon('credit-card', 'fas');
  public faTags = GetFaIcon('tags', 'fas');
  public downloadingInvoicePdf: boolean;

  constructor(private invoiceService: InvoiceService, private googleAnalyticService: GoogleAnalyticService) { }

  ngOnInit(): void {
  }

  public downloadInvoicePdf(accountId: number, invoiceId: number): void {
    this.downloadingInvoicePdf = true;
    const event: GoogleAnalyticEventObject = {
      category: 'Download Invoice',
      action: 'Click',
      label: 'Dashboard'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      this.invoiceService.downloadInvoicePdf(accountId, invoiceId).subscribe(data => {
        const fileName = `Bill_Of_Sale_${invoiceId}`;
        const fileType = 'application/pdf';
        download(fileName, fileType, data);
        this.downloadingInvoicePdf = false;
      });
    });
  }
}

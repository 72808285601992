import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';
import {RegistrationService} from '../../services/registration.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent implements OnInit {
  public loading = true;
  public token: string;
  public authenticated: boolean;
  public invalidToken: boolean;
  public activated: boolean;
  public error: boolean;

  constructor(private route: ActivatedRoute, private authService: AuthService, private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.error = false;
    this.invalidToken = false;
    this.token = this.route.snapshot.params['token'];

    if (!this.token || this.token.length === 0) {
      this.invalidToken = true;
      this.loading = false;
      return;
    }

    this.authService.isAuthenticated$.subscribe(authenticated => {
      this.authenticated = authenticated;
      if (this.authenticated) {
        // authenticated users cannot confirm another account
        this.loading = false;
        return;
      }

      this.registrationService.activateUser(this.token).subscribe(response => {
        if (response) {
          this.activated = true;
        } else {
          this.error = true;
        }
        this.loading = false;
      });
    });
  }

  public logout(): void {
    this.authService.logout({ returnTo: window.location.origin });
  }
}

<div class="container-fluid" *ngIf="showForm">
  <form [formGroup]="form">
    <div class="col-12">
      <div class="form-group">
        <label>Address <span class="text-danger">*</span></label>
        <div class="row mb-3">
          <div class="col-12">
            <input class="form-control" [class.is-invalid]="form.controls.addressLine1.touched && form.controls.addressLine1.errors" formControlName="addressLine1" placeholder="Enter address line 1" />
            <div *ngIf="form.controls.addressLine1.touched && form.controls.addressLine1.errors" class="error-text">
              <div *ngIf="form.controls.addressLine1.errors.required">Address is required</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>City <span class="text-danger">*</span> / State <span class="text-danger">*</span> / Zip <span class="text-danger">*</span></label>
        <div class="row">
          <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
            <input type="text" class="form-control" [class.is-invalid]="form.controls.city.touched && form.controls.city.errors" formControlName="city" placeholder="Enter the city" />
            <div *ngIf="form.controls.city.touched && form.controls.city.errors" class="error-text">
              <div *ngIf="form.controls.city.errors.required">City is required</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
            <select class="form-control" [class.is-invalid]="form.controls.state.touched && form.controls.state.errors" formControlName="state">
              <option value>Select state</option>
              <option *ngFor="let state of states" [ngValue]="state.code">{{state.name}}</option>
            </select>
            <div *ngIf="form.controls.state.touched && form.controls.state.errors" class="error-text">
              <div *ngIf="form.controls.state.errors.required">State is required</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" [class.is-invalid]="form.controls.zip.touched && form.controls.zip.errors" formControlName="zip" placeholder="Enter the zip" />
            <div *ngIf="form.controls.zip.touched && form.controls.zip.errors" class="error-text">
              <div *ngIf="form.controls.zip.errors.pattern">Physical zip format should be XXXXX or XXXXX-XXXX</div>
              <div *ngIf="form.controls.zip.errors.required">Zip is required</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>County</label>
        <div class="row mb-2">
          <div class="col-12">
            <input class="form-control" [class.is-invalid]="form.controls.county.errors"formControlName="county" placeholder="Enter the county" />
            <div *ngIf="form.controls.county.errors" class="error-text">
              <div *ngIf="form.controls.county.errors.maxlength">County is too long</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="side-nav">
  <div *ngIf="isMobile" class="text-right my-2">
    <fa-icon [icon]="faTimes" size="lg" class="mr-2 text-light" (click)="closeSideNav()"></fa-icon>
  </div>
  <nav class="nav flex-column">
    <a *ngIf="!liteVersion" class="nav-link nav-link-border py-3" [routerLink]="'/dashboard'" [routerLinkActive]="'active'"
       [routerLinkActiveOptions]="{exact:true}"
       [class.top-link-mobile]="isMobile">
      <fa-icon [icon]="faHome" size="md" class="mr-2"></fa-icon>
      <span>Dashboard</span>
    </a>

    <mat-expansion-panel class="w-100 nav-link-border" (opened)="expandSelling(true)" (closed)="expandSelling(false)"
                         [hideToggle]="true" [expanded]="sellingExpanded" *ngIf="(!liteVersion && showSeller && !loadingSeller) else sellerLink">

      <mat-expansion-panel-header class="w-100 px-0">
        <span class="nav-link py-3 w-100 d-flex justify-content-between" [class.expanded]="sellingExpanded">
          <span>
            <fa-icon [icon]="faTags" size="md" class="mr-2"></fa-icon>
            <span>Selling</span>
          </span>
          <span>
            <fa-icon [icon]="sellingExpanded ? faAngleDown : faAngleRight" size="lg"></fa-icon>
          </span>
        </span>
      </mat-expansion-panel-header>
      <span class="w-100 px-0">
        <a class="nav-link sub-nav py-3" [routerLink]="'submit-items'" [routerLinkActive]="'active'"
           [class.expanded]="sellingExpanded">
          <fa-icon [icon]="faPaperPlane" size="sm" class="mr-2"></fa-icon>
          <span>Submit Item</span>
        </a>
        <a class="nav-link sub-nav py-3" [routerLink]="'item-drafts'" [routerLinkActive]="'active'"
           [class.expanded]="sellingExpanded">
          <fa-icon [icon]="faPenUl" size="sm" class="mr-2"></fa-icon>
          <span>Item Drafts</span>
        </a>
        <a class="nav-link sub-nav py-3" [routerLink]="'items'" [routerLinkActive]="'active'"
           [class.expanded]="sellingExpanded">
            <fa-icon [icon]="faListUl" size="sm" class="mr-2"></fa-icon>
            <span>Consigned Items</span>
        </a>
        <a class="nav-link sub-nav py-3" [routerLink]="'payouts'" [routerLinkActive]="'active'"
           [class.expanded]="sellingExpanded">
          <fa-icon [icon]="faMoneyCheckAlt" size="sm" class="mr-2"></fa-icon>
          <span>Payouts</span>
        </a>
      </span>
    </mat-expansion-panel>

    <ng-template #sellerLink>
      <a *ngIf="!liteVersion && !showSeller && !loadingSeller" class="nav-link nav-link-border py-3" href="https://www.jjkane.com/info/selling-at-our-auctions" target="_blank" [class.top-link-mobile]="isMobile">
        <fa-icon [icon]="faTags" size="md" class="mr-2"></fa-icon>
        <span>Selling</span>
      </a>
    </ng-template>

    <mat-expansion-panel class="w-100 nav-link-border" (opened)="expandBuying(true)" (closed)="expandBuying(false)"
                         [hideToggle]="true" [expanded]="buyingExpanded" *ngIf="!liteVersion">
      <mat-expansion-panel-header class="w-100 px-0">
      <span class="nav-link py-3 w-100 d-flex justify-content-between" [class.expanded]="buyingExpanded">
        <span>
          <fa-icon [icon]="faCreditCard" size="md" class="mr-2"></fa-icon>
          <span>Buying</span>
        </span>
        <span>
          <fa-icon [icon]="buyingExpanded ? faAngleDown : faAngleRight" size="lg"></fa-icon>
        </span>
      </span>
      </mat-expansion-panel-header>
      <span class="w-100 px-0">
      <a class="nav-link sub-nav py-3" [routerLink]="'purchased-items'" [routerLinkActive]="'active'"
         [class.expanded]="buyingExpanded">
          <fa-icon [icon]="faShoppingCart" size="sm" class="mr-2"></fa-icon>
        <span>Purchased Items</span>
      </a>
      <a class="nav-link sub-nav py-3" [routerLink]="'invoices'" [routerLinkActive]="'active'"
         [class.expanded]="buyingExpanded">
          <fa-icon [icon]="faReceipt" size="sm" class="mr-2"></fa-icon>
        <span>Invoices</span>
      </a>
      <a class="nav-link sub-nav py-3" href="https://www.jjkane.com/info/how-to-bid/" target="_blank" [routerLinkActive]="'active'"
         [class.expanded]="buyingExpanded">
          <fa-icon [icon]="faQuestionCircle" size="sm" class="mr-2"></fa-icon>
        <span>How to Bid</span>
      </a>
      <a class="nav-link sub-nav py-3" href="https://www.jjkane.com/make-a-payment" target="_blank" [routerLinkActive]="'active'"
         [class.expanded]="buyingExpanded">
        <fa-icon [icon]="faCashRegister" size="sm" class="mr-2"></fa-icon>
        <span>Make a Payment</span>
      </a>
    </span>
    </mat-expansion-panel>

    <a *ngIf="devToolsEnabled" class="nav-link py-3" [routerLink]="liteVersion? '/graphQl' : 'graphQl'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="faToolbox" size="md" class="mr-2"></fa-icon>
      <span>GraphQL</span>
    </a>

    <a *ngIf="userIsAdmin" class="nav-link py-3" [routerLink]="'admin'" [routerLinkActive]="'active'">
      <fa-icon [icon]="faUsersCog" size="md" class="mr-2"></fa-icon>
      <span>Admin</span>
    </a>
  </nav>
</div>

<div *ngIf="!liteVersion" class="bottom-content text-center text-light w-100 mb-3">
  <a class="cursor-pointer contact-us" href="https://www.jjkane.com/contact-us/" target="_blank" [routerLinkActive]="'active'">
    <fa-icon [icon]="faPhone" size="lg" class="mr-2"></fa-icon>
    <span>
      Contact Us
    </span>
  </a>
</div>

<div class="wrapper py-2">
    <div class="row px-3">
        <div class="col-6 h5 font-weight-bold text-left">Auctions</div>
    </div>
    <div class="col-12">
        <div class="row mb-2">
          <div class="col-xs-12 col-md-6 mt-3 auction-border-right">
            <span class="h5">Recent</span>
            <div>
              <ng-container *ngIf="recentAuctions; else auctionPlaceholder">
                <recent-auctions-card *ngFor="let auction of recentAuctions; let odd = odd" [auction]="auction.sale" [payout]="auction.payout"
                                      [invoice]="auction.invoice" [odd]="odd">
                </recent-auctions-card>
              </ng-container>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 mt-3">
              <span class="h5">Upcoming</span>
            <a class="viewAll" target="javascript:;" (click)="openAllAuctions()"><span>View All</span></a>
              <div class="px-3">
                  <ng-container *ngIf="upcomingAuctions; else auctionPlaceholder">
                    <upcoming-auctions-card *ngFor="let auction of upcomingAuctions; let odd = odd" [odd]="odd" [auction]="auction">
                    </upcoming-auctions-card>
                  </ng-container>
              </div>
          </div>
        </div>
    </div>

  <ng-template #auctionPlaceholder>
    <placeholder-auction-card>
    </placeholder-auction-card>
    <placeholder-auction-card [odd]="true">
    </placeholder-auction-card>
  </ng-template>
</div>

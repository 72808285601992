<div class="card-wrapper p-3 cursor-pointer" (click)="quickFilterClicked()">
  <div class="row d-flex">
    <div class="col-8 title">
      <div class="h5">{{title}}</div>
    </div>
    <div class="col-4 count">
      <div class="h5 text-right" *ngIf="count || count === 0; else loading">{{count}}</div>
    </div>
    <ng-template #loading>
      <div class="p-3 loading">
      </div>
    </ng-template>
  </div>
</div>

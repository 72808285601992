import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DaterangePickerComponent} from '../daterange-picker/daterange-picker.component';
import {GetFaIcon} from '../../functions/icon-helpers';

@Component({
  selector: 'searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {

  @Input()
  title = 'Search';

  @Input()
  placeholder = 'Search here...';

  @Input()
  searchTipText = null;

  @Input()
  hasCollapsedFilters = false;

  @Input()
  sortFilterOptions: SortFilterData;

  @Input()
  hasQuickFilters = false;

  @Input()
  quickFilters: Array<string> = [];

  @Input()
  hasExport = false;

  @Input()
  hasItemSearchToggle = false;

  @Output()
  searched: EventEmitter<string> = new EventEmitter();

  @Output()
  exitSearch = new EventEmitter();

  @Output()
  exportClicked: EventEmitter<null> = new EventEmitter();

  @Output()
  quickFilterClicked: EventEmitter<string> = new EventEmitter();

  @Output()
  applyClicked: EventEmitter<SortFilterOutput> = new EventEmitter();

  @Output()
  itemSearchTypeToggled: EventEmitter<string> = new EventEmitter();

  @ViewChild('searchText', { static: false }) searchText: ElementRef;
  @ViewChildren('filterForms') filterForms: QueryList<ElementRef>;
  @ViewChild('sortForm') sortForm: ElementRef;
  @ViewChild('directionForm') directionForm: ElementRef;

  public show = false;
  public showFilters = false;
  public appliedFilters: Array<SelectedFilter> = [];
  public sortValue: string;
  public sortBackEndValue: string;
  public direction = 'asc';
  public faDownload = GetFaIcon('download', 'fas');
  public faSearch = GetFaIcon('search', 'fas');
  public faTimes = GetFaIcon('times', 'fas');
  public faEllipsisH = GetFaIcon('ellipsis-h', 'fas');
  public faEllipsisV = GetFaIcon('ellipsis-v', 'fas');
  public searchType = 'seller';

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  public search(): void {
    this.searched.emit(this.searchText.nativeElement.value);
  }
  public inputChange(): void{
    this.show = this.searchText.nativeElement.value !== '';
  }
  public clearSearch(): void {
    this.searchText.nativeElement.value = '';
    this.searched.emit(this.searchText.nativeElement.value);
    this.exitSearch.emit();
    this.show = false;
  }

  public setSearchText(val: string): void {
    this.searchText.nativeElement.value = val;
    this.inputChange();
  }

  public export(): void {
    this.exportClicked.emit();
  }

  public quickFilterAction(val: string): void {
    this.quickFilterClicked.emit(val);
  }

  public showCollapsedFilters(): void {
    this.showFilters = !this.showFilters;
  }

  public selectOption(filter: string, value: string, backEndFilter: string): void {
    if (value) {
      if (filter === 'Auction Date' && value === 'Custom') {
        const modal = this.modalService.open(DaterangePickerComponent, {backdrop: 'static', keyboard: false});
        modal.result.then(dates => {
          if (dates?.startDate && dates?.endDate) {
            const startDate = `${dates.startDate.month}/${dates.startDate.day}/${dates.startDate.year}`;
            const endDate = `${dates.endDate.month}/${dates.endDate.day}/${dates.endDate.year}`;
            const filterValue = `${startDate} - ${endDate}`;
            const auctionFilter = this.sortFilterOptions.filterData.find(x => x.title === 'Auction Date');
            auctionFilter.options.splice(-1, 0, filterValue);
            setTimeout(() => {
              this.selectFilterProgramatically('Auction Date', filterValue, 'auctionDate');
            }, 0);
          }
        }).catch(e => {
          // if you close the modal, undefined is printed here. If you don't catch it, an error is printed instead
          console.log(e);
        });
      } else {
        this.removeFilterSelection(filter);
        this.appliedFilters.push({
          filter,
          selection: value,
          backEndFilter
        });
      }
    } else {
      this.removeFilterSelection(filter);
    }
  }

  public selectSortProgramatically(value: string, backEndSort: string): void {
    // Update UI
    // The Identifier for the filterForms is in the previous element (label)
    const optionsArray = Array.apply(null, this.sortForm.nativeElement.options);
    const selectedOption = optionsArray.find(el => el.text === value);
    const index = optionsArray.indexOf(selectedOption);
    this.sortForm.nativeElement.selectedIndex = index;

    // Apply filter data
    this.sortValue = value;
    this.sortBackEndValue = backEndSort;
  }

  public selectFilterProgramatically(filter: string, value: string, backEndFilter: string): void {
    // Update UI
    const filForms = this.filterForms.toArray();
    filForms.forEach( filterElement => {
      // The Identifier for the filterForms is in the previous element (label)
      if (filterElement.nativeElement.previousSibling.textContent === filter) {
        const optionsArray = Array.apply(null, filterElement.nativeElement.options);
        const selecteOption = optionsArray.find(el => el.value === value);
        const index = optionsArray.indexOf(selecteOption);
        filterElement.nativeElement.selectedIndex = index;
      }
    });

    // Apply filter data
    this.selectOption(filter, value, backEndFilter);
  }

  public removeFilterSelection(filter: string): void {
    const temp = this.appliedFilters.find( x => x.filter === filter);
    if (temp) {
      const index = this.appliedFilters.indexOf(temp);
      this.appliedFilters.splice(index, index + 1);
    }
  }

  public sortSelect(value: any): void {
    if (value.target.value) {
      this.sortValue = value.target.options[value.target.selectedIndex].text;
      this.sortBackEndValue = value.target.value;
    } else {
      this.sortValue = null;
      this.sortBackEndValue = null;
    }
  }
  public sortDirectionChange(dir: string): void {
    // dir = 'asc' or 'desc'
    this.direction = dir;
  }

  public apply(): void {
    this.applyClicked.emit(this.generateSortFilterOutput());
  }

  public reset(): void {
    this.resetUI();
    this.applyClicked.emit(this.generateSortFilterOutput());
  }

  public generateSortFilterOutput(): SortFilterOutput {
    return {
      filterResults: this.appliedFilters,
      rangeFilterResults: this.sortFilterOptions.rangeFilterData,
      sortResults: this.sortValue,
      backEndSortResult: this.sortBackEndValue,
      sortDirection: this.direction,
      searchTerm: this.searchText.nativeElement.value
    };
  }

  public resetUI(): void {
    if (this.filterForms) {
      const filForms = this.filterForms.toArray();
      filForms.forEach(el => {
        el.nativeElement.selectedIndex = 0;
      });
    }
    if (this.sortFilterOptions?.rangeFilterData) {
      this.sortFilterOptions.rangeFilterData.forEach(x => {
        x.min = null;
        x.max = null;
      });
    }
    if (this.sortForm) {
      this.sortForm.nativeElement.selectedIndex = 0;
    }
    if (this.directionForm) {
      this.directionForm.nativeElement.checked = true;
    }
    this.direction = 'asc';
    this.appliedFilters = [];
    this.sortValue = null;
  }

  public sanitizeNumericData(): void {
    this.sortFilterOptions.rangeFilterData.forEach(x => {
      if (x.min) {
        x.min = +x.min?.toString().replace(/[^-0-9.]/g, '');
      }
      if (x.max) {
        x.max = +x.max?.toString().replace(/[^-0-9.]/g, '');
      }
    });
  }

  public validFormEntries(): boolean {
    if (this.sortFilterOptions?.rangeFilterData) {
      for (const rangeFilter of this.sortFilterOptions.rangeFilterData) {
        if (rangeFilter.min === 0 || rangeFilter.max === 0){
          return true;
        }
        if ((rangeFilter.min && !rangeFilter.max) || (!rangeFilter.min && rangeFilter.max)) {
          return false;
        }
        if (rangeFilter.min && rangeFilter.max) {
          if (rangeFilter.min > rangeFilter.max) {
            return false;
          }
        }
      }
    }
    return true;
  }

  public toggleSearchType(searchType: string): void {
    this.searchType = searchType;
    this.itemSearchTypeToggled.emit(searchType);
  }
}

export interface SortFilterData {
  hasSortOptions: boolean;
  hasFilterOptions: boolean;
  filterData: Array<FilterData>;
  sortData: Array<SortData>;
  rangeFilterData?: Array<RangeFilterData>;
}

export interface SortData {
  title: string;
  backEndTitle: string;
}

export interface FilterData {
  title: string;
  backEndTitle: string;
  options: Array<string>;
}

export interface SelectedFilter {
  filter: string;
  selection: string;
  backEndFilter: string;
}

export interface SortFilterOutput {
  backEndSortResult?: string;
  filterResults?: Array<SelectedFilter>;
  rangeFilterResults?: Array<RangeFilterData>;
  sortResults?: string;
  sortDirection?: any;
  searchTerm?: string;
}

export interface RangeFilterData {
  title: string;
  min?: number;
  max?: number;
  minFieldName?: string;
  maxFieldName?: string;
}

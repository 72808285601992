import { Injectable } from '@angular/core';
import {DatePipe} from '@angular/common';
import {SaleSummaryFragment} from "../../generated/graphql";


@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  constructor(private datePipe: DatePipe) { }

  public generateAuctionLocation(auction: SaleSummaryFragment): string {
    if (auction.saleType === 'Timed Auction') {
      return 'J.J.Kane Exchange (Online Only)';
    } else if (auction.saleType === 'Private Sale') {
      return 'Private Sale';
    } else {
      return `${auction.city}, ${auction.state}`;
    }
  }

  public generateAuctionName(auction: SaleSummaryFragment): string{
    let name;
    if (auction.saleType === 'Timed Auction') {
      name = auction.saleType;
    } else {
      name = `${auction.city}, ${auction.state}`;
    }
    //console.log("Auction Information:");
    //console.log(auction);
    const formattedDate = this.datePipe.transform(auction.date, 'MM/dd/yyyy', '+0500');
    //console.log(formattedDate);
    return `${name} - ${formattedDate}`;
  }
}

<div>
    <!-- Active Image -->
    <div *ngIf="!loading">
        <img src="{{imageUrls[selectedIndex]}}" class="img-fluid">
    </div>
    <div *ngIf="loading">
      <div class="loading-image-fluid loading">&nbsp;</div>
    </div>

    <!-- Preview Images -->
    <div *ngIf="!loading" class="d-flex flex-nowrap mt-3">

      <div class="flex-shrink-1 align-self-center mr-1 cursor-pointer" (click)="previousImage()" [GA]="{event: {category: 'Image Navigation', action: 'Click', label: 'Item Detail'}}">
        <fa-icon [icon]="faChevronCircleLeft" size="lg"></fa-icon>
      </div>
      <div class="flex-grow-1 mx-1 w-100 cursor-pointer" *ngFor="let image of thumbnailUrls; let i = index;" [class.active]="startPreviewGalleryIndex + i === selectedIndex">
        <img *ngIf="image != ''" src="{{ image }}" class="img-fluid img-stretch" (click)="selectImage(i)" [GA]="{event: {category: 'Image Navigation', action: 'Click', label: 'Item Detail'}}">
        <div *ngIf="image === ''" class="w-100 h-100"></div>
      </div>
      <div class="flex-shrink-1 align-self-center ml-1 cursor-pointer" (click)="nextImage()" [GA]="{event: {category: 'Image Navigation', action: 'Click', label: 'Item Detail'}}">
        <fa-icon [icon]="faChevronCircleRight" size="lg"></fa-icon>
      </div>
    </div>

    <div *ngIf="loading" class="d-flex flex-nowrap mt-3">

      <div class="flex-shrink-1 align-self-center mr-1">
        <fa-icon [icon]="faChevronCircleLeft" size="lg"></fa-icon>
      </div>
      <div class="flex-grow-1 mx-1 w-100">
        <div class="loading-image-fluid loading">&nbsp;</div>
      </div>
      <div class="flex-grow-1 mx-1 w-100">
        <div class="loading-image-fluid loading">&nbsp;</div>
      </div>
      <div class="flex-grow-1 mx-1 w-100">
        <div class="loading-image-fluid loading">&nbsp;</div>
      </div>
      <div class="flex-grow-1 mx-1 w-100">
        <div class="loading-image-fluid loading">&nbsp;</div>
      </div>
      <div class="flex-shrink-1 align-self-center ml-1">
        <fa-icon [icon]="faChevronCircleRight" size="lg"></fa-icon>
      </div>
    </div>

    <!-- Download Button -->
    <div class="row mt-3">
      <div class="col-12">
        <div class="d-flex justify-content-center" (click)="downloadButton()" [GA]="{event: {category: 'Image Download', action: 'Click', label: 'Item Detail'}}">
          <button type="button" class="btn bg-altec text-center"><strong>Download Images</strong></button>
        </div>
      </div>
    </div>

</div>
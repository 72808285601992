<div class="wrapper mt-2 px-3 py-1">
    <div class="font-weight-bold my-2">Search {{ title }}</div>
    <label class="sr-only" for="searchText">{{ placeholder }}</label>
    <div class="input-group mb-2">
      <div class="d-flex mr-1 search-toggle" *ngIf="hasItemSearchToggle">
        <div class="form-control text-center cursor-pointer" [class.selected]="searchType === 'seller'" (click)="toggleSearchType('seller')">
          Selling
        </div>
        <div class="form-control text-center cursor-pointer" [class.selected]="searchType === 'buyer'" (click)="toggleSearchType('buyer')">
          Buying
        </div>
      </div>
      <input #searchText type="text" class="form-control" id="searchText" (input)="inputChange()" (keydown.enter)="search()" placeholder="{{ placeholder }}">
      <div class="input-group-append" *ngIf="show">
        <span id="search-clear"><fa-icon (click)="clearSearch()" [icon]="faTimes"></fa-icon></span>
      </div>
        <div class="input-group-append" (click)="search()">
            <div class="input-group-text altec-gold-bg cursor-pointer">
                <fa-icon [icon]="faSearch" size="md" class="text-light"></fa-icon>
            </div>
        </div>
    </div>
  <span *ngIf="searchTipText" id="search-tips" data-toggle="tooltip" data-placement="bottom" title="{{searchTipText}}">Search Tips</span>
    <div *ngIf="hasQuickFilters" class="mb-2 mt-3">
        <span *ngFor="let filter of quickFilters">
            <searchbar-quick-filter [title]="filter" (clicked)="quickFilterAction($event)"></searchbar-quick-filter>
        </span>
    </div>

    <div *ngIf="hasCollapsedFilters"  class="mt-2 mb-1">
        <div class="row" [class.justify-content-between]="hasExport" [class.justify-content-start]="!hasExport">
            <div class="col-lg-8 col-md-6 col-sm-12 my-2">
                <a class="btn filter-button mr-3 px-2 py-2" (click)="showCollapsedFilters()">{{ showFilters ? 'Hide' : 'Show' }} Sort/Filter Options</a>
                <a class="btn filter-button-text-only mr-3 px-2 py-2" (click)="reset()">Clear Sort/Filter Options</a>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12 my-md-2 d-flex justify-content-end">
                <div class="mobile-expand">
                    <a *ngIf="hasExport" class="btn text-light button-success py-2" (click)="export()" [GA]="{event: {category: 'Export Results', action: 'Click', label: title}}">
                        <fa-icon [icon]="faDownload" class="icon mr-2"></fa-icon>
                        <span>Export Results</span>
                    </a>
                </div>

            </div>

        </div>

        <div [hidden]="!showFilters">
            <hr/>

            <div *ngIf="sortFilterOptions?.hasFilterOptions">
                <h6 class="my-3">Filter Options</h6>
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-12 mb-3" *ngFor="let filter of sortFilterOptions?.filterData">
                        <label class="mb-0">{{ filter.title }}</label>
                        <select #filterForms class="form-control" (change)="selectOption(filter.title, $event.target.value, filter.backEndTitle)">
                            <option selected="selected" value>Show All</option>
                            <option *ngFor="let filterVal of filter.options">{{ filterVal }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 col-12 mb-3 range-filter" *ngFor="let filter of sortFilterOptions?.rangeFilterData">
                      <div>
                        <label class="mb-0">{{ filter.title }}</label>
                      </div>
                      <input class="form-control range-input" type="number" placeholder="Min" [(ngModel)]="filter.min" [class.error]="(filter.min === null && filter.max !== null) || (filter.min > filter.max)" (keyup)="sanitizeNumericData()"/>
                      <fa-icon [icon]="faEllipsisH" size="sm" class="form-control range-divider range-divider-desktop"></fa-icon>
                      <fa-icon [icon]="faEllipsisV" size="sm" class="form-control range-divider range-divider-mobile"></fa-icon>
                      <input class="form-control range-input"  type="number" placeholder="Max" [(ngModel)]="filter.max" [class.error]="(filter.min !== null && filter.max === null) || (filter.min > filter.max)" (keyup)="sanitizeNumericData()"/>
                      <div class="error-text">
                        <small *ngIf="(filter.min !== null && filter.max !== null) && (filter.min > filter.max)">Min should not be greater than max</small>
                        <small *ngIf="(filter.min === null && filter.max !== null) || (filter.min !== null && filter.max === null)">Both min and max must be provided</small>
                      </div>
                    </div>
                </div>
            </div>

            <div *ngIf="sortFilterOptions?.hasSortOptions">
                <h6 class="my-3">Sort Options</h6>
                <div class="row">
                    <div class="col-md-3 col-xs-12 mb-3" >
                        <label class="mb-0">Field</label>
                        <select #sortForm class="form-control" (change)="sortSelect($event)">
                            <option value>Select...</option>
                            <option *ngFor="let sort of sortFilterOptions?.sortData" [value]="sort.backEndTitle">{{ sort.title }}</option>
                        </select>
                    </div>

                    <div class="col-md-3 col-xs-12 mb-3" >
                        <div>
                            <label class="mb-0">Direction</label>
                        </div>
                        <div class="pt-1">
                            <div class="form-check form-check-inline" (click)="sortDirectionChange('asc')">
                                <input #directionForm class="form-check-input" type="radio" name="direction" id="ascendingCheck" value="ascending" [checked]="direction === 'asc'">
                                <label class="form-check-label" for="ascendingCheck">Ascending</label>
                            </div>
                            <div class="form-check form-check-inline" (click)="sortDirectionChange('desc')">
                                <input class="form-check-input" type="radio" name="direction" id="descendingCheck" value="descending" [checked]="direction === 'desc'">
                                <label class="form-check-label" for="descendingCheck">Descending</label>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="text-right">
                <button class="btn text-light bg-altec" (click)="apply()" [GA]="{event: {category: 'Filter/Sort Apply', action: 'Click', label: title}}" [disabled]="!validFormEntries()">Apply</button>
            </div>
        </div>


    </div>
</div>

import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ContactService} from '../../Shared/services/contact.service';
import {map} from 'rxjs/operators';
import {SiteMessageStatus} from '../dto/SiteMessage';
import {Observable, Subscription} from 'rxjs';
import {CompanySelectionService} from '../../Shared/services/company-selection.service';
import {
  DeleteSiteMessageMutation,
  DeleteSiteMessageMutationVariables,
  JjkIntegration,
  RdsMessageInput,
  SaveSiteMessagesMutation,
  SiteMessage,
  SiteMessageQuery,
  SiteMessageQueryVariables,
  UpdateSiteMessagesMutation,
  UpdateSiteMessagesMutationVariables
} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class SiteMessagesService implements OnDestroy {

  public accountID: number;
  public today: Date = new Date(Date.now());
  public messages: Array<SiteMessage> = [];
  public activeMessages: Array<SiteMessage>;
  public upcomingMessages: Array<SiteMessage>;
  public previousMessages: Array<SiteMessage>;
  private $selectedCompanySubscription: Subscription;

  constructor(private http: HttpClient, private contactService: ContactService, private companySelection: CompanySelectionService, private jjkIntegration: JjkIntegration) {
    // todo: we're using parentAccountId, but this.companySelection.getSelectedAccountIds() may be more appropriate
    // todo: consider removing entirely. Instead of keeping state here, use CompanySelectionService where needed as the source of truth
    this.$selectedCompanySubscription = this.companySelection.$selectedCompany.subscribe(() => {
      this.contactService.getContactInfo().subscribe(contactInfo => {
        this.accountID = contactInfo.parentAccountId;
      });
    });
  }

  public ngOnDestroy(): void {
    this.$selectedCompanySubscription.unsubscribe();
  }

  public saveMessage(saveSiteMessageVariables: SaveSiteMessageVariables): Observable<SaveSiteMessagesMutation['saveSiteMessage']> {
    return this.jjkIntegration.saveSiteMessages(saveSiteMessageVariables)
      .pipe(map(response => response.data.saveSiteMessage));
  }

  public updateMessage(updateSiteMessagesMutationVariables: UpdateSiteMessagesMutationVariables): Observable<UpdateSiteMessagesMutation['updateSiteMessage']> {
    return this.jjkIntegration.updateSiteMessages(updateSiteMessagesMutationVariables)
      .pipe(map(response => response.data.updateSiteMessage));
  }

  public scanSiteMessages(siteMessageQueryVariables: SiteMessageQueryVariables): Observable<SiteMessageQuery['scanSiteMessages']> {
    return this.jjkIntegration.siteMessage(siteMessageQueryVariables)
      .pipe(map(response => response.data.scanSiteMessages));
  }

  public deleteMessage(deleteMessageVariables: DeleteSiteMessageMutationVariables): Observable<DeleteSiteMessageMutation['deleteSiteMessage']> {
    return this.jjkIntegration.deleteSiteMessage(deleteMessageVariables)
      .pipe(map(response => response.data.deleteSiteMessage));
  }

  public sortMessages(): void {
    const sortSubmitDate = this.messages.slice(0);
    sortSubmitDate.sort((a, b) => {
      return Number(b.submitTimestamp) - Number(a.submitTimestamp);
    });

    this.activeMessages = sortSubmitDate.filter(el => {
      return el.status === 'ACTIVE' && new Date(el.startDate) < this.today && new Date(el.endDate) > this.today;
    });
    this.upcomingMessages = sortSubmitDate.filter(el => el.status === SiteMessageStatus.Active && new Date(el.startDate) > this.today);
    this.previousMessages = sortSubmitDate.filter(el => el.status === SiteMessageStatus.Cancelled || new Date(el.endDate) < this.today);
  }
}

export interface SuccessSave {
  numberOfRecordsUpdated: number;
  insertId: number;
}

export interface SuccessDelete {
  success: boolean;
}

export class SaveSiteMessageVariables {
  accountID: number;
  adminMessage: RdsMessageInput;
}

export class ScanSiteMessageVariables {
  accountID: number;
}

export class DeleteMessageVariables {
  accountID: number;
  messageId: number;
}

export class ScanSiteMessages {
  accountIds: number[];
  itemId: number;
}

export interface DeleteMessageQueryResponse {
  deleteSiteMessage: SuccessDelete;
}

export interface SaveMessageQueryResponse {
  saveSiteMessages: SuccessSave;
}

export interface MessageScanResponse {
  scanSiteMessages: Array<SiteMessage>;
}

<div class="container-fluid" *ngIf="showForm">
  <!--  Vehicle -->
  <div class="col-12" *ngIf="vehicle">
    <div class="row">
      <div class="col-12">
        <h5 class="section-label">Vehicle</h5>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-5 col-sm-12">
        <div>
          <label>Category: </label> {{vehicle.category}}
        </div>
        <div>
          <label>VIN: </label> {{vehicle.vin}}
        </div>
        <div>
          <label>Year: </label> {{vehicle.year}}
        </div>
        <div>
          <label>Make: </label> {{vehicle.make}}
        </div>
        <div>
          <label>Model: </label> {{vehicle.model}}
        </div>
        <div>
          <label>Unit Number: </label> {{vehicle.unitNumber}}
        </div>
        <div>
          <label>License Plate Number: </label> {{vehicle.licensePlateNumber}}
        </div>
      </div>
      <div class="col-md-7 col-sm-12">
        <div>
          <label>Engine: </label> {{vehicle.engine}}
        </div>
        <div>
          <label>Fuel: </label> {{vehicle.fuel}}
        </div>
        <div>
          <label>Transmission: </label> {{vehicle.transmission}}
        </div>
        <div>
          <label>Odometer: </label> {{vehicle.odometer}}
        </div>
        <div>
          <label>Hours: </label> {{vehicle.hours}}
        </div>
        <div>
          <label>Conditions: </label> {{vehicle.conditions}}
        </div>
        <div>
          <label>Optional Equipment: </label> {{vehicle.optionalEquipment}}
        </div>
      </div>
    </div>
  </div>
  <!--  Unit -->
  <div class="col-12" *ngIf="unit">
    <div class="row">
      <div class="col-12">
        <h5 class="section-label">Unit</h5>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-5 col-sm-12">
        <div>
          <label>Serial Number: </label> {{unit.serialNumber}}
        </div>
        <div>
          <label>Make: </label> {{unit.make}}
        </div>
        <div>
          <label>Model: </label> {{unit.model}}
        </div>
        <div>
          <label>Fuel: </label> {{unit.fuel}}
        </div>
      </div>
      <div class="col-md-7 col-sm-12">
        <div>
          <label>Height: </label> {{unit.height}}
        </div>
        <div>
          <label>Capacity: </label> {{unit.capacity}}
        </div>
        <div>
          <label>Mounted: </label> {{unit.mounted}}
        </div>
        <div>
          <label>Optional Equipment: </label> {{unit.optionalEquipment}}
        </div>
      </div>
    </div>
  </div>
  <!--  Item -->
  <div class="col-12" *ngIf="item">
    <div class="row">
      <div class="col-12">
        <h5 class="section-label">Item</h5>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-5 col-sm-12">
        <div>
          <label>Category: </label> {{item.category}}
        </div>
        <div>
          <label>Year: </label> {{item.year}}
        </div>
        <div>
          <label>Make: </label> {{item.make}}
        </div>
        <div>
          <label>Model: </label> {{item.model}}
        </div>
        <div>
          <label>Unit Number: </label> {{item.unitNumber}}
        </div>
      </div>
      <div class="col-md-7 col-sm-12">
        <div>
          <label>Conditions: </label> {{item.conditions}}
        </div>
        <div>
          <label>Optional Equipment: </label> {{item.optionalEquipment}}
        </div>
      </div>
    </div>
  </div>
  <!--  Location -->
  <div class="col-12" *ngIf="location">
    <div class="row">
      <div class="col-12">
        <h5 class="section-label">Location</h5>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div>{{location.addressLine1}}</div>
        <div *ngIf="location.city && location.state; else individualCityState">
          {{location.city}}, {{location.state}}
        </div>
        <ng-template #individualCityState>
          <div *ngIf="location.city">{{location.city}}</div>
          <div *ngIf="location.state">{{location.state}}</div>
        </ng-template>
        <div>{{location.zip}}</div>
      </div>
    </div>
  </div>
  <!-- Photos -->
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <h5 class="section-label">Photos</h5>
      </div>
    </div>
    <div class="row" *ngFor="let file of submitItemService.uploadQueue; let odd = odd" [class.odd]="odd">
      <div class="col-6">{{ file.name }}</div>
      <div class="col-6">{{ file.size | filesize }}</div>
    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import { GetFaIcon } from 'src/app/Shared/functions/icon-helpers';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SubmitItemService} from '../../services/submit-item.service';
import {RDSDraftItem} from '../../../item-drafts/services/drafts.service';
import {Subject} from 'rxjs';
import { GetDropdownValuesQuery } from '../../../generated/graphql';

@Component({
  selector: 'app-titled-item-step-wizard',
  templateUrl: './titled-item-step-wizard.component.html',
  styleUrls: ['./titled-item-step-wizard.component.scss']
})
export class TitledItemStepWizardComponent implements OnInit {

  @Input() public isDraft = false;
  @Input() public draft: RDSDraftItem;
  public dropdownValues: GetDropdownValuesQuery;
  public ddvSubject: Subject<GetDropdownValuesQuery> = new Subject<GetDropdownValuesQuery>();
  public parentForm: FormGroup;
  public selectedStep = 1;
  public loadingData = true;

  public navigationSteps = [
    {
      title: 'Vehicle Info',
      icon: GetFaIcon('truck-pickup', 'fas')
    },
    {
      title: 'Vehicle Specs',
      icon: GetFaIcon('tachometer-alt', 'fas')
    },
    {
      title: 'Unit Info',
      icon: GetFaIcon('truck', 'fas')
    },
    {
      title: 'Location',
      icon: GetFaIcon('map-marker-alt', 'fas')
    },
    {
      title: 'Pictures',
      icon: GetFaIcon('upload', 'fas')
    },
    {
      title: 'Confirmation',
      icon: GetFaIcon('check', 'fas')
    }
  ];

  constructor(private fb: FormBuilder, private submitItemService: SubmitItemService) {
    this.parentForm = this.fb.group({});
  }

  ngOnInit(): void {
    if (this.draft === null) {
      this.setStep(this.selectedStep);
    } else {
      this.setStep(this.draft.currentStep);
    }
    this.submitItemService.getDropdownValues().subscribe(response => {
      if (response) {
        this.dropdownValues = response;
        this.ddvSubject.next(response);
        this.loadingData = false;
      }
    });
  }

  public setStep(step: number): void {
    this.selectedStep = step;
  }
}

<div  class="activation-results p-4">
  <h3 *ngIf="loading; else results">Activating user account...</h3>
</div>
<ng-template #results>
  <ng-container *ngIf="activated">
    <h3>Congratulations! Your user account has been activated!</h3>
    <p>
      <a [routerLink]="'/login'">Log in now</a>.
    </p>
  </ng-container>
  <ng-container *ngIf="authenticated">
    <h3>You are currently logged in</h3>
    <p>
      If you wish to activate a new account, please <a href="javascript:;" (click)="logout()">log out</a> first.
    </p>
  </ng-container>
  <ng-container *ngIf="invalidToken">
    <h3>Invalid Complete Registration Link</h3>
  </ng-container>
  <ng-container *ngIf="error">
    <h3>An error has occurred.</h3>
    <p>
      Some functionality may currently be unavailable. We apologize for the inconvenience. Try refreshing the page, or activating again at a later time.
    </p>
  </ng-container>
</ng-template>

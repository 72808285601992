<div class="container-fluid" *ngIf="showForm">
  <form [formGroup]="form">
    <div class="col-12">
      <div class="form-group">
        <label>Serial Number</label>
        <input class="form-control" [class.is-invalid]="form.controls.serialNumber.errors" formControlName="serialNumber" placeholder="Enter serial number"/>
        <div *ngIf="form.controls.serialNumber.errors" class="error-text">
          <div *ngIf="form.controls.serialNumber.errors.maxlength">Serial Number is too long</div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Make / Model</label>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-md-0 mb-3">
            <select class="form-control" formControlName="makeAndModel" [class.loading]="loading">
              <ng-container *ngIf="!loading">
                <option value>Select make</option>
                <option *ngFor="let makeAndModel of dropdownValues?.upperMakeAndModel" [ngValue]="makeAndModel">{{makeAndModel.make}}</option>
              </ng-container>
            </select>
            <input class="form-control" formControlName="make" hidden/>
          </div>
          <div class="col-md-6 col-xs-12">
            <select class="form-control" formControlName="model" [class.loading]="loading">
              <ng-container *ngIf="!loading">
                <option value>Select model</option>
                <option *ngFor="let model of models" [ngValue]="model">{{model}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Fuel</label>
        <select class="form-control" formControlName="fuel" [class.loading]="loading">
          <ng-container *ngIf="!loading">
            <option value>Select a fuel type</option>
            <option *ngFor="let fuel of dropdownValues?.upperFuel" [ngValue]="fuel.name">{{fuel.name}}</option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Height</label>
        <input class="form-control" [class.is-invalid]="form.controls.height.errors"
               formControlName="height" placeholder="Enter the height"/>
        <div *ngIf="form.controls.height.errors" class="error-text">
          <div *ngIf="form.controls.height.errors.pattern">Height must be numeric</div>
          <div *ngIf="form.controls.height.errors.maxlength">Height is too long</div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Capacity</label>
        <div class="row">
          <div class="col-md-8 col-xs-12 mb-md-0 mb-3">
            <input class="form-control" [class.is-invalid]="form.controls.capacity.errors" formControlName="capacity" placeholder="Enter the capacity"/>
            <div *ngIf="form.controls.capacity.errors" class="error-text">
              <div *ngIf="form.controls.capacity.errors.pattern">Capacity must be numeric</div>
              <div *ngIf="form.controls.capacity.errors.maxlength">Capacity is too long</div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <select class="form-control" formControlName="capacityUnit" [class.loading]="loading">
              <ng-container *ngIf="!loading">
                <option value>Select capacity unit</option>
                <option *ngFor="let capacity of dropdownValues?.capacity" [ngValue]="capacity.name">{{capacity.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Mounted</label>
        <select class="form-control" formControlName="mounted">
          <option value>Select the mount type</option>
          <option *ngFor="let mounted of dropdownValues?.mounted" [ngValue]="mounted.name">{{mounted.name}}</option>
        </select>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Optional Equipment</label>
        <textarea class="form-control" [class.is-invalid]="form.controls.optionalEquipment.errors" formControlName="optionalEquipment" placeholder="Enter any optional equipment"></textarea>
        <div *ngIf="form.controls.optionalEquipment.errors" class="error-text">
          <div *ngIf="form.controls.optionalEquipment.errors.maxlength">Optional Equipment is too long</div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Notes</label>
        <textarea class="form-control" [class.is-invalid]="form.controls.notes.errors" formControlName="notes" placeholder="Notes"></textarea>
        <div *ngIf="form.controls.notes.errors" class="error-text">
          <div *ngIf="form.controls.notes.errors.maxlength">Notes is too long</div>
        </div>
      </div>
    </div>

  </form>
</div>

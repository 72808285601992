<div class="p-3 pl-4 auction-card card-border" [class.odd]="odd">
  <div class="auction-section header-section">
    <div class="row">
      <div class="col-3">
        <div class="fa-icons">
          <fa-icon [icon]="faCreditCard" size="lg" *ngIf="invoice"></fa-icon>
          <fa-icon [icon]="faTags" size="lg" class="mr-2" *ngIf="auction.itemCount > 0"></fa-icon>
        </div>
      </div>
      <div class="col-9">
        <div class="auction-info">
          <div class="h5">{{ auction.date | date: 'MMM d'}}</div>
          <div *ngIf="auction.saleType === 'Timed Auction'; else locationTemplate">
            Online Only
          </div>
        </div>
        <ng-template #locationTemplate>
          {{auction.city}}, {{auction.state}}
        </ng-template>
      </div>
    </div>
  </div>

  <div class="report-section">
    <div class="auction-section" *ngIf="invoice">
      <div class="h5 w-100 text-center">
        Total: {{invoice.invoiceTotal | currency}}
      </div>
      <div class="w-100 text-center">
        <div class="spinner-border" *ngIf="downloadingInvoicePdf; else downloadLink">
          <span class="sr-only"></span>
        </div>
        <ng-template #downloadLink>
          <a class="alt-link" (click)="downloadInvoicePdf(invoice.accountId, invoice.id)">
            Download Invoice
          </a>
        </ng-template>
      </div>
    </div>

    <div class="auction-section pb-0" *ngIf="auction.itemCount > 0">
      <div class="h5 w-100 text-center">{{auction.itemCount}} {{ auction?.itemCount === 1 ? 'sale' : 'sales' }}</div>
        <div class="h5 w-100 text-center">
          Net: <span [class.green]="payout.sale?.payoutsFinalized">{{ !payout.sale?.payoutsFinalized ? 'Pending' : payout.sellerNet | currency}}</span>
        </div>
        <div class="w-100 text-center">
          <a class="alt-link"
             [GA]="{event: {category: 'View Payout', action: 'Click', label: 'Dashboard'}, route: ['/dashboard/payouts', payout.id]}">
            View Payout
          </a>
        </div>
      </div>
  </div>

</div>

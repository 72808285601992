<div [ngClass]="getItemClass()">
  <div [ngClass]="{'errors-tablet': this.isTablet, 'errors-mobile': this.isMobile, 'errors': this.isDesktop}" *ngIf="errorMessage"><span class="error-icon" [ngClass]="{'mobile' : this.isTablet || this.isMobile}"><fa-icon [icon]="errorIcon"></fa-icon></span>{{ errorMessage }}</div>

  <div class="container-fluid">
    <div class="row no-gutters">

      <div class="icon-details d-flex col-xl-1 col-md-2 col-sm-12 justify-content-sm-center" [ngClass]="getStyleClass()">
        <div class="align-self-center text-center text-md-left w-100">
          <span>
            <fa-icon [icon]="completed ? checkIcon : circleIcon" size="2x"></fa-icon>
          </span>
        </div>
      </div>

      <div class="title-details d-flex col-xl-5 col-md-4 col-sm-12 justify-content-center w-100 pl-0 pl-lg-2" [ngClass]="getStyleClass()">
        <div *ngIf="!payoutId" class="align-self-center text-center text-md-left w-100"><b>{{ title }}</b></div>
        <div *ngIf="payoutId" class="align-self-center text-center text-md-left w-100 alt-link" [GA]="{event: {category: 'Select Final Payout Status', action: 'Click', label: 'Item Detail'}}" (click)="openPayout(payoutId)"><b>{{ title }}</b></div>
      </div>

      <div class="data-details col-md-6 col-sm-12 content d-flex justify-content-center justify-content-lg-end my-3" [ngClass]="getStyleClass()">
        <div class="align-self-center">
          <div class="text-center">&nbsp;</div>
          <div class="text-center" *ngIf="completed">{{ date | date: 'shortDate' }}</div>
          <div class="text-center">&nbsp;</div>
        </div>
      </div>

    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NewMessageModalComponent} from '../../components/new-message-modal/new-message-modal.component';
import {SaveSiteMessageVariables, SiteMessagesService} from '../../services/site-messages.service';
import {DraftsService, RDSDraftItem} from '../../../item-drafts/services/drafts.service';
import {GetFaIcon} from 'src/app/Shared/functions/icon-helpers';
import {NewDraftItemModalComponent} from '../../components/new-draft-item-modal/new-draft-item-modal.component';
import {ConfirmDeleteItemModalComponent} from '../../components/confirm-delete-item-modal/confirm-delete-item-modal.component';
import {ConfirmDeleteMessageModalComponent} from '../../components/confirm-delete-message-modal/confirm-delete-message-modal.component';
import {LoadingModalComponent} from '../../../Shared/components/loading-modal/loading-modal.component';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {
  DeleteSiteMessageMutationVariables,
  SiteMessage,
  SiteMessageQueryVariables,
  UpdateSiteMessagesMutationVariables
} from '../../../generated/graphql';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public faTimesCircle = GetFaIcon('times-circle', 'fas');
  public faSearch = GetFaIcon('search', 'fas');
  public faSync = GetFaIcon('sync', 'fas');
  public faPlus = GetFaIcon('plus', 'fas');
  public draftItemsTableData: Array<RDSDraftItem> = [];
  public messageItemsList: Array<SiteMessage> = [];
  public tableViewSelection = 'all';
  public loadingDraftItems = true;
  public loadingSiteMessages = true;
  public previewMessage: SiteMessage;
  public showPreviewMessage = false;
  public page = 0;
  public customId: string;
  public accountIds: number[];
  public hasMore = false;

  constructor(private modalService: NgbModal, public messageService: SiteMessagesService, private router: Router, public draftService: DraftsService, private companySelection: CompanySelectionService) {
    this.accountIds = this.companySelection.getSelectedAccountIds();
  }

  ngOnInit(): void {
    this.messageItemsList = this.messageService.messages;
    this.queryDraftItems();
    this.scanSiteMessages();
  }

  public clearDraftTableSettings(): void {
    this.draftItemsTableData = [];
  }

  public setCustomId(id: string): void {
    this.customId = id;
    this.clearDraftTableSettings();
    this.queryDraftItems();
  }

  public refresh(): void {
    this.clearDraftTableSettings();
    this.queryDraftItems();
    this.scanSiteMessages();
  }

  public queryDraftItems(): void {
    this.loadingDraftItems = true;
    this.draftService.queryDraftItems(this.accountIds).subscribe(res => {
      if (res && res.records) {
        this.draftItemsTableData = this.draftItemsTableData.concat(res.records);
        // if (res.lastEvaluatedKey){
        //   this.hasMore = true;
        //   this.lastEvaluatedKey = res.lastEvaluatedKey;
        // } else {
        this.hasMore = false;
        // }
      }
      this.loadingDraftItems = false;
    });
  }

  // Modal to allow user to submit test draft items
  public openNewDraftItemModal(): void {
    const modal: NgbModalRef = this.modalService.open(NewDraftItemModalComponent, {backdrop: 'static', keyboard: false});
    modal.result.then(item => {
      if (item) {
        this.draftService.saveItem(item).subscribe(() => {
          this.clearDraftTableSettings();
          this.queryDraftItems();
        });
      }
    });
  }

  // Modal to confirm user deleting Draft Item
  public openConfirmModal(item: RDSDraftItem): void {
    const modal: NgbModalRef = this.modalService.open(ConfirmDeleteItemModalComponent, {backdrop: 'static', keyboard: false});
    modal.result.then(res => {
      if (res) {
        this.draftService.deleteItem(item).subscribe(() => {
          this.clearDraftTableSettings();
          this.queryDraftItems();
        });
      }
    });
  }

  public scanSiteMessages(): void {
    const siteMessage: SiteMessageQueryVariables = {
      accountID: this.messageService.accountID
    };
    this.loadingSiteMessages = true;
    this.messageService.scanSiteMessages(siteMessage).subscribe(res => {
      if (res) {
        this.messageService.messages = res;
        this.messageItemsList = this.messageService.messages;
        this.messageService.sortMessages();
      }
      this.loadingSiteMessages = false;
    });
  }

  public openNewMessageModal(): void {
    const modal: NgbModalRef = this.modalService.open(NewMessageModalComponent, {backdrop: 'static', keyboard: false});
    modal.result.then(mes => {
      const loadingModal: NgbModalRef = this.modalService.open(LoadingModalComponent, {backdrop: 'static', keyboard: false, size: 'sm'});
      if (mes) {
        const siteMessage: SaveSiteMessageVariables = {
          accountID: this.messageService.accountID,
          adminMessage: {
            id: mes.id,
            userId: String(mes.userId),
            submitTimestamp: mes.submitTimestamp,
            status: mes.status,
            level: mes.level,
            sitePlacement: mes.sitePlacement,
            message: mes.message,
            title: mes.title,
            startDate: mes.startDate,
            endDate: mes.endDate
          }
        };
        this.messageService.saveMessage(siteMessage).subscribe(() => {
          this.scanSiteMessages();
          this.router.navigateByUrl('dashboard/admin');
          loadingModal.close();
        });
      }
    });
  }

  public deleteMessage(item: SiteMessage): void {
    const mes = this.messageService.messages.find(el => el === item);
    const modal: NgbModalRef = this.modalService.open(ConfirmDeleteMessageModalComponent, {backdrop: 'static', keyboard: false});
    modal.result.then(res => {
      const loadingModal: NgbModalRef = this.modalService.open(LoadingModalComponent, {backdrop: 'static', keyboard: false, size: 'sm'});
      if (res) {
        const deleteSiteMessageMutationVariables: DeleteSiteMessageMutationVariables = {
          accountID: this.messageService.accountID,
          messageId: mes.id
        };
        this.messageService.deleteMessage(deleteSiteMessageMutationVariables).subscribe(() => {
          this.scanSiteMessages();
          this.router.navigateByUrl('dashboard/admin');
          loadingModal.close();
        });
      } else {
        loadingModal.close();
      }
    });
  }

  public editMessage(item: SiteMessage): void {
    const modal: NgbModalRef = this.modalService.open(NewMessageModalComponent, {backdrop: 'static', keyboard: false});
    modal.componentInstance.message = item;
    modal.result.then(mes => {
      const loadingModal: NgbModalRef = this.modalService.open(LoadingModalComponent, {backdrop: 'static', keyboard: false, size: 'sm'});
      if (mes) {
        const siteMessage: UpdateSiteMessagesMutationVariables = {
          accountID: this.messageService.accountID,
          siteMessage: {
            id: item.id,
            userId: String(item.userId),
            submitTimestamp: mes.submitTimestamp,
            status: mes.status,
            level: mes.level,
            sitePlacement: mes.sitePlacement,
            message: mes.message,
            title: mes.title,
            startDate: mes.startDate,
            endDate: mes.endDate
          }
        };
        this.messageService.updateMessage(siteMessage).subscribe(() => {
          this.scanSiteMessages();
          loadingModal.close();
          this.router.navigateByUrl('dashboard/admin');
        });
      }
    });
  }

  public previewMessageEv(arg: SiteMessage): void {
    this.previewMessage = arg;
    this.showPreviewMessage = true;
  }

}

<div class="modal-header">
  <h4 class="modal-title">
    <div class="spinner-border">
      <span class="sr-only"></span>
    </div>
    Preparing Export
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Your file is being prepared for export, this may take a few moments.</p>
</div>

<div class="modal-header">
    <h4 class="modal-title">My Representative</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Contact your local J.J.Kane sale representative for helpful information on purchasing or selling at one of our upcoming sales.</p>
    <div class="mb-1">
        <fa-icon [icon]="faUser" size="md" class="mr-2"></fa-icon>
        Cal Supporterson
    </div>
    <div class="mb-1">
        <fa-icon [icon]="faPhone" size="md" class="mr-2"></fa-icon>
        (555) 555-1234
    </div>
    <div class="mb-1">
        <fa-icon [icon]="faEnvelope" size="md" class="mr-2"></fa-icon>
        cspprt@jjkane.com
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="activeModal.close()">Close</button>
  </div>
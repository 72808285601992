<div class="container-fluid" *ngIf="showForm">
  <form [formGroup]="form">
    <div class="col-12">
      <div class="form-group">
        <label>Category / Subcategory</label>
        <div class="row">
          <div class="col-md-6 col-sm-12 mb-3 mb-md-0">
            <select class="form-control" formControlName="category" [class.loading]="loading">
              <ng-container *ngIf="!loading">
                <option value>Select a category</option>
                <option *ngFor="let category of dropdownValues?.categories" [ngValue]="category.name">{{category.name}}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-md-6 col-sm-12">
            <select class="form-control" formControlName="subcategory" [class.loading]="loading">
              <ng-container *ngIf="!loading">
                <option value>Select a subcategory</option>
                <option *ngFor="let subcategory of filteredSubcategories" [ngValue]="subcategory.name">{{subcategory.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Year / Make / Model</label>
        <div class="row">
          <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
            <select class="form-control" formControlName="year">
              <option value>Select year</option>
              <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
            </select>
          </div>
          <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
            <select class="form-control" formControlName="makeAndModel" [class.loading]="loading">
              <ng-container *ngIf="!loading">
                <option value>Select make</option>
                <option *ngFor="let makeAndModel of dropdownValues?.makeAndModel" [ngValue]="makeAndModel">{{makeAndModel.make}}</option>
              </ng-container>
            </select>
            <input class="form-control" formControlName="make" hidden/>
          </div>
          <div class="col-md-4 col-sm-12">
            <select class="form-control" formControlName="model" [class.loading]="loading">
              <ng-container *ngIf="!loading">
                <option value>Select model</option>
                <option *ngFor="let model of models" [ngValue]="model">{{model}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>Unit Number</label>
        <input class="form-control" formControlName="unitNumber" placeholder="Enter unit number"/>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>Optional Equipment</label>
        <textarea class="form-control" formControlName="optionalEquipment" placeholder="Enter any optional equipment"></textarea>
      </div>
    </div>
  </form>
</div>

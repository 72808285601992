export function CheckFileExist(fileName: string): boolean {
  let http = new XMLHttpRequest();

    http.open('HEAD', fileName, false);
    http.send();

    if(http.status == 404 || http.status == 403){
      return false;
    } else {
      return true;
    }
}

 <div class="modal-header">
  <b>Misc. Fees</b>
  <button type="button" class="close" aria-b="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div *ngIf="itemDetail.fee1Actual > 0"><b>{{itemDetail.sale.fee1Description}}: </b> {{itemDetail.fee1Actual | currency}}</div>
  <div *ngIf="itemDetail.fee1aActual > 0"><b>{{itemDetail.sale.fee1ADescription}}: </b> {{itemDetail.fee1aActual | currency}}</div>
  <div *ngIf="itemDetail.fee2Actual > 0"><b>{{itemDetail.sale.fee2Description}}: </b> {{itemDetail.fee2Actual | currency}}</div>
  <div *ngIf="itemDetail.fee3Actual > 0"><b>{{itemDetail.sale.fee3Description}}: </b> {{itemDetail.fee3Actual | currency}}</div>
  <div *ngIf="itemDetail.fee4Actual > 0"><b>{{itemDetail.sale.fee4Description}}: </b> {{itemDetail.fee4Actual | currency}}</div>
  <div *ngIf="itemDetail.fee5Actual > 0"><b>{{itemDetail.sale.fee5Description}}: </b> {{itemDetail.fee5Actual | currency}}</div>
  <div *ngIf="itemDetail.fee6Actual > 0"><b>{{itemDetail.sale.fee6Description}}: </b> {{itemDetail.fee6Actual | currency}}</div>
  <div *ngIf="itemDetail.fee7Actual > 0"><b>{{itemDetail.sale.fee7Description}}: </b> {{itemDetail.fee7Actual | currency}}</div>
  <div *ngIf="itemDetail.fee8Actual > 0"><b>{{itemDetail.sale.fee8Description}}: </b> {{itemDetail.fee8Actual | currency}}</div>
  <div *ngIf="itemDetail.fee9Actual > 0"><b>{{itemDetail.sale.fee9Description}}: </b> {{itemDetail.fee9Actual | currency}}</div>
  <div class="mt-4"><b>Total: </b> {{itemDetail.fee1Actual + itemDetail.fee1aActual + itemDetail.fee2Actual + itemDetail.fee3Actual +
  itemDetail.fee4Actual + itemDetail.fee5Actual + itemDetail.fee6Actual + itemDetail.fee7Actual + itemDetail.fee8Actual + itemDetail.fee9Actual | currency}}</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn text-light bg-altec" (click)="activeModal.close()">Close</button>
</div>

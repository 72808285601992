<div class="container-fluid">
  <div class="mt-3">
    <h3>Payouts</h3>
  </div>
  <div class="mb-3">
    <searchbar #searchbar [title]="'Payouts'" [placeholder]="'Search Payouts'" [hasCollapsedFilters]="true"
               [sortFilterOptions]="sortFilter" (applyClicked)="applyFilterSortOptions($event)"
               (searched)="search($event)" [searchTipText]="'You can search by Payout ID.'"></searchbar>
  </div>
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="totalElements === 0; else counter">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="no-results">
            <h5><b>Not finding what you're searching for?</b></h5>
            <ul>
              <li>Check your spelling, or try fewer terms and more generic texts.</li>
              <li><a href="https://www.jjkane.com/contact-us/" target="_blank" >Contact Us</a> for more assistance.</li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #counter>
      <div class="font-italic font-weight-light mb-3">
        Showing {{ totalElements > 10 ? (1 + (10 * page)) : 1 }} - {{ payouts.length >= 10  ? (10 + (10 * page)) : totalElements }} of {{totalElements}} Items
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loadingCell>
    <div class="loading-container d-flex justify-content-center">
      <div class="loading-text-field loading my-3 w-50"></div>
    </div>
  </ng-template>

  <mat-table [dataSource]="payouts" multiTemplateDataRows class="mat-elevation-z8 cursor-pointer" *ngIf="loading || totalElements > 0">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Payout ID</mat-header-cell>
      <mat-cell *matCellDef="let payout">
        <span class="mobile-label">Payout ID:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell">
          <a class="mb-1" (click)="clickDetail(payout)" [GA]="{event: {category: 'Payout Select', action: 'Click', label: 'Payouts'}}">{{ payout.id }} </a>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="auctionDate">
      <mat-header-cell *matHeaderCellDef>Auction Date</mat-header-cell>
      <mat-cell *matCellDef="let payout">
        <span class="mobile-label">Auction Date:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell">
          {{payout.sale.date | date: 'LL/d/yyyy'}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="auction">
      <mat-header-cell *matHeaderCellDef>Auction</mat-header-cell>
      <mat-cell *matCellDef="let payout">
        <span class="mobile-label">Auction:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell" class="location-cell">
          {{generateAuctionName(payout.sale)}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="salePriceSubtotal">
      <mat-header-cell *matHeaderCellDef>Total Auction Price</mat-header-cell>
      <mat-cell *matCellDef="let payout">
        <span class="mobile-label">Total Auction Price:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell">
          {{payout.salePriceSubtotal | currency}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sellerNet">
      <mat-header-cell *matHeaderCellDef>Total Seller Net</mat-header-cell>
      <mat-cell *matCellDef="let payout">
        <span class="mobile-label">Total Seller Net:</span>&nbsp;
        <div class="{{ payout.sale.payoutsFinalized  && payout.sellerNet > 0 ? 'finalized' : (payout.sellerNet < 0 && payout.sale.payoutsFinalized ? 'negative-seller-net' : 'not-finalized') }}"
             *ngIf="!loading; else loadingCell">{{ !payout.sale.payoutsFinalized ? 'Pending' : payout.sellerNet | currency}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="trackingInfo">
      <mat-header-cell *matHeaderCellDef>Tracking Info</mat-header-cell>
      <mat-cell *matCellDef="let payout">
        <span class="mobile-label">Tracking Info:</span>&nbsp;
        <div *ngIf="!loading; else loadingCell" class="tracking-info">
          <a *ngIf="generateTrackingLink(payout.upsTrackingNumber); else unknownTrackingNumber"
             [href]="generateTrackingLink(payout.upsTrackingNumber)" target="_blank" [GA]="{event: {category: 'Tracking Info Select', action: 'Click', label: 'Payouts'}}">
            {{payout.upsTrackingNumber}}
          </a>
          <ng-template #unknownTrackingNumber>
            {{payout.upsTrackingNumber}}
          </ng-template>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="more">
      <mat-header-cell *matHeaderCellDef>More</mat-header-cell>
      <mat-cell *matCellDef="let payout" class="more-icon"><fa-icon (click)="googleAnalyticCall()" [icon]="payout === expandedPayout ? faChevronUp : faChevronDown"></fa-icon></mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedPayout">
      <mat-cell *matCellDef="let payout" [attr.colspan]="columnsToDisplay.length">
        <div class="payout-detail container-fluid" [@detailExpand]="payout === expandedPayout ? 'expanded' : 'collapsed'">
          <div class="col-12">
            <div class="decorated"><span class="font-weight-bold">Reports</span></div>
            <div class="row pb-3">
              <div class="col-4 text-center">
                <div class="report-button" [class.downloading]="downloadingSellerTotalReport" *ngIf="!loading; else loadingCell" [GA]="{event: {category: 'Download Seller Total Report', action: 'Click', label: 'Payouts'}}" (click)="showSellerTotalReport(payout.accountId, payout.id)">
                  <fa-icon *ngIf="!downloadingSellerTotalReport; else spinner" [icon]="faMoneyCheckAlt" size="2x"></fa-icon>
                  <div>Seller Total Report</div>
                </div>
              </div>
              <div class="col-4 text-center">
                <div class="report-button" [class.downloading]="downloadingPayoutLetter" *ngIf="!loading; else loadingCell" [GA]="{event: {category: 'Download Payout Letter', action: 'Click', label: 'Payouts'}}" (click)="showPayoutLetter(payout.accountId, payout.id)">
                  <fa-icon *ngIf="!downloadingPayoutLetter; else spinner" [icon]="faChartBar" size="2x"></fa-icon>
                  <div>Payout Letter</div>
                </div>
              </div>
              <div class="col-4 text-center">
                <div class="report-button" [class.downloading]="downloadingPayoutDetailReport" *ngIf="!loading; else loadingCell" [GA]="{event: {category: 'Download Payout Detail Report', action: 'Click', label: 'Payouts'}}" (click)="showPayoutDetailReport(payout.accountId, payout.id)">
                  <fa-icon *ngIf="!downloadingPayoutDetailReport; else spinner" [icon]="faChartPie" size="2x"></fa-icon>
                  <div>Payout Detail Report</div>
                </div>
              </div>
              <ng-template #spinner>
                <div class="spinner-border">
                  <span class="sr-only"></span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let payout; columns: columnsToDisplay;"
        class="payout-row" [class.payout-expanded-row]="expandedPayout === payout"
        (click)="expandedPayout = expandedPayout === payout ? null : payout">
    </mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedPayout']" class="payout-detail-row"></mat-row>
  </mat-table>

  <div class="mt-3">
    <pagination [pageSize]="pageSize" [numberOfItems]="totalElements" [index]="page" (paging)="pageChange($event)"></pagination>
  </div>
</div>

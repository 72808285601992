/* tslint:disable:object-literal-key-quotes */
import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {
  SearchbarComponent,
  SortFilterData,
  SortFilterOutput
} from '../../../Shared/components/searchbar/searchbar.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  FiltersRequestInput,
  ItemService,
  SearchItemVariables
} from '../../services/item.service';
import {ProfileResponse} from '../../../Shared/services/contact.service';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {environment} from '../../../../environments/environment';
import {concat, Observable} from 'rxjs';
import {PaginationComponent} from '../../../Shared/components/pagination/pagination.component';
import {first} from 'rxjs/operators';
import {formatCityState} from '../../../Shared/functions/address-helpers';
import {download} from '../../../Shared/functions/download-helpers';
import {getItemStatus} from '../../../Shared/functions/item-helpers';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExportModalComponent} from '../../../Shared/components/export-modal/export-modal.component';
import * as Papa from 'papaparse';
import {equalsIgnoreOrder} from '../../../Shared/functions/array-helpers';
import {ItemManagmentService} from '../../../Shared/services/item-managment-service';
import { SellerFilterOptionsQuery, BuyerFilterOptionsQuery, Item, GetBuyerItemsExportDataQuery, GetSellerItemsExportDataQuery } from '../../../generated/graphql';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @ViewChild('paginator') public paginationComponent: PaginationComponent;
  @ViewChild('searchbar') public searchbarComponent: SearchbarComponent;

  public appliedSortFilters: SortFilterOutput;
  public searchTerm = '';
  public page = 0;
  public loading = true;
  public users: ProfileResponse;
  public displayItems: Array<ItemData> = [];
  public itemFilters: (SellerFilterOptionsQuery['sellerFilterOptions'] | BuyerFilterOptionsQuery['buyerFilterOptions']);
  public contentSize: number;
  public searchTipText: string;
  private sellerSearchTipText = 'You can search by Unit #, Item ID, Year, Make, Model, Upper Make, Upper Model, Upper Serial #, VIN, Engine, Fuel, Transmission, or Lot #.';
  private buyerSearchTipText = 'You can search by Unit #, Invoice #, Item ID, Year, Make, Model, Upper Make, Upper Model, Upper Serial #, VIN, Engine, Fuel, Transmission, or Lot #.';
  public sortFilter: SortFilterData;
  public buyerSortFilter: SortFilterData = {
    hasFilterOptions: true,
    hasSortOptions: true,
    sortData: [
      {
        title: ItemSorts.AUCTION_DATE,
        backEndTitle: 'saleDate'
      },
      {
        title: ItemSorts.ITEM_TOTAL,
        backEndTitle: 'buyersTotal'
      },
      {
        title: ItemSorts.ITEM_NUMBER,
        backEndTitle: 'itemId'
      }
    ]
    ,
    filterData: [
      {
        title: ItemFilters.AUCTION,
        options: [],
        backEndTitle: 'saleInfo'
      },
      {
        title: ItemFilters.PICK_UP_LOCATION,
        options: [],
        backEndTitle: 'offSitePhysical'
      },
      {
        title: ItemFilters.CATEGORY,
        options: [],
        backEndTitle: 'category'
      },
      {
        title: ItemFilters.MAKE,
        options: [],
        backEndTitle: 'make'
      },
      {
        title: ItemFilters.MODEL,
        options: [],
        backEndTitle: 'model'
      },
      {
        title: ItemFilters.UPPER_MAKE,
        options: [],
        backEndTitle: 'upperMake'
      },
      {
        title: ItemFilters.UPPER_MODEL,
        options: [],
        backEndTitle: 'upperModel'
      }
    ]
  };
  public sellerSortFilter: SortFilterData = {
    hasFilterOptions: true,
    hasSortOptions: true,
    sortData: [
      {
        title: ItemSorts.UNIT_NUMBER,
        backEndTitle: 'unitNumber'
      },
      {
        title: ItemSorts.AUCTION_DATE,
        backEndTitle: 'saleDate'
      }
    ]
    ,
    filterData: [
      {
        title: ItemFilters.STATUS,
        options: ['Submitted', 'Sold'],
        backEndTitle: 'cpStatuses'
      },
      {
        title: ItemFilters.INVENTORY_MANAGEMENT,
        options: ['Not On Site', 'Condition Pending', 'Awaiting Title', 'Auction Ready'],
        backEndTitle: 'inventoryManagementStatuses'
      },
      {
        title: ItemFilters.AUCTION,
        options: [],
        backEndTitle: 'saleInfo'
      },
      {
        title: ItemFilters.LOCATION,
        options: [],
        backEndTitle: 'offSitePhysical'
      },
      {
        title: ItemFilters.CATEGORY,
        options: [],
        backEndTitle: 'category'
      },
      {
        title: ItemFilters.MAKE,
        options: [],
        backEndTitle: 'make'
      },
      {
        title: ItemFilters.MODEL,
        options: [],
        backEndTitle: 'model'
      },
      {
        title: ItemFilters.UPPER_MAKE,
        options: [],
        backEndTitle: 'upperMake'
      }
    ]
  };
  public filtersLoaded = false;
  public loadedFirstTime = false;
  public isBuyerItemSearch: boolean;

  private MAX_ITEM_SEARCH_PAGE_SIZE = 2000;
  private PAGES_PER_REQUEST = 3;

  constructor(private titleService: Title,
              private router: Router,
              private companySelection: CompanySelectionService,
              private itemService: ItemService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private itemManagementService: ItemManagmentService) {
    this.titleService.setTitle('Items - JJKane');
  }

  ngOnInit(): void {
    this.isBuyerItemSearch = this.router.url.indexOf('purchased-items') >= 0;
    if (this.isBuyerItemSearch) {
      this.sortFilter = this.buyerSortFilter;
      this.searchTipText = this.buyerSearchTipText;
    } else {
      this.sortFilter = this.sellerSortFilter;
      this.searchTipText = this.sellerSearchTipText;
    }

    this.getItemFilters();
    this.loading = true;
  }

  public pageChange(page: number, resetDisplay?: boolean): void {
    this.loading = true;
    this.page = page;
    this.search(this.searchTerm, this.page, this.appliedSortFilters, resetDisplay);
    window.scroll(0, 0);
  }

  public applyFilterSortOptions(data: SortFilterOutput): void {
    this.appliedSortFilters = data;
    this.searchTerm = data.searchTerm;
    this.search(this.searchTerm, this.page, data, this.loadedFirstTime);
  }

  public navigate(params: number): void {
    if (this.isBuyerItemSearch) {
      this.router.navigateByUrl('/dashboard/purchased-items/' + params);
    } else {
      this.router.navigateByUrl('/dashboard/items/details/' + params);
    }
  }

  public exportItems(): void {
    const modalRef = this.modalService.open(ExportModalComponent, {backdrop: 'static', keyboard: false});
    const variables = new SearchItemVariables();
    const accountIds = this.companySelection.getSelectedAccountIds();
    const pageSize = this.MAX_ITEM_SEARCH_PAGE_SIZE;
    const pagesPerRequest = this.PAGES_PER_REQUEST;
    variables.searchObject = this.generateSearchObject(this.searchTerm, accountIds, 0, pageSize, this.appliedSortFilters);

    if (this.contentSize) {
      const numberOfPages = Math.ceil(this.contentSize / pageSize);
      const numberOfRequests = Math.ceil(numberOfPages / pagesPerRequest);
      const exportDataRequests = [];

      for (let i = 0; i < numberOfRequests; i++) {
        const startPage = (i * pagesPerRequest) + 1;
        let endPage = (i + 1) * pagesPerRequest;
        if (endPage > numberOfPages) {
          endPage = numberOfPages;
        }

        const clonedVariable = {...variables};
        clonedVariable.exportStartPage = startPage;
        clonedVariable.exportEndPage = endPage;
        exportDataRequests.push(this.itemService.getItemsExportData(clonedVariable, this.isBuyerItemSearch));
      }

      let allItemData;
      if(this.isBuyerItemSearch){
        allItemData = (allItemData as GetBuyerItemsExportDataQuery['exportItems']) = [];
      } else {
        allItemData = (allItemData as GetSellerItemsExportDataQuery['exportItems']) = [];
      }
      concat(...exportDataRequests).subscribe({
        next: data => {
          allItemData = allItemData.concat(data);
        },
        complete: () => {
          const fileName = this.isBuyerItemSearch ? 'purchased-items-export.csv' : 'consigned-items-export.csv';
          const fileType = 'text/csv;charset=utf-8';
          const file = Papa.unparse(allItemData.map(item => this.transformItem(item)));
          download(fileName, fileType, file);
          modalRef.dismiss();
        }
      });
    }
  }

  private transformItem(item: Item): any {
    if (this.isBuyerItemSearch) {
      return {
        'Item Number': item.id,
        'Lot Number': item.lotNumber,
        'Auction Name': item.saleName,
        'Auction Date': new Date(item.saleDate).toLocaleDateString(),
        'Parent Category': item.parentCategory,
        'Category': item.category,
        'Catalog Description': item.catalogDescription,
        'VIN': item.vin,
        'Upper Serial Number': item.upperSerialNumber,
        'Odometer': item.odometer,
        'Hours': item.hours,
        'Titled': item.titled,
        'Buyer Pickup Date': item.buyerPickupDate ?
          new Date(item.buyerPickupDate).toLocaleDateString() : item.buyerPickupDate,
        'Physical City': item.offSitePhysicalCity,
        'Physical State': item.offSitePhysicalState,
        'Physical Zip': item.offSitePhysicalZip,
        'Auction Price': item.salePrice,
        'Item Total': item.buyersTotal
      };
    } else {
      return {
        'Item Number': item.id,
        'Unit Number': item.unitNumber,
        'Category': item.category,
        'VIN': item.vin,
        'Year': item.year,
        'Make': item.make,
        'Model': item.model,
        'Lot Number': item.lotNumber,
        'Auction': item.saleInfo,
        'Type': item.saleType === 'Timed Auction' || item.saleType === 'Public Auction' ? 'Auction' : item.saleType,
        'Auction Date': new Date(item.saleDate).toLocaleDateString(),
        'Auction Location': formatCityState(item.offSitePhysicalCity,  item.offSitePhysicalState),
        'Catalog Description': item.catalogDescription,
        'Status': getItemStatus(item.itemStatus),
        'On Site': this.itemManagementService.isOnSite(item) || this.itemManagementService.isSold(item) ? 'Yes' : 'No',
        'Conditions Documented': this.itemManagementService.isConditionDocumented(item) || this.itemManagementService.isSold(item) ? 'Yes' : 'No',
        'Title Received': this.itemManagementService.isTitleSubmitted(item) ? 'Yes' : 'No',
        'Auction Ready': this.itemManagementService.isAuctionReady(item) || this.itemManagementService.isSold(item) ? 'Yes' : 'No',
        'Seller Net': item.amountOwed,
        'Auction Price': item.salePrice,
        'License Plate Number': item.plateNumber,
        'Engine': item.engine,
        'Fuel': item.fuel,
        'Transmission': item.transmission,
        'Odometer': item.odometer,
        'Hours': item.hours,
        'Conditions': item.conditions,
        'Optional Equipment': item.optionalEquipment,
        'Upper Serial Number': item.upperSerialNumber,
        'Upper Make': item.upperMake,
        'Upper Model': item.upperModel,
        'Upper Fuel': item.upperFuel,
        'Height': item.height,
        'Capacity': item.capacity,
        'Mounted': item.mounted,
        'Upper Optional Equipment': item.upperOptionalEquipment
      };
    }
  }

  public getItemFilters(): void {
    const variables = new FiltersRequestInput();
    variables.accountIds = this.companySelection.getSelectedAccountIds();

    let filterObservable;
    if (this.isBuyerItemSearch) {
      filterObservable = this.itemService.buyerItemFilters(variables);
    } else {
      filterObservable = this.itemService.sellerItemFilters(variables);
    }

    filterObservable.subscribe(filterOptions => {
        this.itemFilters = filterOptions;

        // push the returned filter values into the dropdown filter lists
        const filterNames = Object.keys(this.itemFilters);
        for (const filterName of filterNames) {
          const filter = this.sortFilter.filterData.find(x => x.backEndTitle === filterName);
          if (filter) {
            if (filterName === 'offSitePhysical') {
              filter.options = filterOptions[filterName].map(l => l.name + ', ' + l.description);
            } else {
              filter.options = filterOptions[filterName].map(s => s.name);
            }
          }
        }

        // Have to setTimeout here to push call for document queries until after DOM has updated from above additions
        setTimeout(() => {

          // Wait to loop through params until after 'smart' filters have been loaded in
          // Necessary if a user selected one of these smart filters as the UI would not have anything to select before this point
          this.route.queryParams.pipe(first()).subscribe((queryParams => {
            // Before looping through params and making initial page load, first we have to check to see if the user is still on the same account
            // If the user has swapped accounts the filters will not match up and therefore results will appear incorrect
            const sellerAccountIdStringArray = variables.accountIds.map(x => x.toString());

            // If the user is still on the same account, keep params and make search call with their previous filters/sorts
            // otherwise reset and make a fresh search call
            if (!queryParams.Caller || equalsIgnoreOrder(queryParams.Caller, sellerAccountIdStringArray)) {
              let triggerShowFilterBar = false;
              for (const [param, value] of Object.entries(queryParams)) {

                // Determine if the query param is a filter
                const isFilter = this.sortFilter.filterData.find(el => el.title === param);
                if (isFilter){
                  this.searchbarComponent.selectFilterProgramatically(param, String(value), isFilter.backEndTitle);
                  triggerShowFilterBar = true;
                } else if (param === 'Sort') {
                  const sortData = this.sortFilter.sortData.find(el => el.title === value);
                  this.searchbarComponent.selectSortProgramatically(String(value), sortData.backEndTitle);
                  triggerShowFilterBar = true;
                } else if (param === 'Direction') {
                  this.searchbarComponent.sortDirectionChange(String(value));
                } else if (param === 'Search') {
                  this.searchTerm = String(value);
                  this.searchbarComponent.setSearchText(this.searchTerm);
                } else if (param === 'Page') {
                  this.page = parseInt(String(value), 10);
                }
              }

              if (triggerShowFilterBar) {
                this.searchbarComponent.showCollapsedFilters();
              }
              this.searchbarComponent.apply();

            } else {
              this.searchbarComponent.resetUI();
              this.pageChange(0, true);
            }

            // After loading initial query params, make initial page load
            this.filtersLoaded = true;
          }));

        }, 0);
    });
  }

  public search(searchQuery: string, pageNumber: number, filterResultArray?: SortFilterOutput, resetDisplay?: boolean): void {
    this.page = pageNumber;
    const newQueryParams: Params = {};

    // If the user is searching for a new search term, reset selected page on paginator,
    // Or if optional param calling for resetting is passed in
    if ((searchQuery !== this.searchTerm || resetDisplay) && this.paginationComponent) {
      this.page = 0;
      this.paginationComponent.firstNoEmit();
    }
    this.searchTerm = searchQuery;
    const accountIdArray = this.companySelection.getSelectedAccountIds();
    const variables = this.generateSearchObject(searchQuery, accountIdArray, this.page, 10, filterResultArray);

    // update the URL params
    if (filterResultArray) {
      filterResultArray.filterResults.forEach(filter => {
        newQueryParams[filter.filter] = filter.selection;
      });
      if (filterResultArray.sortResults) {
        newQueryParams.Sort = filterResultArray.sortResults;
      }

      if (filterResultArray.sortDirection) {
        newQueryParams.Direction = filterResultArray.sortDirection;
      }
    }

    newQueryParams.Page = this.page;
    if (this.searchTerm){
      newQueryParams.Search = this.searchTerm;
    }
    newQueryParams.Caller = accountIdArray;

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: newQueryParams
      }
    );
    this.loading = true;
    this.displayItems = [];
    this.itemService.searchItems(variables).subscribe(items => {
      this.contentSize = items.totalElements ? items.totalElements : 0;
      if (items.content) {
        items.content.forEach(item => {
          let itemData: ItemData;
          const date = new Date(item.saleDate);
          let itemIsHolding = false;
          environment.holdingSaleIds.forEach(holdingSaleId => {
            if (holdingSaleId === item.saleId){
              itemIsHolding = true;
            }
          });
          itemData = {
            id: item.id,
            buyerAccountId: item.buyerAccountId,
            imagePath: item.imageCount > 0 ? `${environment.jjkImageUrl}${environment.jjkImageNamePrefix}${item.itemId}-1${environment.jjkWaterMark}` : '',
            itemId: item.itemId,
            category: item.category,
            unitNumber: item.unitNumber ? item.unitNumber : 'None',
            auction: item.saleInfo,
            vin: item.vin,
            year: item.year,
            make: item.make,
            model: item.model,
            lotNumber: item.lotNumber,
            type: item.saleType,
            auctionDate: date.toLocaleDateString(),
            location: formatCityState(item.offSitePhysicalCity,  item.offSitePhysicalState),
            catalogDescription: item.catalogDescription,
            status: getItemStatus(item.itemStatus),
            payoutId: item.sellerPayoutId,
            titleStatus: '',
            amountOwed: item.amountOwed,
            salePrice: item.salePrice,
            titled: item.titled,
            haveTitle: item.haveTitle,
            payoutsFinalized: item.sale?.payoutsFinalized,
            itemIsHolding,
            invoiceId: item.bidderBuyerInvoiceId,
            buyersTotal: item.buyersTotal,
          };
          this.displayItems.push(itemData);
        });
      }
      this.loading = false;
      this.loadedFirstTime = true;
    });
  }

  private generateSearchObject(searchQuery: string, accountIdArray: number[], page: number, pageSize: number, filterResultArray?: SortFilterOutput): SearchItemVariables['searchObject'] {
    filterResultArray = filterResultArray ? filterResultArray : this.appliedSortFilters;

    const searchObject: SearchItemVariables['searchObject'] = {
      sellerAccountIds: this.isBuyerItemSearch ? null : accountIdArray,
      buyerAccountIds: this.isBuyerItemSearch ? accountIdArray : null,
      searchTerm: searchQuery,
      pageSize: pageSize,
      page: (page + 1)
    };

    if (this.isBuyerItemSearch) {
      searchObject.buyerAccountIds = accountIdArray;
    } else {
      searchObject.sellerAccountIds = accountIdArray;
    }

    if (filterResultArray) {
      filterResultArray.filterResults.forEach(filter => {
        searchObject[filter.backEndFilter] = filter.selection;
      });
      if (filterResultArray.sortResults) {
        searchObject.sortField = filterResultArray.backEndSortResult;
      }

      if (filterResultArray.sortDirection) {
        searchObject.sortDirection = filterResultArray.sortDirection;
      }
    }
    return searchObject;
  }
}

export interface ItemData {
  id: number;
  buyerAccountId: number;
  imagePath?: string;
  itemId: string;
  category: string;
  unitNumber: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  lotNumber: string;
  auction: string;
  type: string;
  auctionDate: string;
  location: string;
  catalogDescription: string;
  status: string;
  payoutId: number;
  titleStatus: string;
  auctionPrice?: number;
  titled: boolean;
  haveTitle: boolean;
  payoutsFinalized: boolean;
  itemIsHolding?: boolean;
  amountOwed: number;
  salePrice: number;
  invoiceId: number;
  buyersTotal: number;
}

export enum TitleStatus {
  TITLE_RECEIVED = 'Title Received',
  TITLE_SUBMITTED = 'Title Submitted',
  AWAITING_TITLE = 'Awaiting Title'
}

export enum ItemFilters {
  STATUS = 'Status',
  INVENTORY_MANAGEMENT = 'Inventory Management',
  AUCTION = 'Auction',
  LOCATION = 'Auction Location',
  CATEGORY = 'Category',
  MAKE = 'Make',
  MODEL = 'Model',
  UPPER_MAKE = 'Upper Make',
  PICK_UP_LOCATION = 'Pick Up Location',
  UPPER_MODEL = 'Upper Model'
}

export enum ItemSorts {
  UNIT_NUMBER = 'Unit Number',
  AUCTION_DATE = 'Auction Date',
  ITEM_TOTAL = 'Item Total',
  ITEM_NUMBER = 'Item Number'
}


    <div class="modal-header">
        <a>
            <h5>{{ modalTitle }}</h5>
        </a>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-center">

        <form [formGroup]="newItemForm">
            <div class="row">

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>User ID</b></label>
                        <input class="form-control" formControlName="userId" placeholder="User ID...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Submit Time</b></label>
                        <input class="form-control" formControlName="submitTimestamp" placeholder="Submit Time...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Current Step</b></label>
                        <input class="form-control" formControlName="currentStep" placeholder="Current Step...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Item Type</b></label>
                        <input class="form-control" formControlName="itemType" placeholder="Item Type...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Category</b></label>
                        <input class="form-control" formControlName="category" placeholder="Category...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Unit Number</b></label>
                        <input class="form-control" formControlName="unitNumber" placeholder="Unit Number...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>VIN</b></label>
                        <input class="form-control" formControlName="vin" placeholder="VIN...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Year</b></label>
                        <input class="form-control" formControlName="year" placeholder="Year...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Make</b></label>
                        <input class="form-control" formControlName="make" placeholder="Make...">
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Model</b></label>
                        <input class="form-control" formControlName="model" placeholder="Model...">
                    </div>
                </div>

            </div>

        </form>
    </div>

    <div class="modal-footer text-center">
        <button type="button" class="btn btn-light" (click)="activeModal.close()">Cancel</button>
        <button type="button" class="btn btn-success" (click)="submit()"> {{ submitButtonText }}</button>
    </div>

import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor() { }

  public generateTrackingLink(trackingNumber: string): string {
    const upsPattern = new RegExp(/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/);
    const upsUrl = 'https://wwwapps.ups.com/tracking/tracking.cgi/';

    if (upsPattern.test(trackingNumber)) {
      const params = new HttpParams().set('tracknum', trackingNumber);
      return `${upsUrl}?${params}`;
    }
    return null;
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {NavigationStepData} from '../../models/navigation-step-data';
import { GetFaIcon } from 'src/app/Shared/functions/icon-helpers';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SubmitItemService} from '../../services/submit-item.service';
import {RDSDraftItem} from '../../../item-drafts/services/drafts.service';
import {Subject} from 'rxjs';
import { GetDropdownValuesQuery } from '../../../generated/graphql';

@Component({
  selector: 'app-miscellaneous-item-step-wizard',
  templateUrl: './miscellaneous-item-step-wizard.component.html',
  styleUrls: ['./miscellaneous-item-step-wizard.component.scss']
})
export class MiscellaneousItemStepWizardComponent implements OnInit {

  @Input() public isDraft = false;
  @Input() public draft: RDSDraftItem;
  public ddvSubject: Subject<GetDropdownValuesQuery> = new Subject<GetDropdownValuesQuery>();
  public parentForm: FormGroup;
  public selectedStep = 1;
  public loadingData = true;
  public dropdownValues: GetDropdownValuesQuery;

  public navigationSteps: NavigationStepData[] = [
    {
      title: 'Item Info',
      icon: GetFaIcon('list', 'fas')
    },
    {
      title: 'Location',
      icon: GetFaIcon('map-marker-alt', 'fas')
    },
    {
      title: 'Pictures',
      icon: GetFaIcon('upload', 'fas')
    },
    {
      title: 'Confirmation',
      icon: GetFaIcon('check', 'fas')
    }
  ];

  constructor(private fb: FormBuilder, private submitItemService: SubmitItemService) {
    this.parentForm = this.fb.group({
    });
  }

  ngOnInit(): void {
    if (this.draft === null){
      this.setStep(this.selectedStep);
    } else {
      this.setStep(this.draft.currentStep);
    }
    this.submitItemService.getDropdownValues().subscribe(response => {
      if (response) {
        this.dropdownValues = response;
        this.ddvSubject.next(response);
        this.loadingData = false;
      }
    });
    // todo: retrieve data from a saved form and pass them to the relevant form components
  }

  public setStep(step: number): void {
    this.selectedStep = step;
  }
}

import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const XS_MOBILE_BREAKPOINT = 400;
export const MD_MOBILE_BREAKPOINT = 550;
export const MOBILE_BREAKPOINT = 767;
export const TABLET_BREAKPOINT = 991;
export const DEKSTOP_BREAKPOINT = 1300;


@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {

  public resizeTimeout: any;

  public screenSize: ScreenType = ScreenType.Desktop;
  public screenData: ScreenData = {
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    screenType: this.screenSize
  }
  public $screenSize: BehaviorSubject<ScreenData> = new BehaviorSubject(this.screenData);

  constructor() {

    // Add Listener for window resize
    window.addEventListener('resize', (event) => {

      // Only call resize function if resize event hasnt been triggered in 100ms
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.setScreenSize();
      }).bind(this), 100);

    });

    // Check screen size on first load
    this.setScreenSize();
  }

  ngOnDestroy() {
    this.$screenSize.complete();
  }

  public setScreenSize() {
    // Emit screen size to all subscribers and set for anyone who just wants the current value
    this.screenData.screenHeight = window.screen.height;
    this.screenData.screenWidth = window.screen.width;

    if(window.screen.width <= MOBILE_BREAKPOINT){

      this.screenSize = ScreenType.Mobile;
      this.screenData.screenType = ScreenType.Mobile;
      this.$screenSize.next(this.screenData);

    } else if (window.screen.width <= TABLET_BREAKPOINT) {

      this.screenSize = ScreenType.Tablet;
      this.screenData.screenType = ScreenType.Tablet;
      this.$screenSize.next(this.screenData);

    } else {

      this.screenSize = ScreenType.Desktop;
      this.screenData.screenType = ScreenType.Desktop;
      this.$screenSize.next(this.screenData);

    }
  }
}

export interface ScreenData {
  screenType: ScreenType;
  screenWidth: number;
  screenHeight: number;
}

export enum ScreenType {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile'
}

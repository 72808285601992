import { Directive, Input, HostListener } from '@angular/core';
import { GoogleAnalyticService, GoogleAnalyticObject, GoogleAnalyticEventObject } from '../services/google-analytic.service';
import { Router, RouterLink, Params } from '@angular/router';

@Directive({
  selector: '[GA]'
})
export class GoogleAnalyticDirective {

  @Input() GA: GoogleAnalyticObject;

  constructor(private googleAnalyticService: GoogleAnalyticService, private router: Router) { }

  @HostListener('click')
  onclick(): void {
    if (this.GA) {
      this.googleAnalyticService.logAnalyticEvent(this.GA.event, () => {
        if(this.GA.route){
          if(this.GA.queryParams){
            this.router.navigate(this.GA.route, {queryParams: this.GA.queryParams});
          } else {
            this.router.navigate(this.GA.route);
          }
        }
      });
    }
  }

}

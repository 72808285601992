import { Component, OnInit } from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {environment} from "../../../../environments/environment";
import {take} from "rxjs/operators";
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-graphql',
  templateUrl: './graphql.component.html',
  styleUrls: ['./graphql.component.scss']
})
export class GraphqlComponent implements OnInit {
  public accessToken: String;
  public httpHeaders: String;
  public idToken: String;
  public graphQlUrl: String = environment.graphQLConfig.apiUrl;

  constructor(authService: AuthService) {
    authService.getAccessTokenSilently().pipe(take(1))
      .subscribe(token => {
        this.httpHeaders = JSON.stringify({
            'Authorization': `Bearer ${token}`
          }, null, 4
        );

        this.accessToken = JSON.stringify(jwt_decode(token), null, 4);
      });

    authService.user$.pipe(take(1)).subscribe(user => {
      this.idToken = JSON.stringify(user, null, 4);
    });
  }

  ngOnInit(): void {
  }

}

import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import { Item } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})

export class ItemManagmentService{
  constructor(private datePipe: DatePipe){
  }

  public isSubmitted(item: Item): boolean {
    return item.itemStatus !== 'Pulled';
  }

  public isOnSite(item: Item): boolean {
    return !!item.arrivalDate;
  }

  public isConditionDocumented(item: Item): boolean {
    return !!item.conditions;
  }

  public isTitleSubmitted(item: Item): boolean {
    return !item.titled || item.titled && item.haveTitle;
  }  

  public isAuctionReady(item: Item): boolean {
      return item.itemStatus === 'Active' && this.isOnSite(item) && this.isConditionDocumented(item) && this.isTitleSubmitted(item);
  }

  public isSold(item: Item): boolean {
    return item.itemStatus === 'Sold';
  }

  public isPayoutFinalized(item: Item): boolean {
    return !!(this.isSold(item) && item.sale?.payoutsFinalized);
  }

  public titleMissingMessage(item: Item): string {
    if (item.titled && !item.haveTitle && item.itemStatus === 'Pending'
      && item.arrivalDate && item.conditions) {
      return this.generateTitleMissingMessage(item, Date.now());
    } else if (item.titled && item.haveTitle && item.titleReceivedDate
      && item.inspectionDate && item.arrivalDate) {
      // this is a titled item and all 3 relevant dates are present to determine the missing title timeframe
      if (item.titleReceivedDate > item.inspectionDate
        && item.titleReceivedDate > item.arrivalDate) {
        // the title was received after it arrived and was inspected
        return this.generateTitleMissingMessage(item, item.titleReceivedDate);
      }
    }
    return '';
  }

  private generateTitleMissingMessage(item: Item, endDate: number): string {
    let titleMissingStartDate = item.createDate;

    if (item.arrivalDate && item.arrivalDate > titleMissingStartDate) {
      titleMissingStartDate = item.arrivalDate;
    }

    if (item.inspectionDate && item.inspectionDate > titleMissingStartDate) {
      titleMissingStartDate = item.inspectionDate;
    }

    const formattedStartDate = this.datePipe.transform(titleMissingStartDate, 'MM/dd/yyyy');
    const formattedEndDate = this.datePipe.transform(endDate, 'MM/dd/yyyy');
    return `Title Missing: ${formattedStartDate} - ${formattedEndDate}`;
  }
}

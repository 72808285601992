<nav class="navbar fixed-top navbar-dark top-nav">

  <!-- Mobile Hamburger Menu -->
  <fa-icon *ngIf="isMobile" [icon]="faBars" size="lg" class="ml-1 mr-3 text-light collapse-button pull-left"
           (click)="toggleExpand(); mobileProfileCollapsed = true; mobileCompanyCollapsed = true;"></fa-icon>

  <div>

    <!-- Logo -->
    <a class="navbar-brand mr-0 py-0 pull-left">
      <img src="assets/JJKaneLogoBlack.png" width="auto">
    </a>

    <!-- Desktop Company Select -->
    <div *ngIf="!isMobile && !liteVersion && companyService.showCompanyDropdown" ngbDropdown display="dynamic"
         class="d-inline-block pull-left">
      <div *ngIf="companies.length === 1;else companySelectDropdown">
        <h3 class="btn text-light">{{ selectedCompany?.name }}</h3>
      </div>
      <ng-template #companySelectDropdown>
        <button class="btn text-light btn-secondary" id="companyDropdown" ngbDropdownToggle>
          <fa-icon [icon]="faBuilding" size="lg" class="mr-2 text-light collapse-button">
          </fa-icon>
          {{ selectedCompany?.name }}
        </button>
        <div ngbDropdownMenu aria-labelledby="companyDropdown">
          <button class="btn" ngbDropdownItem *ngFor="let company of companies" (click)="swapCompany(company)">{{ company?.name }}</button>
        </div>
      </ng-template>
    </div>

  </div>

  <!-- Desktop Profile Bar -->
  <ng-container *ngIf="!liteVersion && $user | async as user">

    <div class="d-flex justify-content-between" *ngIf="!isMobile">
      <ul class="navbar-nav">

        <li class="nav-item">

          <div ngbDropdown #myDrop="ngbDropdown" display="dynamic" placement="bottom-right"
               class="d-inline-block pull-right">
            <button class="btn text-light btn-secondary" id="profileDropdown" ngbDropdownToggle>
              <fa-icon [icon]="faUser" size="lg" class="mr-2 text-light collapse-button">
              </fa-icon>
              <span class="text-capitalize">{{ user.firstName }} {{ user.lastName }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="profileDropdown" style="width: 40vw;max-width:400px;">
              <div class="row px-2 py-2 d-flex align-items-center">
                <div class="col-6">
                  <div class="mb-2"><b>{{ user.firstName }} {{ user.lastName }}</b></div>
                  <div class="mb-2">{{ selectedCompany.name ? selectedCompany.name : 'N/A' }}</div>
                  <div>{{ user.email }}</div>
                </div>
                <div class="col-6">
                  <button [GA]="{event: {category: 'My Profile Select', action: 'Click', label: 'Site'}}"
                          (click)="profileAction('viewProfile'); myDrop.close();"
                          class="w-100 btn text-light bg-altec mb-2">My Profile
                  </button>
                  <button (click)="profileAction('logout')" class="w-100 btn btn-light">Log Out</button>
                </div>
              </div>
            </div>
          </div>

        </li>
      </ul>
    </div>

    <div *ngIf="isMobile">
      <!-- Mobile Company Bar -->
      <fa-icon *ngIf="companyService.showCompanyDropdown" [icon]="faBuilding" size="lg"
               class="text-light collapse-button"
               (click)="mobileCompanyCollapsed = !mobileCompanyCollapsed;"></fa-icon>
      <!-- Mobile Profile Bar -->
      <fa-icon [icon]="faUser" size="lg" class="text-light collapse-button ml-4 mr-1"
               (click)="mobileProfileCollapsed = !mobileProfileCollapsed;"></fa-icon>
    </div>


    <!-- Mobile Profile Subnav -->
    <div *ngIf="isMobile" class="navbar-collapse" [class.collapse]="mobileProfileCollapsed" id="navbarContent">
      <div class="row px-2 py-2 d-flex align-items-center">
        <div class="col-6">
          <div class="mb-2 text-light"><b>{{ user.firstName }} {{ user.lastName }}</b></div>
          <div class="mb-2 text-light">{{ selectedCompany.name ? selectedCompany.name : 'N/A' }} </div>
          <div class="text-light">{{ user.email }}</div>
        </div>
        <div class="col-6">
          <button (click)="profileAction('viewProfile'); mobileProfileCollapsed = true;"
                  class="w-100 btn text-light bg-altec mb-2">My Profile
          </button>
          <button (click)="profileAction('logout')" class="w-100 btn btn-light">Log Out</button>
        </div>
      </div>
    </div>

    <!-- Mobile Company Subnav -->
    <div *ngIf="isMobile" class="navbar-collapse" [class.collapse]="mobileCompanyCollapsed" id="navbarContent">
      <div class="row px-2 py-2 d-flex align-items-center">
        <button
          class="btn text-light w-100"
          [class.mobile-company-selected]="selectedCompany?.name === company?.name"
          *ngFor="let company of companies"
          (click)="swapCompany(company); mobileCompanyCollapsed = !mobileCompanyCollapsed;">{{ company?.name }}</button>
      </div>
    </div>

  </ng-container>
</nav>

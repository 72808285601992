import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { GetFaIcon } from '../../functions/icon-helpers';
import { AuthUserService } from '../../services/auth-user.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { ContactService } from '../../services/contact.service';
import { CompanySelectionService } from '../../../Shared/services/company-selection.service';


@Component({
    selector: 'side-navbar',
    templateUrl: './side-navbar.component.html',
    styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit, OnDestroy {
    public faHome = GetFaIcon('home', 'fas');
    public faTags = GetFaIcon('tags', 'fas');
    public faListUl = GetFaIcon('list-ul', 'fas');
    public faPenUl = GetFaIcon('pencil-alt', 'fas');
    public faGavel = GetFaIcon('gavel', 'fas');
    public faMoneyCheckAlt = GetFaIcon('money-check-alt', 'fas');
    public faTimes = GetFaIcon('times', 'fas');
    public faAngleRight = GetFaIcon('angle-right', 'fas');
    public faAngleDown = GetFaIcon('angle-down', 'fas');
    public faPhone = GetFaIcon('phone', 'fas');
    public faPaperPlane = GetFaIcon('paper-plane', 'fas');
    public faToolbox = GetFaIcon('toolbox', 'fas');
    public faUsersCog = GetFaIcon('users-cog', 'fas');
    public faCreditCard = GetFaIcon('credit-card', 'fas');
    public faShoppingCart = GetFaIcon('shopping-cart', 'fas');
    public faReceipt = GetFaIcon('receipt', 'fas');
    public faQuestionCircle = GetFaIcon('question-circle', 'fas');
    public faCashRegister = GetFaIcon('cash-register', 'fas');
    public sellingExpanded = false;
    public buyingExpanded = false;
    public devToolsEnabled: boolean = environment.devToolsEnabled;
    public userSub: Subscription;
    public userIsAdmin: boolean = false;
    public sellingTabSubRoutes: Array<string> = ['/dashboard/submit-items', '/dashboard/item-drafts', '/dashboard/items', '/dashboard/payouts'];
    public buyingTabSubRoutes: Array<string> = ['/dashboard/purchased-items', '/dashboard/invoices'];
    public loadingSeller: boolean = true;
    public showSeller: boolean = false;
    public showSellerSub: Subscription;

    @Input()
    isMobile: boolean;

    @Input()
    liteVersion: boolean;

    @Output()
    close: EventEmitter<null> = new EventEmitter();


  constructor(private auth: AuthUserService, private router: Router, private contactService: ContactService, private companySelection: CompanySelectionService) {
        this.userSub = this.auth.$currentUser.subscribe(user => {
            if(user){
                this.userIsAdmin = user.admin;
            }
        });

        // Expands Selling subnav if user directly goes to link inside
        this.router.events.subscribe( event => {
            if (event instanceof NavigationEnd){
              let url = event.url;
              if (url.indexOf('?') !== -1) {
                  url = url.substring(0, url.indexOf('?'));
              }
              if (this.sellingTabSubRoutes.includes(url) && !this.sellingExpanded){
                this.expandSelling(true);
              }
              if (this.buyingTabSubRoutes.includes(url) && !this.buyingExpanded){
                this.expandBuying(true);
              }
            }
          });
    }

    ngOnInit(): void {
      this.showSellerSub = this.companySelection.$showSeller.subscribe(showSeller => this.showSeller = this.companySelection.showSeller.getValue());
        if(!this.liteVersion){
          this.loadingSeller = false;
        }
    }

    ngOnDestroy(): void {
        this.userSub.unsubscribe();
        this.showSellerSub.unsubscribe();
    }

    public closeSideNav(): void {
        this.close.emit();
    }

  public expandBuying(val: boolean): void {
    this.buyingExpanded = val;
  }

  public expandSelling(val: boolean): void {
    this.sellingExpanded = val;
  }

}

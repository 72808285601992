import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import { AuthResponseData, AuthUserService } from '../../../Shared/services/auth-user.service';
import { GetFaIcon } from '../../../../../src/app/Shared/functions/icon-helpers';
import { ContactService, ContactInfo } from '../../../Shared/services/contact.service';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import { Profile } from '../../../generated/graphql';
import { AccountService } from '../../services/account.service';
import { Router } from '@angular/router';

enum ProfileEditingValues {
  PersonalName = 'pName',
  PersonalEmail = 'pEmail',
  PersonalPhone = 'pPhone',
  PersonalAddress = 'pAddress',
  PersonalCity = 'pCity',
  PersonalState = 'pState',
  PersonalZip = 'pZip',
  CompanyName = 'cName',
  CompanyPhone = 'cPhone',
  CompanyAddress = 'cAddress',
  CompanyCity = 'cCity',
  CompanyState = 'cState',
  CompanyZip = 'cZip',
}

@Component({
  selector: 'account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  public faUser = GetFaIcon('user', 'fas');
  public faBuilding = GetFaIcon('building', 'fas');
  public faCircle = GetFaIcon('circle', 'fas');
  public faEdit = GetFaIcon('edit', 'fas');
  public faCheck = GetFaIcon('check', 'fas');

  public user: Profile;
  public authUser: AuthResponseData;
  public accountLoaded: boolean = false;
  public pwRequested: boolean = false;
  private $selectedCompanySubscription: Subscription;

  constructor(private userAuth: AuthUserService,
              private contactService: ContactService,
              private titleService: Title,
              private companySelection: CompanySelectionService,
              private accountService: AccountService,
              private router: Router) {
    this.titleService.setTitle('Account - JJKane');
  }

  ngOnInit(): void {
    this.$selectedCompanySubscription = this.companySelection.$selectedCompany.subscribe(() => {
      this.accountLoaded = false;
      this.contactService.getContactInfo().subscribe(contactInfo => {
        this.user = contactInfo;
        this.accountLoaded = true;
      });
    });

    this.userAuth.$currentUser.pipe(take(1)).subscribe(res => {
      this.authUser = res;
    });
  }

  ngOnDestroy(): void {
    this.$selectedCompanySubscription.unsubscribe();
  }

  public changePassword(): void {
    this.pwRequested = true;
    this.accountService.changePassword(this.authUser.email).subscribe(response => {
      window.location.href = response.url;
      this.pwRequested = false;
    });
  }

  public changeCompanyView(): void {
  }

}



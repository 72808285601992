import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

import {map} from 'rxjs/operators';
import { JjkIntegration, SearchInvoicesQuery, InvoiceSearchRequest, InvoiceSaleLocationsQuery } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient, private jjkIntegration: JjkIntegration) {}

  public searchInvoices(invoiceSearchRequest: InvoiceSearchRequest): Observable<SearchInvoicesQuery['searchInvoices']> {
    return this.jjkIntegration.searchInvoices({searchObject: invoiceSearchRequest})
      .pipe(map(response => response.data.searchInvoices));
  }

  public downloadInvoicePdf(accountId: string | number, invoiceId: string | number): Observable<any> {
    let url = environment.graphQLConfig.downloadInvoiceReportUrl;
    url = url.replace(':accountId', accountId.toString());
    url = url.replace(':invoiceId', invoiceId.toString());
    return this.http.get(url, {responseType: 'arraybuffer'});
  }

  public getInvoiceSaleLocations(accountIds: number[]): Observable<InvoiceSaleLocationsQuery['invoiceSaleLocations']> {
    return this.jjkIntegration.invoiceSaleLocations({accountIds})
      .pipe(map(response => response.data.invoiceSaleLocations));
  }
}

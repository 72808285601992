import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Success } from '../../Shared/services/common.service';
import { GetBusinessCodesQuery, JjkIntegration, GeocodeQuery, Registration } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private jjkIntegration: JjkIntegration) { }

  public register(registrationObject: Registration): Observable<boolean> {
    return this.jjkIntegration.register({registrationObject}).pipe(map(response => {
      return response.data.register.success;
    }));
  }

  public getBusinessCodes(): Observable<GetBusinessCodesQuery['businessCodes']> {
    return this.jjkIntegration.getBusinessCodes().pipe(map(response => {
      return response.data.businessCodes;
    }));
  }

  public activateUser(token: string): Observable<boolean> {
    return this.jjkIntegration.activateUser({token}).pipe(map(response => {
      return response.data.activateUser.success;
    }));
  }

  public geocode(zip: string): Observable<GeocodeQuery['geocode']> {
    return this.jjkIntegration.geocode({zip}).pipe(map(response => {
      return response.data.geocode;
    }));
  }
}

<div class="modal-header">
  <h4 class="modal-title">{{modalContent.title}}</h4>
  <button *ngIf="modalContent.closeButton" type="button" class="close" aria-label="Close" (click)="activeModal.close(modalContent)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{modalContent.message}}</p>
</div>
<div class="modal-footer" *ngIf="modalContent.showButtons">
  <button *ngIf="modalContent.cancelButton" type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <span *ngFor="let button of modalContent.buttonsArray; let i = index">
    <button type="button" class="btn {{button.class}}" (click)="activeModal.close(button)">{{button.name}}</button>
  </span>
</div>
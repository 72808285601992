<div class="wrapper">
    <div class="my-3 wrapper">
        <div class="col-12 d-flex justify-content-start">
            <span class="h2 text-center">Forgot Password</span>
        </div>

        <div class="container mt-4" *ngIf="!success">
            <form [formGroup]="form">
                <div class="row d-flex justify-content-center">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Please enter your username and we'll email you a temporary password.</label>
                            <div class="row mb-2">
                                <div class="col-12">
                                    <input class="form-control" formControlName="username" placeholder="Username" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 my-3 btn-section">
                        <button class="btn btn-light px-4">
                            Cancel
                        </button>
                        <button class="btn text-light bg-altec px-4 float-right" (click)="submit()">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="container mt-4" *ngIf="success">
            <div class="row d-flex justify-content-center">
                <div class="col-12 text-center">
                    <h3>Thank You</h3>
                    <p>If you have a user on file, an email will be sent to you with instructions on how to proceed.</p>
                </div>
            </div>
        </div>
    </div>
</div>
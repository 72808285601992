import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mobile-item-model',
  templateUrl: './mobile-item-model.component.html',
  styleUrls: ['./mobile-item-model.component.scss']
})
export class MobileItemModelComponent implements OnInit {

  @Input() show: string;
  @Input() unitNumber: string;
  @Input() itemId: string;
  @Input() imageSrc: string;
  @Input() description: string;

  constructor(private router: Router, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  public navigate(){
    this.activeModal.close();
    this.router.navigateByUrl('/dashboard/items/details/' + this.itemId);
  }

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {
  SearchbarComponent,
  SortFilterData,
  SortFilterOutput
} from '../../../Shared/components/searchbar/searchbar.component';
import {Auction, Payout} from '../../../Shared/models/auctions';
import {GetFaIcon} from '../../../Shared/functions/icon-helpers';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PayoutService} from '../../services/payout.service';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {Subscription} from 'rxjs';
import {download} from '../../../Shared/functions/download-helpers';
import {first} from 'rxjs/operators';
import {equalsIgnoreOrder} from '../../../Shared/functions/array-helpers';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';
import {FilterItem} from '../../../Items/services/item.service';
import {AuctionService} from '../../../Shared/services/auction.service';
import {TrackingService} from '../../../Shared/services/tracking.service';
import { GetPayoutFiltersQuery, PayoutSearchRequest, SearchPayoutsQuery } from '../../../generated/graphql';

@Component({
  selector: 'app-payouts',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PayoutComponent implements OnInit, OnDestroy {
  @ViewChild('searchbar', {static: true})
  public searchbarComponent: SearchbarComponent;

  public page = 0;
  public pageSize = 10;
  public loading = true;
  public payouts: SearchPayoutsQuery['searchPayouts']['content'];
  public faChevronUp = GetFaIcon('chevron-up', 'fas');
  public faChevronDown = GetFaIcon('chevron-down', 'fas');
  public faMoneyCheckAlt = GetFaIcon('money-check-alt', 'fas');
  public faChartBar = GetFaIcon('chart-bar', 'fas');
  public faChartPie = GetFaIcon('chart-pie', 'fas');
  public columnsToDisplay = ['id', 'auctionDate', 'auction', 'salePriceSubtotal', 'sellerNet', 'trackingInfo', 'more'];
  public expandedPayout: Payout;
  public payoutId: string;
  public totalElements = 0;
  public selectedCompanySubscription: Subscription;
  public downloadingPayoutDetailReport: boolean;
  public downloadingPayoutLetter: boolean;
  public downloadingSellerTotalReport: boolean;
  public appliedSortFilters: SortFilterOutput;

  public sortFilter: SortFilterData = {
    hasFilterOptions: true,
    hasSortOptions: true,
    sortData: [
      {
        title: 'Payout ID',
        backEndTitle: 'sellerPayoutId'
      },
      {
        title: 'Auction Date',
        backEndTitle: 'sale.saleDate'
      },
      {
        title: 'Auction Price',
        backEndTitle: 'salePriceSubtotal'
      },
      {
        title: 'Seller Net',
        backEndTitle: 'sellerNet'
      }
    ],
    filterData: [
      {
        title: 'Auction Date',
        options: ['This Month', 'This Year', 'Custom'],
        backEndTitle: 'auctionDate'
      },
      {
        title: 'Auction',
        options: [],
        backEndTitle: 'saleId'
      },
    ],
    rangeFilterData: [
      {
        title: 'Auction Price',
        minFieldName: 'salePriceSubtotalMin',
        maxFieldName: 'salePriceSubtotalMax'
      },
      {
        title: 'Seller Net',
        minFieldName: 'sellerNetMin',
        maxFieldName: 'sellerNetMax'
      }
    ]
  };

  private skeletonData: any = [
    {}, {}, {}, {}, {}, {}, {}, {}, {}
  ];
  private auctionFilterOptions: GetPayoutFiltersQuery['payoutFilters'];

  constructor(private titleService: Title,
              private route: ActivatedRoute,
              private payoutService: PayoutService,
              private companySelection: CompanySelectionService,
              private router: Router,
              private googleAnalyticService: GoogleAnalyticService,
              private auctionService: AuctionService,
              private trackingService: TrackingService) {
    this.titleService.setTitle('Payouts - JJKane');
  }

  ngOnInit(): void {
    this.payouts = this.skeletonData;
    this.payoutService.getPayoutFilters(this.companySelection.getSelectedAccountIds()).subscribe(filters => {
      this.auctionFilterOptions = filters;
      this.sortFilter.filterData[1].options = filters.sale.map(x => x.name);

      this.selectedCompanySubscription = this.companySelection.$selectedCompany.subscribe(() => {
        this.route.queryParams.pipe(first()).subscribe(queryParams => {
          if (queryParams.auctionDate) {
            // add the custom option before the timeout is set up
            const filter = this.sortFilter.filterData.find(x => x.backEndTitle === 'auctionDate');
            if (filter.options.findIndex(x => x === queryParams.auctionDate) < 0) {
              // add the custom date to the list
              filter.options.splice(-1, 0, queryParams.auctionDate);
            }
          }
          setTimeout(() => {
            const urlAccountIds = queryParams.accountId;
            const selectedAccountIds = this.companySelection.getSelectedAccountIds().map(x => x.toString());
            if (!equalsIgnoreOrder(urlAccountIds, selectedAccountIds)) {
              // the specified account ids do not match the currently selected ones, perform a new search
              this.resetSearch();
              return;
            }

            let triggerShowFilterBar = false;
            this.page = +queryParams.page ?? 0;
            if (queryParams.payoutId) {
              this.payoutId = queryParams.payoutId;
              this.searchbarComponent.setSearchText(this.payoutId);
            }
            if (queryParams.auction) {
              this.searchbarComponent.selectFilterProgramatically('Auction', queryParams.auction, 'saleId');
              triggerShowFilterBar = true;
            }
            if (queryParams.auctionDate) {
              this.searchbarComponent.selectFilterProgramatically('Auction Date', queryParams.auctionDate, 'auctionDate');
              triggerShowFilterBar = true;
            }
            if (queryParams.sellerNetMin && queryParams.sellerNetMax) {
              const filter = this.sortFilter.rangeFilterData.find(x => x.minFieldName === 'sellerNetMin');
              filter.min = +queryParams.sellerNetMin;
              filter.max = +queryParams.sellerNetMax;
              triggerShowFilterBar = true;
            }
            if (queryParams.salePriceSubtotalMin && queryParams.salePriceSubtotalMax) {
              const filter = this.sortFilter.rangeFilterData.find(x => x.minFieldName === 'salePriceSubtotalMin');
              filter.min = +queryParams.salePriceSubtotalMin;
              filter.max = +queryParams.salePriceSubtotalMax;
              triggerShowFilterBar = true;
            }
            if (queryParams.sortField) {
              const sortData = this.sortFilter.sortData.find(el => el.backEndTitle === queryParams.sortField);
              this.searchbarComponent.selectSortProgramatically(sortData.title, sortData.backEndTitle);
              triggerShowFilterBar = true;
            }
            if (queryParams.sortDirection) {
              this.searchbarComponent.sortDirectionChange(queryParams.sortDirection);
            }
            if (triggerShowFilterBar) {
              this.searchbarComponent.showCollapsedFilters();
            }
            this.appliedSortFilters = this.searchbarComponent.generateSortFilterOutput();
            this.loading = true;
            this.pageChange(this.page);
          }, 0);
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.selectedCompanySubscription?.unsubscribe();
  }

  public resetSearch(): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {}
      }
    );
    this.searchbarComponent.resetUI();
    this.totalElements = 0;
    this.pageChange(0);
  }

  public applyFilterSortOptions(data: SortFilterOutput): void {
    this.appliedSortFilters = data;
    this.search(data.searchTerm);
  }

  public search(searchQuery: string): void {
    this.payoutId = searchQuery;
    this.pageChange(0);
  }
public googleAnalyticCall(): void {
    if (!this.expandedPayout){
      const event: GoogleAnalyticEventObject = {
        category: 'Expand Card',
        action: 'Click',
        label: 'Payouts'
      };
      this.googleAnalyticService.logAnalyticEvent(event, () => {});
    }
}

  public pageChange(page: number): void {
    this.loading = true;
    this.expandedPayout = null;
    this.payouts = this.skeletonData;
    this.page = page ?? 0;
    window.scroll(0, 0);

    var payoutSearchRequest: PayoutSearchRequest = {};
    payoutSearchRequest.sellerAccountIds = this.companySelection.getSelectedAccountIds();
    payoutSearchRequest.page = this.page + 1;
    payoutSearchRequest.pageSize = this.pageSize;
    payoutSearchRequest.sellerPayoutId = +this.payoutId;
    let auction;
    let auctionDate;
    if (this.appliedSortFilters) {
      if (this.appliedSortFilters.sortResults && this.appliedSortFilters.sortDirection) {
        payoutSearchRequest.sortField = this.appliedSortFilters.backEndSortResult;
        payoutSearchRequest.sortDirection = this.appliedSortFilters.sortDirection;
      }

      this.appliedSortFilters.filterResults?.forEach(filter => {
        if (filter.backEndFilter === 'saleId') {
          // we must send in the description, not the name
          auction = filter.selection;
          const selection = this.auctionFilterOptions.sale.find(x => x.name === filter.selection).description;
          payoutSearchRequest[filter.backEndFilter] = selection;
        } else if (filter.backEndFilter === 'auctionDate') {
          auctionDate = filter.selection;
          const now = new Date();
          if (filter.selection === 'This Month') {
            payoutSearchRequest.auctionDateStart = `${now.getFullYear()}-${now.getMonth() + 1}-1 00:00:00`;
            payoutSearchRequest.auctionDateEnd = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 23:59:59`;
          } else if (filter.selection === 'This Year') {
            payoutSearchRequest.auctionDateStart = `${now.getFullYear()}-1-1 00:00:00`;
            payoutSearchRequest.auctionDateEnd = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 23:59:59`;
          } else {
            // custom
            const dates = filter.selection.split(' - ');
            const startDate = new Date(dates[0]);
            const enddate = new Date(dates[1]);
            dates[0] = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
            dates[1] = `${enddate.getFullYear()}-${enddate.getMonth() + 1}-${enddate.getDate()}`;
            payoutSearchRequest.auctionDateStart = `${dates[0]} 00:00:00`;
            payoutSearchRequest.auctionDateEnd = `${dates[1]} 23:59:59`;
          }
        } else {
          payoutSearchRequest[filter.backEndFilter] = filter.selection;
        }

      });

      this.appliedSortFilters.rangeFilterResults?.forEach(filter => {
        if ((filter.min || filter.min === 0)  && (filter.max || filter.max === 0)) {
          if(filter.minFieldName === 'sellerNetMin') {
            payoutSearchRequest.payoutFinalized = true;
          }
          payoutSearchRequest[filter.minFieldName] = filter.min;
          payoutSearchRequest[filter.maxFieldName] = filter.max;
        }
      });
    }

    const newQueryParams: Params = {};
    newQueryParams.page = this.page;
    if (payoutSearchRequest.sellerPayoutId){
      newQueryParams.payoutId = payoutSearchRequest.sellerPayoutId;
    }
    if (auction) {
      newQueryParams.auction = auction;
    }
    if (auctionDate) {
      newQueryParams.auctionDate = auctionDate;
    }
    if (payoutSearchRequest.sellerNetMin && payoutSearchRequest.sellerNetMax) {
      newQueryParams.sellerNetMin = payoutSearchRequest.sellerNetMin;
      newQueryParams.sellerNetMax = payoutSearchRequest.sellerNetMax;
    }
    if (payoutSearchRequest.salePriceSubtotalMin && payoutSearchRequest.salePriceSubtotalMax) {
      newQueryParams.salePriceSubtotalMin = payoutSearchRequest.salePriceSubtotalMin;
      newQueryParams.salePriceSubtotalMax = payoutSearchRequest.salePriceSubtotalMax;
    }
    if (payoutSearchRequest.sortField) {
      newQueryParams.sortField = payoutSearchRequest.sortField;
    }
    if (payoutSearchRequest.sortDirection) {
      newQueryParams.sortDirection = payoutSearchRequest.sortDirection;
    }
    newQueryParams.accountId = this.companySelection.getSelectedAccountIds();

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: newQueryParams
      }
    );

    // the API only accepts Longs and throws an exception otherwise, briefly show the loading page and then no results
    if (!this.isPayoutIdValid()) {
      setTimeout(() => {
        this.totalElements = 0;
        this.payouts = [];
        this.loading = false;
      }, 500);
      return;
    }

    this.payoutService.searchPayouts(payoutSearchRequest).subscribe(response => {
      this.totalElements = response.totalElements;
      this.payouts = response.content;
      this.loading = false;
    });
  }

  public clickDetail(payout: Payout): void {
    const payoutId = payout.id;
    this.router.navigate(['/dashboard/payouts', payoutId]);
  }

  public showSellerTotalReport(accountId: number, payoutId: number): void {
    this.downloadingSellerTotalReport = true;
    this.payoutService.downloadSellerTotalReport(accountId, payoutId).subscribe(data => {
      const fileName = `Seller_Total_Report_${payoutId}`;
      const fileType = 'application/pdf';
      download(fileName, fileType, data);
      this.downloadingSellerTotalReport = false;
    });
  }

  public showPayoutLetter(accountId: number, payoutId: number): void {
    this.downloadingPayoutLetter = true;
    this.payoutService.downloadPayoutLetter(accountId, payoutId).subscribe(data => {
      const fileName = `Payout_Letter_${payoutId}`;
      const fileType = 'application/pdf';
      download(fileName, fileType, data);
      this.downloadingPayoutLetter = false;
    });
  }

  public showPayoutDetailReport(accountId: number, payoutId: number): void {
    this.downloadingPayoutDetailReport = true;
    this.payoutService.downloadPayoutDetailReport(accountId, payoutId).subscribe(data => {
      const fileName = `Payout_Detail_Report_${payoutId}`;
      const fileType = 'application/pdf';
      download(fileName, fileType, data);
      this.downloadingPayoutDetailReport = false;
    });
  }

  public generateTrackingLink(trackingNumber: string): string {
    return this.trackingService.generateTrackingLink(trackingNumber);
  }

  public generateAuctionName(auction: Auction): string {
    return this.auctionService.generateAuctionName(auction);
  }

  public isPayoutIdValid(): boolean {
    if (this.payoutId && (isNaN(+this.payoutId) || +this.payoutId <= 0)) { return false; }
    return true;
  }
}

import {
  ContentChild, Directive, Input, TemplateRef
} from '@angular/core';
import { GoogleAnalyticObject } from '../services/google-analytic.service';

@Directive({
  selector: 'tab-engine-content'
})

export class TabContentDirective {
  @Input('id') public id: string;
  @Input('title') public title: string;
  @Input('gaTrigger') public gaTrigger: boolean = false;
  @ContentChild(TemplateRef, {static: true}) public template: TemplateRef<any>;

  constructor() {
  }
}


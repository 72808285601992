<div class="wrapper-fluid footer">
    <div class="col-12 text-light py-3 text-center">
        <div class="">Copyright © 2021 J.J. Kane and/or its affiliates., All Rights Reserved.</div>
        <div>"J.J. Kane Auctioneers" is a registered trademark of J.J. Kane Associates, Inc.</div>

        <ul class="list">
            <li class="social">
              <a href="https://www.jjkane.com/info/privacy-policy/">
                Privacy Policy
              </a>
            </li>
          |
            <li class="social">
                <a href="https://www.jjkane.com/info/site-terms-and-conditions/">
                  Website Terms and Conditions
                </a>
            </li>
          |
            <li class="social">
                <a href="https://www.altec.com/ccpa-privacy-policy/">
                  California Resident Privacy Policy
                </a>
            </li>
        </ul>
    </div>
</div>

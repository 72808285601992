import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AGGREGATE_COMPANY_NAME, CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanySelectionModalComponent} from '../../../Shared/components/company-selection-modal/company-selection-modal.component';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {DraftsService, RDSDraftItem} from '../../../item-drafts/services/drafts.service';

@Component({
  selector: 'app-submit-item',
  templateUrl: './submit-item.component.html',
  styleUrls: ['./submit-item.component.scss']
})
export class SubmitItemComponent implements OnInit, OnDestroy {
  public draft: RDSDraftItem;
  public companySubscription: Subscription;
  public showTitledItemWizard = false;
  public showMiscellaneousItemWizard = false;
  public isDraft = false;

  constructor(private titleService: Title, private router: Router, private route: ActivatedRoute, private modalService: NgbModal, private companySelectionService: CompanySelectionService, private draftsService: DraftsService) {
    this.titleService.setTitle('Submit Item - JJKane');
    this.companySubscription = this.companySelectionService.$selectedCompany.subscribe(
      companies => {
        if (companies) {
          if (companies.name === AGGREGATE_COMPANY_NAME) {
              if (!this.companySelectionService.temporarySelection) {
                this.showCompanySelectionModal();
              }
          }
        }
        });
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(first()).subscribe((res => {
      this.isDraft = res.Draft;
      if (this.isDraft === undefined) {
        this.draft = null;
      } else {
        this.draft = this.draftsService.activeDraft;
      }

      if (this.draft) {
        if (this.draft.itemType === 'Titled') {
          this.showTitledItemWizard = true;
        } else if (this.draft.itemType === 'Misc') {
          this.showMiscellaneousItemWizard = true;
        }
      }

    }));
  }

  ngOnDestroy(): void {
    if (this.companySelectionService.temporarySelection) {
        this.companySelectionService.selectCompany(AGGREGATE_COMPANY_NAME);
        this.companySelectionService.temporarySelection = false;
      }
    this.companySubscription.unsubscribe();
  }

  goToItemDrafts(): void {
    this.router.navigateByUrl('dashboard/item-drafts');
  }

  public showCompanySelectionModal(): void {
    this.modalService.open(CompanySelectionModalComponent, {backdrop: 'static', keyboard: false});
  }

}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Auction, Payout} from '../../../Shared/models/auctions';
import {GetFaIcon} from '../../../Shared/functions/icon-helpers';
import {
  SortFilterOutput
} from '../../../Shared/components/searchbar/searchbar.component';
import {
  ItemService,
  SearchItemVariables,
  SearchItemInput,
} from '../../../Items/services/item.service';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {environment} from '../../../../environments/environment';
import { CommonService } from 'src/app/Shared/services/common.service';
import {formatCityState} from '../../../Shared/functions/address-helpers';
import {download} from '../../../Shared/functions/download-helpers';
import {getItemStatus} from '../../../Shared/functions/item-helpers';
import {PayoutService} from '../../services/payout.service';
import {CustomModalComponent} from '../../../Shared/components/custom-modal/custom-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuctionService} from '../../../Shared/services/auction.service';
import {GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';
import {TrackingService} from '../../../Shared/services/tracking.service';

@Component({
  selector: 'app-payout-detail',
  templateUrl: './payout-detail.component.html',
  styleUrls: ['./payout-detail.component.scss']
})
export class PayoutDetailComponent implements OnInit {
  private modalRef: NgbModalRef;
  public appliedSortFilters: SortFilterOutput;
  public payoutId: number;
  public loadingPayout = true;
  public loadingItems = true;
  public loading = true;
  public faMoneyCheckAlt = GetFaIcon('money-check-alt', 'fas');
  public faChartBar = GetFaIcon('chart-bar', 'fas');
  public faChartPie = GetFaIcon('chart-pie', 'fas');
  public sortBy = 'unitNumber';
  public direction = 'asc';
  public displayItems: Array<ItemData> = [];
  public downloadingPayoutDetailReport: boolean;
  public downloadingPayoutLetter: boolean;
  public downloadingSellerTotalReport: boolean;

  public page = 0;
  public pageSize = 10;
  public pageLast: boolean;
  public pageFirst: boolean;
  public totalPages: number;
  public numberOfItems: number;
  public itemsOnPage = 0;

  public payoutDate: number;

  public payout: Payout[] = [];

  public items: ItemData[];

  constructor(private modalService: NgbModal, private commonService: CommonService, private route: ActivatedRoute, private companySelection: CompanySelectionService, private itemService: ItemService, private payoutService: PayoutService, private router: Router, private auctionService: AuctionService, private googleAnalyticService: GoogleAnalyticService, private trackingService: TrackingService) { }

  ngOnInit(): void {
    this.loadingPayout = true;
    this.loadingItems = true;
    this.commonService.updateIsLoading(true);
    this.commonService.isLoading$.subscribe(val=>{
      this.loading = val;
      if (!this.loading){
        if (this.page > 1){
          const yOffset = -40;
          const element = document.getElementById('items_scroll');
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        } else {
          window.scroll(0, 0);
        }
      }
    });
    this.payoutId = Number(this.route.snapshot.params.payoutId);
    this.fetchPayoutInformation(this.payoutId);
  }

  public showSellerTotalReport(accountId: number, payoutId: number): void {
    this.downloadingSellerTotalReport = true;
    this.payoutService.downloadSellerTotalReport(accountId, payoutId).subscribe(data => {
      const fileName = `Seller_Total_Report_${payoutId}`;
      const fileType = 'application/pdf';
      download(fileName, fileType, data);
      this.downloadingSellerTotalReport = false;
    });
  }

  public showPayoutLetter(accountId: number, payoutId: number): void {
    this.downloadingPayoutLetter = true;
    this.payoutService.downloadPayoutLetter(accountId, payoutId).subscribe(data => {
      const fileName = `Payout_Letter_${payoutId}`;
      const fileType = 'application/pdf';
      download(fileName, fileType, data);
      this.downloadingPayoutLetter = false;
    });
  }

  public showPayoutDetailReport(accountId: number, payoutId: number): void {
    this.downloadingPayoutDetailReport = true;
    this.payoutService.downloadPayoutDetailReport(accountId, payoutId).subscribe(data => {
      const fileName = `Payout_Detail_Report_${payoutId}`;
      const fileType = 'application/pdf';
      download(fileName, fileType, data);
      this.downloadingPayoutDetailReport = false;
    });
  }

  public selectSort(sortField: string): void {
    this.sortBy = sortField;
    const filterData = {
      backEndSortResult: sortField,
      filterResults: [],
      searchTerm: '',
      sortDirection: this.direction,
      sortResults: sortField
    };
    this.page = this.page - 1;
    this.applyFilterSortOptions(filterData);
  }

  public sortDirectionChange(direction: string): void {
    this.direction = direction;
    const filterData = {
      backEndSortResult: this.sortBy,
      filterResults: [],
      searchTerm: '',
      sortDirection: direction,
      sortResults: this.sortBy
    };
    this.page = this.page - 1;
    this.applyFilterSortOptions(filterData);
  }

  public applyFilterSortOptions(data: SortFilterOutput): void {
    this.appliedSortFilters = data;
    this.pageChange(this.page);
  }

  public fetchPayoutInformation(payoutId: number): void {

    const accountIds = this.companySelection.getSelectedAccountIds();

    this.payoutService.getPayoutDetails(accountIds, payoutId).subscribe(response => {
      this.payout = response.content["0"];
      if(!this.payout){
        if (this.modalRef) {
          this.modalRef.dismiss();
        }
        const modalContent = {
          title: 'Payout Not Available',
          message: 'This payout is not available under this account.',
          closeButton: false,
          showButtons: true,
          cancelButton: false,
          buttonsArray: [
            {
              name: 'OK',
              action: 'navigate',
              navigation: '/dashboard',
              navParams: null,
              class: 'btn-danger' // Possible values: btn-success, btn-warning, btn-danger
            }
          ]
        };
        this.modalRef = this.modalService.open(CustomModalComponent, {backdrop: 'static', keyboard: false});

        this.modalRef.componentInstance.modalContent = modalContent;

        this.modalRef.result.then(res => {
          if (res.action === 'navigate'){
            if (res.navigation){
              if (res.navParams){
                this.router.navigate([res.navigation, res.navParams]);
              } else {
                this.router.navigateByUrl(res.navigation);
              }
            }
          }
        });
        return false;
      }
      this.loadingPayout = false;
    });

    this.fetchPayoutItemInformation(String(payoutId));

  }

  public fetchPayoutItemInformation(payoutId: string, filterResultArray?: SortFilterOutput): void {

    if (!filterResultArray){
      filterResultArray = {
        backEndSortResult: this.sortBy,
        filterResults: [],
        searchTerm: '',
        sortDirection: this.direction,
        sortResults: this.sortBy
      };
    }

    const sellerAccountIdArray = this.companySelection.getSelectedAccountIds();
    const variables: SearchItemVariables['searchObject'] = {
      payoutIds: [Number(payoutId)],
      sellerAccountIds: sellerAccountIdArray,
      searchTerm: null,
      pageSize: this.pageSize,
      page: (this.page + 1)
    };
    const newQueryParams: Params = {};
    newQueryParams.Page = this.page;
    newQueryParams.Caller = sellerAccountIdArray;

    if (filterResultArray) {
      filterResultArray.filterResults.forEach(filter => {
        newQueryParams[filter.filter] = filter.selection;
      });
      if (filterResultArray.sortResults) {
        newQueryParams.Sort = filterResultArray.sortResults;
      }

      if (filterResultArray.sortDirection) {
        newQueryParams.Direction = filterResultArray.sortDirection;
      }
    }

    this.itemService.searchItems(variables).subscribe(items => {
      this.page = items.number;
      this.pageSize = items.pageSize;
      this.pageLast = items.last;
      this.pageFirst = items.first;
      this.totalPages = items.totalPages;
      this.numberOfItems = items.totalElements;
      this.itemsOnPage = items.numberOfElements;

      if (items.content) {
        this.items = [];
        items.content.forEach(item => {
          let itemData: ItemData;
          const date = new Date(item.saleDate);
          itemData = {
            id: item.id,
            imageCount: item.imageCount,
            imagePath: item.imageCount > 0 ? `${environment.jjkImageUrl}${environment.jjkImageNamePrefix}${item.itemId}-1${environment.jjkWaterMark}` : '',
            itemId: item.itemId,
            category: item.category,
            unitNumber: item.unitNumber ? item.unitNumber : 'None',
            auction: item.saleInfo,
            vin: item.vin,
            year: item.year,
            make: item.make,
            model: item.model,
            lotNumber: item.lotNumber,
            type: item.saleType,
            auctionDate: date.toLocaleDateString(),
            location: formatCityState(item.offSitePhysicalCity,  item.offSitePhysicalState),
            catalogDescription: item.catalogDescription,
            status: getItemStatus(item.itemStatus),
            titleStatus: '',
            auctionPrice: item.salePrice,
            sellerNet: item.amountOwed,
            titled: item.titled,
            haveTitle: item.haveTitle,
            payoutFinalized: item.sale?.payoutsFinalized
          };
          this.items.push(itemData);
        });
      }
      this.loadingItems = false;
    });

  }

  private generateSearchObject(payoutId: string, sellerAccountIdArray: number[], page: number, pageSize: number, filterResultArray?: SortFilterOutput): SearchItemInput {
    filterResultArray = filterResultArray ? filterResultArray : this.appliedSortFilters;

    const searchObject: SearchItemInput = {
      sellerAccountIds: sellerAccountIdArray,
      searchTerm: null,
      pageSize,
      page: (page + 1),
      payoutIds: [Number(payoutId)]
    };

    if (filterResultArray) {
      filterResultArray.filterResults.forEach(filter => {
        searchObject[filter.backEndFilter] = filter.selection;
      });
      if (filterResultArray.sortResults) {
        searchObject.sortField = filterResultArray.backEndSortResult;
      }

      if (filterResultArray.sortDirection) {
        searchObject.sortDirection = filterResultArray.sortDirection;
      }
    }
    return searchObject;
  }


  public pageChange(page: number): void {
    window.scroll(0,0);
    this.loadingItems = true;
    this.page = page;
    this.fetchPayoutItemInformation(String(this.payoutId), this.appliedSortFilters);
  }

  public generateAuctionName(auction: Auction): string {
    return this.auctionService.generateAuctionName(auction);
  }

  public generateTrackingLink(trackingNumber: string): string {
    return this.trackingService.generateTrackingLink(trackingNumber);
  }
}

export interface ItemData {
  id: number;
  imageCount?: number;
  imagePath?: string;
  itemId: string;
  category: string;
  unitNumber: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  lotNumber: string;
  auction: string;
  type: string;
  auctionDate: string;
  location: string;
  catalogDescription: string;
  status: string;
  titleStatus: string;
  auctionPrice?: number;
  sellerNet?: number;
  titled: boolean;
  haveTitle: boolean;
  payoutFinalized: boolean;
}

export enum TitleStatus {
  TITLE_RECEIVED = 'Title Received',
  TITLE_SUBMITTED = 'Title Submitted',
  AWAITING_TITLE = 'Awaiting Title'
}

export enum ItemFilters {
  STATUS = 'Status',
  INVENTORY_MANAGEMENT = 'Inventory Management',
  AUCTION = 'Auction',
  LOCATION = 'Auction Location',
  CATEGORY = 'Category',
  MAKE = 'Make',
  MODEL = 'Model',
  UPPER_MAKE = 'Upper Make'
}

export enum ItemSorts {
    UNIT_NUMBER = 'Unit Number',
    AUCTION_DATE = 'Auction Date'
}

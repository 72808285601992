import { Component, OnInit, Input } from '@angular/core';
import {DatePipe} from '@angular/common';
import {AuctionService} from '../../../Shared/services/auction.service';
import {Sale} from '../../../generated/graphql';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';

@Component({
  selector: 'upcoming-auctions-card',
  templateUrl: './upcoming-auctions-card.component.html',
  styleUrls: ['./upcoming-auctions-card.component.scss']
})
export class UpcomingAuctionsCardComponent implements OnInit {

  @Input()
  auction: Sale;

  @Input()
  odd: boolean = false;

  constructor(private datePipe: DatePipe, private auctionService: AuctionService, private googleAnalyticService: GoogleAnalyticService) { }

  ngOnInit(): void {
  }

  getSaleInfo(): string {
    return this.auctionService.generateAuctionName(this.auction);
  }

  openAuction(): void {
    const event: GoogleAnalyticEventObject = {
      category: 'Upcoming Auction link',
      action: 'Click',
      label: 'Dashboard'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      const date = this.datePipe.transform(this.auction.date, 'yyyy-MM-dd');
      window.open(`https://www.jjkane.com/auctions/timed-auction/${date}/${this.auction.id}`);
    });
  }
}

<div class="mobile-timeline pt-3">
  <div *ngIf="!loading">
    <checklist-card [title]="'Submitted'" [completed]="this.itemManagementService.isSubmitted(itemDetail)" [date]="itemDetail.createDate">
    </checklist-card>
    <checklist-card [title]="'On Site'" [completed]="this.itemManagementService.isOnSite(itemDetail) || this.itemManagementService.isSold(itemDetail)" [date]="itemDetail.arrivalDate">
    </checklist-card>
    <checklist-card [title]="'Condition Documented'" [completed]="this.itemManagementService.isConditionDocumented(itemDetail) || this.itemManagementService.isSold(itemDetail)"
                    [date]="itemDetail.inspectionDate">
    </checklist-card>
      <checklist-card *ngIf="itemDetail.titled" [title]="'Title Submitted'" [completed]="this.itemManagementService.isTitleSubmitted(itemDetail) || this.itemManagementService.isSold(itemDetail)" [date]="itemDetail.titleReceivedDate"
                      [errorMessage]="this.itemManagementService.titleMissingMessage(itemDetail)">
    </checklist-card>
    <checklist-card [title]="'Auction Ready'" [completed]="this.itemManagementService.isAuctionReady(itemDetail) || this.itemManagementService.isSold(itemDetail)">
    </checklist-card>
    <checklist-card [title]="'Sold'" [completed]="this.itemManagementService.isSold(itemDetail)" [date]="itemDetail.saleDate">
    </checklist-card>
    <checklist-card [title]="'Payout Final'" [completed]="this.itemManagementService.isPayoutFinalized(itemDetail)" [payoutId]="itemDetail.sellerPayoutId">
    </checklist-card>
  </div>

  <!-- Loading -->
  <div *ngIf="loading">
      <div class="item loading" *ngFor="let step of [0,1,2];">
          <div class="marker loading"></div>
          <div class="content d-flex justify-content-between my-5 ml-3 loading">
          </div>
      </div>
  </div>
</div>

<div class="modal-header">
    <a>
        <h5>Confirm Action</h5>
    </a>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body text-center">
    Are you sure you want to delete this item? This can not be reversed.
</div>

<div class="modal-footer text-center">
    <button type="button" class="btn btn-light" (click)="activeModal.close()">Cancel</button>
    <button type="button" class="btn btn-danger delete-button" (click)="delete()"> Delete </button>
</div>

<div class="modal-header">
  <h5 class="mb-1 alt-link" (click)="navigate()">Unit #: {{ unitNumber }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="show === 'image'" id="image" class="modal-body text-center">
  <img src="{{imageSrc}}" class="image img-fluid"/>
</div>

<div *ngIf="show === 'description'" id="description" class="modal-body">
  <p>{{description}}</p>
</div>

export const environment = {
  envName: 'dev',
  production: true,
  graphQLConfig: {
    apiUrl: 'https://2u78p379eb.execute-api.us-east-1.amazonaws.com/graphql',
    test: 'https://2u78p379eb.execute-api.us-east-1.amazonaws.com/',
    downloadItemDetailImagesUrl: 'https://2u78p379eb.execute-api.us-east-1.amazonaws.com/downloadItemDetailImages/:itemId',
    downloadPayoutReportUrl: 'https://2u78p379eb.execute-api.us-east-1.amazonaws.com/downloadPayoutReport/:accountId/:payoutId/:reportName',
    downloadInvoiceReportUrl: 'https://2u78p379eb.execute-api.us-east-1.amazonaws.com/downloadInvoice/:accountId/:invoiceId',
    changePasswordUrl: 'https://2u78p379eb.execute-api.us-east-1.amazonaws.com/changePassword/:email'
  },
  url: 'https://dev.jjkaneportal.com/',
  auth0Config: {
    domain: 'dev-altec-customer-portal.auth0.com',
    clientId: '0rVA2qu4c1LQmUdCee1Mkb63UvduhHq5',
    audience: 'jjk-portal-api'
  },
  aimApi: 'https://cysidv7mvg.execute-api.us-east-1.amazonaws.com/dev',
  devToolsEnabled: false,
  jjkImageUrl: 'https://staging.cdn.jjkaimdev.com',
  jjkImageNamePrefix: '/',
  jjkWaterMark: '?template=Thumb',
  connectStaticsUrl: 'https://atg-dev.altec.com/static/',
  holdingSaleIds: [
    167
  ]
};

<div class="wrapper px-3 pb-5">

    <div class="pt-3" *ngIf="showPreviewMessage">
        <site-message-alert [message]="previewMessage"></site-message-alert>
        <div class="w-100">
            <p class="cursor-pointer text-right" (click)="showPreviewMessage = !showPreviewMessage">
                <i>Dismiss</i>
            </p>
        </div>
    </div>

    <div class="py-3">
      <span class="text-left"><h2>Admin</h2></span>
    </div>

    <!-- Tabbed Section -->
    <tab-engine [activeTabID]="'siteMessages'">

        <!-- Site Messages Tab -->
        <tab-engine-content [title]="'Site Messages'" [id]="'siteMessages'">
            <ng-template>

                <div class="w-100 my-3 text-right">
                    <button type="button" class="btn bg-altec text-light w-mobile-100" (click)="openNewMessageModal()">Create New Message</button>
                </div>

                <div class="px-3">
                    <!-- Active Messages Section -->
                    <section>
                        <!-- Header -->
                        <div class="py-3">
                            <span class="text-left"><h4 class="mb-0 ml-2">Active Messages</h4></span>
                        </div>
                        <!-- Messages -->
                        <div *ngIf="messageService?.activeMessages?.length != 0" class="messages px-4 py-4">
                            <div *ngIf="messageService?.activeMessages?.length; else loading"></div>
                            <div *ngFor="let message of messageService.activeMessages; let i = index">
                              <div>
                                <site-message-card [message]="message" (delete)="deleteMessage($event)" (edit)="editMessage($event)" (preview)="previewMessageEv($event)">
                                </site-message-card>
                                <hr *ngIf="i != messageService.activeMessages.length-1">
                              </div>
                            </div>
                        </div>
                        <!-- No Messages -->
                        <div *ngIf="messageService?.activeMessages?.length === 0" class="text-center py-4 no-messages">
                            No Active Messages
                        </div>
                    </section>

                    <!-- Upcoming Messages Section -->
                    <section>
                        <!-- Header -->
                        <div class="py-3">
                            <span class="text-left"><h4 class="mb-0 ml-2">Upcoming Messages</h4></span>
                        </div>
                        <!-- Messages -->
                        <div *ngIf="messageService?.upcomingMessages?.length != 0" class="messages px-4 py-4">
                            <div *ngIf="messageService?.upcomingMessages?.length; else loading"></div>
                            <div *ngFor="let message of messageService.upcomingMessages; let i = index">
                                <site-message-card [message]="message" (delete)="deleteMessage($event)" (edit)="editMessage($event)" (preview)="previewMessageEv($event)">
                                </site-message-card>
                                <hr *ngIf="i != messageService.upcomingMessages.length-1">
                            </div>
                        </div>
                        <!-- No Messages -->
                        <div *ngIf="messageService?.upcomingMessages?.length === 0" class="text-center py-4 no-messages">
                            No Upcoming Messages
                        </div>
                    </section>

                    <!-- Previous Messages Section -->
                    <section>
                        <!-- Header -->
                        <div class="py-3">
                            <span class="text-left"><h4 class="mb-0 ml-2">Previous Messages</h4></span>
                        </div>
                        <!-- Messages -->
                        <div *ngIf="messageService?.previousMessages?.length != 0" class="messages px-4 py-4">
                            <div *ngIf="messageService?.previousMessages?.length; else loading"></div>
                            <div *ngFor="let message of messageService.previousMessages; let i = index">
                              <div>
                                <site-message-card [message]="message" [showDelete]="false" (edit)="editMessage($event)" (preview)="previewMessageEv($event)">
                                </site-message-card>
                                <hr *ngIf="i != messageService.previousMessages.length-1">
                            </div>
                            </div>
                        </div>
                        <!-- No Messages -->
                        <div *ngIf="messageService?.previousMessages?.length === 0" class="text-center py-4 no-messages">
                            No Previous Messages
                        </div>
                    </section>

                </div>


                <ng-template #loading>
                    <div class="mt-3 p-3 loading">
                    </div>
                  </ng-template>
            </ng-template>
        </tab-engine-content>

        <!-- Draft Items Tab -->
        <tab-engine-content [title]="'Draft Items'" [id]="'draftItems'">
            <ng-template>

                <div class="row no-gutters my-3">
                    <div class="col-lg-3">
                        <div class="form-group text-left mb-0">
                            <input #userId class="form-control" placeholder="{{ draftService.accountID}}">
                        </div>
                    </div>
                    <div class="col-lg-1 col-sm-12">
                        <div class="form-group text-left">
                            <button class="btn bg-altec text-light w-mobile-100" (click)="setCustomId(userId.value)">
                                <fa-icon [icon]="faSearch"></fa-icon>
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-3 offset-lg-5 col-sm-12 w-100 d-flex justify-content-md-end justify-content-center pb-3">

                        <button type="button" class="btn btn-light mr-2" (click)="refresh()">
                            <fa-icon [icon]="faSync"></fa-icon>
                        </button>

                        <button type="button" class="btn bg-altec text-light" (click)="openNewDraftItemModal()">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </button>

                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">User ID</th>
                                <th scope="col">Item ID</th>
                                <th scope="col">Submit Time</th>
                                <th scope="col">Created Time</th>
                                <th scope="col">Step</th>
                                <th scope="col">Unit #</th>
                                <th scope="col">Lot #</th>
                                <th scope="col">Sale</th>
                                <th scope="col">Location</th>
                                <th scope="col">Auction Price</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of draftItemsTableData;">
                                <td>
                                    {{ item.userId }}
                                </td>
                                <td>
                                    {{ item.itemId }}
                                </td>
                                <td>
                                  {{ item.submitTimestamp | date: 'MM/dd/yyyy HH:mm:ss' }}
                                </td>
                                <td>
                                    {{ item.dateCreated | date: 'MM/dd/yyyy HH:mm:ss' }}
                                </td>
                                <td>
                                    {{ item.currentStep }}
                                </td>
                                <td>
                                    {{ item.unitNumber }}
                                </td>
                                <td>
                                    {{ item.lotNumber }}
                                </td>
                                <td>
                                    {{ item.sale }}
                                </td>
                                <td>
                                    {{ item.location }}
                                </td>
                                <td>
                                    {{ item.auctionPrice }}
                                </td>
                                <td>
                                    <fa-icon [icon]="faTimesCircle" class="text-danger cursor-pointer" (click)="openConfirmModal(item)"></fa-icon>
                                </td>
                            </tr>

                            <tr *ngIf="loadingDraftItems" class="table-load-odd">
                                <td colspan="11">
                                    <span>&nbsp;</span>
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-even">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-odd">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-even">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-odd">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-even">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-odd">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-even">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-odd">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="loadingDraftItems" class="table-load-even">
                                <td colspan="11">
                                    &nbsp;
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div *ngIf="hasMore" class="d-flex justify-content-center w-100">
                    <button type="button" class="btn btn-light" (click)="queryDraftItems()">Load More</button>
                </div>

                <div *ngIf="!hasMore && !loadingDraftItems" class="row mt-3">
                    <p class="w-100 text-center"><i>{{ draftItemsTableData.length > 0 ? 'End of Results' : 'No Results' }}</i></p>
                </div>

            </ng-template>
        </tab-engine-content>
    </tab-engine>

</div>

<div class="modal-header">
  <a>
    <h5 class="mb-1 alt-link" (click)="navigate()">Unit #: {{ unitNumber }}</h5>
  </a>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body text-center">
  <p class="mt-3">Are you sure you want to delete this draft?</p>
</div>

<div class="modal-footer text-center">
      <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="deleteDraft()" [GA]="{event: {category: 'Delete Draft', action: 'Click', label: 'Item Drafts'}}">Delete Draft</button>
</div>

<div class="container-fluid" *ngIf="showForm">
  <div class="col-12">
    <label>Upload Queue</label>
    <div class="row">
      <div class="col-12">
        <a [href]="instructionPdfUrl" target="_blank">View Instructions for Vehicle Photos</a>
      </div>
    </div>
    <div class="row">
      <div *ngIf="submitService.uploadQueue && submitService.uploadQueue.length > 0" class="col-12 mb-3">
        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Size</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of submitService.uploadQueue; let odd = odd" [class.odd]="odd">
              <td>{{ file.name }}</td>
              <td>{{ file.size | filesize }}</td>
              <td><span class="remove-button text-altec-warn float-right" (click)="removeFile(file)"><fa-icon [icon]="faTimesCircle"></fa-icon><span class="d-none d-sm-inline"> Remove</span></span></td>
            </tr>
          </tbody>
        </table>
        <div>
          <button class="btn btn-sm text-light bg-altec-warn float-right" (click)="removeAll()">Remove All</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ngx-file-drop dropZoneClassName="noop" contentClassName="noop" (onFileDrop)="dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="row">
              <div class="col-md-8 col-xs-12">
                <div class="drop-area">
                  Drop video or image files here or select files to upload
                </div>
              </div>
              <div class="col-md-4 col-xs-12 d-flex align-items-center">
                <button class="btn text-light bg-altec" (click)="openFileSelector()">Select Files</button>
              </div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </div>
  </div>
</div>

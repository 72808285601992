<div class="container-fluid" *ngIf="showForm">
  <form [formGroup]="form">
    <div class="col-12">
      <div class="form-group">
        <label>Engine</label>
        <select class="form-control" formControlName="engine" [class.loading]="loading">
          <ng-container *ngIf="!loading">
            <option value>Select an engine</option>
            <option *ngFor="let engine of dropdownValues?.engines" [ngValue]="engine.name">{{engine.name}}</option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Fuel</label>
        <select class="form-control" formControlName="fuel" [class.loading]="loading">
          <ng-container *ngIf="!loading">
            <option value>Select a fuel type</option>
            <option *ngFor="let fuel of dropdownValues?.fuel" [ngValue]="fuel.name">{{fuel.name}}</option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Transmission</label>
        <select class="form-control" formControlName="transmission" [class.loading]="loading">
          <ng-container *ngIf="!loading">
            <option value>Select a transmission</option>
            <option *ngFor="let transmission of dropdownValues?.transmissions" [ngValue]="transmission.name">{{transmission.name}}</option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Odometer</label>
        <input class="form-control" [class.is-invalid]="form.controls.odometer.errors"
               formControlName="odometer" placeholder="Enter the current odometer reading" />
        <div *ngIf="form.controls.odometer.errors" class="error-text">
          <div *ngIf="form.controls.odometer.errors.pattern">Odometer must be numeric</div>
          <div *ngIf="form.controls.odometer.errors.maxlength">Odometer is too long</div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Hours</label>
        <input class="form-control" [class.is-invalid]="form.controls.hours.errors"
               formControlName="hours" placeholder="Enter the current runtime" />
        <div *ngIf="form.controls.hours.errors" class="error-text">
          <div *ngIf="form.controls.hours.errors.pattern">Hours must be numeric</div>
          <div *ngIf="form.controls.hours.errors.maxlength">Hours is too long</div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Optional Equipment</label>
        <textarea class="form-control" [class.is-invalid]="form.controls.optionalEquipment.errors" formControlName="optionalEquipment" placeholder="Enter any optional equipment"></textarea>
        <div *ngIf="form.controls.optionalEquipment.errors" class="error-text">
          <div *ngIf="form.controls.optionalEquipment.errors.maxlength">Optional Equipment is too long</div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Air Conditioning</label>
        <div>
          <ui-switch formControlName="airConditioning" color="#168ff9" checkedLabel="Yes" uncheckedLabel="No" checkedTextColor="white">
          </ui-switch>
        </div>
      </div>
    </div>
  </form>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateUs } from '../../Submit Items/models/state-us';
import { map } from 'rxjs/operators';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JjkIntegration, GetCountriesQuery, GetCountriesDocument, Country } from '../../generated/graphql';
import { Apollo, ApolloBase } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();

  constructor(private http: HttpClient, private jjkIntegration: JjkIntegration) {}

  updateIsLoading(value: boolean) {
    this.isLoading.next(value);
  }

  public getStatesUSList(): Observable<StateUs[]> {
    return this.http.get('assets/states-US.json')
      .pipe(map (response => response as StateUs[]));
  }

  public getCountries(): Observable<Country[]> {
    return this.jjkIntegration.getCountries()
      .pipe(map(response => {
        return response.data.countries;
      }));
  }
}

export interface Success {
  success: boolean;
}

<div class="container-fluid">
  <h3 class="my-3">Payout ID: {{payoutId}}</h3>
  <div class="row d-flex">
    <div class="col-xs-12 col-lg-5">
      <div>
        <label><b>Auction Date:</b></label> <span *ngIf="!loadingPayout; else loadingSpan"> {{payout.sale.date | date: 'shortDate'}}</span>
      </div>
      <div>
        <label><b>Auction:</b></label> <span *ngIf="!loadingPayout; else loadingSpan"> {{generateAuctionName(payout.sale)}}</span>
      </div>
      <div>
        <label><b>Total Auction Price:</b></label> <span *ngIf="!loadingPayout; else loadingSpan"> {{payout.salePriceSubtotal | currency}}</span>
      </div>
      <div>
        <label><b>Total Seller Net:</b></label><span class="{{ payout.sale.payoutsFinalized  && payout.sellerNet > 0 ? 'finalized' : (payout.sellerNet < 0 && payout.sale.payoutsFinalized ? 'negative-seller-net' : 'not-finalized') }}" *ngIf="!loadingPayout; else loadingSpan"> {{ payout.sale.payoutsFinalized ? (payout.sellerNet | currency) : "Pending" }}</span>
      </div>
      <div>
        <label><b>Tracking Info:</b></label>
          <span *ngIf="!loadingPayout; else loadingSpan">
            <a [href]="generateTrackingLink(payout.upsTrackingNumber)" target="_blank" [GA]="{event: {category: 'Tracking Info Select', action: 'Click', label: 'Payouts'}}">{{payout.upsTrackingNumber ? payout.upsTrackingNumber : null}}</a></span>
      </div>
    </div>

    <div class="col-xs-12 col-lg-6 pt-5">
      <div class="row">
        <div class="col-sm-4 col-xs-12 text-center pb-3">
          <div class="report-button" *ngIf="!loadingPayout; else loadingCell" [GA]="{event: {category: 'Download Seller Total Report', action: 'Click', label: 'Payout Details'}}" (click)="showSellerTotalReport(payout.accountId, payout.id)">
            <fa-icon *ngIf="!downloadingSellerTotalReport; else spinner" [icon]="faMoneyCheckAlt" size="2x"></fa-icon>
            <div>Seller Total Report</div>
          </div>
        </div>
        <div class="col-sm-4 col-xs-12 text-center pb-3">
          <div class="report-button" *ngIf="!loadingPayout; else loadingCell" [GA]="{event: {category: 'Download Payout Letter', action: 'Click', label: 'Payout Details'}}" (click)="showPayoutLetter(payout.accountId, payout.id)">
            <fa-icon *ngIf="!downloadingPayoutLetter; else spinner" [icon]="faChartBar" size="2x"></fa-icon>
            <div>Payout Letter</div>
          </div>
        </div>
        <div class="col-sm-4 col-xs-12 text-center pb-3">
          <div class="report-button" *ngIf="!loadingPayout; else loadingCell" [GA]="{event: {category: 'Download Payout Detail Report', action: 'Click', label: 'Payout Details'}}" (click)="showPayoutDetailReport(payout.accountId, payout.id)">
            <fa-icon *ngIf="!downloadingPayoutDetailReport; else spinner" [icon]="faChartPie" size="2x"></fa-icon>
            <div>Payout Detail Report</div>
          </div>
        </div>
        <ng-template #spinner>
          <div class="spinner-border">
            <span class="sr-only"></span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #loadingCell>
    <div class="loading-container d-flex justify-content-center">
      <div class="loading-text-field loading my-3 w-75"></div>
    </div>
  </ng-template>

  <ng-template #loadingSpan>
    <div class="loading-text-field loading ml-3 w-25 inline-block">&nbsp;</div>
  </ng-template>

  <h5 id="items_scroll" class="items-header">Items (<div class="inline-block" [ngClass]="{'loading loading-text-field w-5': loadingItems}">{{ numberOfItems }}</div>)</h5>

  <div class="row">
    <div class="col-md-12 col-lg-2 pb-3 d-flex align-items-center sort-label">
      <label class="mb-0"><b>Sort By: </b></label>
    </div>
    <div class="col-md-12 col-lg-3 pb-3">
      <select class="form-control sort-dropdown" (change)="selectSort($event.target.value)" [GA]="{event: {category: 'Filter/Sort Apply Selected', action: 'Click', label: 'Payout Detail'}}">
        <option selected="selected" value="unitNumber">Unit Number</option>
        <!--<option value="salePrice">Auction Price</option>
        <option value="amountOwed">Seller Net</option>-->
      </select>
    </div>
    <div class="col-md-12 col-lg-5 pb-3 d-flex align-items-center">
      <div class="form-check form-check-inline" (change)="sortDirectionChange('asc')" [GA]="{event: {category: 'Filter/Sort Apply Ascending', action: 'Click', label: 'Payout Detail'}}">
        <input class="form-check-input" type="radio" name="direction" id="ascendingCheck" value="ascending" [checked]="direction === 'asc'">
        <label class="form-check-label" for="ascendingCheck">Ascending</label>
      </div>
      <div class="form-check form-check-inline" (change)="sortDirectionChange('desc')" [GA]="{event: {category: 'Filter/Sort Apply Descending', action: 'Click', label: 'Payout Detail'}}">
        <input class="form-check-input" type="radio" name="direction" id="descendingCheck" value="descending" [checked]="direction === 'desc'">
        <label class="form-check-label" for="descendingCheck">Descending</label>
      </div>
    </div>
    <div *ngIf="!loadingItems" class="font-italic font-weight-light counter">
      Showing {{pageFirst ? 1 : ((page - 1) * pageSize) + 1}} - {{pageLast ? numberOfItems : page * pageSize}} of {{numberOfItems}} Items
    </div>
  </div>
  <div>
    <div class="items">
      <ng-container *ngIf="!loadingItems; else loadingCards">
        <div *ngFor="let item of items; let odd = odd">
          <payout-item-card [item]="item" [odd]="odd"></payout-item-card>
        </div>
      </ng-container>
      <ng-template #loadingCards>
        <div *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]; let odd = odd">
          <payout-item-card [odd]="odd" [loading]="loadingItems"></payout-item-card>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="mt-3">
    <pagination [pageSize]="pageSize" [numberOfItems]="numberOfItems" [index]="0" (paging)="pageChange($event)"></pagination>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss']
})
export class DaterangePickerComponent implements OnInit {
  public startDate: NgbDateStruct;
  public endDate: NgbDateStruct;
  public today = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public set(): void {
    this.activeModal.close({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  get datesInOrder(): boolean {
    if (this.startDate && this.endDate) {
      if (this.startDate.year > this.endDate.year) {
        return false;
      }
      if (this.startDate.year === this.endDate.year) {
        if (this.startDate.month > this.endDate.month) {
          return false;
        }
        if (this.startDate.month === this.endDate.month) {
          if (this.startDate.day > this.endDate.day) {
            return false;
          }
        }
      }
    }

    return true;
  }

  get validDateEntries(): boolean {
    if (!this.startDate || !this.endDate || !this.datesInOrder) {
      return false;
    }
    return true;
  }
}

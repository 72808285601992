import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ScreenSizeService, ScreenType} from '../../../Shared/services/screen-size.service';
import {GetFaIcon} from '../../../Shared/functions/icon-helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'checklist-card',
  templateUrl: './checklist-card.component.html',
  styleUrls: ['./checklist-card.component.scss']
})
export class ChecklistCardComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  date: Date;

  @Input()
  completed: boolean;

  @Input()
  payoutId: number;

  @Input()
  errorMessage: string;

  @Input()
  loading: boolean = false;

  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public isDesktop: boolean = false;
  public screenSize$: Subscription;

  public checkIcon = GetFaIcon('check-circle', 'fas');
  public circleIcon = GetFaIcon('circle', 'far');
  public errorIcon = GetFaIcon('exclamation-circle', 'fas');

  constructor(private screenSize: ScreenSizeService, private router: Router) {
    this.screenSize$ = this.screenSize.$screenSize.subscribe(size => {
      this.isMobile = false;
      this.isTablet = false;
      this.isDesktop = false;
      switch (size.screenType) {
        case ScreenType.Mobile:
          this.isMobile = true;
          break;
        case ScreenType.Tablet:
          this.isTablet = true;
          break;
        case ScreenType.Desktop:
          this.isDesktop = true;
          break;
        default:
          this.isDesktop = true;
          break;
      }
    });
  }

  ngOnInit(): void {
  }

  public getItemClass(): string {
    let classList: string;
    if (this.isMobile) {
      classList = 'item-mobile';
    } else {
      classList = 'item';
    }
    classList += ' ' + this.getStyleClass();
    return classList;
  }

  public getStyleClass(): string {
    return this.completed ? 'green' : 'black';
  }

  public openPayout(payoutId: number): void {
    this.router.navigate(['/dashboard/payouts/', payoutId]);
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'searchbar-quick-filter',
  templateUrl: './searchbar-quick-filter.component.html',
  styleUrls: ['./searchbar-quick-filter.component.scss']
})
export class SearchbarQuickFilterComponent implements OnInit {

  @Input() title: string;

  @Output() clicked: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public qfClicked() {
    this.clicked.emit(this.title);
  }

}

import {Component, OnInit, Input, Output, EventEmitter, OnChanges, ComponentRef} from '@angular/core';
import { GetFaIcon } from 'src/app/Shared/functions/icon-helpers';
import { RDSSiteMessage, SiteMessageLevel } from '../../dto/SiteMessage';
import {SiteMessage} from '../../../generated/graphql';

@Component({
  selector: 'site-message-card',
  templateUrl: './site-message-card.component.html',
  styleUrls: ['./site-message-card.component.scss']
})
export class SiteMessageCardComponent implements OnInit, OnChanges {
  public faLongArrowAltRight = GetFaIcon('long-arrow-alt-right', 'fas');
  public faTrash = GetFaIcon('trash', 'fas');
  public faEdit = GetFaIcon('edit', 'fas');
  public faInfoCircle = GetFaIcon('info-circle', 'fas');
  public faExclamationTriangle = GetFaIcon('exclamation-triangle', 'fas');
  public faEye = GetFaIcon('eye', 'fas');

  @Input() message: SiteMessage;
  @Input() showDelete: boolean = true;

  @Output() delete: EventEmitter<SiteMessage> = new EventEmitter();
  @Output() edit: EventEmitter<SiteMessage> = new EventEmitter();
  @Output() preview: EventEmitter<SiteMessage> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SiteMessage): void {}

  public deleteMessage() {
    this.delete.emit(this.message);
  }

  public editMessage() {
    this.edit.emit(this.message);
  }

  public previewMessage() {
    this.preview.emit(this.message);
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DraftsService, RDSDraftItem} from '../../services/drafts.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Input() show: string;
  @Input() unitNumber: string;
  @Input() itemId: string;
  @Input() draftItem: RDSDraftItem;

  constructor(private router: Router, public activeModal: NgbActiveModal, public draftService: DraftsService) {
  }

  ngOnInit(): void {
  }

  public navigate(): void {
    this.activeModal.close();
    this.router.navigateByUrl('/dashboard');
  }

  public deleteDraft(): void {
    this.activeModal.close();
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpBackend, HttpClient} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DashboardComponent } from './Dashboard/pages/dashboard/dashboard.component';
import { SubmitItemComponent } from './Submit Items/pages/submit-item/submit-item.component';
import { ItemsComponent } from './Items/pages/items/items.component';
import { PayoutComponent } from './Payouts/pages/payouts/payout.component';
import { SideNavbarComponent } from './Shared/components/side-navbar/side-navbar.component';
import { TopNavbarComponent } from './Shared/components/top-navbar/top-navbar.component';
import { DashboardInfoCardComponent } from './Dashboard/components/dashboard-info-card/dashboard-info-card.component';
import { SearchbarComponent } from './Shared/components/searchbar/searchbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DashboardAuctionsComponent } from './Dashboard/components/dashboard-auctions/dashboard-auctions.component';
import { UpcomingAuctionsCardComponent } from './Dashboard/components/upcoming-auctions-card/upcoming-auctions-card.component';
import { FooterComponent } from './Shared/components/footer/footer.component';
import { ContactUsModalComponent } from './Shared/components/side-navbar/contact-us-modal/contact-us-modal.component';
import { RecentAuctionsCardComponent } from './Dashboard/components/recent-auctions-card/recent-auctions-card.component';
import { BaseStepWizardComponent } from './Submit Items/components/base-step-wizard/base-step-wizard.component';
import { VehicleInfoStepComponent } from './Submit Items/components/steps/vehicle-info-step/vehicle-info-step.component';
import { VehicleSpecsStepComponent } from './Submit Items/components/steps/vehicle-specs-step/vehicle-specs-step.component';
import { TitledItemStepWizardComponent } from './Submit Items/components/titled-item-step-wizard/titled-item-step-wizard.component';
import { MiscellaneousItemStepWizardComponent } from './Submit Items/components/miscellaneous-item-step-wizard/miscellaneous-item-step-wizard.component';
import { LocationStepComponent } from './Submit Items/components/steps/location-step/location-step.component';
import { ItemInfoStepComponent } from './Submit Items/components/steps/item-info-step/item-info-step.component';
import { ConfirmationStepComponent } from './Submit Items/components/steps/confirmation-step/confirmation-step.component';
import { UnitInfoStepComponent } from './Submit Items/components/steps/unit-info-step/unit-info-step.component';
import { PicturesStepComponent } from './Submit Items/components/steps/pictures-step/pictures-step.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SearchbarQuickFilterComponent } from './Shared/components/searchbar-quick-filter/searchbar-quick-filter.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ItemCardComponent } from './Items/components/item-card/item-card.component';
import { PaginationComponent } from './Shared/components/pagination/pagination.component';
import { ContactUsComponent } from './Shared/components/contact-us/contact-us.component';
import { ItemDetailComponent } from './Items/pages/item-detail/item-detail.component';
import { TabComponentComponent } from './Shared/components/tab-component/tab-component.component';
import { TabContentDirective } from './Shared/directives/tab-content.directive';
import { ImageCarouselComponent } from './Shared/components/image-carousel/image-carousel.component';
import {CurrencyPipe, DatePipe} from '@angular/common';
import { ChecklistComponent } from './Items/components/checklist/checklist.component';
import { PlaceholderAuctionCardComponent } from './Dashboard/components/placeholder-auction-card/placeholder-auction-card.component';
import {ErrorInterceptor} from './Shared/interceptors/error.interceptor';
import { ErrorModalComponent } from './Shared/components/error-modal/error-modal.component';
import { CustomModalComponent } from './Shared/components/custom-modal/custom-modal.component';
import { LoadingModalComponent } from './Shared/components/loading-modal/loading-modal.component';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { GraphqlComponent } from './Devtools/pages/graphql/graphql.component';
import {LoginComponent} from './Login/pages/login/login.component';
import { IndexComponent } from './Dashboard/pages/index/index.component';
import { MatTableModule } from '@angular/material/table';
import { MobileItemModelComponent } from './Items/components/mobile-item-model/mobile-item-model.component';
import { AccountDetailsComponent } from './Account/pages/account-details/account-details.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ProfileRowComponent } from './Account/components/profile-row/profile-row.component';
import { DeleteModalComponent } from './item-drafts/components/delete-modal/delete-modal.component';
import { DraftsComponent } from './item-drafts/pages/drafts/drafts.component';
import { DraftCardComponent } from './item-drafts/components/draft-card/draft-card.component';
import { BusinessWizardComponent } from './Registration/components/business-wizard/business-wizard.component';
import { PersonalWizardComponent } from './Registration/components/personal-wizard/personal-wizard.component';
import { PersonalComponent } from './Registration/components/steps/personal/personal.component';
import { PasswordComponent } from './Registration/components/steps/password/password.component';
import { RegistrationComponent } from './Registration/pages/registration.component';
import { RegistrationBaseStepWizardComponent } from './Registration/components/registration-base-step-wizard/registration-base-step-wizard.component';
import { BusinessComponent } from './Registration/components/steps/business/business.component';
import { SuccessComponent } from './Registration/components/steps/success/success.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ForgotPasswordComponent } from './Password/pages/forgot-password/forgot-password.component';
import { CompanySelectionModalComponent } from './Shared/components/company-selection-modal/company-selection-modal.component';
import { BaseLayoutComponent } from './Shared/components/base-layout/base-layout.component';
import { FailureComponent } from './Registration/components/steps/failure/failure.component';
import { NewDraftItemModalComponent } from './Admin/components/new-draft-item-modal/new-draft-item-modal.component';
import { ConfirmDeleteItemModalComponent } from './Admin/components/confirm-delete-item-modal/confirm-delete-item-modal.component';
import { ConfirmDeleteMessageModalComponent } from './Admin/components/confirm-delete-message-modal/confirm-delete-message-modal.component';
import { AdminComponent } from './Admin/pages/admin/admin.component';
import { NewMessageModalComponent } from './Admin/components/new-message-modal/new-message-modal.component';
import { SiteMessageAlertComponent } from './Admin/components/site-message-alert/site-message-alert.component';
import { SiteMessageCardComponent } from './Admin/components/site-message-card/site-message-card.component';
import { SubmitModalComponent } from './Submit Items/components/submit-modal/submit-modal.component';
import { ActivationComponent } from './Registration/pages/activation/activation.component';
import { InventoryManagementComponent } from './Dashboard/components/inventory-management/inventory-management.component';
import { InventoryManagementCardComponent } from './Dashboard/components/inventory-management-card/inventory-management-card.component';
import { PayoutDetailComponent } from './Payouts/pages/payout-detail/payout-detail.component';
import { ChecklistCardComponent } from './Items/components/checklist-card/checklist-card.component';
import { ExportModalComponent } from './Shared/components/export-modal/export-modal.component';
import { PayoutItemCardComponent } from './Payouts/pages/payout-item-card/payout-item-card.component';
import { GoogleAnalyticDirective } from './Shared/directives/google-analytic.directive';
import { DaterangePickerComponent } from './Shared/components/daterange-picker/daterange-picker.component';
import { MatRadioModule } from '@angular/material/radio';
import { InvoiceComponent } from './Invoices/pages/invoice/invoice.component';
import { MiscFeesModalComponent } from './Items/components/misc-fees-modal/misc-fees-modal.component';

import {APOLLO_OPTIONS, NamedOptions, APOLLO_NAMED_OPTIONS} from 'apollo-angular';
import {HttpLink, HttpLinkHandler} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SubmitItemComponent,
    ItemsComponent,
    PayoutComponent,
    SideNavbarComponent,
    TopNavbarComponent,
    DashboardInfoCardComponent,
    SearchbarComponent,
    DashboardAuctionsComponent,
    UpcomingAuctionsCardComponent,
    FooterComponent,
    ContactUsModalComponent,
    RecentAuctionsCardComponent,
    BaseStepWizardComponent,
    VehicleInfoStepComponent,
    VehicleSpecsStepComponent,
    TitledItemStepWizardComponent,
    MiscellaneousItemStepWizardComponent,
    LocationStepComponent,
    ItemInfoStepComponent,
    ConfirmationStepComponent,
    UnitInfoStepComponent,
    PicturesStepComponent,
    SearchbarQuickFilterComponent,
    ChecklistComponent,
    ItemCardComponent,
    PaginationComponent,
    ContactUsComponent,
    SearchbarQuickFilterComponent,
    ItemDetailComponent,
    TabContentDirective,
    TabComponentComponent,
    ImageCarouselComponent,
    PlaceholderAuctionCardComponent,
    ErrorModalComponent,
    CustomModalComponent,
    LoadingModalComponent,
    GraphqlComponent,
    LoginComponent,
    IndexComponent,
    MobileItemModelComponent,
    AccountDetailsComponent,
    ProfileRowComponent,
    DraftsComponent,
    DraftCardComponent,
    DeleteModalComponent,
    RegistrationComponent,
    BusinessWizardComponent,
    PersonalWizardComponent,
    BusinessComponent,
    RegistrationBaseStepWizardComponent,
    PersonalComponent,
    PasswordComponent,
    SuccessComponent,
    FailureComponent,
    AdminComponent,
    NewMessageModalComponent,
    SiteMessageCardComponent,
    SiteMessageAlertComponent,
    CompanySelectionModalComponent,
    NewDraftItemModalComponent,
    ConfirmDeleteItemModalComponent,
    ConfirmDeleteMessageModalComponent,
    ForgotPasswordComponent,
    BaseLayoutComponent,
    SubmitModalComponent,
    ActivationComponent,
    InventoryManagementComponent,
    InventoryManagementCardComponent,
    PayoutDetailComponent,
    ChecklistCardComponent,
    ExportModalComponent,
    PayoutItemCardComponent,
    GoogleAnalyticDirective,
    DaterangePickerComponent,
    InvoiceComponent,
    MiscFeesModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatRadioModule,
    AutocompleteLibModule,
    NgbModule,
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    UiSwitchModule,
    NgxFileDropModule,
    NgxFilesizeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AuthModule.forRoot({
      domain: environment.auth0Config.domain,
      clientId: environment.auth0Config.clientId,
      scope: 'portal-user:seller',
      audience: environment.auth0Config.audience,
      cacheLocation: 'localstorage',
      connection: 'JJK-AIM',
      theme: 'JJK',
      jjkEnv: environment.envName,
      httpInterceptor: {
        allowedList: [
          environment.aimApi + '/*',
          environment.graphQLConfig.apiUrl,
          environment.graphQLConfig.test + '*',
          environment.graphQLConfig.downloadItemDetailImagesUrl + '*',
          environment.graphQLConfig.downloadPayoutReportUrl + '*'
        ],
      }
    }),
    MatTableModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphQLConfig.apiUrl
          }),
        };
      },
      deps: [HttpLink]  
    },
    { provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpBackend: HttpBackend): NamedOptions {
        return {
          noAuthClient: {
            cache: new InMemoryCache(),
            link: new HttpLinkHandler(new HttpClient(httpBackend), {uri: environment.graphQLConfig.apiUrl})
          }
        };
      },
      deps: [HttpBackend]
    },
    CurrencyPipe,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

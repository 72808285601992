import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StateUs} from '../../../models/state-us';
import {SubmitItemService} from '../../../services/submit-item.service';
import {Observable} from 'rxjs';
import {RDSDraftItem} from '../../../../item-drafts/services/drafts.service';
import { GetDropdownValuesQuery } from '../../../../generated/graphql';

@Component({
  selector: 'app-location-step',
  templateUrl: './location-step.component.html',
  styleUrls: ['./location-step.component.scss']
})
export class LocationStepComponent implements OnInit {
  @Input() public showForm: boolean;
  @Input() public parentForm: FormGroup;
  public dropdownValues: GetDropdownValuesQuery;
  @Input() public ddvSubject: Observable<GetDropdownValuesQuery>;
  @Input() public draft: RDSDraftItem;
  public form: FormGroup;
  public states: StateUs[];
  public loading = true;

  constructor(private fb: FormBuilder, private submitItemService: SubmitItemService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      addressLine1: ['', [Validators.required, Validators.maxLength(255)]], //Need to max 255 both fields
      city: ['', [Validators.required, Validators.maxLength(50)]],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern('^[\\d]{5}(-[\\d]{4})?$')]],
      county: ['', Validators.maxLength(100)],
    });

    this.ddvSubject.subscribe(values => {
      this.dropdownValues = values;
      this.loading = values == null;
      if (!this.loading && this.draft) {
        this.form.setValue({
          addressLine1: this.draft.offSitePhysicalAddress,
          city: this.draft.offSitePhysicalCity,
          state: this.draft.offSitePhysicalState,
          zip: this.draft.offSitePhysicalZip,
          county: this.draft.taxCounty,
        });
      }
    });

    this.parentForm.addControl('locationFormGroup', this.form);

    this.submitItemService.getStatesUSList().subscribe(states => {
      this.states = states;
    });
  }

}

<div class="container-fluid">
    <div class="my-3">
      <h3>Create an Account</h3>
      <div class="row mt-3" *ngIf="showBusinessWizard || showPersonalWizard">
        <div class="col-12">
            ( <span class="text-danger"> * </span> ) Required Information
        </div>
      </div>
      <div class="row my-4">
        <div *ngIf="!showBusinessWizard && !showPersonalWizard" class="offset-md-4 col-md-4 col-12">
          <h5 class="text-center">What kind of account are you registering for?</h5>
          <button class="btn text-light bg-altec btn-block" (click)="showBusinessWizard = true">Business</button>
          <h5 class="text-center">or</h5>
          <button class="btn text-light bg-altec btn-block" (click)="showPersonalWizard = true">Personal</button>
        </div>
      </div>
      <div *ngIf="showBusinessWizard">
        <app-business-wizard></app-business-wizard>
      </div>
      <div *ngIf="showPersonalWizard">
        <app-personal-wizard></app-personal-wizard>
      </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { UserCompany } from './company-selection.service';
import { GoogleAnalyticService } from './google-analytic.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  public $currentUser: BehaviorSubject<AuthResponseData> = new BehaviorSubject(undefined);
  public isCurrentUserAdmin: boolean = false;
  public isSeller: boolean = false;

  constructor(private authService: AuthService, private googleAnalyticService: GoogleAnalyticService) {
    this.authService.user$.subscribe(user => {
      const currentUser = this.transformUserData(user);
      this.setCurrentUser(currentUser);
    });
  }

  public setCurrentUser(currentUser: AuthResponseData): void {
    this.$currentUser.next(currentUser);
    this.isCurrentUserAdmin = currentUser.admin;
    this.isSeller = currentUser.isSeller;
    this.googleAnalyticService.setGAUser(currentUser.jjkId);
  }

  public transformUserData(auth0User: any): AuthResponseData {
    return {
      firstName: auth0User.given_name,
      lastName: auth0User.family_name,
      accounts: auth0User['https://jjkaneportal.com/accounts'],
      email: auth0User.email,
      auth0Id: auth0User.sub,
      jjkId: parseInt(auth0User.sub.replace('auth0|AIMDB|', ''), 10),
      admin: auth0User['https://jjkaneportal.com/admin'],
      isSeller: auth0User['https://jjkaneportal.com/isSeller']
    };
  }
}

export interface AuthResponseData {
  email: string;
  firstName: string;
  auth0Id: string;
  jjkId: number;
  lastName: string;
  accounts: Array<UserCompany>;
  admin: boolean;
  isSeller: boolean;
}


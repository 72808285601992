import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {GraphQLRequest} from '../../Shared/models/graphql-request';
import {map} from 'rxjs/operators';
import {GraphQLResponse} from '../../Shared/models/graphql-response';
import {Observable} from 'rxjs';
import {SortFilterOutput} from '../../Shared/components/searchbar/searchbar.component';
import { JjkIntegration } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class DraftsService {

  public accountIds: number[];
  public activeDraft: RDSDraftItem;

  constructor(private jjkIntegration: JjkIntegration) { }

  public queryDraftItems(accountIds: number[], searchOptions?: SortFilterOutput): Observable<any> {
    return this.jjkIntegration.draftItems({accountIds, searchOptions}).pipe(map(response => {
      return response.data.queryDraftItems;
    }));
  }

  public getUserFilterOptions(accountIds: number[]): Observable<any> {
    return this.jjkIntegration.draftFilterValues({accountIds}).pipe(map(response => {
      return response.data.draftFilterValues
    }));
  }

  public saveItem(draftItem: RDSDraftItem): Observable<any> {
    return this.jjkIntegration.saveDraftItems({draftItem}).pipe(map(response => {
      return response.data.saveDraftItem;
    }));
  }

  public updateItem(draftItem: RDSDraftItem): Observable<any> {
    return this.jjkIntegration.updateDraftItem({draftItem}).pipe(map(response => {
      return response.data.updateDraftItem;
    }))
  }

  public deleteItem(draftItem: RDSDraftItem): Observable<any> {
    return this.jjkIntegration.deleteDraftItem({draftId: draftItem.id}).pipe(map(response => {
      return response.data.deleteDraftItem;
    }));
  } 
}

export interface DraftFilterResponse {
  draftFilterValues: DraftFilter;
}

export interface DraftFilter {
  category: string[];
  make: string[];
  model: string[];
  upperMake: string[];
  upperModel: string[];
}

export interface DraftQueryResponse {
  queryDraftItems: DraftResponse;
}

export interface DraftSearchResponse {
  searchDraftItems: DraftResponse;
}

export interface DraftResponse {
  results: Array<RDSDraftItem>;
}

export interface RDSDraftItem {
  id?: number;
  itemId?: string;
  itemType?: string;
  currentStep?: number;
  userId?: string;
  submitTimestamp?: string;
  parentCategory?: string;
  category?: string;
  vin?: string;
  year?: string;
  make?: string;
  model?: string;
  unitNumber?: string;
  sellerFleetNumber2?: string;
  plateNumber?: string;
  engine?: string;
  fuel?: string;
  transmission?: string;
  odometer?: string;
  hours?: string;
  optionalEquipment?: string;
  airConditioning?: boolean;
  upperSerialNumber?: string;
  upperMake?: string;
  upperModel?: string;
  upperFuel?: string;
  height?: string;
  capacity?: string;
  capacityUnit: string;
  capacityType?: string;
  mounted?: string;
  upperOptionalEquipment?: string;
  itemNotes?: string;
  dateCreated?: string;
  offSitePhysicalAddress?: string;
  offSitePhysicalCity?: string;
  offSitePhysicalState?: string;
  offSitePhysicalZip?: string;
  taxCounty?: string;
  titled?: boolean;
}

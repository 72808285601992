<div class="fluid-container px-2">
    <div class="row">
        <div *ngIf="messageService?.activeMessages?.length != 0" class="col-12">
            <div class="mt-2" *ngFor="let message of messageService.activeMessages">
                <site-message-alert [message]="message"></site-message-alert>
            </div>
        </div>

        <div class="col-xl-3 col-lg-6 col-sm-12 mt-2" *ngIf="showSeller || loadingSeller">
          <div class="blue-card" *ngIf="!loadingSeller; else loading">
            <dashboard-info-card [title]="'Submitted Items'" [data]="inventoryManagementDetails?.assignedItems"
                                 [icon]="faTasks" [color]="'blue'" (clicked)="submittedItemsClicked()">
            </dashboard-info-card>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-sm-12 mt-2" *ngIf="showSeller || loadingSeller">
            <div class="green-card" *ngIf="!loadingSeller; else loading">
                <dashboard-info-card [title]="'YTD Payouts'" [data]="ytdPayouts" [icon]="faMoneyCheckAlt"
                                     [color]="'green'" (clicked)="ytdPayoutsClicked()">
                </dashboard-info-card>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 mt-2" [class.col-xl-3]="showSeller || loadingSeller">
          <div class="blue-card" *ngIf="!loadingSeller; else loading">
            <dashboard-info-card [title]="'Auction Registrations'" [data]="currentRegisteredAuctions" [icon]="faGavel"
                                 [color]="'orange'">
            </dashboard-info-card>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 mt-2" [class.col-xl-3]="showSeller || loadingSeller">
          <div class="blue-card" *ngIf="!loadingSeller; else loading">
            <dashboard-info-card [title]="'Est. Purchase Balance'" [data]="estimatedPurchaseBalance" [icon]="faReceipt"
                                 [color]="'teal'" (clicked)="estimatedPurchaseBalanceClicked()">
            </dashboard-info-card>
          </div>
        </div>
      <ng-template #loading>
        <div class="loading">
        </div>
      </ng-template>
    </div>
  <searchbar [title]="'Items'" [placeholder]="'Search Items'" [hasQuickFilters]="showSeller && searchType === 'seller'" [hasItemSearchToggle]="showSeller" [searchTipText]="searchTipText" [quickFilters]="searchbarQuickFilters"
             (quickFilterClicked)="quickFilter($event)" (searched)="search($event)" (itemSearchTypeToggled)="toggleItemSearchType($event)"></searchbar>
  <div class="mt-2" *ngIf="showSeller">
        <inventory-management [inventoryManagementDetails]="inventoryManagementDetails" (quickFilterClicked)="quickFilter($event)"></inventory-management>
    </div>
    <div class="row mb-2">
        <div class="col-12 mt-2">
            <dashboard-auctions [upcomingAuctions]="upcomingAuctions" [recentAuctions]="recentAuctions"></dashboard-auctions>
        </div>
    </div>
</div>

<div class="container-fluid">
  <!-- Title -->
  <h3 class="my-3">Item Drafts</h3>

  <!-- Search/Filter -->
  <div class="mb-3">
    <searchbar #searchbar [title]="'Item Drafts'" [placeholder]="'Search Item Drafts'" [hasCollapsedFilters]="true"
               [sortFilterOptions]="sortFilter" [hasExport]="false" (exportClicked)="exportResults()" (applyClicked)="applyFilterSortOptions($event)"
               (exitSearch)="search()" (searched)="searchDrafts($event)"></searchbar>
  </div>

  <!-- Items -->
  <div *ngIf="!loading">
    <div *ngIf="draftItems.records < 1; else second">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="no-results">
            <p><em>You have no active item drafts</em></p>
          </div>
        </div>
      </div>
    </div>
    <ng-template #second>
      <div *ngFor="let item of draftItems.records; let i = index" class="py-3 px-3 border-bottom" [class.odd]="i % 2 == 0">
        <draft-card (deleteDraft)="search()" [draftItem]="item"></draft-card>
      </div>
    </ng-template>
  </div>

  <!-- Skeleton Loading -->
  <div *ngIf="loading">
    <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]; let i = index;" class="py-3 px-3 border-bottom" [class.odd]="i % 2 == 0">
      <div class="row d-flex align-items-center">

        <!-- Unit Details -->
        <div class="col-md-3 col-xs-12">
          <div class="loading-text-field loading my-3 w-75"></div>
          <div class="loading-text-field loading my-2 w-50"></div>
          <div class="loading-text-field loading my-2 w-60"></div>
          <div class="loading-text-field loading my-2 w-30"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
          <div class="loading-text-field loading my-2 w-20"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
        </div>

        <!-- Auction Details -->
        <div class="col-md-3 col-xs-12">
          <div class="loading-text-field loading my-2 w-75"></div>
          <div class="loading-text-field loading my-2 w-50"></div>
          <div class="loading-text-field loading my-2 w-30"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
          <div class="loading-text-field loading my-2 w-20"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
        </div>

        <!-- Status -->
        <div class="col-md-3 col-xs-12">
          <div class="loading-text-field loading my-2 w-50"></div>
          <div class="loading-text-field loading my-2 w-30"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
          <div class="loading-text-field loading my-2 w-20"></div>
        </div>

      </div>
    </div>
  </div>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, NavigationEnd, RouterLink, Params } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticService implements OnDestroy {

  private unsubscribe$ = new Subject();

  constructor(public router: Router) {
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe($event => {
      try {
        if (typeof ga === 'function') {
          if ($event instanceof NavigationEnd) {
            const toSend = window.location.pathname + window.location.search;
            ga('set', 'page', toSend);
            ga('send', 'pageview');
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  public logAnalyticEvent(data: GoogleAnalyticEventObject, callback: Function) {
    if (typeof ga === 'function') {
      ga('send', 'event', {
        eventCategory: data.category,
        eventAction: data.action,
        eventLabel: data.label,
        transport: 'beacon',
        hitCallback: callback()
      });
    }
  }

  public setGAUser(id: number){
    ga('set', 'userId', id);
    ga('send', 'event', 'authentication', 'user-id available');
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export interface GoogleAnalyticEventObject {
  category: string;
  action: string;
  label: string;
}

export interface GoogleAnalyticObject {
  event: GoogleAnalyticEventObject;
  route?: any[];
  queryParams?: Params;
}

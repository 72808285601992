<div class="container-fluid padding">
  <!-- Header -->
  <h3 *ngIf="!loading" class="my-3">
    <ng-container *ngIf="isBuyerItemDetail; else sellerHeader">
      Item # {{itemDetail.itemId}}
    </ng-container>
    <ng-template #sellerHeader>
      Unit # {{itemDetail.unitNumber}}
    </ng-template>
  </h3>
  <h3 *ngIf="loading" class="loading-header loading my-3 w-50"></h3>

  <!-- Basic Auction Info Section -->
  <div class="row mb-3 padding">

    <!-- Image Carousel -->
    <div class="col-md-12 col-lg-5">

      <image-carousel [imageUrls]="imageUrls" [loading]="loading" (download)="downloadImages()"></image-carousel>

    </div>

    <!-- Data -->
    <div *ngIf="!loading" class="col-md-12 col-lg-7">

      <ng-container *ngIf="isBuyerItemDetail; else sellerStatus">
        <label><b>Item Total: {{itemDetail.buyersTotal | currency}}</b></label>

        <div class="spinner-border" *ngIf="downloadingInvoicePdf; else downloadLink">
          <span class="sr-only"></span>
        </div>
        <ng-template #downloadLink>
          <fa-icon [icon]="faReceipt" size="md" class="ml-2 receipt cursor-pointer" (click)="downloadInvoicePdf(itemDetail.buyerAccountId, itemDetail.bidderBuyerInvoiceId)"></fa-icon>
        </ng-template>
      </ng-container>

      <ng-template #sellerStatus>
        <div>
          <label><b>Status: </b></label>
          <p *ngIf="!payoutId" class="mb-1 ml-1 d-inline-block">{{translatedItemStatus}}</p>
          <p *ngIf="payoutId" class="mb-1 ml-1 d-inline-block alt-link"
             [GA]="{event: {category: 'Select Payout Status', action: 'Click', label: 'Item Detail'}}"
             (click)="openPayout(payoutId)">{{translatedItemStatus}}</p>
          <ng-container *ngIf="itemDetail.salePrice !== 0">
            <p *ngIf="itemDetail.amountOwed != null && itemDetail.amountOwed > 0"
               class="mb-1 ml-1 d-inline-block finalized">{{ itemDetail.amountOwed | currency  }}</p>
            <p *ngIf="itemDetail.amountOwed != null && itemDetail.amountOwed < 0"
               class="mb-1 ml-1 d-inline-block negative-seller-net">{{ itemDetail.amountOwed | currency  }}</p>
            <p *ngIf="itemDetail.amountOwed === null || itemDetail.amountOwed === 0" class="mb-1 ml-1 d-inline-block ">
              <b>{{ itemDetail.amountOwed | currency  }}</b></p>
            <p
              *ngIf="translatedItemStatus === 'Sold' && !itemDetail.sale.payoutsFinalized ? 'Pending' : itemDetail.amountOwed | currency"></p>
          </ng-container>
        </div>

        <div *ngIf="itemDetail.titled">
          <label><b>Title: </b></label>
          <div *ngIf="titleStatus === 'Awaiting Title'" class="d-inline-block ml-2">
            <fa-icon [icon]="faFileContract" size="md" class="mr-2 error-text"></fa-icon>
            <span>Awaiting Title</span>
          </div>
          <div *ngIf="titleStatus === 'Title Submitted'" class="d-inline-block ml-2">
            <fa-icon [icon]="faFileContract" size="md" class="mr-2 success-text"></fa-icon>
            <span class="mr-2">Title Submitted</span>
            <span>{{itemDetail.titleReceivedDate | date: 'shortDate'}}</span>
          </div>
        </div>
      </ng-template>


      <div *ngIf="isBuyerItemDetail">
        <label><b>Invoice #: </b></label>
        <p class="mb-1 ml-1 d-inline-block">{{itemDetail.bidderBuyerInvoiceId}}</p>
      </div>

      <div>
        <label><b>Lot #: </b></label>
        <p class="mb-1 ml-1 d-inline-block">{{itemDetail.lotNumber}}</p>
      </div>

      <div>
        <label><b>Auction: </b></label>
        <p class="mb-1 ml-1 d-inline-block">{{itemIsHolding ? '' : itemDetail.saleInfo}}</p>
      </div>

      <div>
        <label><b>Type: </b></label>
        <p class="mb-1 ml-1 d-inline-block">{{itemIsHolding ? '' : itemDetail.saleType === 'Timed Auction' || itemDetail.saleType === 'Public Auction' ? 'Auction' : itemDetail.saleType}}</p>
      </div>

      <div>
        <label><b>Auction Date: </b></label>
        <p class="mb-1 ml-1 d-inline-block">{{itemIsHolding ? '' : itemDetail.saleDate | date: 'shortDate'}}</p>
      </div>

      <div>
        <label><b>{{ isBuyerItemDetail ? 'Pick Up' : 'Auction'}} Location: </b></label>
        <p class="mb-1 ml-1 d-inline-block">{{itemIsHolding ? '' : formatCityState(itemDetail.offSitePhysicalCity, itemDetail.offSitePhysicalState)}}</p>
      </div>

      <div>
        <label><b>Catalog Description:</b></label>
        <p class="mb-1 px-0 py-0 d-block">
          {{itemDetail.catalogDescription}}
        </p>
      </div>

    </div>

    <div *ngIf="loading" class="col-md-12 col-lg-7">

      <div>
        <div class="loading-text-field loading my-3 w-20"></div>
      </div>

      <div>
        <div class="loading-text-field loading my-3 w-10"></div>
      </div>

      <div>
        <div class="loading-text-field loading my-3 w-30"></div>
      </div>

      <div>
        <div class="loading-text-field loading my-3 w-50"></div>
      </div>

      <div>
        <div class="loading-text-field loading my-3 w-30"></div>
      </div>

      <div>
        <div class="loading-text-field loading my-3 w-60"></div>
      </div>

      <div>
        <div class="loading-text-field loading my-3 w-40"></div>

        <div class="loading-text-field loading w-90"></div>
        <div class="loading-text-field loading w-90"></div>
        <div class="loading-text-field loading w-90"></div>
        <div class="loading-text-field loading w-90"></div>

      </div>

    </div>

  </div>

  <!-- Tabbed Section -->
  <tab-engine [activeTabID]="'itemInfo'" >

    <!-- Item Info Tab -->
    <tab-engine-content [title]="'Item Info'" [id]="'itemInfo'" [gaTrigger]="true">
      <ng-template>
        <div *ngIf="!loading">
          <div class="col-12 px-0">
            <h5><b>Vehicle Info</b></h5>
          </div>

          <div class="row no-gutters">
            <div class="col-lg-6">
              <div>
                <label><b>Category: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.category}}</p>
              </div>
              <div>
                <label><b>VIN: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.vin}}</p>
              </div>
              <div>
                <label><b>Year: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.year}}</p>
              </div>
              <div>
                <label><b>Make: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.make}}</p>
              </div>
              <div>
                <label><b>Model: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.model}}</p>
              </div>
              <div>
                <label><b>Item Number: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.id}}</p>
              </div>
              <div>
                <label><b>License Plate Number: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.plateNumber}}</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label><b>Engine: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.engine}}</p>
              </div>
              <div>
                <label><b>Fuel: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.fuel}}</p>
              </div>
              <div>
                <label><b>Transmission: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.transmission}}</p>
              </div>
              <div>
                <label><b>Odometer: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.odometer | number}}</p>
              </div>
              <div>
                <label><b>Hours: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.hours}}</p>
              </div>
              <div>
                <label><b>Conditions: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.conditions}}</p>
              </div>
              <div>
                <label><b>Optional Equipment: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.optionalEquipment}}</p>
              </div>
            </div>
          </div>

          <div class="col-12 px-0 mt-3">
            <h5><b>Unit Info</b></h5>
          </div>

          <div class="row no-gutters">
            <div class="col-lg-6">
              <div>
                <label><b>Serial Number: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.upperSerialNumber}}</p>
              </div>
              <div>
                <label><b>Make: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.upperMake}}</p>
              </div>
              <div>
                <label><b>Model: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.upperModel}}</p>
              </div>
              <div>
                <label><b>Fuel: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.upperFuel}}</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label><b>Height(ft): </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.height}}</p>
              </div>
              <div>
                <label><b>Capacity: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.capacity}}</p>
              </div>
              <div>
                <label><b>Mounted: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.mounted}}</p>
              </div>
              <div>
                <label><b>Optional Equipment: </b></label>
                <p class="mb-1 ml-1 d-inline-block">{{itemDetail.upperOptionalEquipment}}</p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="loading">

          <div class="col-12 px-0">
            <div class="loading-text-field loading my-3 w-40"></div>
          </div>

          <div class="row no-gutters">
            <div class="col-lg-6">
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
              <div class="loading-text-field loading my-3 w-10"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-40"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
              <div class="loading-text-field loading my-3 w-30"></div>
            </div>
            <div class="col-lg-6">
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
              <div class="loading-text-field loading my-3 w-10"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-40"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
            </div>
          </div>

          <div class="col-12 px-0 mt-3">
            <div class="loading-text-field loading my-3 w-40"></div>
          </div>

          <div class="row no-gutters">

            <div class="col-lg-6">
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
              <div class="loading-text-field loading my-3 w-10"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
            </div>

            <div class="col-lg-6">
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
              <div class="loading-text-field loading my-3 w-10"></div>
              <div class="loading-text-field loading my-3 w-20"></div>
            </div>

          </div>

        </div>

      </ng-template>
    </tab-engine-content>

    <!-- Auction Results Tab -->
    <tab-engine-content *ngIf="translatedItemStatus === 'Sold'" [title]="'Auction Results'" [id]="'auctionResults'" [gaTrigger]="true">
      <ng-template>
        <div *ngIf="!loading">
          <div class="row no-gutters">
            <ng-container *ngIf="isBuyerItemDetail; else sellerAuctionResults">
              <div class="col-lg-4 d-flex align-items-center">
                <div>
                  <p class="font-size-large">Item Total: {{itemDetail.buyersTotal | currency}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div>
                  <label><b>Invoice #: </b></label>
                  <p class="mb-1 ml-1 d-inline-block"(click)="downloadInvoicePdf(itemDetail.buyerAccountId, itemDetail.bidderBuyerInvoiceId)"><a href="javascript:">{{itemDetail.bidderBuyerInvoiceId}}</a></p>
                </div>
                <div>
                  <label><b>Bidder #: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.bidderNumber}}</p>
                </div>
                <div>
                  <label><b>Auction Price: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.salePrice | currency}}</p>
                </div>
                <div>
                  <label><b>Buyer's Fee: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.buyerFee | currency}}</p>
                </div>
                <div>
                  <label><b>Tax: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.tax | currency}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div>
                  <label><b>Title Fee: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.titleFee | currency}}</p>
                </div>
                <div>
                  <label><b>CA License Fee: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.caLicenseFee | currency}}</p>
                </div>
                <div>
                  <label><b>CA Weight Fee: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.caWeightFee | currency}}</p>
                </div>
                <div>
                  <label><b>Misc Fees: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.fee1Actual + itemDetail.fee1aActual + itemDetail.fee2Actual + itemDetail.fee3Actual +
                    itemDetail.fee4Actual + itemDetail.fee5Actual + itemDetail.fee6Actual + itemDetail.fee7Actual + itemDetail.fee8Actual + itemDetail.fee9Actual| currency}}
                      <a *ngIf="itemDetail.fee1Actual + itemDetail.fee1aActual + itemDetail.fee2Actual + itemDetail.fee3Actual + itemDetail.fee4Actual + itemDetail.fee5Actual
                      + itemDetail.fee6Actual + itemDetail.fee7Actual + itemDetail.fee8Actual + itemDetail.fee9Actual > 0" href="javascript:" (click)="openMiscFeesModal()">View Fees</a>
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-template #sellerAuctionResults>
              <div class="col-lg-4 d-flex align-items-center">
                <div>
                  <p class="font-size-large">Seller Net:</p>
                  <ng-container *ngIf="itemDetail.salePrice !== 0; else noSaleTemplate">
                    <p *ngIf="itemDetail.amountOwed != null && itemDetail.amountOwed > 0"
                       class="mb-1 text-start text-lg-center finalized">{{ itemDetail.amountOwed | currency  }}</p>
                    <p *ngIf="itemDetail.amountOwed != null && itemDetail.amountOwed < 0"
                       class="mb-1 text-start text-lg-center negative-seller-net">{{ itemDetail.amountOwed | currency  }}</p>
                    <p
                      *ngIf="translatedItemStatus === 'Sold' && !itemDetail.sale.payoutsFinalized ? 'Pending' : itemDetail.amountOwed | currency"></p>
                    <p *ngIf="itemDetail.amountOwed === null || itemDetail.amountOwed === 0"
                       class="mb-1 text-start text-lg-center inline"><b>{{ itemDetail.amountOwed | currency  }}</b></p>
                  </ng-container>
                  <ng-template #noSaleTemplate>
                    <p class="mb-1 text-start text-lg-center inline"><b>No Sale</b></p>
                  </ng-template>
                </div>
              </div>
              <div class="col-lg-4">
                <div>
                  <label><b>Auction Price: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.salePrice | currency}}</p>
                </div>
                <div>
                  <label><b>Commission: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.commission | currency}}</p>
                </div>
                <div>
                  <label><b>Transportation: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.expenseTransportation | currency}}</p>
                </div>
                <div>
                  <label><b>Washing: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.expenseWashing | currency}}</p>
                </div>
                <div>
                  <label><b>Decommission: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.expenseDecommission | currency}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div>
                  <label><b>Adjustments: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.adjustmentAmount | currency}}</p>
                </div>
                <div>
                  <label><b>Part & Repairs: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.expensePartsRepairs | currency}}</p>
                </div>
                <div>
                  <label><b>Misc 1: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.expenseMisc1 | currency}}</p>
                </div>
                <div>
                  <label><b>Misc 2: </b></label>
                  <p class="mb-1 ml-1 d-inline-block">{{itemDetail.expenseMisc2 | currency}}</p>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div *ngIf="loading">
          <div class="row no-gutters">
            <div class="col-lg-4 d-flex align-items-center">
              <div class="loading-header loading my-3 w-50"></div>
            </div>
            <div class="col-lg-4">
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-40"></div>
              <div class="loading-text-field loading my-3 w-50"></div>
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-40"></div>
            </div>
            <div class="col-lg-4">
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-40"></div>
              <div class="loading-text-field loading my-3 w-30"></div>
              <div class="loading-text-field loading my-3 w-50"></div>
            </div>
          </div>
        </div>

      </ng-template>
    </tab-engine-content>

    <!-- Checklist Tab -->
    <tab-engine-content *ngIf="!isBuyerItemDetail" [title]="'Item Management'" [id]="'checklist'" [gaTrigger]="true">
      <ng-template>
        <div class="row no-gutters">
          <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12">
            <checklist [itemDetail]="itemDetail" [loading]="loading"></checklist>
          </div>
        </div>
      </ng-template>
    </tab-engine-content>

  </tab-engine>

</div>

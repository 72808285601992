<div *ngIf="message.level === 'INFO'" class="alert alert-secondary mb-0 custom-alert info" role="alert">
    <h4 class="alert-heading">
        <fa-icon class="mr-2" [icon]="faInfoCircle" size='lg'></fa-icon>
        {{ message.title }}
    </h4>
    <hr>
    <p class="mb-1">{{ message.message }}</p>
</div>

<div *ngIf="message.level === 'WARNING'" class="alert alert-warning custom-alert warning" role="alert">
    <h4 class="alert-heading">
        <fa-icon class="mr-2" [icon]="faExclamationTriangle" size='lg'></fa-icon>
        {{ message.title }}
    </h4>
    <hr>
    <p class="mb-1">{{ message.message }}</p>
</div>
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetFaIcon } from 'src/app/Shared/functions/icon-helpers';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss']
})
export class ContactUsModalComponent implements OnInit {

  public faUser = GetFaIcon('user', 'fas');
  public faPhone = GetFaIcon('phone', 'fas');
  public faEnvelope = GetFaIcon('envelope', 'fas');

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}


<div class="wrapper">

    <div class="my-3 wrapper">

        <!-- Current User -->
        <div class="col-12 d-flex justify-content-start">
            <span class="h2 text-center">Account Details</span>
        </div>

        <div class="container mt-4">
            <div class="row d-flex justify-content-center">

                <!-- Account Info -->
                <div class="col-md-12 col-lg-6">

                    <!-- Title -->
                    <div class="row no-gutters">
                        <div class="col-3 d-flex align-items-center justify-content-end">
                            <fa-layers [fixedWidth]="true" size="4x">
                                <fa-icon [icon]="faCircle" class="icon-background"></fa-icon>
                                <fa-icon [icon]="faUser" [inverse]="true" transform="shrink-6"></fa-icon>
                            </fa-layers>
                        </div>
                        <div class="col-9 pl-2 d-flex align-items-center">
                            <span class="h5">Personal Information</span>
                        </div>
                    </div>

                    <!-- Account Details -->
                    <div class="offset-md-3 col-md-9 col-sm-12 px-0 pl-2 mt-2">

                        <profile-row [loaded]="accountLoaded" [title]="'First Name'" [value]="authUser?.firstName"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Last Name'" [value]="authUser?.lastName"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Email'" [value]="authUser?.email"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Phone'" [value]="user?.accountInfo.phoneNumber"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Address'" [value]="user?.accountInfo.address"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'City'" [value]="user?.accountInfo.city"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'State'" [value]="user?.accountInfo.state"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Zip'" [value]="user?.accountInfo.zip"></profile-row>

                       <div class="w-100 button-wrapper">
                           <button (click)="changePassword()" [disabled]="pwRequested" [ngClass]="{'btn-outline-dark': pwRequested, 'text-light bg-altec': !pwRequested}" class="btn mt-3">Change Password</button>
                       </div>

                    </div>

                </div>

                <!-- Company Info -->
                <div class="col-md-12 col-lg-6 mt-4 mt-lg-0">

                    <!-- Title -->
                    <div class="row no-gutters">
                        <div class="col-3 d-flex align-items-center justify-content-end">
                            <fa-layers [fixedWidth]="true" size="4x">
                                <fa-icon [icon]="faCircle" class="icon-background"></fa-icon>
                                <fa-icon [icon]="faBuilding" [inverse]="true" transform="shrink-6"></fa-icon>
                            </fa-layers>
                        </div>
                        <div class="col-9 pl-2 d-flex align-items-center">
                            <span class="h5">Company Information</span>
                        </div>
                    </div>

                    <!-- Account Details -->
                    <div class="offset-md-3 col-md-9 col-sm-12 px-0 pl-2 mt-2">
                        <profile-row [loaded]="accountLoaded" [title]="'Name'" [value]="user?.accountInfo.companyInfo.companyName"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Phone'" [value]="user?.accountInfo.companyInfo.companyPhone"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Address'" [value]="user?.accountInfo.companyInfo.companyAddress"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'City'" [value]="user?.accountInfo.companyInfo.companyCity"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'State'" [value]="user?.accountInfo.companyInfo.companyState"></profile-row>
                        <profile-row [loaded]="accountLoaded" [title]="'Zip'" [value]="user?.accountInfo.companyInfo.companyZip"></profile-row>

                    </div>


                </div>


            </div>
        </div>
    </div>

</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchbarComponent, SortFilterData, SortFilterOutput} from '../../../Shared/components/searchbar/searchbar.component';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {first} from 'rxjs/operators';
import {download} from '../../../Shared/functions/download-helpers';
import {equalsIgnoreOrder} from '../../../Shared/functions/array-helpers';
import { InvoiceSearchRequest } from '../../../generated/graphql';
import {InvoiceService} from '../../services/invoice.service';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @ViewChild('searchbar', {static: true})
  public searchbarComponent: SearchbarComponent;

  public loading = true;
  public searchTerm = '';
  public page = 0;
  public totalElements: number;
  public invoices: any[];
  public searchTipText = 'You can search by Invoice ID and Lot #.';
  public sortFilter: SortFilterData = {
    hasFilterOptions: true,
    hasSortOptions: true,
    sortData: [
      {
        title: 'Auction Date',
        backEndTitle: 'saleDate'
      },
      {
        title: 'Location',
        backEndTitle: 'saleLocation'
      },
      {
        title: 'Invoice Total',
        backEndTitle: 'invoiceTotal'
      },
      {
        title: 'Invoice Balance',
        backEndTitle: 'invoiceBalance'
      },
    ],
    filterData: [
      {
        title: 'Auction Date',
        options: ['This Month', 'This Year', 'Custom'],
        backEndTitle: 'auctionDate'
      },
      {
        title: 'Location',
        options: [],
        backEndTitle: 'auctionLocation'
      },
      {
        title: 'Invoice Balance',
        options: ['Unpaid'],
        backEndTitle: 'invoiceBalance'
      },
    ]
  };
  public appliedSortFilters: SortFilterOutput;
  public columnsToDisplay = ['id', 'auctionDate', 'auctionLocation', 'bidderNumber', 'invoiceTotal', 'invoiceBalance'];
  public invoiceIdsBeingDownloaded = [];
  private skeletonData: any = [
    {}, {}, {}, {}, {}, {}, {}, {}, {}
  ];
  private selectedAuctionDate: string;

  constructor(private invoiceService: InvoiceService,
              private companySelection: CompanySelectionService,
              private titleService: Title,
              private router: Router,
              private route: ActivatedRoute,
              private googleAnalyticService: GoogleAnalyticService) {
    this.titleService.setTitle('Invoices - JJKane');
  }

  ngOnInit(): void {
    this.invoices = this.skeletonData;

    this.invoiceService.getInvoiceSaleLocations(this.companySelection.getSelectedAccountIds()).subscribe(response => {
      const locationFilter = this.sortFilter.filterData.find(x => x.backEndTitle === 'auctionLocation');
      locationFilter.options = response.map(x => x.description + ', ' + x.name);
      this.initializeStateFromUrl();
    });
  }

  public pageChange(page: number): void {
    this.loading = true;
    this.page = page ?? 0;
    this.invoices = this.skeletonData;
    window.scroll(0, 0);

    const request = this.buildSearchRequest();
    this.updateUrlFromRequest(request);

    this.invoiceService.searchInvoices(request).subscribe(response => {
      this.invoices = response.content;
      this.totalElements = response.totalElements;
      this.loading = false;
    });
  }

  private buildSearchRequest(): InvoiceSearchRequest {
    var request : InvoiceSearchRequest = {
      buyerAccountIds: this.companySelection.getSelectedAccountIds(),
      page: (this.page + 1),
      pageSize: 10,
      searchTerm: this.searchTerm
    };

    if (this.appliedSortFilters) {
      this.addFiltersToRequest(request);
      this.addSortSelectionToRequest(request);
    }

    return request;
  }

  private addFiltersToRequest(request: InvoiceSearchRequest): void {
    this.selectedAuctionDate = null;
    this.appliedSortFilters.filterResults?.forEach(filter => {
      if (filter.backEndFilter === 'auctionDate') {
        this.selectedAuctionDate = filter.selection;
        const now = new Date();
        if (filter.selection === 'This Month') {
          request.auctionDateStart = `${now.getFullYear()}-${now.getMonth() + 1}-1 00:00:00`;
          request.auctionDateEnd = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 23:59:59`;
        } else if (filter.selection === 'This Year') {
          request.auctionDateStart = `${now.getFullYear()}-1-1 00:00:00`;
          request.auctionDateEnd = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 23:59:59`;
        } else {
          // custom
          const dates = filter.selection.split(' - ');
          const startDate = new Date(dates[0]);
          const endDate = new Date(dates[1]);
          dates[0] = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
          dates[1] = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
          request.auctionDateStart = `${dates[0]} 00:00:00`;
          request.auctionDateEnd = `${dates[1]} 23:59:59`;
        }
      } else {
        request[filter.backEndFilter] = filter.selection;
      }
    });
  }

  private addSortSelectionToRequest(request: InvoiceSearchRequest): void {
    if (this.appliedSortFilters.sortResults && this.appliedSortFilters.sortDirection) {
      request.sortField = this.appliedSortFilters.backEndSortResult;
      request.sortDirection = this.appliedSortFilters.sortDirection;
    }
  }

  private updateUrlFromRequest(request: InvoiceSearchRequest): void {
    const newQueryParams: Params = {};
    newQueryParams.page = this.page;
    if (request.searchTerm) {
      newQueryParams.searchTerm = request.searchTerm;
    }
    if (request.sortField) {
      newQueryParams.sortField = request.sortField;
    }
    if (request.sortDirection) {
      newQueryParams.sortDirection = request.sortDirection;
    }
    if (this.selectedAuctionDate) {
      newQueryParams.auctionDate = this.selectedAuctionDate;
    }
    if (request.auctionLocation) {
      newQueryParams.auctionLocation = request.auctionLocation;
    }
    if (request.invoiceBalance) {
      newQueryParams.invoiceBalance = request.invoiceBalance;
    }
    newQueryParams.accountId = this.companySelection.getSelectedAccountIds();

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: newQueryParams
      }
    );
  }

  private initializeStateFromUrl(): void {
    this.route.queryParams.pipe(first()).subscribe(queryParams => {
      if (queryParams.auctionDate) {
        // add the custom option before the timeout is set up
        const filter = this.sortFilter.filterData.find(x => x.backEndTitle === 'auctionDate');
        if (filter.options.findIndex(x => x === queryParams.auctionDate) < 0) {
          // add the custom date to the list
          filter.options.splice(-1, 0, queryParams.auctionDate);
        }
      }
      setTimeout(() => {
        const urlAccountIds = queryParams.accountId;
        const selectedAccountIds = this.companySelection.getSelectedAccountIds().map(x => x.toString());
        if (!equalsIgnoreOrder(urlAccountIds, selectedAccountIds)) {
          // the specified account ids do not match the currently selected ones, perform a new search
          this.resetSearch();
          return;
        }
        if (queryParams.page) {
          this.page = parseInt(String(queryParams.page), 10);
        }

        if (queryParams.searchTerm) {
          this.searchbarComponent.setSearchText(queryParams.searchTerm);
        }

        let triggerShowFilterBar = false;
        if (queryParams.sortField) {
          const sortData = this.sortFilter.sortData.find(el => el.backEndTitle === queryParams.sortField);
          this.searchbarComponent.selectSortProgramatically(sortData.title, sortData.backEndTitle);
          triggerShowFilterBar = true;
        }

        if (queryParams.sortDirection) {
          this.searchbarComponent.sortDirectionChange(queryParams.sortDirection);
          triggerShowFilterBar = true;
        }

        if (queryParams.auctionDate) {
          this.searchbarComponent.selectFilterProgramatically('Auction Date', queryParams.auctionDate, 'auctionDate');
          triggerShowFilterBar = true;
        }

        if (queryParams.auctionLocation) {
          this.searchbarComponent.selectFilterProgramatically('Location', queryParams.auctionLocation, 'auctionLocation');
          triggerShowFilterBar = true;
        }

        if (queryParams.invoiceTotal) {
          this.searchbarComponent.selectFilterProgramatically('Invoice Total', queryParams.invoiceTotal, 'invoiceTotal');
          triggerShowFilterBar = true;
        }

        if (queryParams.invoiceBalance) {
          this.searchbarComponent.selectFilterProgramatically('Invoice Balance', queryParams.invoiceBalance, 'invoiceBalance');
          triggerShowFilterBar = true;
        }

        if (triggerShowFilterBar) {
          this.searchbarComponent.showCollapsedFilters();
        }

        this.appliedSortFilters = this.searchbarComponent.generateSortFilterOutput();

        this.loading = true;
        this.pageChange(this.page);
      }, 0);
    });
  }

  public resetSearch(): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {}
      }
    );
    this.searchbarComponent.resetUI();
    this.totalElements = 0;
    this.pageChange(0);
  }

  public applyFilterSortOptions(data: SortFilterOutput): void {
    this.appliedSortFilters = data;
    this.searchTerm = data.searchTerm;
    this.pageChange(0);
  }

  public search(searchQuery: string): void {
    this.searchTerm = searchQuery;
    this.pageChange(0);
  }

  public downloadInvoicePdf(accountId: string, invoiceId: string): void {
    this.invoiceIdsBeingDownloaded.push(invoiceId);

    const event: GoogleAnalyticEventObject = {
      category: 'Download Invoice',
      action: 'Click',
      label: 'Invoices'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      this.invoiceService.downloadInvoicePdf(accountId, invoiceId).subscribe(data => {
        const fileName = `Bill_Of_Sale_${invoiceId}`;
        const fileType = 'application/pdf';
        download(fileName, fileType, data);
        this.invoiceIdsBeingDownloaded = this.invoiceIdsBeingDownloaded.filter(x => x !== invoiceId);
      });
    });
  }

  public downloadingInvoicePdf(invoiceId: string): boolean {
    return this.invoiceIdsBeingDownloaded.find(x => x === invoiceId);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RDSDraftItem} from '../../../../item-drafts/services/drafts.service';
import {Observable} from 'rxjs';
import { GetDropdownValuesQuery } from '../../../../generated/graphql';

@Component({
  selector: 'app-vehicle-specs-step',
  templateUrl: './vehicle-specs-step.component.html',
  styleUrls: ['./vehicle-specs-step.component.scss']
})
export class VehicleSpecsStepComponent implements OnInit {

  @Input() public parentForm: FormGroup;
  @Input() public showForm: boolean;
  @Input() public dropdownValues: GetDropdownValuesQuery;
  @Input() public ddvSubject: Observable<GetDropdownValuesQuery>;
  @Input() public isDraft = false;
  @Input() public draft: RDSDraftItem;
  public loading: boolean;
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      engine: [''],
      fuel: [''],
      transmission: [''],
      odometer: ['', [Validators.maxLength(7), Validators.pattern('^[\\d]+$')]],
      hours: ['', [Validators.maxLength(12), Validators.pattern('^[\\d]+$')]],
      optionalEquipment: ['', Validators.maxLength(1000)],
      airConditioning: [true],
    });


    this.ddvSubject.subscribe(values => {
      this.dropdownValues = values;
      this.loading = values == null;
      if (!this.loading && this.draft) {
        this.form.setValue({
          engine: this.draft.engine,
          fuel: this.draft.fuel,
          transmission: this.draft.transmission,
          odometer: this.draft.odometer,
          hours: this.draft.hours,
          optionalEquipment: this.draft.optionalEquipment,
          airConditioning: this.draft.airConditioning,
        });
      }
    });

    this.parentForm.addControl('vehicleSpecsFormGroup', this.form);
  }

}

<div class="top-nav-container">
  <div class="row">
    <div class="col-12">
      <top-navbar [liteVersion]="liteVersion" [isMobile]="isMobile" (expandClicked)="drawer.toggle()"></top-navbar>
    </div>
  </div>
</div>
<mat-sidenav-container class="example-container" autosize>
  <mat-sidenav fixedInViewport [fixedTopGap]="getTopGap()" [opened]="!isMobile" #drawer
               mode="{{ isMobile ? 'over' : 'side'}}">
    <div>
      <side-navbar [liteVersion]="liteVersion" [isMobile]="isMobile" (close)="drawer.toggle()"></side-navbar>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <footer></footer>
  </mat-sidenav-content>
</mat-sidenav-container>

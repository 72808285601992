import {Component, Input, OnInit} from '@angular/core';
import {GetRecentAuctionsQuery, GetUpcomingAuctionsQuery} from "../../../generated/graphql";
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';

@Component({
  selector: 'dashboard-auctions',
  templateUrl: './dashboard-auctions.component.html',
  styleUrls: ['./dashboard-auctions.component.scss']
})
export class DashboardAuctionsComponent implements OnInit {

  @Input() upcomingAuctions: GetUpcomingAuctionsQuery['upcomingAuctions'];
  @Input() recentAuctions: GetRecentAuctionsQuery['recentAuctions'];

  constructor(private googleAnalyticService: GoogleAnalyticService) { }

  ngOnInit(): void {
  }


  openAllAuctions(): void {
    const event: GoogleAnalyticEventObject = {
      category: 'All Upcoming Auctions link',
      action: 'Click',
      label: 'Dashboard'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      window.open(`https://www.jjkane.com/auctions`);
    });
  }
}

<app-base-step-wizard [navigationSteps]="navigationSteps" [parentForm]="parentForm" (selectStep)="setStep($event)" [draftItem]="draft" [titled]="true">
  <div>
    <app-vehicle-info-step [draft]="draft" [parentForm]="parentForm" [showForm]="this.selectedStep === 1" [ddvSubject]="ddvSubject.asObservable()"></app-vehicle-info-step>
    <app-vehicle-specs-step [draft]="draft" [parentForm]="parentForm" [showForm]="this.selectedStep === 2" [ddvSubject]="ddvSubject.asObservable()"></app-vehicle-specs-step>
    <app-unit-info-step [draft]="draft" [parentForm]="parentForm" [showForm]="this.selectedStep === 3" [ddvSubject]="ddvSubject.asObservable()"></app-unit-info-step>
    <app-location-step [draft]="draft" [parentForm]="parentForm" [showForm]="this.selectedStep === 4" [ddvSubject]="ddvSubject.asObservable()"></app-location-step>
    <app-pictures-step [draft]="draft" [parentForm]="parentForm" [showForm]="this.selectedStep === 5"></app-pictures-step>
    <app-confirmation-step [draft]="draft" [parentForm]="parentForm" [showForm]="this.selectedStep === 6" [ddvSubject]="ddvSubject.asObservable()"></app-confirmation-step>
  </div>
</app-base-step-wizard>

<div class="container-fluid">
  <!-- Title -->
  <h3 class="my-3">{{isBuyerItemSearch ? 'Purchased Items' : 'Consigned Items'}}</h3>
  <!-- Search/Filter -->
  <div class="mb-3">
    <searchbar #searchbar [title]="isBuyerItemSearch ? 'Purchased Items' : 'Consigned Items'" [placeholder]="isBuyerItemSearch ? 'Search Purchased Items' : ' Search Consigned Items'" [hasCollapsedFilters]="true" [searchTipText]="searchTipText"
               [sortFilterOptions]="sortFilter" [hasExport]="true" (exportClicked)="exportItems()"
               (searched)="search($event, page)" (applyClicked)="applyFilterSortOptions($event)"></searchbar>
  </div>
  <!-- Counter -->
  <div class="mb-3" *ngIf="!loading">
    <ng-container *ngIf="contentSize === 0; else second">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="no-results">
            <h5><b>Not finding what you're searching for?</b></h5>
            <ul>
              <li>Check your spelling, or try fewer terms and more generic texts.</li>
              <li><a href="https://www.jjkane.com/contact-us/" target="_blank" >Contact Us</a> for more assistance.</li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #second>
      <ng-container *ngIf="contentSize <= 10; else third">
        <div class="font-italic font-weight-light">
          Showing {{ contentSize > 10 ? (1 + (10 * page)) : 1 }} of {{contentSize}} Items
        </div>
      </ng-container>
    </ng-template>
    <ng-template #third>
      <div class="font-italic font-weight-light">
        Showing {{ contentSize > 10 ? (1 + (10 * page)) : 1 }}
        - {{ contentSize > 10 * (page + 1) ? (10 * (page + 1)) : contentSize }} of {{contentSize}} Items
      </div>
    </ng-template>
  </div>
  <!-- Loading Counter -->
  <div class="font-italic font-weight-light mb-3 loading-text-field w-20" *ngIf="loading">
  </div>
  <!-- Items -->
  <div *ngIf="!loading">
    <div *ngFor="let item of displayItems; let i = index" class="py-3 px-3 border-bottom" [class.odd]="i % 2 == 0">
      <item-card [item]="item" (select)="navigate($event)"></item-card>
    </div>
  </div>
  <!-- Skeleton Loading -->
  <div *ngIf="loading">
    <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]; let i = index;" class="py-3 px-3 border-bottom"
         [class.odd]="i % 2 == 0">
      <div class="row d-flex align-items-center">
        <!-- Image -->
        <div class="col-md-3 col-xs-12 w-100 loading-image loading">
        </div>
        <!-- Unit Details -->
        <div class="col-md-3 col-xs-12">
          <div class="loading-text-field loading my-3 w-75"></div>
          <div class="loading-text-field loading my-2 w-50"></div>
          <div class="loading-text-field loading my-2 w-60"></div>
          <div class="loading-text-field loading my-2 w-30"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
          <div class="loading-text-field loading my-2 w-20"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
        </div>
        <!-- Auction Details -->
        <div class="col-md-3 col-xs-12">
          <div class="loading-text-field loading my-2 w-75"></div>
          <div class="loading-text-field loading my-2 w-50"></div>
          <div class="loading-text-field loading my-2 w-30"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
          <div class="loading-text-field loading my-2 w-20"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
        </div>
        <!-- Status -->
        <div class="col-md-3 col-xs-12">
          <div class="loading-text-field loading my-2 w-50"></div>
          <div class="loading-text-field loading my-2 w-30"></div>
          <div class="loading-text-field loading my-2 w-40"></div>
          <div class="loading-text-field loading my-2 w-20"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Pagination -->
  <div class="mt-3">
    <pagination #paginator [pageSize]="10" [numberOfItems]="contentSize" [index]="page"
                (paging)="pageChange($event)"></pagination>
  </div>
</div>

<ul class="pagination justify-content-center" *ngIf="numberOfItems > pageSize">
  
    <!--back to start-->
    <li *ngIf="hasFirstAndLast" class="page-item" [ngClass]="isEndBtnDisabled('first')">
      <a (click)='first()' class="page-link" href="javascript:;" aria-label="First">
        <span>First</span>
      </a>
    </li>
  
    <!--back one-->
    <li class="page-item" [ngClass]="isEndBtnDisabled('previous')">
      <a (click)="previous()" class="page-link" href="javascript:;" aria-label="Previous">
        <span>Prev</span>
      </a>
    </li>

    <li class="page-item" [ngClass]='isPageActive(page)' *ngFor="let page of pages$ | async as pags">
      <a (click)="goToPage(page)" class="page-link" href="javascript:;">{{ page }}</a>
    </li>
  
    <!--forward one-->
    <li class="page-item" [ngClass]="isEndBtnDisabled('next')">
      <a (click)="next(index)" class="page-link" href="javascript:;" aria-label="Next">
        <span>Next</span>
      </a>
    </li>
  
    <!--forward to end-->
    <li *ngIf="hasFirstAndLast" class="page-item" [ngClass]="isEndBtnDisabled('last')">
      <a (click)="last()" class="page-link" href="javascript:;" aria-label="Last">
        <span>Last</span>
      </a>
    </li>

</ul>
  
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'placeholder-auction-card',
  templateUrl: './placeholder-auction-card.component.html',
  styleUrls: ['./placeholder-auction-card.component.scss']
})
export class PlaceholderAuctionCardComponent implements OnInit {

  @Input()
  odd: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ItemService} from '../../services/item.service';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {CommonService} from '../../../Shared/services/common.service';
import {environment} from '../../../../environments/environment';
import {formatCityState} from '../../../Shared/functions/address-helpers';
import {GetFaIcon} from '../../../Shared/functions/icon-helpers';
import {download} from '../../../Shared/functions/download-helpers';
import {getItemStatus} from '../../../Shared/functions/item-helpers';
import {CustomModalComponent} from '../../../Shared/components/custom-modal/custom-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';
import {TabComponentComponent} from '../../../Shared/components/tab-component/tab-component.component';
import {MiscFeesModalComponent} from '../../components/misc-fees-modal/misc-fees-modal.component';
import {InvoiceService} from '../../../Invoices/services/invoice.service';
import { Item } from '../../../generated/graphql';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss']
})
export class ItemDetailComponent implements OnInit {

  public itemDetail: Item;
  public translatedItemStatus: string;
  public payoutId: number;
  itemId: number;
  public titleStatus: string;
  public imageUrls: Array<string> = [];
  public loading = true;
  public formatCityState = formatCityState;
  public faFileContract = GetFaIcon('file-contract', 'fas');
  public faReceipt = GetFaIcon('receipt', 'fas');
  @ViewChild('tab-engine') public tabEngComponent: TabComponentComponent;
  public isBuyerItemDetail: boolean;
  public downloadingInvoicePdf: boolean;
  private modalRef: NgbModalRef;
  public itemIsHolding: boolean = false;

  constructor(private commonService: CommonService,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private companySelection: CompanySelectionService,
              private googleAnalyticService: GoogleAnalyticService,
              private itemService: ItemService,
              private router: Router,
              private invoiceService: InvoiceService) {
  }

  ngOnInit(): void {
    this.commonService.updateIsLoading(true);
    this.commonService.isLoading$.subscribe(val => {
      this.loading = val;
      if (!this.loading) {
        window.scroll(0, 0);
      }
    });
    this.isBuyerItemDetail = this.router.url.indexOf('purchased-items') >= 0;

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.itemId = +params.get('itemId');
      const accountIds = this.companySelection.getSelectedAccountIds();
      this.itemService.getItemDetails(accountIds, this.itemId, this.isBuyerItemDetail).subscribe(response => {
        if (!response) {
          // we did not receive an item, redirect the user
          this.missingItemRedirect();
        } else {
          this.itemDetail = response;
          environment.holdingSaleIds.forEach(holdingSaleId => {
            if (holdingSaleId === this.itemDetail.saleId){
              this.itemIsHolding = true;
            }
          });

          if (this.itemDetail.sellerPayoutId) {
            this.payoutId = this.itemDetail.sellerPayoutId;
          }
          if (this.itemDetail.titled && this.itemDetail.haveTitle) {
            this.titleStatus = 'Title Submitted';
          } else if (this.itemDetail.titled && this.itemDetail.conditions && !this.itemDetail.haveTitle) {
            this.titleStatus = 'Awaiting Title';
          }
          if (this.itemDetail?.imageUrls) {
            this.imageUrls = this.itemDetail.imageUrls.map(x => environment.jjkImageUrl + x);
          }
          this.translatedItemStatus = getItemStatus(this.itemDetail.itemStatus);
          this.loading = false;
          this.commonService.updateIsLoading(this.loading);
        }
      });
    });
  }

  private missingItemRedirect(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
    const modalContent = {
      title: 'Item Not Available',
      message: 'This item is not available under this account.',
      closeButton: false,
      showButtons: true,
      cancelButton: false,
      buttonsArray: [
        {
          name: 'OK',
          action: 'navigate',
          navigation: '/dashboard',
          navParams: null,
          class: 'btn-danger' // Possible values: btn-success, btn-warning, btn-danger
        }
      ]
    };
    this.modalRef = this.modalService.open(CustomModalComponent, {backdrop: 'static', keyboard: false});

    this.modalRef.componentInstance.modalContent = modalContent;

    this.modalRef.result.then(res => {
      if (res.action === 'navigate') {
        if (res.navigation) {
          if (res.navParams) {
            this.router.navigate([res.navigation, res.navParams]);
          } else {
            this.router.navigateByUrl(res.navigation);
          }
        }
      }
    });
  }

  public openPayout(payoutId: number): void {
    this.router.navigate(['/dashboard/payouts/', payoutId]);
  }


  downloadImages(): void {
    this.itemService.downloadImages(this.itemDetail.id?.toString()).subscribe(data => {
      const fileName = `images_${this.itemDetail.id}`;
      const fileType = 'application/zip';
      download(fileName, fileType, data);
    });
  }

  public openMiscFeesModal(): void {
    const modal: NgbModalRef = this.modalService.open(MiscFeesModalComponent, {backdrop: 'static', keyboard: false});
    modal.componentInstance.itemDetail = this.itemDetail;
  }

  public downloadInvoicePdf(accountId: number, invoiceId: number): void {
    this.downloadingInvoicePdf = true;
    const event: GoogleAnalyticEventObject = {
      category: 'Download Invoice',
      action: 'Click',
      label: 'Item Detail'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      this.invoiceService.downloadInvoicePdf(accountId, invoiceId).subscribe(data => {
        const fileName = `Bill_Of_Sale_${invoiceId}`;
        const fileType = 'application/pdf';
        download(fileName, fileType, data);
        this.downloadingInvoicePdf = false;
      });
    });
  }
}

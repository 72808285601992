export interface RDSSiteMessage {
    id: number,
    userId: string,
    submitTimestamp: string,
    status?: SiteMessageStatus,
    level?: SiteMessageLevel,
    sitePlacement?: SiteMessagePlacement,
    message?: string,
    title?: string,
    endDate?: string,
    startDate?: string
  }
  
export enum SiteMessageLevel {
    Warning = 'WARNING',
    Info = 'INFO'
}

export enum SiteMessagePlacement {
    Login = 'LOGIN',
    Dashboard = 'DASHBOARD'
}

export enum SiteMessageStatus {
    Active = 'ACTIVE',
    Cancelled = 'CANCELLED'
}
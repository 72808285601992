import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {GraphQLRequest} from '../models/graphql-request';
import {map} from 'rxjs/operators';
import {GraphQLResponse} from '../models/graphql-response';
import {CompanySelectionService} from './company-selection.service';
import { JjkIntegration, ContactUsMessage, Profile} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private companySelectionService: CompanySelectionService, private http: HttpClient, private jjkIntegration: JjkIntegration) { }

  public getContactInfo(): Observable<Profile> {
    return this.jjkIntegration.getContactInfo({type: 'Contact'})
    .pipe(map(response => {
      const account = this.companySelectionService.$selectedCompany.value;
        if (account.defaultContact) {
          const matchedUser = response.data.profiles.find(x => x.defaultContact === account.defaultContact);
          if (matchedUser) {
            return matchedUser;
          }
        }

        return response.data.profiles[0];
    }));
  }

  public sendContactUsMessage(messageObject: ContactUsMessage): Observable<boolean> {
    return this.jjkIntegration.sendContactUsMessageMutation({messageObject}).pipe(map(response => {
      return response.data.sendContactUsMessage.success;
    }));
  }
}

export interface ProfileResponse {
  profiles: ContactInfo[];
}
export interface ContactInfo {
  parentAccountId: number;
  defaultContact: number;
  accountInfo: AccountInfo;
}

export interface AccountInfo {
  address: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  country: string;
  phoneNumber: string;
  phoneType: string;
  firstName: string;
  lastName: string;
  email: string;
  sellerCode: string;
  supportContact: SupportContact;
  companyInfo: CompanyInfo;
}
export interface SupportContact {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}
export interface CompanyInfo {
  companyName: string;
  companyId: number;
  companyPhone: string;
  companyCity: string;
  companyCountry: string;
  companyAddress: string;
  companyState: string;
  companyZip: string;
}

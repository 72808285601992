<div class="mt-4" *ngIf="showForm">
    <form [formGroup]="form">
        <div class="col-12">
            <div class="form-group">
                <label>Password <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <input type="password" class="form-control" formControlName="password" placeholder="Password" />
                        <div *ngIf="form.controls.password.errors && form.controls.password.touched" class="error-text">
                            <div *ngIf="form.controls.password.errors.required">Password is required.</div>
                            <div *ngIf="form.controls.password.errors.mustMatch">Passwords must match.</div>
                            <div *ngIf="form.controls.password.errors.pattern">Password must follow the below guidelines.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="form-group">
                <label>Confirm Password <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <input type="password" class="form-control" formControlName="confirm" placeholder="Confirm Password" />
                        <div *ngIf="form.controls.confirm.errors && form.controls.confirm.touched" class="error-text">
                            <div *ngIf="form.controls.confirm.errors.required">Password confirmation is required.</div>
                            <div *ngIf="form.controls.confirm.errors.mustMatch">Passwords must match.</div>
                            <div *ngIf="form.controls.confirm.errors.pattern">Password must follow the below guidelines.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mb-4">
            <ul>
                <li>
                    At least eight characters
                </li>
                <li>
                    At least one uppercase and one lowercase letter
                </li>
                <li>
                    At least one number
                </li>
                <li>
                    At least one special character(&, #, $, !, etc)
                </li>
            </ul>            
        </div>

        <div class="col-12 my-2">
            <div class="form-check">
                <div class="row mb-2">
                    <div class="col-12">
                        <input type="checkbox" class="form-check-input" formControlName="accept" />
                        <label class="form-check-label" for="accept">I accept JJKane's <a href="https://www.jjkane.com/info/site-terms-and-conditions/">terms and conditions</a> for my new account</label>
                        <div *ngIf="form.controls.accept.errors && form.controls.accept.touched" class="error-text">
                            <div>You must accept our terms and conditions to register.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 my-3 recaptcha">
            <re-captcha formControlName="recaptcha" name="recaptcha" siteKey="6Lc8CGMaAAAAALWsyco9EuejQila1IEI46WN4H2P"></re-captcha>
        </div>
        
    </form>
</div>

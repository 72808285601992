<div class="mt-4" *ngIf="showForm">
    <form [formGroup]="form">
        <div class="col-12">
            <div class="form-group">
                <label>Company Name <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <input class="form-control" formControlName="businessName" placeholder="Company Name" />
                        <div *ngIf="form.controls.businessName.errors && form.controls.businessName.touched" class="error-text">
                            <div *ngIf="form.controls.businessName.errors.required">Business Name is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Business Type <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <select class="form-control" formControlName="businessType" placeholder="Business Type" [class.loading]="loading">
                            <ng-container *ngIf="!loading">
                                <option selected value="">Select Business Type</option>
                                <option *ngFor="let code of businessCodes" [ngValue]="code.name">{{code.name}}</option>
                            </ng-container>
                        </select>
                        <div *ngIf="form.controls.businessType.errors && form.controls.businessType.touched" class="error-text">
                            <div *ngIf="form.controls.businessType.errors.required">Business Type is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Phone Number <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <input class="form-control" formControlName="businessPhoneNumber" (keyup)="formatPhone($event)" placeholder="10 Digit Phone Number" />
                        <div *ngIf="form.controls.businessPhoneNumber.errors && form.controls.businessPhoneNumber.touched" class="error-text">
                            <div *ngIf="form.controls.businessPhoneNumber.errors.required">Phone Number is required.</div>
                            <div *ngIf="form.controls.businessPhoneNumber.errors.minlength || form.controls.businessPhoneNumber.errors.maxlength">Phone Number must be 10 digits.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Phone Type <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <select class="form-control" formControlName="businessPhoneType">
                            <option selected value="">Select Phone Type</option>
                            <option value="Home">Home</option>
                            <option value="Mobile">Mobile</option>
                            <option value="Office">Office</option>
                        </select>
                        <div *ngIf="form.controls.businessPhoneType.errors && form.controls.businessPhoneType.touched" class="error-text">
                            <div *ngIf="form.controls.businessPhoneType.errors.required">Phone Type is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>Address <span class="text-danger">*</span></label>
                <div class="row mb-3">
                    <div class="col-12">
                        <input class="form-control" formControlName="businessAddressLine1" placeholder="Address line 1" />
                        <div *ngIf="form.controls.businessAddressLine1.errors && form.controls.businessAddressLine1.touched" class="error-text">
                            <div *ngIf="form.controls.businessAddressLine1.errors.required">Address Line 1 is required.</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="form-control" formControlName="businessAddressLine2" placeholder="Address line 2" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <div class="row">
                    <div class="col-12">
                        <label>Zip <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" formControlName="businessZip" (keyup)="checkForValidZip()" placeholder="Zip Code" />
                        <div *ngIf="form.controls.businessZip.errors && form.controls.businessZip.touched" class="error-text">
                            <div *ngIf="form.controls.businessZip.errors.pattern">Physical zip format should be XXXXX</div>
                            <div *ngIf="form.controls.businessZip.errors.required">Zip Code is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-2" *ngIf="geocodeLoading || geocodeLocations.length > 0">
            <label>City / County / State Selection<span class="text-danger">*</span></label>
            <mat-table [dataSource]="geocodeLocations" multiTemplateDataRows class="mat-elevation-z8 cursor-pointer" *ngIf="geocodeLoading || geocodeLocations.length > 0">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>Select</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">Select:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        <mat-radio-button (click)="selectLocation(geocodeLocation)"
                        [checked]="selectedLocation && selectedLocation.city === geocodeLocation.city && selectedLocation.county === geocodeLocation.county" ></mat-radio-button>
                    </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">City:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        {{geocodeLocation.city}}
                    </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="county">
                    <mat-header-cell *matHeaderCellDef>County</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">County:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        {{geocodeLocation.county}}
                    </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
                    <mat-cell *matCellDef="let geocodeLocation">
                    <span class="mobile-label">State:</span>&nbsp;
                    <div *ngIf="!geocodeLoading; else loadingCell">
                        {{geocodeLocation.state}}
                    </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row *matRowDef="let geocodeLocation; columns: columnsToDisplay;"
                    class="location-row" [class.selected-row]="selectedLocation && selectedLocation.city === geocodeLocation.city && selectedLocation.county === geocodeLocation.county">
                </mat-row>
            </mat-table>
        </div>

        <div class="col-12">
            <div class="form-group">
                <label>Country <span class="text-danger">*</span></label>
                <div class="row mb-2">
                    <div class="col-12">
                        <select class="form-control" formControlName="businessCountry" placeholder="Country" [class.loading]="loading">
                            <ng-container *ngIf="!loading">
                                <option selected value="">Select Country</option>
                                <option *ngFor="let country of countries" [ngValue]="country.description">{{country.description}}</option>
                            </ng-container>
                        </select>
                        <div *ngIf="form.controls.businessCountry.errors && form.controls.businessCountry.touched" class="error-text">
                            <div *ngIf="form.controls.businessCountry.errors.required">Country is required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

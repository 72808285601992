
    <div class="modal-header">
        <a>
            <h5>{{ modalTitle }}</h5>
        </a>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-center">

        <form [formGroup]="newMessageForm">
            <div class="row">

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Title</b></label>
                        <input class="form-control" formControlName="title" placeholder="Enter title here..." [ngClass]="{ 'is-invalid': submitted && formControls.title.errors }" required>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group text-left">
                        <label><b>Message</b></label>
                        <textarea class="form-control" formControlName="message" placeholder="Enter message here..." [ngClass]="{ 'is-invalid': submitted && formControls.message.errors }" required></textarea>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left">
                        <label><b>Start Date</b></label>
                        <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="startingDate" [ngModelOptions]="{standalone: true}" ngbDatepicker #dStart="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && formControls.startDate.errors }" required>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dStart.toggle()" type="button">
                                    <fa-icon [icon]="faCalendar"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left">
                        <label><b>Start Time</b></label>
                        <ngb-timepicker [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" [spinners]="false" [meridian]="true"></ngb-timepicker>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left">
                        <label><b>End Date</b></label>
                        <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="endingDate" [ngModelOptions]="{standalone: true}" ngbDatepicker #dEnd="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && formControls.endDate.errors }" required>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()" type="button">
                                    <fa-icon [icon]="faCalendar"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left">
                        <label><b>End Time</b></label>
                        <ngb-timepicker [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" [spinners]="false" [meridian]="true"></ngb-timepicker>
                    </div>
                </div>

                <div class="col-12 text-left">
                    <div>
                        <label><b>Site Placement</b></label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="sitePlacement" id="loginRadio" value="LOGIN" [ngClass]="{ 'is-invalid': submitted && formControls.sitePlacement.errors }" required>
                        <label class="form-check-label" for="loginRadio">Login</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="sitePlacement" id="dashboardRadio" value="DASHBOARD" [ngClass]="{ 'is-invalid': submitted && formControls.sitePlacement.errors }" required>
                        <label class="form-check-label" for="dashboardRadio">Dashboard</label>
                    </div>
                </div>

                <div class="col-12 text-left mt-3">
                    <div>
                        <label><b>Message Level</b></label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="messageLevel" id="infoRadio" value="INFO" [ngClass]="{ 'is-invalid': submitted && formControls.messageLevel.errors }" required>
                        <label class="form-check-label" for="infoRadio">Info</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="messageLevel" id="warningRadio" value="WARNING" [ngClass]="{ 'is-invalid': submitted && formControls.messageLevel.errors }" required>
                        <label class="form-check-label" for="warningRadio">Warning</label>
                      </div>
                </div>

            </div>

        </form>
    </div>

    <div class="modal-footer text-center">
        <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-success" (click)="submit()"> {{ submitButtonText }}</button>
    </div>

<div class="row py-3 card-border" [class.odd]="odd">
    <div class="col-6">
        <div class="h5"><a href="javascript:;" (click)="openAuction()">{{ auction.date | date: 'MMM d'}}</a></div>
        <div *ngIf="auction.saleType === 'Timed Auction'; else locationTemplate">
          Online Only
        </div>
        <ng-template #locationTemplate>
          {{auction.city}}, {{auction.state}}
        </ng-template>
    </div>
    <div class="col-6">
        <div class="d-flex justify-content-end align-items-center flex-wrap h-100">
          <a *ngIf="auction.sellerHasItems" class="btn text-light bg-altec" [GA]="{event: {category: 'View Items', action: 'Click', label: 'Dashboard'}, route: ['/dashboard/items'], queryParams: {Auction: getSaleInfo()}}">View Items</a>
        </div>
    </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { GetFaIcon } from 'src/app/Shared/functions/icon-helpers';

@Component({
  selector: 'profile-row',
  templateUrl: './profile-row.component.html',
  styleUrls: ['./profile-row.component.scss']
})
export class ProfileRowComponent implements OnInit {

  public faCheck = GetFaIcon('check', 'fas');
  public faEdit = GetFaIcon('edit', 'fas');
  public faSpinner = GetFaIcon('spinner', 'fas');

  @Input() title: string;
  @Input() value: string;
  @Input() loaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StateUs } from '../../../../Submit Items/models/state-us';
import { RegistrationService } from '../../../services/registration.service';
import { GetCountriesQuery, GeocodeLocation } from '../../../../generated/graphql';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {

  @Input() public showForm: boolean;
  @Input() public parentForm: FormGroup;
  @Input() public loading: boolean;
  @Input() public states: StateUs[];
  @Input() public countries: GetCountriesQuery['countries'];;
  
  public form: FormGroup;
  public geocodeLocations: GeocodeLocation[] = [];
  public selectedLocation: GeocodeLocation;
  public geocodeLoading: boolean = false;

  public columnsToDisplay = ['select', 'city', 'county', 'state'];

  constructor(private fb: FormBuilder, private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      personalPhoneNumber: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      personalPhoneType: ['', Validators.required],
      personalAddressLine1: ['', Validators.required],
      personalAddressLine2: [''],
      personalCity: ['', Validators.required],
      personalState: ['', Validators.required],
      personalZip: ['', [Validators.required, Validators.pattern('^[\\d]{5}$')]],
      personalCounty: ['', Validators.required],
      personalCountry: ['', Validators.required],
    });

    this.parentForm.addControl('personalGroup', this.form);
  }

  public checkForValidZip() {
    if(this.form.controls["personalZip"].valid) {
      this.geocodeLoading = true;
      this.geocodeLocations = [];
      this.registrationService.geocode(this.form.controls["personalZip"].value).subscribe(result => {
        if(result.success) {
          this.geocodeLocations = result.objects;
          this.geocodeLoading = false;
        }
      });
    }
  }

  public selectLocation(location: GeocodeLocation) {
    this.selectedLocation = location;

    this.form.controls["personalCity"].setValue(location.city);
    this.form.controls["personalCounty"].setValue(location.county);
    this.form.controls["personalState"].setValue(location.state);
  }

  public formatPhone(event) {
    if(event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) { return; }
  
    let phoneNumber = event.target.value;
		phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

		if(phoneNumber.length == 0) {
			phoneNumber = "";
		} else if(phoneNumber.length < 3) {
			phoneNumber = "(" + phoneNumber;
		} else if(phoneNumber.length == 3) {
			phoneNumber = "(" + phoneNumber + ") ";
		} else if(phoneNumber.length < 7) {
			phoneNumber = "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, phoneNumber.length);
		} else if(phoneNumber.length >= 7) {
			phoneNumber = "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, phoneNumber.length);
		}

		this.form.controls["personalPhoneNumber"].setValue(phoneNumber);
  }

}

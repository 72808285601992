import {Component, Input, OnInit} from '@angular/core';
import {ItemManagmentService} from '../../../Shared/services/item-managment-service';
import { Item } from '../../../generated/graphql';

@Component({
  selector: 'checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {
  @Input()
  itemDetail: Item;

  @Input()
  loading: boolean = false;

  constructor(public itemManagementService: ItemManagmentService) {}

  ngOnInit(): void {}
}

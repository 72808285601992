<div class="wrapper px-3">
  <div class="py-3">
    <span class="text-center"><h2>Contact Us</h2></span>
  </div>
  <div class="container mt-3">
    <div class="row no-gutters justify-content-around">
      <div *ngIf="accountInfo.supportContact.firstName" class="col-lg-4 col-sm-12 gold-bar my-3">
        <div class="section-content h-100 mb-3">
          <div class="text-center py-3 mb-md-4 mb-xs-0"><h4>My Representative</h4></div>
          <div class="ml-3">
            <div>
              <div class="mt-3">
                <fa-icon [icon]="faUser" size="lg" class="mr-2 light-text"></fa-icon>
                <span class="light-text rep-text">{{accountInfo?.supportContact?.firstName}} {{accountInfo?.supportContact?.lastName}}</span>
                <span *ngIf="loading" class="loading-text-field loading my-3">&nbsp;</span>
              </div>
              <div class="mt-3">
                <fa-icon [icon]="faPhone" size="lg" class="mr-2 light-text"></fa-icon>
                <span class="light-text rep-text">{{accountInfo?.supportContact?.phoneNumber}}</span>
                <span *ngIf="loading" class="loading-text-field loading my-3">&nbsp;</span>
              </div>
              <div class="mt-3">
                <fa-icon [icon]="faEnvelope" size="lg" class="mr-2 light-text"></fa-icon>
                <span class="light-text rep-text">{{accountInfo?.supportContact?.email}}</span>
                <span *ngIf="loading" class="loading-text-field loading my-3">&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [class.col-lg-7]="accountInfo.supportContact.firstName" class="col-sm-12 gold-bar my-3">
        <div>
          <div *ngIf="wasSuccess && wasSubmitted;" class="section-content text-center">
            <br>
            <br>
            <h1>Thank You!</h1>
            <p>We appreciate you contacting us.</p>
            <p>One of our representatives will get back to you shortly.</p>
          </div>
          <div *ngIf="!wasSuccess;" class="section-content text-center">
            <div class="section-content">
              <div class="text-center py-3"><h4>Send Message</h4></div>
              <div class="col-12">
                <form [formGroup]="contactForm">
                  <div class="form-group">

                    <!-- Subject errors -->
                    <span class="error-text" *ngIf="subject.invalid && (subject.dirty || subject.touched)">
                      <div *ngIf="subject.errors.required">Subject is required</div>
                    </span>
                    <select [class.error-select]="subject.invalid && (subject.dirty || subject.touched)"
                            class="light-text form-control" id="formType" formControlName="subject">
                      <option value="" selected disabled hidden>Subject</option>
                      <option>Interested in Buying</option>
                      <option>Interested in Selling</option>
                      <option>Contact Local Sales Rep</option>
                      <option>Contact Corporate HQ</option>
                      <option>Technical Support / Site Admin</option>
                    </select>
                    <div class="md-form mt-3">
                      <!-- Comments errors -->
                      <span class="error-text" *ngIf="comments.invalid && (comments.dirty || comments.touched)">
                                            <div *ngIf="comments.errors.required">Comments are required</div>
                                        </span>

                      <textarea [class.error-field]="comments.invalid && (comments.dirty || comments.touched)"
                                type="text" rows="5" placeholder="Comments" id="comments-element"
                                formControlName="comments" class="md-textarea md-textarea-auto form-control"
                                mdbInput></textarea>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button class="btn text-light bg-altec mb-3 pull-right" [GA]="{event: {category: 'Submit Contact Us', action: 'Click', label: 'Contact Us'}}" (click)="send()" [disabled]="loading">Send</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div *ngIf="!wasSuccess && wasSubmitted;" class="section-content text-center">
            <br>
            <br>
            <h1>An Error Has Occurred</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
};



export type AccountInfo = {
  __typename?: 'AccountInfo';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  companyInfo?: Maybe<CompanyInfo>;
  supportContact?: Maybe<SupportContact>;
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type AllAccounts = {
  __typename?: 'AllAccounts';
  accountId?: Maybe<Scalars['Int']>;
};

export type AuctionItem = {
  __typename?: 'AuctionItem';
  id?: Maybe<Scalars['Int']>;
  saleId?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
};

export type AuctionItemInput = {
  parentCategory?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  sellerFleetNumber2?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  optionalEquipment?: Maybe<Scalars['String']>;
  airConditioning?: Maybe<Scalars['Boolean']>;
  upperSerialNumber?: Maybe<Scalars['String']>;
  upperMake?: Maybe<Scalars['String']>;
  upperModel?: Maybe<Scalars['String']>;
  upperFuel?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  capacityType?: Maybe<Scalars['String']>;
  mounted?: Maybe<Scalars['String']>;
  upperOptionalEquipment?: Maybe<Scalars['String']>;
  itemNotes?: Maybe<Scalars['String']>;
  offSitePhysicalAddress?: Maybe<Scalars['String']>;
  offSitePhysicalCity?: Maybe<Scalars['String']>;
  offSitePhysicalState?: Maybe<Scalars['String']>;
  offSitePhysicalZip?: Maybe<Scalars['String']>;
  taxCounty?: Maybe<Scalars['String']>;
};

export type BusinessCode = {
  __typename?: 'BusinessCode';
  id?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BusinessInfo = {
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  businessPhoneNumber?: Maybe<Scalars['String']>;
  businessPhoneType?: Maybe<Scalars['String']>;
  businessAddressLine1?: Maybe<Scalars['String']>;
  businessAddressLine2?: Maybe<Scalars['String']>;
  businessCity?: Maybe<Scalars['String']>;
  businessState?: Maybe<Scalars['String']>;
  businessZip?: Maybe<Scalars['String']>;
  businessCounty?: Maybe<Scalars['String']>;
  businessCountry?: Maybe<Scalars['String']>;
};

export type BuyerFilterOptions = {
  __typename?: 'BuyerFilterOptions';
  saleInfo?: Maybe<Array<Maybe<LookupValues>>>;
  offSitePhysical?: Maybe<Array<Maybe<LookupValues>>>;
  category?: Maybe<Array<Maybe<LookupValues>>>;
  make?: Maybe<Array<Maybe<LookupValues>>>;
  model?: Maybe<Array<Maybe<LookupValues>>>;
  upperMake?: Maybe<Array<Maybe<LookupValues>>>;
  upperModel?: Maybe<Array<Maybe<LookupValues>>>;
};

export type Capacity = {
  __typename?: 'Capacity';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  companyName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyCountry?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyZip?: Maybe<Scalars['String']>;
};

export type ContactUsMessage = {
  customerComments?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  leadSource?: Maybe<Scalars['String']>;
  mailingPostalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneType?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type Engine = {
  __typename?: 'Engine';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Filter = {
  __typename?: 'Filter';
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FiltersRequest = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type FiltersResponse = {
  __typename?: 'FiltersResponse';
  filterList?: Maybe<Array<Maybe<Filter>>>;
};

export type ForgotPassword = {
  username?: Maybe<Scalars['String']>;
};

export type Fuel = {
  __typename?: 'Fuel';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Geocode = {
  __typename?: 'Geocode';
  success?: Maybe<Scalars['Boolean']>;
  objects?: Maybe<Array<Maybe<GeocodeLocation>>>;
};

export type GeocodeLocation = {
  __typename?: 'GeocodeLocation';
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type InventoryManagementDetailsResponse = {
  __typename?: 'InventoryManagementDetailsResponse';
  notOnSite?: Maybe<Scalars['Int']>;
  conditionPending?: Maybe<Scalars['Int']>;
  awaitingTitle?: Maybe<Scalars['Int']>;
  auctionReady?: Maybe<Scalars['Int']>;
  assignedItems?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['Int']>;
};

export type InventorySale = {
  __typename?: 'InventorySale';
  id?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  auctionStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  creditCardConvenienceFee?: Maybe<Scalars['Float']>;
  creditCardsNotAccepted?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  fax?: Maybe<Scalars['String']>;
  fee1ADescription?: Maybe<Scalars['String']>;
  fee1Description?: Maybe<Scalars['String']>;
  fee2Description?: Maybe<Scalars['String']>;
  fee3Description?: Maybe<Scalars['String']>;
  fee4Description?: Maybe<Scalars['String']>;
  fee5Description?: Maybe<Scalars['String']>;
  fee6Description?: Maybe<Scalars['String']>;
  fee7Description?: Maybe<Scalars['String']>;
  fee8Description?: Maybe<Scalars['String']>;
  fee9Description?: Maybe<Scalars['String']>;
  numberOfRings?: Maybe<Scalars['String']>;
  offSiteItems?: Maybe<Scalars['Boolean']>;
  payoutsFinalized?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  primarySalesContact?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  termsAdditional?: Maybe<Scalars['String']>;
  termsAdditionalPayment?: Maybe<Scalars['String']>;
  termsBuyersFee?: Maybe<Scalars['String']>;
  termsContactInfo?: Maybe<Scalars['String']>;
  termsDesc?: Maybe<Scalars['String']>;
  termsExchangeNotif?: Maybe<Scalars['String']>;
  termsFinancing?: Maybe<Scalars['String']>;
  termsLiveInternetBidding?: Maybe<Scalars['String']>;
  termsLocation?: Maybe<Scalars['String']>;
  termsNotice?: Maybe<Scalars['String']>;
  termsPayment?: Maybe<Scalars['String']>;
  termsRemoval?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  saleName?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  signatureReceived?: Maybe<Scalars['Boolean']>;
  signatureRequired?: Maybe<Scalars['Boolean']>;
  signatureRequested?: Maybe<Scalars['Boolean']>;
  bidderNumber?: Maybe<Scalars['Int']>;
  bidderType?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceBalance?: Maybe<Scalars['Float']>;
  invoiceTotal?: Maybe<Scalars['Float']>;
  paymentType?: Maybe<Scalars['String']>;
  saleId?: Maybe<Scalars['Int']>;
  sale?: Maybe<InventorySale>;
};

export type InvoiceSearchRequest = {
  buyerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  auctionLocation?: Maybe<Scalars['String']>;
  auctionDateStart?: Maybe<Scalars['String']>;
  auctionDateEnd?: Maybe<Scalars['String']>;
  invoiceBalance?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};

export type InvoiceSearchResponse = {
  __typename?: 'InvoiceSearchResponse';
  number?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalElements?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Boolean']>;
  totalPages?: Maybe<Scalars['Int']>;
  numberOfElements?: Maybe<Scalars['Int']>;
  content?: Maybe<Array<Maybe<Invoice>>>;
};

export type Item = {
  __typename?: 'Item';
  id?: Maybe<Scalars['Int']>;
  assignedTo?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['Float']>;
  accountCode?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  adjustmentAmount?: Maybe<Scalars['Float']>;
  airConditioning?: Maybe<Scalars['Boolean']>;
  amountOwed?: Maybe<Scalars['Float']>;
  bidderBuyerInvoiceId?: Maybe<Scalars['Int']>;
  bidderNumber?: Maybe<Scalars['Int']>;
  bidderTotal?: Maybe<Scalars['Float']>;
  bodyDescription?: Maybe<Scalars['String']>;
  buyerAccountId?: Maybe<Scalars['Int']>;
  buyerAccountName?: Maybe<Scalars['String']>;
  buyerFee?: Maybe<Scalars['Float']>;
  buyersTotal?: Maybe<Scalars['Float']>;
  catalogDescription?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  caWeightFee?: Maybe<Scalars['Float']>;
  caLicenseFee?: Maybe<Scalars['Float']>;
  titleFee?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  engine?: Maybe<Scalars['String']>;
  expenseDecommission?: Maybe<Scalars['Float']>;
  expenseMisc1?: Maybe<Scalars['Float']>;
  expenseMisc2?: Maybe<Scalars['Float']>;
  expensePartsRepairs?: Maybe<Scalars['Float']>;
  expenseTransportation?: Maybe<Scalars['Float']>;
  expenseWashing?: Maybe<Scalars['Float']>;
  fee1aActual?: Maybe<Scalars['Float']>;
  fee1Actual?: Maybe<Scalars['Float']>;
  fee2aActual?: Maybe<Scalars['Float']>;
  fee2Actual?: Maybe<Scalars['Float']>;
  fee3Actual?: Maybe<Scalars['Float']>;
  fee4Actual?: Maybe<Scalars['Float']>;
  fee5Actual?: Maybe<Scalars['Float']>;
  fee6Actual?: Maybe<Scalars['Float']>;
  fee7Actual?: Maybe<Scalars['Float']>;
  fee8Actual?: Maybe<Scalars['Float']>;
  fee9Actual?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['String']>;
  haveTitle?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['String']>;
  imageCount?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  inspectionReport?: Maybe<Scalars['Boolean']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['String']>;
  itemStatus?: Maybe<Scalars['String']>;
  keywordList?: Maybe<Scalars['String']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberFinal?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  mounted?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['String']>;
  odometerType?: Maybe<Scalars['String']>;
  offSite?: Maybe<Scalars['Boolean']>;
  offSitePhysicalCity?: Maybe<Scalars['String']>;
  offSitePhysicalState?: Maybe<Scalars['String']>;
  offSitePhysicalZip?: Maybe<Scalars['String']>;
  parentCategory?: Maybe<Scalars['String']>;
  publicDocuments?: Maybe<Scalars['Boolean']>;
  removed?: Maybe<Scalars['Boolean']>;
  sale?: Maybe<InventorySale>;
  saleCity?: Maybe<Scalars['String']>;
  saleDate?: Maybe<Scalars['Float']>;
  saleDateString?: Maybe<Scalars['String']>;
  saleId?: Maybe<Scalars['Int']>;
  saleInfo?: Maybe<Scalars['String']>;
  saleName?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Float']>;
  saleState?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  sellerPayoutId?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
  titled?: Maybe<Scalars['Boolean']>;
  transmission?: Maybe<Scalars['String']>;
  trueMileageUnknown?: Maybe<Scalars['Boolean']>;
  unitNumber?: Maybe<Scalars['String']>;
  upperBodyDescription?: Maybe<Scalars['String']>;
  upperMake?: Maybe<Scalars['String']>;
  upperModel?: Maybe<Scalars['String']>;
  upperOptionalEquipment?: Maybe<Scalars['String']>;
  upperSerialNumber?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  webDescription?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  optionalEquipment?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  capacityType?: Maybe<Scalars['String']>;
  upperFuel?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  pickupAddress?: Maybe<Scalars['String']>;
  pickupCity?: Maybe<Scalars['String']>;
  pickupState?: Maybe<Scalars['String']>;
  pickupZip?: Maybe<Scalars['String']>;
  pickupDate?: Maybe<Scalars['Float']>;
  arrivalDate?: Maybe<Scalars['Float']>;
  imageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  inspectionDate?: Maybe<Scalars['Float']>;
  titleReceivedDate?: Maybe<Scalars['Float']>;
  buyerPickupDate?: Maybe<Scalars['Float']>;
};

export type ItemsSearchResponse = SearchResponse & {
  __typename?: 'ItemsSearchResponse';
  number?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalElements?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Boolean']>;
  totalPages?: Maybe<Scalars['Int']>;
  numberOfElements?: Maybe<Scalars['Int']>;
  content?: Maybe<Array<Maybe<Item>>>;
};

export type LookupValues = {
  __typename?: 'LookupValues';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type MakeAndModel = {
  __typename?: 'MakeAndModel';
  make?: Maybe<Scalars['String']>;
  models?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Mounted = {
  __typename?: 'Mounted';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  saveAuctionItem?: Maybe<AuctionItem>;
  sendContactUsMessage?: Maybe<Success>;
  register?: Maybe<Success>;
  forgotPassword?: Maybe<Success>;
  activateUser?: Maybe<Success>;
  saveDraftItem?: Maybe<RdsTest>;
  deleteDraftItem?: Maybe<RdsTest>;
  updateDraftItem?: Maybe<RdsTest>;
  saveSiteMessage?: Maybe<Success>;
  deleteSiteMessage?: Maybe<Success>;
  updateSiteMessage?: Maybe<Success>;
};


export type MutationSaveAuctionItemArgs = {
  accountId: Scalars['Int'];
  contactId: Scalars['Int'];
  auctionItem: AuctionItemInput;
};


export type MutationSendContactUsMessageArgs = {
  messageObject?: Maybe<ContactUsMessage>;
};


export type MutationRegisterArgs = {
  registrationObject?: Maybe<Registration>;
};


export type MutationForgotPasswordArgs = {
  forgotPasswordObject?: Maybe<ForgotPassword>;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationSaveDraftItemArgs = {
  draftItem?: Maybe<RdsDraftItemInput>;
};


export type MutationDeleteDraftItemArgs = {
  draftId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateDraftItemArgs = {
  draftItem?: Maybe<RdsDraftItemInput>;
};


export type MutationSaveSiteMessageArgs = {
  accountID?: Maybe<Scalars['Int']>;
  adminMessage?: Maybe<RdsMessageInput>;
};


export type MutationDeleteSiteMessageArgs = {
  accountID?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateSiteMessageArgs = {
  accountID?: Maybe<Scalars['Int']>;
  siteMessage?: Maybe<RdsMessageInput>;
};

export type Payout = {
  __typename?: 'Payout';
  id?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  adjustments?: Maybe<Scalars['Float']>;
  advertising?: Maybe<Scalars['Float']>;
  altecSalesCredit?: Maybe<Scalars['Boolean']>;
  checkPayableTo?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['Float']>;
  decommission?: Maybe<Scalars['Float']>;
  expense1Amount?: Maybe<Scalars['Float']>;
  expense2Amount?: Maybe<Scalars['Float']>;
  expense3Amount?: Maybe<Scalars['Float']>;
  expense4Amount?: Maybe<Scalars['Float']>;
  expense5Amount?: Maybe<Scalars['Float']>;
  income1Amount?: Maybe<Scalars['Float']>;
  income2Amount?: Maybe<Scalars['Float']>;
  income3Amount?: Maybe<Scalars['Float']>;
  income4Amount?: Maybe<Scalars['Float']>;
  income5Amount?: Maybe<Scalars['Float']>;
  misc1Amount?: Maybe<Scalars['Float']>;
  misc2Amount?: Maybe<Scalars['Float']>;
  partsRepairs?: Maybe<Scalars['Float']>;
  payoutStatus?: Maybe<Scalars['String']>;
  releasePayoutReport?: Maybe<Scalars['Boolean']>;
  revised?: Maybe<Scalars['Boolean']>;
  saleId?: Maybe<Scalars['Int']>;
  salePriceSubtotal?: Maybe<Scalars['Float']>;
  sellerFee?: Maybe<Scalars['Float']>;
  sellerNet?: Maybe<Scalars['Float']>;
  totalLots?: Maybe<Scalars['Int']>;
  totalNoSaleAmount?: Maybe<Scalars['Float']>;
  transportation?: Maybe<Scalars['Float']>;
  washing?: Maybe<Scalars['Float']>;
  sale?: Maybe<PayoutSale>;
  upsTrackingNumber?: Maybe<Scalars['String']>;
};

export type PayoutFiltersResponse = {
  __typename?: 'PayoutFiltersResponse';
  sale?: Maybe<Array<Maybe<LookupValues>>>;
};

export type PayoutSale = {
  __typename?: 'PayoutSale';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  adminFee?: Maybe<Scalars['Float']>;
  invoicesProcessed?: Maybe<Scalars['Boolean']>;
  payoutsProcessed?: Maybe<Scalars['Boolean']>;
  lastInvoiceProcessingDate?: Maybe<Scalars['Date']>;
  lastPayoutProcessingDate?: Maybe<Scalars['Date']>;
  auctionStatus?: Maybe<Scalars['String']>;
  buyersFeeInternet?: Maybe<Scalars['Float']>;
  buyersFeePercentage?: Maybe<Scalars['Float']>;
  buyersFeeTaxable?: Maybe<Scalars['Boolean']>;
  caAdditionalBuyerFeePercentage?: Maybe<Scalars['Float']>;
  caOOSTitledTax?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contractPrinted?: Maybe<Scalars['Boolean']>;
  copies?: Maybe<Scalars['Float']>;
  county?: Maybe<Scalars['String']>;
  creditCardConvenienceFee?: Maybe<Scalars['Float']>;
  creditCardsNotAccepted?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  defaultEmailAddress?: Maybe<Scalars['String']>;
  defaultEmailName?: Maybe<Scalars['String']>;
  receivableNoticeEmailBody?: Maybe<Scalars['String']>;
  receivableNoticeEmailBody2?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Date']>;
  fax?: Maybe<Scalars['String']>;
  fee1ADescription?: Maybe<Scalars['String']>;
  fee1Description?: Maybe<Scalars['String']>;
  fee2Description?: Maybe<Scalars['String']>;
  fee3Description?: Maybe<Scalars['String']>;
  fee4Description?: Maybe<Scalars['String']>;
  fee5Description?: Maybe<Scalars['String']>;
  fee8Description?: Maybe<Scalars['String']>;
  financialAccountId?: Maybe<Scalars['String']>;
  financialLocationId?: Maybe<Scalars['String']>;
  firstPostDate?: Maybe<Scalars['Date']>;
  gatavtPercent?: Maybe<Scalars['Float']>;
  info?: Maybe<Scalars['String']>;
  invoiceAddress?: Maybe<Scalars['String']>;
  invoiceCity?: Maybe<Scalars['String']>;
  invoiceCountry?: Maybe<Scalars['String']>;
  invoiceFX?: Maybe<Scalars['String']>;
  invoiceState?: Maybe<Scalars['String']>;
  invoiceStateCode?: Maybe<Scalars['String']>;
  invoiceTerms?: Maybe<Scalars['String']>;
  invoiceTX?: Maybe<Scalars['String']>;
  invoiceZipCode?: Maybe<Scalars['String']>;
  lastPostDate?: Maybe<Scalars['Date']>;
  leadSources?: Maybe<Scalars['String']>;
  letterOfAuthorizationPrinted?: Maybe<Scalars['Boolean']>;
  lotNumbersAssigned?: Maybe<Scalars['Boolean']>;
  miscPayout1?: Maybe<Scalars['Float']>;
  miscPayout2?: Maybe<Scalars['Float']>;
  notaryFee?: Maybe<Scalars['Float']>;
  numberOfRings?: Maybe<Scalars['Float']>;
  offSiteItems?: Maybe<Scalars['Boolean']>;
  omitOdometerDisc?: Maybe<Scalars['Boolean']>;
  payoutsFinalized?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  post?: Maybe<Scalars['Boolean']>;
  proxibidAuctionId?: Maybe<Scalars['String']>;
  proxibidEnabled?: Maybe<Scalars['Boolean']>;
  releasePayoutReports?: Maybe<Scalars['Boolean']>;
  reportConfiguration?: Maybe<Scalars['String']>;
  ruleVersion?: Maybe<Scalars['String']>;
  saleNameFormatted?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  showOdometer?: Maybe<Scalars['Boolean']>;
  siteContact?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Date']>;
  state?: Maybe<Scalars['String']>;
  stateTableVersion?: Maybe<Scalars['String']>;
  stateTaxRate?: Maybe<Scalars['Float']>;
  suppressInvoiceDisplay?: Maybe<Scalars['Boolean']>;
  target?: Maybe<Scalars['Float']>;
  taxCity?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  taxCounty?: Maybe<Scalars['String']>;
  taxState?: Maybe<Scalars['String']>;
  taxVersion?: Maybe<Scalars['String']>;
  termsAdditional?: Maybe<Scalars['String']>;
  termsAdditionalPayment?: Maybe<Scalars['String']>;
  termsBuyersFee?: Maybe<Scalars['String']>;
  termsContactInfo?: Maybe<Scalars['String']>;
  termsDesc?: Maybe<Scalars['String']>;
  termsFinancing?: Maybe<Scalars['String']>;
  termsLiveInternetBidding?: Maybe<Scalars['String']>;
  termsLocation?: Maybe<Scalars['String']>;
  termsNotice?: Maybe<Scalars['String']>;
  termsPayment?: Maybe<Scalars['String']>;
  termsRemoval?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  titleFee?: Maybe<Scalars['Float']>;
  totalLots?: Maybe<Scalars['Float']>;
  unofficialTotal?: Maybe<Scalars['Float']>;
  validAuction?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['String']>;
  saleName?: Maybe<Scalars['String']>;
  saleNameTimedAuctionFormatted?: Maybe<Scalars['String']>;
};

export type PayoutSearchRequest = {
  sellerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  salePriceSubtotalMin?: Maybe<Scalars['Float']>;
  salePriceSubtotalMax?: Maybe<Scalars['Float']>;
  sellerNetMin?: Maybe<Scalars['Float']>;
  sellerNetMax?: Maybe<Scalars['Float']>;
  auctionDateStart?: Maybe<Scalars['String']>;
  auctionDateEnd?: Maybe<Scalars['String']>;
  sellerPayoutId?: Maybe<Scalars['Int']>;
  payoutFinalized?: Maybe<Scalars['Boolean']>;
  saleId?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};

export type PayoutSearchResponse = {
  __typename?: 'PayoutSearchResponse';
  number?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalElements?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Boolean']>;
  totalPages?: Maybe<Scalars['Int']>;
  numberOfElements?: Maybe<Scalars['Int']>;
  content?: Maybe<Array<Maybe<Payout>>>;
};

export type PersonalInfo = {
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  personalPhoneNumber?: Maybe<Scalars['String']>;
  personalPhoneType?: Maybe<Scalars['String']>;
  personalAddressLine1?: Maybe<Scalars['String']>;
  personalAddressLine2?: Maybe<Scalars['String']>;
  personalCity?: Maybe<Scalars['String']>;
  personalState?: Maybe<Scalars['String']>;
  personalZip?: Maybe<Scalars['String']>;
  personalCounty?: Maybe<Scalars['String']>;
  personalCountry?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  parentAccountId?: Maybe<Scalars['Int']>;
  defaultContact?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  accountInfo?: Maybe<AccountInfo>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  subcategories?: Maybe<Array<Maybe<Subcategory>>>;
  makeAndModel?: Maybe<Array<Maybe<MakeAndModel>>>;
  engines?: Maybe<Array<Maybe<Engine>>>;
  fuel?: Maybe<Array<Maybe<Fuel>>>;
  transmissions?: Maybe<Array<Maybe<Transmission>>>;
  upperMakeAndModel?: Maybe<Array<Maybe<UpperMakeAndModel>>>;
  upperFuel?: Maybe<Array<Maybe<UpperFuel>>>;
  capacity?: Maybe<Array<Maybe<Capacity>>>;
  mounted?: Maybe<Array<Maybe<Mounted>>>;
  sellerFilterOptions?: Maybe<SellerFilterOptions>;
  buyerFilterOptions?: Maybe<BuyerFilterOptions>;
  invoiceSaleLocations?: Maybe<Array<Maybe<LookupValues>>>;
  upcomingAuctions?: Maybe<Array<Maybe<Sale>>>;
  recentAuctions?: Maybe<Array<Maybe<RecentAuction>>>;
  searchPayouts?: Maybe<PayoutSearchResponse>;
  payoutDetails?: Maybe<PayoutSearchResponse>;
  payoutFilters?: Maybe<PayoutFiltersResponse>;
  profiles?: Maybe<Array<Maybe<Profile>>>;
  ytdSales?: Maybe<Scalars['Float']>;
  estimatedPurchaseBalance?: Maybe<Scalars['Float']>;
  currentRegisteredAuctions?: Maybe<Scalars['Float']>;
  countries?: Maybe<Array<Maybe<Country>>>;
  businessCodes?: Maybe<Array<Maybe<BusinessCode>>>;
  itemFilters?: Maybe<FiltersResponse>;
  searchItems?: Maybe<ItemsSearchResponse>;
  itemDetails?: Maybe<Item>;
  inventoryManagementDetails?: Maybe<InventoryManagementDetailsResponse>;
  notOnSiteCount?: Maybe<Scalars['Int']>;
  conditionPendingCount?: Maybe<Scalars['Int']>;
  awaitingTitleCount?: Maybe<Scalars['Int']>;
  auctionReadyCount?: Maybe<Scalars['Int']>;
  assignedItemsCount?: Maybe<Scalars['Int']>;
  exportItems?: Maybe<Array<Maybe<Item>>>;
  geocode?: Maybe<Geocode>;
  searchInvoices?: Maybe<InvoiceSearchResponse>;
  queryDraftItems?: Maybe<RdsDraftSearchResponse>;
  draftFilterValues?: Maybe<RdsFilters>;
  scanSiteMessages?: Maybe<Array<Maybe<SiteMessage>>>;
};


export type QuerySellerFilterOptionsArgs = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryBuyerFilterOptionsArgs = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryInvoiceSaleLocationsArgs = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryUpcomingAuctionsArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRecentAuctionsArgs = {
  buyerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sellerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchPayoutsArgs = {
  searchRequest: PayoutSearchRequest;
};


export type QueryPayoutDetailsArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
  payoutId: Scalars['Int'];
};


export type QueryPayoutFiltersArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryProfilesArgs = {
  type?: Maybe<Scalars['String']>;
};


export type QueryYtdSalesArgs = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryEstimatedPurchaseBalanceArgs = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryCurrentRegisteredAuctionsArgs = {
  searchObject?: Maybe<InvoiceSearchRequest>;
};


export type QueryItemFiltersArgs = {
  filtersRequest?: Maybe<FiltersRequest>;
};


export type QuerySearchItemsArgs = {
  searchObject: SearchItems;
};


export type QueryItemDetailsArgs = {
  sellerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  buyerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  itemId: Scalars['Int'];
};


export type QueryInventoryManagementDetailsArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryNotOnSiteCountArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryConditionPendingCountArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryAwaitingTitleCountArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryAuctionReadyCountArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryAssignedItemsCountArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryExportItemsArgs = {
  searchObject: SearchItems;
  exportStartPage: Scalars['Int'];
  exportEndPage: Scalars['Int'];
};


export type QueryGeocodeArgs = {
  zip: Scalars['String'];
};


export type QuerySearchInvoicesArgs = {
  searchObject: InvoiceSearchRequest;
};


export type QueryQueryDraftItemsArgs = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  searchOptions?: Maybe<SearchOptions>;
};


export type QueryDraftFilterValuesArgs = {
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryScanSiteMessagesArgs = {
  accountID?: Maybe<Scalars['Int']>;
};

export type RdsDraftItem = {
  __typename?: 'RDSDraftItem';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  currentStep?: Maybe<Scalars['Int']>;
  itemType?: Maybe<Scalars['String']>;
  submitTimestamp?: Maybe<Scalars['String']>;
  parentCategory?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  sellerFleetNumber2?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  optionalEquipment?: Maybe<Scalars['String']>;
  airConditioning?: Maybe<Scalars['Boolean']>;
  unitNumber?: Maybe<Scalars['String']>;
  upperSerialNumber?: Maybe<Scalars['String']>;
  upperMake?: Maybe<Scalars['String']>;
  upperModel?: Maybe<Scalars['String']>;
  upperFuel?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  capacityUnit?: Maybe<Scalars['String']>;
  capacityType?: Maybe<Scalars['String']>;
  mounted?: Maybe<Scalars['String']>;
  upperOptionalEquipment?: Maybe<Scalars['String']>;
  itemNotes?: Maybe<Scalars['String']>;
  offSitePhysicalAddress?: Maybe<Scalars['String']>;
  offSitePhysicalCity?: Maybe<Scalars['String']>;
  offSitePhysicalState?: Maybe<Scalars['String']>;
  offSitePhysicalZip?: Maybe<Scalars['String']>;
  taxCounty?: Maybe<Scalars['String']>;
  titled?: Maybe<Scalars['Boolean']>;
};

export type RdsDraftItemInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  currentStep?: Maybe<Scalars['Int']>;
  itemType?: Maybe<Scalars['String']>;
  submitTimestamp?: Maybe<Scalars['String']>;
  parentCategory?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  sellerFleetNumber2?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  optionalEquipment?: Maybe<Scalars['String']>;
  airConditioning?: Maybe<Scalars['Boolean']>;
  unitNumber?: Maybe<Scalars['String']>;
  upperSerialNumber?: Maybe<Scalars['String']>;
  upperMake?: Maybe<Scalars['String']>;
  upperModel?: Maybe<Scalars['String']>;
  upperFuel?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  capacityUnit?: Maybe<Scalars['String']>;
  capacityType?: Maybe<Scalars['String']>;
  mounted?: Maybe<Scalars['String']>;
  upperOptionalEquipment?: Maybe<Scalars['String']>;
  itemNotes?: Maybe<Scalars['String']>;
  offSitePhysicalAddress?: Maybe<Scalars['String']>;
  offSitePhysicalCity?: Maybe<Scalars['String']>;
  offSitePhysicalState?: Maybe<Scalars['String']>;
  offSitePhysicalZip?: Maybe<Scalars['String']>;
  taxCounty?: Maybe<Scalars['String']>;
  titled?: Maybe<Scalars['Boolean']>;
};

export type RdsDraftSearchResponse = {
  __typename?: 'RDSDraftSearchResponse';
  records?: Maybe<Array<Maybe<RdsDraftItem>>>;
};

export type RdsFilters = {
  __typename?: 'RDSFilters';
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  make?: Maybe<Array<Maybe<Scalars['String']>>>;
  model?: Maybe<Array<Maybe<Scalars['String']>>>;
  upperMake?: Maybe<Array<Maybe<Scalars['String']>>>;
  upperModel?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RdsMessageInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  submitTimestamp?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  sitePlacement?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type RdsTest = {
  __typename?: 'RDSTest';
  test?: Maybe<Scalars['String']>;
};

export type RecentAuction = {
  __typename?: 'RecentAuction';
  sale?: Maybe<Sale>;
  payout?: Maybe<Payout>;
  invoice?: Maybe<Invoice>;
};

export type Registration = {
  individual?: Maybe<Scalars['Boolean']>;
  personalInfo?: Maybe<PersonalInfo>;
  businessInfo?: Maybe<BusinessInfo>;
  password?: Maybe<Scalars['String']>;
  recaptcha?: Maybe<Scalars['String']>;
};

export type Sale = {
  __typename?: 'Sale';
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  startTime?: Maybe<Scalars['Date']>;
  endTime?: Maybe<Scalars['Date']>;
  auctionStatus?: Maybe<Scalars['String']>;
  saleName?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  proxibidAuctionId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  itemCount?: Maybe<Scalars['Int']>;
  sellerHasItems?: Maybe<Scalars['Boolean']>;
};

export type SearchItems = {
  category?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  sellerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  buyerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  saleInfo?: Maybe<Scalars['String']>;
  offSitePhysical?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  itemStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  cpStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  inventoryManagementStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  upperMake?: Maybe<Scalars['String']>;
  upperModel?: Maybe<Scalars['String']>;
  payoutIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type SearchOptions = {
  backEndSortResult?: Maybe<Scalars['String']>;
  filterResults?: Maybe<Array<Maybe<SelectedFilter>>>;
  sortResults?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type SearchResponse = {
  number?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalElements?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Boolean']>;
  totalPages?: Maybe<Scalars['Int']>;
  numberOfElements?: Maybe<Scalars['Int']>;
};

export type SelectedFilter = {
  filter?: Maybe<Scalars['String']>;
  selection?: Maybe<Scalars['String']>;
  backEndFilter?: Maybe<Scalars['String']>;
};

export type SellerFilterOptions = {
  __typename?: 'SellerFilterOptions';
  saleInfo?: Maybe<Array<Maybe<LookupValues>>>;
  offSitePhysical?: Maybe<Array<Maybe<LookupValues>>>;
  category?: Maybe<Array<Maybe<LookupValues>>>;
  make?: Maybe<Array<Maybe<LookupValues>>>;
  model?: Maybe<Array<Maybe<LookupValues>>>;
  upperMake?: Maybe<Array<Maybe<LookupValues>>>;
};

export type SiteMessage = {
  __typename?: 'SiteMessage';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  submitTimestamp?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  sitePlacement?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type SiteMessageId = {
  __typename?: 'SiteMessageID';
  messageId?: Maybe<Scalars['Int']>;
};

export type Subcategory = {
  __typename?: 'Subcategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentCategoryName?: Maybe<Scalars['String']>;
  combination?: Maybe<Scalars['String']>;
};

export type Success = {
  __typename?: 'Success';
  success?: Maybe<Scalars['Boolean']>;
};

export type SupportContact = {
  __typename?: 'SupportContact';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Transmission = {
  __typename?: 'Transmission';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UpperFuel = {
  __typename?: 'UpperFuel';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UpperMakeAndModel = {
  __typename?: 'UpperMakeAndModel';
  make?: Maybe<Scalars['String']>;
  models?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SaveSiteMessagesMutationVariables = Exact<{
  accountID?: Maybe<Scalars['Int']>;
  adminMessage?: Maybe<RdsMessageInput>;
}>;


export type SaveSiteMessagesMutation = (
  { __typename?: 'Mutation' }
  & { saveSiteMessage?: Maybe<(
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  )> }
);

export type UpdateSiteMessagesMutationVariables = Exact<{
  accountID?: Maybe<Scalars['Int']>;
  siteMessage?: Maybe<RdsMessageInput>;
}>;


export type UpdateSiteMessagesMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteMessage?: Maybe<(
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  )> }
);

export type SiteMessageQueryVariables = Exact<{
  accountID?: Maybe<Scalars['Int']>;
}>;


export type SiteMessageQuery = (
  { __typename?: 'Query' }
  & { scanSiteMessages?: Maybe<Array<Maybe<(
    { __typename?: 'SiteMessage' }
    & Pick<SiteMessage, 'id' | 'userId' | 'submitTimestamp' | 'title' | 'message' | 'status' | 'level' | 'sitePlacement' | 'endDate' | 'startDate'>
  )>>> }
);

export type DeleteSiteMessageMutationVariables = Exact<{
  accountID?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
}>;


export type DeleteSiteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteSiteMessage?: Maybe<(
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  )> }
);

export type YtdSalesQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type YtdSalesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ytdSales'>
);

export type SaleSummaryFragment = (
  { __typename?: 'Sale' }
  & Pick<Sale, 'id' | 'saleName' | 'date' | 'startTime' | 'endTime' | 'auctionStatus' | 'saleType' | 'city' | 'state' | 'sellerHasItems' | 'itemCount'>
);

export type GetUpcomingAuctionsQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetUpcomingAuctionsQuery = (
  { __typename?: 'Query' }
  & { upcomingAuctions?: Maybe<Array<Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'sellerHasItems'>
    & SaleSummaryFragment
  )>>> }
);

export type GetRecentAuctionsQueryVariables = Exact<{
  buyerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  sellerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetRecentAuctionsQuery = (
  { __typename?: 'Query' }
  & { recentAuctions?: Maybe<Array<Maybe<(
    { __typename?: 'RecentAuction' }
    & { sale?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'itemCount'>
      & SaleSummaryFragment
    )>, payout?: Maybe<(
      { __typename?: 'Payout' }
      & Pick<Payout, 'id' | 'accountId' | 'payoutStatus' | 'saleId' | 'sellerNet' | 'totalLots'>
      & { sale?: Maybe<(
        { __typename?: 'PayoutSale' }
        & Pick<PayoutSale, 'payoutsFinalized'>
      )> }
    )>, invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'accountId' | 'invoiceTotal'>
    )> }
  )>>> }
);

export type GetInventoryManagementDetailsQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetInventoryManagementDetailsQuery = (
  { __typename?: 'Query' }
  & { inventoryManagementDetails?: Maybe<(
    { __typename?: 'InventoryManagementDetailsResponse' }
    & Pick<InventoryManagementDetailsResponse, 'notOnSite' | 'conditionPending' | 'awaitingTitle' | 'auctionReady' | 'assignedItems'>
  )> }
);

export type GetEstimatedPurchaseBalanceQueryVariables = Exact<{
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type GetEstimatedPurchaseBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'estimatedPurchaseBalance'>
);

export type GetCurrentRegisteredAuctionsQueryVariables = Exact<{
  searchObject: InvoiceSearchRequest;
}>;


export type GetCurrentRegisteredAuctionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'currentRegisteredAuctions'>
);

export type GetNotOnSiteCountQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetNotOnSiteCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'notOnSiteCount'>
);

export type GetConditionPendingCountQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetConditionPendingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'conditionPendingCount'>
);

export type GetAwaitingTitleCountQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetAwaitingTitleCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'awaitingTitleCount'>
);

export type GetAuctionReadyCountQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetAuctionReadyCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'auctionReadyCount'>
);

export type GetAssignedItemsCountQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetAssignedItemsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'assignedItemsCount'>
);

export type SearchInvoicesQueryVariables = Exact<{
  searchObject: InvoiceSearchRequest;
}>;


export type SearchInvoicesQuery = (
  { __typename?: 'Query' }
  & { searchInvoices?: Maybe<(
    { __typename?: 'InvoiceSearchResponse' }
    & Pick<InvoiceSearchResponse, 'number' | 'totalElements'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'accountId' | 'invoiceNumber' | 'invoiceTotal' | 'bidderNumber' | 'invoiceBalance'>
      & { sale?: Maybe<(
        { __typename?: 'InventorySale' }
        & Pick<InventorySale, 'date' | 'city' | 'state'>
      )> }
    )>>> }
  )> }
);

export type InvoiceSaleLocationsQueryVariables = Exact<{
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type InvoiceSaleLocationsQuery = (
  { __typename?: 'Query' }
  & { invoiceSaleLocations?: Maybe<Array<Maybe<(
    { __typename?: 'LookupValues' }
    & Pick<LookupValues, 'name' | 'description'>
  )>>> }
);

export type SearchItemsQueryVariables = Exact<{
  searchObject: SearchItems;
}>;


export type SearchItemsQuery = (
  { __typename?: 'Query' }
  & { searchItems?: Maybe<(
    { __typename?: 'ItemsSearchResponse' }
    & Pick<ItemsSearchResponse, 'number' | 'pageSize' | 'totalElements' | 'last' | 'first' | 'totalPages' | 'numberOfElements'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'amountOwed' | 'bidderBuyerInvoiceId' | 'buyerAccountId' | 'buyersTotal' | 'catalogDescription' | 'category' | 'engine' | 'fuel' | 'haveTitle' | 'height' | 'imageCount' | 'itemId' | 'itemStatus' | 'lotNumber' | 'make' | 'model' | 'mounted' | 'odometer' | 'offSitePhysicalCity' | 'offSitePhysicalState' | 'offSitePhysicalZip' | 'parentCategory' | 'saleDate' | 'saleId' | 'saleInfo' | 'saleName' | 'salePrice' | 'saleType' | 'sellerPayoutId' | 'titled' | 'transmission' | 'unitNumber' | 'upperMake' | 'upperModel' | 'upperOptionalEquipment' | 'upperSerialNumber' | 'vin' | 'year'>
      & { sale?: Maybe<(
        { __typename?: 'InventorySale' }
        & Pick<InventorySale, 'payoutsFinalized'>
      )> }
    )>>> }
  )> }
);

export type SellerFilterOptionsQueryVariables = Exact<{
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type SellerFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { sellerFilterOptions?: Maybe<(
    { __typename?: 'SellerFilterOptions' }
    & { saleInfo?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, offSitePhysical?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, category?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, make?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, model?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, upperMake?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>> }
  )> }
);

export type BuyerFilterOptionsQueryVariables = Exact<{
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type BuyerFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { buyerFilterOptions?: Maybe<(
    { __typename?: 'BuyerFilterOptions' }
    & { saleInfo?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, offSitePhysical?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, category?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, make?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, model?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, upperMake?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>>, upperModel?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>> }
  )> }
);

export type ItemDetailsQueryVariables = Exact<{
  sellerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  buyerAccountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  itemId: Scalars['Int'];
}>;


export type ItemDetailsQuery = (
  { __typename?: 'Query' }
  & { itemDetails?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'unitNumber' | 'itemStatus' | 'salePrice' | 'lotNumber' | 'saleInfo' | 'saleType' | 'saleDate' | 'catalogDescription' | 'category' | 'vin' | 'year' | 'make' | 'model' | 'itemId' | 'engine' | 'fuel' | 'transmission' | 'odometer' | 'upperSerialNumber' | 'upperMake' | 'upperModel' | 'height' | 'mounted' | 'upperOptionalEquipment' | 'plateNumber' | 'conditions' | 'optionalEquipment' | 'capacity' | 'capacityType' | 'upperFuel' | 'hours' | 'offSite' | 'offSitePhysicalCity' | 'offSitePhysicalState' | 'offSitePhysicalZip' | 'commission' | 'expenseTransportation' | 'expenseWashing' | 'expenseDecommission' | 'adjustmentAmount' | 'expensePartsRepairs' | 'expenseMisc1' | 'expenseMisc2' | 'amountOwed' | 'imageUrls' | 'haveTitle' | 'titled' | 'inspectionDate' | 'titleReceivedDate' | 'createDate' | 'sellerPayoutId' | 'bidderBuyerInvoiceId' | 'bidderNumber' | 'buyerFee' | 'tax' | 'caWeightFee' | 'caLicenseFee' | 'titleFee' | 'fee1aActual' | 'fee1Actual' | 'fee2aActual' | 'fee2Actual' | 'fee3Actual' | 'fee4Actual' | 'fee5Actual' | 'fee6Actual' | 'fee7Actual' | 'fee8Actual' | 'fee9Actual' | 'saleId' | 'buyerAccountId' | 'arrivalDate'>
    & { sale?: Maybe<(
      { __typename?: 'InventorySale' }
      & Pick<InventorySale, 'payoutsFinalized' | 'fee1ADescription' | 'fee1Description' | 'fee2Description' | 'fee3Description' | 'fee4Description' | 'fee5Description' | 'fee6Description' | 'fee7Description' | 'fee8Description' | 'fee9Description'>
    )> }
  )> }
);

export type GetSellerItemsExportDataQueryVariables = Exact<{
  searchObject: SearchItems;
  exportStartPage: Scalars['Int'];
  exportEndPage: Scalars['Int'];
}>;


export type GetSellerItemsExportDataQuery = (
  { __typename?: 'Query' }
  & { exportItems?: Maybe<Array<Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'arrivalDate' | 'conditions' | 'haveTitle' | 'itemStatus' | 'unitNumber' | 'category' | 'vin' | 'year' | 'make' | 'model' | 'lotNumber' | 'saleInfo' | 'saleType' | 'saleDate' | 'offSitePhysicalCity' | 'offSitePhysicalState' | 'catalogDescription' | 'plateNumber' | 'engine' | 'fuel' | 'transmission' | 'titled' | 'odometer' | 'hours' | 'optionalEquipment' | 'upperSerialNumber' | 'upperMake' | 'upperModel' | 'upperFuel' | 'height' | 'capacity' | 'mounted' | 'upperOptionalEquipment' | 'amountOwed' | 'salePrice'>
    & { sale?: Maybe<(
      { __typename?: 'InventorySale' }
      & Pick<InventorySale, 'payoutsFinalized'>
    )> }
  )>>> }
);

export type GetBuyerItemsExportDataQueryVariables = Exact<{
  searchObject: SearchItems;
  exportStartPage: Scalars['Int'];
  exportEndPage: Scalars['Int'];
}>;


export type GetBuyerItemsExportDataQuery = (
  { __typename?: 'Query' }
  & { exportItems?: Maybe<Array<Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'lotNumber' | 'saleName' | 'saleDate' | 'parentCategory' | 'category' | 'catalogDescription' | 'vin' | 'upperSerialNumber' | 'odometer' | 'hours' | 'titled' | 'buyerPickupDate' | 'offSitePhysicalCity' | 'offSitePhysicalState' | 'offSitePhysicalZip' | 'salePrice' | 'buyersTotal'>
  )>>> }
);

export type ForgotPasswordMutationVariables = Exact<{
  forgotPasswordObject?: Maybe<ForgotPassword>;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  )> }
);

export type SearchPayoutsQueryVariables = Exact<{
  searchRequest: PayoutSearchRequest;
}>;


export type SearchPayoutsQuery = (
  { __typename?: 'Query' }
  & { searchPayouts?: Maybe<(
    { __typename?: 'PayoutSearchResponse' }
    & Pick<PayoutSearchResponse, 'numberOfElements' | 'totalElements'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragment
    )>>> }
  )> }
);

export type GetPayoutDetailsQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  payoutId: Scalars['Int'];
}>;


export type GetPayoutDetailsQuery = (
  { __typename?: 'Query' }
  & { payoutDetails?: Maybe<(
    { __typename?: 'PayoutSearchResponse' }
    & Pick<PayoutSearchResponse, 'numberOfElements' | 'totalElements'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragment
    )>>> }
  )> }
);

export type GetPayoutFiltersQueryVariables = Exact<{
  accountIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetPayoutFiltersQuery = (
  { __typename?: 'Query' }
  & { payoutFilters?: Maybe<(
    { __typename?: 'PayoutFiltersResponse' }
    & { sale?: Maybe<Array<Maybe<(
      { __typename?: 'LookupValues' }
      & Pick<LookupValues, 'name' | 'description'>
    )>>> }
  )> }
);

export type PayoutFragment = (
  { __typename?: 'Payout' }
  & Pick<Payout, 'id' | 'sellerNet' | 'salePriceSubtotal' | 'upsTrackingNumber' | 'accountId'>
  & { sale?: Maybe<(
    { __typename?: 'PayoutSale' }
    & Pick<PayoutSale, 'payoutsFinalized' | 'city' | 'state' | 'date' | 'saleType'>
  )> }
);

export type GetBusinessCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessCodesQuery = (
  { __typename?: 'Query' }
  & { businessCodes?: Maybe<Array<Maybe<(
    { __typename?: 'BusinessCode' }
    & Pick<BusinessCode, 'id' | 'name'>
  )>>> }
);

export type GeocodeQueryVariables = Exact<{
  zip: Scalars['String'];
}>;


export type GeocodeQuery = (
  { __typename?: 'Query' }
  & { geocode?: Maybe<(
    { __typename?: 'Geocode' }
    & Pick<Geocode, 'success'>
    & { objects?: Maybe<Array<Maybe<(
      { __typename?: 'GeocodeLocation' }
      & Pick<GeocodeLocation, 'city' | 'county' | 'state'>
    )>>> }
  )> }
);

export type RegisterMutationVariables = Exact<{
  registrationObject: Registration;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register?: Maybe<(
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  )> }
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & { activateUser?: Maybe<(
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  )> }
);

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'code' | 'description'>
  )>>> }
);

export type GetContactInfoQueryVariables = Exact<{
  type?: Maybe<Scalars['String']>;
}>;


export type GetContactInfoQuery = (
  { __typename?: 'Query' }
  & { profiles?: Maybe<Array<Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'parentAccountId' | 'defaultContact'>
    & { accountInfo?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'firstName' | 'lastName' | 'address' | 'city' | 'state' | 'zip' | 'county' | 'country' | 'phoneNumber' | 'phoneType' | 'email' | 'sellerCode'>
      & { companyInfo?: Maybe<(
        { __typename?: 'CompanyInfo' }
        & Pick<CompanyInfo, 'companyName' | 'companyId' | 'companyPhone' | 'companyCity' | 'companyCountry' | 'companyAddress' | 'companyState' | 'companyZip'>
      )>, supportContact?: Maybe<(
        { __typename?: 'SupportContact' }
        & Pick<SupportContact, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
      )> }
    )> }
  )>>> }
);

export type SendContactUsMessageMutationMutationVariables = Exact<{
  messageObject?: Maybe<ContactUsMessage>;
}>;


export type SendContactUsMessageMutationMutation = (
  { __typename?: 'Mutation' }
  & { sendContactUsMessage?: Maybe<(
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  )> }
);

export type AccountInfoFragment = (
  { __typename?: 'AccountInfo' }
  & Pick<AccountInfo, 'firstName' | 'lastName' | 'address' | 'city' | 'state' | 'zip' | 'county' | 'country' | 'phoneNumber' | 'phoneType' | 'email' | 'sellerCode'>
  & { companyInfo?: Maybe<(
    { __typename?: 'CompanyInfo' }
    & CompanyInfoFragment
  )>, supportContact?: Maybe<(
    { __typename?: 'SupportContact' }
    & SupportContactFragment
  )> }
);

export type CompanyInfoFragment = (
  { __typename?: 'CompanyInfo' }
  & Pick<CompanyInfo, 'companyName' | 'companyId' | 'companyPhone' | 'companyCity' | 'companyCountry' | 'companyAddress' | 'companyState' | 'companyZip'>
);

export type SupportContactFragment = (
  { __typename?: 'SupportContact' }
  & Pick<SupportContact, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
);

export type GetDropdownValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDropdownValuesQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'name'>
  )>>>, subcategories?: Maybe<Array<Maybe<(
    { __typename?: 'Subcategory' }
    & Pick<Subcategory, 'id' | 'name' | 'parentCategoryName' | 'combination'>
  )>>>, makeAndModel?: Maybe<Array<Maybe<(
    { __typename?: 'MakeAndModel' }
    & Pick<MakeAndModel, 'make' | 'models'>
  )>>>, engines?: Maybe<Array<Maybe<(
    { __typename?: 'Engine' }
    & Pick<Engine, 'id' | 'name'>
  )>>>, fuel?: Maybe<Array<Maybe<(
    { __typename?: 'Fuel' }
    & Pick<Fuel, 'id' | 'name'>
  )>>>, transmissions?: Maybe<Array<Maybe<(
    { __typename?: 'Transmission' }
    & Pick<Transmission, 'id' | 'name'>
  )>>>, upperMakeAndModel?: Maybe<Array<Maybe<(
    { __typename?: 'UpperMakeAndModel' }
    & Pick<UpperMakeAndModel, 'make' | 'models'>
  )>>>, upperFuel?: Maybe<Array<Maybe<(
    { __typename?: 'UpperFuel' }
    & Pick<UpperFuel, 'id' | 'name'>
  )>>>, capacity?: Maybe<Array<Maybe<(
    { __typename?: 'Capacity' }
    & Pick<Capacity, 'id' | 'name' | 'description' | 'type'>
  )>>>, mounted?: Maybe<Array<Maybe<(
    { __typename?: 'Mounted' }
    & Pick<Mounted, 'id' | 'name' | 'type'>
  )>>> }
);

export type SaveItemMutationVariables = Exact<{
  accountId: Scalars['Int'];
  contactId: Scalars['Int'];
  auctionItem: AuctionItemInput;
}>;


export type SaveItemMutation = (
  { __typename?: 'Mutation' }
  & { saveAuctionItem?: Maybe<(
    { __typename?: 'AuctionItem' }
    & Pick<AuctionItem, 'id' | 'saleId' | 'make' | 'model'>
  )> }
);

export type DraftItemsQueryVariables = Exact<{
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  searchOptions?: Maybe<SearchOptions>;
}>;


export type DraftItemsQuery = (
  { __typename?: 'Query' }
  & { queryDraftItems?: Maybe<(
    { __typename?: 'RDSDraftSearchResponse' }
    & { records?: Maybe<Array<Maybe<(
      { __typename?: 'RDSDraftItem' }
      & Pick<RdsDraftItem, 'id' | 'userId' | 'currentStep' | 'itemType' | 'submitTimestamp' | 'parentCategory' | 'category' | 'vin' | 'year' | 'make' | 'model' | 'sellerFleetNumber2' | 'plateNumber' | 'engine' | 'fuel' | 'transmission' | 'odometer' | 'hours' | 'optionalEquipment' | 'airConditioning' | 'unitNumber' | 'upperSerialNumber' | 'upperMake' | 'upperModel' | 'upperFuel' | 'height' | 'capacity' | 'capacityUnit' | 'capacityType' | 'mounted' | 'upperOptionalEquipment' | 'itemNotes' | 'offSitePhysicalAddress' | 'offSitePhysicalCity' | 'offSitePhysicalState' | 'offSitePhysicalZip' | 'taxCounty' | 'titled'>
    )>>> }
  )> }
);

export type DraftFilterValuesQueryVariables = Exact<{
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type DraftFilterValuesQuery = (
  { __typename?: 'Query' }
  & { draftFilterValues?: Maybe<(
    { __typename?: 'RDSFilters' }
    & Pick<RdsFilters, 'category' | 'make' | 'model' | 'upperMake' | 'upperModel'>
  )> }
);

export type SaveDraftItemsMutationVariables = Exact<{
  draftItem?: Maybe<RdsDraftItemInput>;
}>;


export type SaveDraftItemsMutation = (
  { __typename?: 'Mutation' }
  & { saveDraftItem?: Maybe<(
    { __typename?: 'RDSTest' }
    & Pick<RdsTest, 'test'>
  )> }
);

export type UpdateDraftItemMutationVariables = Exact<{
  draftItem?: Maybe<RdsDraftItemInput>;
}>;


export type UpdateDraftItemMutation = (
  { __typename?: 'Mutation' }
  & { updateDraftItem?: Maybe<(
    { __typename?: 'RDSTest' }
    & Pick<RdsTest, 'test'>
  )> }
);

export type DeleteDraftItemMutationVariables = Exact<{
  draftId?: Maybe<Scalars['Int']>;
}>;


export type DeleteDraftItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteDraftItem?: Maybe<(
    { __typename?: 'RDSTest' }
    & Pick<RdsTest, 'test'>
  )> }
);

export const SaleSummaryFragmentDoc = gql`
    fragment SaleSummary on Sale {
  id
  saleName
  date
  startTime
  endTime
  auctionStatus
  saleType
  city
  state
  sellerHasItems
  itemCount
}
    `;
export const PayoutFragmentDoc = gql`
    fragment Payout on Payout {
  id
  sellerNet
  salePriceSubtotal
  upsTrackingNumber
  accountId
  sale {
    payoutsFinalized
    city
    state
    date
    saleType
  }
}
    `;
export const CompanyInfoFragmentDoc = gql`
    fragment CompanyInfo on CompanyInfo {
  companyName
  companyId
  companyPhone
  companyCity
  companyCountry
  companyAddress
  companyState
  companyZip
}
    `;
export const SupportContactFragmentDoc = gql`
    fragment SupportContact on SupportContact {
  firstName
  lastName
  email
  phoneNumber
}
    `;
export const AccountInfoFragmentDoc = gql`
    fragment AccountInfo on AccountInfo {
  firstName
  lastName
  address
  city
  state
  zip
  county
  country
  phoneNumber
  phoneType
  email
  sellerCode
  companyInfo {
    ...CompanyInfo
  }
  supportContact {
    ...SupportContact
  }
}
    ${CompanyInfoFragmentDoc}
${SupportContactFragmentDoc}`;
export const SaveSiteMessagesDocument = gql`
    mutation SaveSiteMessages($accountID: Int, $adminMessage: RDSMessageInput) {
  saveSiteMessage(accountID: $accountID, adminMessage: $adminMessage) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveSiteMessagesGQL extends Apollo.Mutation<SaveSiteMessagesMutation, SaveSiteMessagesMutationVariables> {
    document = SaveSiteMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSiteMessagesDocument = gql`
    mutation UpdateSiteMessages($accountID: Int, $siteMessage: RDSMessageInput) {
  updateSiteMessage(accountID: $accountID, siteMessage: $siteMessage) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSiteMessagesGQL extends Apollo.Mutation<UpdateSiteMessagesMutation, UpdateSiteMessagesMutationVariables> {
    document = UpdateSiteMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SiteMessageDocument = gql`
    query SiteMessage($accountID: Int) {
  scanSiteMessages(accountID: $accountID) {
    id
    userId
    submitTimestamp
    title
    message
    status
    level
    sitePlacement
    endDate
    startDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SiteMessageGQL extends Apollo.Query<SiteMessageQuery, SiteMessageQueryVariables> {
    document = SiteMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSiteMessageDocument = gql`
    mutation DeleteSiteMessage($accountID: Int, $messageId: Int) {
  deleteSiteMessage(accountID: $accountID, messageId: $messageId) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSiteMessageGQL extends Apollo.Mutation<DeleteSiteMessageMutation, DeleteSiteMessageMutationVariables> {
    document = DeleteSiteMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const YtdSalesDocument = gql`
    query ytdSales($accountIds: [Int]!) {
  ytdSales(accountIds: $accountIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class YtdSalesGQL extends Apollo.Query<YtdSalesQuery, YtdSalesQueryVariables> {
    document = YtdSalesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUpcomingAuctionsDocument = gql`
    query getUpcomingAuctions($accountIds: [Int]!, $limit: Int) {
  upcomingAuctions(accountIds: $accountIds, limit: $limit) {
    ...SaleSummary
    sellerHasItems
  }
}
    ${SaleSummaryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUpcomingAuctionsGQL extends Apollo.Query<GetUpcomingAuctionsQuery, GetUpcomingAuctionsQueryVariables> {
    document = GetUpcomingAuctionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRecentAuctionsDocument = gql`
    query getRecentAuctions($buyerAccountIds: [Int], $sellerAccountIds: [Int], $limit: Int) {
  recentAuctions(
    buyerAccountIds: $buyerAccountIds
    sellerAccountIds: $sellerAccountIds
    limit: $limit
  ) {
    sale {
      ...SaleSummary
      itemCount
    }
    payout {
      id
      accountId
      payoutStatus
      saleId
      sellerNet
      totalLots
      sale {
        payoutsFinalized
      }
    }
    invoice {
      id
      accountId
      invoiceTotal
    }
  }
}
    ${SaleSummaryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRecentAuctionsGQL extends Apollo.Query<GetRecentAuctionsQuery, GetRecentAuctionsQueryVariables> {
    document = GetRecentAuctionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInventoryManagementDetailsDocument = gql`
    query getInventoryManagementDetails($accountIds: [Int]!) {
  inventoryManagementDetails(accountIds: $accountIds) {
    notOnSite
    conditionPending
    awaitingTitle
    auctionReady
    assignedItems
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInventoryManagementDetailsGQL extends Apollo.Query<GetInventoryManagementDetailsQuery, GetInventoryManagementDetailsQueryVariables> {
    document = GetInventoryManagementDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEstimatedPurchaseBalanceDocument = gql`
    query getEstimatedPurchaseBalance($accountIds: [Int]) {
  estimatedPurchaseBalance(accountIds: $accountIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEstimatedPurchaseBalanceGQL extends Apollo.Query<GetEstimatedPurchaseBalanceQuery, GetEstimatedPurchaseBalanceQueryVariables> {
    document = GetEstimatedPurchaseBalanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurrentRegisteredAuctionsDocument = gql`
    query getCurrentRegisteredAuctions($searchObject: InvoiceSearchRequest!) {
  currentRegisteredAuctions(searchObject: $searchObject)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrentRegisteredAuctionsGQL extends Apollo.Query<GetCurrentRegisteredAuctionsQuery, GetCurrentRegisteredAuctionsQueryVariables> {
    document = GetCurrentRegisteredAuctionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNotOnSiteCountDocument = gql`
    query getNotOnSiteCount($accountIds: [Int]!) {
  notOnSiteCount(accountIds: $accountIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNotOnSiteCountGQL extends Apollo.Query<GetNotOnSiteCountQuery, GetNotOnSiteCountQueryVariables> {
    document = GetNotOnSiteCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConditionPendingCountDocument = gql`
    query getConditionPendingCount($accountIds: [Int]!) {
  conditionPendingCount(accountIds: $accountIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConditionPendingCountGQL extends Apollo.Query<GetConditionPendingCountQuery, GetConditionPendingCountQueryVariables> {
    document = GetConditionPendingCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAwaitingTitleCountDocument = gql`
    query getAwaitingTitleCount($accountIds: [Int]!) {
  awaitingTitleCount(accountIds: $accountIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAwaitingTitleCountGQL extends Apollo.Query<GetAwaitingTitleCountQuery, GetAwaitingTitleCountQueryVariables> {
    document = GetAwaitingTitleCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAuctionReadyCountDocument = gql`
    query getAuctionReadyCount($accountIds: [Int]!) {
  auctionReadyCount(accountIds: $accountIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAuctionReadyCountGQL extends Apollo.Query<GetAuctionReadyCountQuery, GetAuctionReadyCountQueryVariables> {
    document = GetAuctionReadyCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAssignedItemsCountDocument = gql`
    query getAssignedItemsCount($accountIds: [Int]!) {
  assignedItemsCount(accountIds: $accountIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssignedItemsCountGQL extends Apollo.Query<GetAssignedItemsCountQuery, GetAssignedItemsCountQueryVariables> {
    document = GetAssignedItemsCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchInvoicesDocument = gql`
    query searchInvoices($searchObject: InvoiceSearchRequest!) {
  searchInvoices(searchObject: $searchObject) {
    number
    totalElements
    content {
      id
      accountId
      invoiceNumber
      invoiceTotal
      bidderNumber
      invoiceBalance
      invoiceTotal
      sale {
        date
        city
        state
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchInvoicesGQL extends Apollo.Query<SearchInvoicesQuery, SearchInvoicesQueryVariables> {
    document = SearchInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceSaleLocationsDocument = gql`
    query invoiceSaleLocations($accountIds: [Int]) {
  invoiceSaleLocations(accountIds: $accountIds) {
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceSaleLocationsGQL extends Apollo.Query<InvoiceSaleLocationsQuery, InvoiceSaleLocationsQueryVariables> {
    document = InvoiceSaleLocationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchItemsDocument = gql`
    query searchItems($searchObject: SearchItems!) {
  searchItems(searchObject: $searchObject) {
    number
    pageSize
    totalElements
    last
    first
    totalPages
    numberOfElements
    ... on ItemsSearchResponse {
      content {
        id
        amountOwed
        bidderBuyerInvoiceId
        buyerAccountId
        buyersTotal
        catalogDescription
        category
        engine
        fuel
        haveTitle
        height
        imageCount
        itemId
        itemStatus
        lotNumber
        make
        model
        mounted
        odometer
        offSitePhysicalCity
        offSitePhysicalState
        offSitePhysicalZip
        parentCategory
        sale {
          payoutsFinalized
        }
        saleDate
        saleId
        saleInfo
        saleName
        salePrice
        saleType
        sellerPayoutId
        titled
        transmission
        unitNumber
        upperMake
        upperModel
        upperOptionalEquipment
        upperSerialNumber
        vin
        year
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchItemsGQL extends Apollo.Query<SearchItemsQuery, SearchItemsQueryVariables> {
    document = SearchItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SellerFilterOptionsDocument = gql`
    query sellerFilterOptions($accountIds: [Int]) {
  sellerFilterOptions(accountIds: $accountIds) {
    saleInfo {
      name
      description
    }
    offSitePhysical {
      name
      description
    }
    category {
      name
      description
    }
    make {
      name
      description
    }
    model {
      name
      description
    }
    upperMake {
      name
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SellerFilterOptionsGQL extends Apollo.Query<SellerFilterOptionsQuery, SellerFilterOptionsQueryVariables> {
    document = SellerFilterOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BuyerFilterOptionsDocument = gql`
    query buyerFilterOptions($accountIds: [Int]) {
  buyerFilterOptions(accountIds: $accountIds) {
    saleInfo {
      name
      description
    }
    offSitePhysical {
      name
      description
    }
    category {
      name
      description
    }
    make {
      name
      description
    }
    model {
      name
      description
    }
    upperMake {
      name
      description
    }
    upperModel {
      name
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BuyerFilterOptionsGQL extends Apollo.Query<BuyerFilterOptionsQuery, BuyerFilterOptionsQueryVariables> {
    document = BuyerFilterOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ItemDetailsDocument = gql`
    query itemDetails($sellerAccountIds: [Int], $buyerAccountIds: [Int], $itemId: Int!) {
  itemDetails(
    sellerAccountIds: $sellerAccountIds
    buyerAccountIds: $buyerAccountIds
    itemId: $itemId
  ) {
    id
    unitNumber
    itemStatus
    salePrice
    lotNumber
    saleInfo
    saleType
    saleDate
    catalogDescription
    category
    vin
    year
    make
    model
    itemId
    engine
    fuel
    transmission
    odometer
    upperSerialNumber
    upperMake
    upperModel
    height
    mounted
    upperOptionalEquipment
    plateNumber
    conditions
    optionalEquipment
    capacity
    capacityType
    upperFuel
    hours
    offSite
    offSitePhysicalCity
    offSitePhysicalState
    offSitePhysicalZip
    commission
    expenseTransportation
    expenseWashing
    expenseDecommission
    adjustmentAmount
    expensePartsRepairs
    expenseMisc1
    expenseMisc2
    amountOwed
    imageUrls
    haveTitle
    titled
    inspectionDate
    titleReceivedDate
    createDate
    sellerPayoutId
    bidderBuyerInvoiceId
    bidderNumber
    buyerFee
    tax
    caWeightFee
    caLicenseFee
    titleFee
    fee1aActual
    fee1Actual
    fee2aActual
    fee2Actual
    fee3Actual
    fee4Actual
    fee5Actual
    fee6Actual
    fee7Actual
    fee8Actual
    fee9Actual
    sale {
      payoutsFinalized
      fee1ADescription
      fee1Description
      fee2Description
      fee3Description
      fee4Description
      fee5Description
      fee6Description
      fee7Description
      fee8Description
      fee9Description
    }
    saleId
    buyerAccountId
    arrivalDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ItemDetailsGQL extends Apollo.Query<ItemDetailsQuery, ItemDetailsQueryVariables> {
    document = ItemDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSellerItemsExportDataDocument = gql`
    query getSellerItemsExportData($searchObject: SearchItems!, $exportStartPage: Int!, $exportEndPage: Int!) {
  exportItems(
    searchObject: $searchObject
    exportStartPage: $exportStartPage
    exportEndPage: $exportEndPage
  ) {
    id
    arrivalDate
    conditions
    haveTitle
    itemStatus
    unitNumber
    category
    vin
    year
    make
    model
    lotNumber
    saleInfo
    saleType
    saleDate
    offSitePhysicalCity
    offSitePhysicalState
    itemStatus
    catalogDescription
    plateNumber
    engine
    fuel
    transmission
    titled
    odometer
    hours
    conditions
    optionalEquipment
    upperSerialNumber
    upperMake
    upperModel
    upperFuel
    height
    capacity
    mounted
    upperOptionalEquipment
    amountOwed
    salePrice
    sale {
      payoutsFinalized
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSellerItemsExportDataGQL extends Apollo.Query<GetSellerItemsExportDataQuery, GetSellerItemsExportDataQueryVariables> {
    document = GetSellerItemsExportDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBuyerItemsExportDataDocument = gql`
    query getBuyerItemsExportData($searchObject: SearchItems!, $exportStartPage: Int!, $exportEndPage: Int!) {
  exportItems(
    searchObject: $searchObject
    exportStartPage: $exportStartPage
    exportEndPage: $exportEndPage
  ) {
    id
    lotNumber
    saleName
    saleDate
    parentCategory
    category
    catalogDescription
    vin
    upperSerialNumber
    odometer
    hours
    titled
    buyerPickupDate
    offSitePhysicalCity
    offSitePhysicalState
    offSitePhysicalZip
    salePrice
    buyersTotal
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBuyerItemsExportDataGQL extends Apollo.Query<GetBuyerItemsExportDataQuery, GetBuyerItemsExportDataQueryVariables> {
    document = GetBuyerItemsExportDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($forgotPasswordObject: ForgotPassword) {
  forgotPassword(forgotPasswordObject: $forgotPasswordObject) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    client = 'noAuthClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchPayoutsDocument = gql`
    query searchPayouts($searchRequest: PayoutSearchRequest!) {
  searchPayouts(searchRequest: $searchRequest) {
    numberOfElements
    totalElements
    content {
      ...Payout
    }
  }
}
    ${PayoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchPayoutsGQL extends Apollo.Query<SearchPayoutsQuery, SearchPayoutsQueryVariables> {
    document = SearchPayoutsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPayoutDetailsDocument = gql`
    query getPayoutDetails($accountIds: [Int]!, $payoutId: Int!) {
  payoutDetails(accountIds: $accountIds, payoutId: $payoutId) {
    numberOfElements
    totalElements
    content {
      ...Payout
    }
  }
}
    ${PayoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPayoutDetailsGQL extends Apollo.Query<GetPayoutDetailsQuery, GetPayoutDetailsQueryVariables> {
    document = GetPayoutDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPayoutFiltersDocument = gql`
    query getPayoutFilters($accountIds: [Int]!) {
  payoutFilters(accountIds: $accountIds) {
    sale {
      name
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPayoutFiltersGQL extends Apollo.Query<GetPayoutFiltersQuery, GetPayoutFiltersQueryVariables> {
    document = GetPayoutFiltersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessCodesDocument = gql`
    query getBusinessCodes {
  businessCodes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessCodesGQL extends Apollo.Query<GetBusinessCodesQuery, GetBusinessCodesQueryVariables> {
    document = GetBusinessCodesDocument;
    client = 'noAuthClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeocodeDocument = gql`
    query geocode($zip: String!) {
  geocode(zip: $zip) {
    success
    objects {
      city
      county
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GeocodeGQL extends Apollo.Query<GeocodeQuery, GeocodeQueryVariables> {
    document = GeocodeDocument;
    client = 'noAuthClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterDocument = gql`
    mutation register($registrationObject: Registration!) {
  register(registrationObject: $registrationObject) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterGQL extends Apollo.Mutation<RegisterMutation, RegisterMutationVariables> {
    document = RegisterDocument;
    client = 'noAuthClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivateUserGQL extends Apollo.Mutation<ActivateUserMutation, ActivateUserMutationVariables> {
    document = ActivateUserDocument;
    client = 'noAuthClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountriesDocument = gql`
    query getCountries {
  countries {
    code
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountriesGQL extends Apollo.Query<GetCountriesQuery, GetCountriesQueryVariables> {
    document = GetCountriesDocument;
    client = 'noAuthClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactInfoDocument = gql`
    query getContactInfo($type: String) {
  profiles(type: $type) {
    parentAccountId
    defaultContact
    accountInfo {
      firstName
      lastName
      address
      city
      state
      zip
      county
      country
      phoneNumber
      phoneType
      email
      sellerCode
      companyInfo {
        companyName
        companyId
        companyPhone
        companyCity
        companyCountry
        companyAddress
        companyState
        companyZip
      }
      supportContact {
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactInfoGQL extends Apollo.Query<GetContactInfoQuery, GetContactInfoQueryVariables> {
    document = GetContactInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendContactUsMessageMutationDocument = gql`
    mutation sendContactUsMessageMutation($messageObject: ContactUsMessage) {
  sendContactUsMessage(messageObject: $messageObject) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendContactUsMessageMutationGQL extends Apollo.Mutation<SendContactUsMessageMutationMutation, SendContactUsMessageMutationMutationVariables> {
    document = SendContactUsMessageMutationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDropdownValuesDocument = gql`
    query getDropdownValues {
  categories {
    name
  }
  subcategories {
    id
    name
    parentCategoryName
    combination
  }
  makeAndModel {
    make
    models
  }
  engines {
    id
    name
  }
  fuel {
    id
    name
  }
  transmissions {
    id
    name
  }
  upperMakeAndModel {
    make
    models
  }
  upperFuel {
    id
    name
  }
  capacity {
    id
    name
    description
    type
  }
  mounted {
    id
    name
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDropdownValuesGQL extends Apollo.Query<GetDropdownValuesQuery, GetDropdownValuesQueryVariables> {
    document = GetDropdownValuesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveItemDocument = gql`
    mutation saveItem($accountId: Int!, $contactId: Int!, $auctionItem: AuctionItemInput!) {
  saveAuctionItem(
    accountId: $accountId
    contactId: $contactId
    auctionItem: $auctionItem
  ) {
    id
    saleId
    make
    model
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveItemGQL extends Apollo.Mutation<SaveItemMutation, SaveItemMutationVariables> {
    document = SaveItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DraftItemsDocument = gql`
    query DraftItems($accountIds: [Int], $searchOptions: SearchOptions) {
  queryDraftItems(accountIds: $accountIds, searchOptions: $searchOptions) {
    records {
      id
      userId
      currentStep
      itemType
      submitTimestamp
      parentCategory
      category
      vin
      year
      make
      model
      sellerFleetNumber2
      plateNumber
      engine
      fuel
      transmission
      odometer
      hours
      optionalEquipment
      airConditioning
      unitNumber
      upperSerialNumber
      upperMake
      upperModel
      upperFuel
      height
      capacity
      capacityUnit
      capacityType
      mounted
      upperOptionalEquipment
      itemNotes
      offSitePhysicalAddress
      offSitePhysicalCity
      offSitePhysicalState
      offSitePhysicalZip
      taxCounty
      titled
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DraftItemsGQL extends Apollo.Query<DraftItemsQuery, DraftItemsQueryVariables> {
    document = DraftItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DraftFilterValuesDocument = gql`
    query draftFilterValues($accountIds: [Int]) {
  draftFilterValues(accountIds: $accountIds) {
    category
    make
    model
    upperMake
    upperModel
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DraftFilterValuesGQL extends Apollo.Query<DraftFilterValuesQuery, DraftFilterValuesQueryVariables> {
    document = DraftFilterValuesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveDraftItemsDocument = gql`
    mutation SaveDraftItems($draftItem: RDSDraftItemInput) {
  saveDraftItem(draftItem: $draftItem) {
    test
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveDraftItemsGQL extends Apollo.Mutation<SaveDraftItemsMutation, SaveDraftItemsMutationVariables> {
    document = SaveDraftItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDraftItemDocument = gql`
    mutation updateDraftItem($draftItem: RDSDraftItemInput) {
  updateDraftItem(draftItem: $draftItem) {
    test
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDraftItemGQL extends Apollo.Mutation<UpdateDraftItemMutation, UpdateDraftItemMutationVariables> {
    document = UpdateDraftItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDraftItemDocument = gql`
    mutation DeleteDraftItem($draftId: Int) {
  deleteDraftItem(draftId: $draftId) {
    test
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDraftItemGQL extends Apollo.Mutation<DeleteDraftItemMutation, DeleteDraftItemMutationVariables> {
    document = DeleteDraftItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

  interface WatchQueryOptionsAlone<V>
    extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}
    
  interface QueryOptionsAlone<V>
    extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}
    
  interface MutationOptionsAlone<T, V>
    extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}
    
  interface SubscriptionOptionsAlone<V>
    extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

  @Injectable({ providedIn: 'root' })
  export class JjkIntegration {
    constructor(
      private saveSiteMessagesGql: SaveSiteMessagesGQL,
      private updateSiteMessagesGql: UpdateSiteMessagesGQL,
      private siteMessageGql: SiteMessageGQL,
      private deleteSiteMessageGql: DeleteSiteMessageGQL,
      private ytdSalesGql: YtdSalesGQL,
      private getUpcomingAuctionsGql: GetUpcomingAuctionsGQL,
      private getRecentAuctionsGql: GetRecentAuctionsGQL,
      private getInventoryManagementDetailsGql: GetInventoryManagementDetailsGQL,
      private getEstimatedPurchaseBalanceGql: GetEstimatedPurchaseBalanceGQL,
      private getCurrentRegisteredAuctionsGql: GetCurrentRegisteredAuctionsGQL,
      private getNotOnSiteCountGql: GetNotOnSiteCountGQL,
      private getConditionPendingCountGql: GetConditionPendingCountGQL,
      private getAwaitingTitleCountGql: GetAwaitingTitleCountGQL,
      private getAuctionReadyCountGql: GetAuctionReadyCountGQL,
      private getAssignedItemsCountGql: GetAssignedItemsCountGQL,
      private searchInvoicesGql: SearchInvoicesGQL,
      private invoiceSaleLocationsGql: InvoiceSaleLocationsGQL,
      private searchItemsGql: SearchItemsGQL,
      private sellerFilterOptionsGql: SellerFilterOptionsGQL,
      private buyerFilterOptionsGql: BuyerFilterOptionsGQL,
      private itemDetailsGql: ItemDetailsGQL,
      private getSellerItemsExportDataGql: GetSellerItemsExportDataGQL,
      private getBuyerItemsExportDataGql: GetBuyerItemsExportDataGQL,
      private forgotPasswordGql: ForgotPasswordGQL,
      private searchPayoutsGql: SearchPayoutsGQL,
      private getPayoutDetailsGql: GetPayoutDetailsGQL,
      private getPayoutFiltersGql: GetPayoutFiltersGQL,
      private getBusinessCodesGql: GetBusinessCodesGQL,
      private geocodeGql: GeocodeGQL,
      private registerGql: RegisterGQL,
      private activateUserGql: ActivateUserGQL,
      private getCountriesGql: GetCountriesGQL,
      private getContactInfoGql: GetContactInfoGQL,
      private sendContactUsMessageMutationGql: SendContactUsMessageMutationGQL,
      private getDropdownValuesGql: GetDropdownValuesGQL,
      private saveItemGql: SaveItemGQL,
      private draftItemsGql: DraftItemsGQL,
      private draftFilterValuesGql: DraftFilterValuesGQL,
      private saveDraftItemsGql: SaveDraftItemsGQL,
      private updateDraftItemGql: UpdateDraftItemGQL,
      private deleteDraftItemGql: DeleteDraftItemGQL
    ) {}
      
    saveSiteMessages(variables?: SaveSiteMessagesMutationVariables, options?: MutationOptionsAlone<SaveSiteMessagesMutation, SaveSiteMessagesMutationVariables>) {
      return this.saveSiteMessagesGql.mutate(variables, options)
    }
    
    updateSiteMessages(variables?: UpdateSiteMessagesMutationVariables, options?: MutationOptionsAlone<UpdateSiteMessagesMutation, UpdateSiteMessagesMutationVariables>) {
      return this.updateSiteMessagesGql.mutate(variables, options)
    }
    
    siteMessage(variables?: SiteMessageQueryVariables, options?: QueryOptionsAlone<SiteMessageQueryVariables>) {
      return this.siteMessageGql.fetch(variables, options)
    }
    
    siteMessageWatch(variables?: SiteMessageQueryVariables, options?: WatchQueryOptionsAlone<SiteMessageQueryVariables>) {
      return this.siteMessageGql.watch(variables, options)
    }
    
    deleteSiteMessage(variables?: DeleteSiteMessageMutationVariables, options?: MutationOptionsAlone<DeleteSiteMessageMutation, DeleteSiteMessageMutationVariables>) {
      return this.deleteSiteMessageGql.mutate(variables, options)
    }
    
    ytdSales(variables: YtdSalesQueryVariables, options?: QueryOptionsAlone<YtdSalesQueryVariables>) {
      return this.ytdSalesGql.fetch(variables, options)
    }
    
    ytdSalesWatch(variables: YtdSalesQueryVariables, options?: WatchQueryOptionsAlone<YtdSalesQueryVariables>) {
      return this.ytdSalesGql.watch(variables, options)
    }
    
    getUpcomingAuctions(variables: GetUpcomingAuctionsQueryVariables, options?: QueryOptionsAlone<GetUpcomingAuctionsQueryVariables>) {
      return this.getUpcomingAuctionsGql.fetch(variables, options)
    }
    
    getUpcomingAuctionsWatch(variables: GetUpcomingAuctionsQueryVariables, options?: WatchQueryOptionsAlone<GetUpcomingAuctionsQueryVariables>) {
      return this.getUpcomingAuctionsGql.watch(variables, options)
    }
    
    getRecentAuctions(variables?: GetRecentAuctionsQueryVariables, options?: QueryOptionsAlone<GetRecentAuctionsQueryVariables>) {
      return this.getRecentAuctionsGql.fetch(variables, options)
    }
    
    getRecentAuctionsWatch(variables?: GetRecentAuctionsQueryVariables, options?: WatchQueryOptionsAlone<GetRecentAuctionsQueryVariables>) {
      return this.getRecentAuctionsGql.watch(variables, options)
    }
    
    getInventoryManagementDetails(variables: GetInventoryManagementDetailsQueryVariables, options?: QueryOptionsAlone<GetInventoryManagementDetailsQueryVariables>) {
      return this.getInventoryManagementDetailsGql.fetch(variables, options)
    }
    
    getInventoryManagementDetailsWatch(variables: GetInventoryManagementDetailsQueryVariables, options?: WatchQueryOptionsAlone<GetInventoryManagementDetailsQueryVariables>) {
      return this.getInventoryManagementDetailsGql.watch(variables, options)
    }
    
    getEstimatedPurchaseBalance(variables?: GetEstimatedPurchaseBalanceQueryVariables, options?: QueryOptionsAlone<GetEstimatedPurchaseBalanceQueryVariables>) {
      return this.getEstimatedPurchaseBalanceGql.fetch(variables, options)
    }
    
    getEstimatedPurchaseBalanceWatch(variables?: GetEstimatedPurchaseBalanceQueryVariables, options?: WatchQueryOptionsAlone<GetEstimatedPurchaseBalanceQueryVariables>) {
      return this.getEstimatedPurchaseBalanceGql.watch(variables, options)
    }
    
    getCurrentRegisteredAuctions(variables: GetCurrentRegisteredAuctionsQueryVariables, options?: QueryOptionsAlone<GetCurrentRegisteredAuctionsQueryVariables>) {
      return this.getCurrentRegisteredAuctionsGql.fetch(variables, options)
    }
    
    getCurrentRegisteredAuctionsWatch(variables: GetCurrentRegisteredAuctionsQueryVariables, options?: WatchQueryOptionsAlone<GetCurrentRegisteredAuctionsQueryVariables>) {
      return this.getCurrentRegisteredAuctionsGql.watch(variables, options)
    }
    
    getNotOnSiteCount(variables: GetNotOnSiteCountQueryVariables, options?: QueryOptionsAlone<GetNotOnSiteCountQueryVariables>) {
      return this.getNotOnSiteCountGql.fetch(variables, options)
    }
    
    getNotOnSiteCountWatch(variables: GetNotOnSiteCountQueryVariables, options?: WatchQueryOptionsAlone<GetNotOnSiteCountQueryVariables>) {
      return this.getNotOnSiteCountGql.watch(variables, options)
    }
    
    getConditionPendingCount(variables: GetConditionPendingCountQueryVariables, options?: QueryOptionsAlone<GetConditionPendingCountQueryVariables>) {
      return this.getConditionPendingCountGql.fetch(variables, options)
    }
    
    getConditionPendingCountWatch(variables: GetConditionPendingCountQueryVariables, options?: WatchQueryOptionsAlone<GetConditionPendingCountQueryVariables>) {
      return this.getConditionPendingCountGql.watch(variables, options)
    }
    
    getAwaitingTitleCount(variables: GetAwaitingTitleCountQueryVariables, options?: QueryOptionsAlone<GetAwaitingTitleCountQueryVariables>) {
      return this.getAwaitingTitleCountGql.fetch(variables, options)
    }
    
    getAwaitingTitleCountWatch(variables: GetAwaitingTitleCountQueryVariables, options?: WatchQueryOptionsAlone<GetAwaitingTitleCountQueryVariables>) {
      return this.getAwaitingTitleCountGql.watch(variables, options)
    }
    
    getAuctionReadyCount(variables: GetAuctionReadyCountQueryVariables, options?: QueryOptionsAlone<GetAuctionReadyCountQueryVariables>) {
      return this.getAuctionReadyCountGql.fetch(variables, options)
    }
    
    getAuctionReadyCountWatch(variables: GetAuctionReadyCountQueryVariables, options?: WatchQueryOptionsAlone<GetAuctionReadyCountQueryVariables>) {
      return this.getAuctionReadyCountGql.watch(variables, options)
    }
    
    getAssignedItemsCount(variables: GetAssignedItemsCountQueryVariables, options?: QueryOptionsAlone<GetAssignedItemsCountQueryVariables>) {
      return this.getAssignedItemsCountGql.fetch(variables, options)
    }
    
    getAssignedItemsCountWatch(variables: GetAssignedItemsCountQueryVariables, options?: WatchQueryOptionsAlone<GetAssignedItemsCountQueryVariables>) {
      return this.getAssignedItemsCountGql.watch(variables, options)
    }
    
    searchInvoices(variables: SearchInvoicesQueryVariables, options?: QueryOptionsAlone<SearchInvoicesQueryVariables>) {
      return this.searchInvoicesGql.fetch(variables, options)
    }
    
    searchInvoicesWatch(variables: SearchInvoicesQueryVariables, options?: WatchQueryOptionsAlone<SearchInvoicesQueryVariables>) {
      return this.searchInvoicesGql.watch(variables, options)
    }
    
    invoiceSaleLocations(variables?: InvoiceSaleLocationsQueryVariables, options?: QueryOptionsAlone<InvoiceSaleLocationsQueryVariables>) {
      return this.invoiceSaleLocationsGql.fetch(variables, options)
    }
    
    invoiceSaleLocationsWatch(variables?: InvoiceSaleLocationsQueryVariables, options?: WatchQueryOptionsAlone<InvoiceSaleLocationsQueryVariables>) {
      return this.invoiceSaleLocationsGql.watch(variables, options)
    }
    
    searchItems(variables: SearchItemsQueryVariables, options?: QueryOptionsAlone<SearchItemsQueryVariables>) {
      return this.searchItemsGql.fetch(variables, options)
    }
    
    searchItemsWatch(variables: SearchItemsQueryVariables, options?: WatchQueryOptionsAlone<SearchItemsQueryVariables>) {
      return this.searchItemsGql.watch(variables, options)
    }
    
    sellerFilterOptions(variables?: SellerFilterOptionsQueryVariables, options?: QueryOptionsAlone<SellerFilterOptionsQueryVariables>) {
      return this.sellerFilterOptionsGql.fetch(variables, options)
    }
    
    sellerFilterOptionsWatch(variables?: SellerFilterOptionsQueryVariables, options?: WatchQueryOptionsAlone<SellerFilterOptionsQueryVariables>) {
      return this.sellerFilterOptionsGql.watch(variables, options)
    }
    
    buyerFilterOptions(variables?: BuyerFilterOptionsQueryVariables, options?: QueryOptionsAlone<BuyerFilterOptionsQueryVariables>) {
      return this.buyerFilterOptionsGql.fetch(variables, options)
    }
    
    buyerFilterOptionsWatch(variables?: BuyerFilterOptionsQueryVariables, options?: WatchQueryOptionsAlone<BuyerFilterOptionsQueryVariables>) {
      return this.buyerFilterOptionsGql.watch(variables, options)
    }
    
    itemDetails(variables: ItemDetailsQueryVariables, options?: QueryOptionsAlone<ItemDetailsQueryVariables>) {
      return this.itemDetailsGql.fetch(variables, options)
    }
    
    itemDetailsWatch(variables: ItemDetailsQueryVariables, options?: WatchQueryOptionsAlone<ItemDetailsQueryVariables>) {
      return this.itemDetailsGql.watch(variables, options)
    }
    
    getSellerItemsExportData(variables: GetSellerItemsExportDataQueryVariables, options?: QueryOptionsAlone<GetSellerItemsExportDataQueryVariables>) {
      return this.getSellerItemsExportDataGql.fetch(variables, options)
    }
    
    getSellerItemsExportDataWatch(variables: GetSellerItemsExportDataQueryVariables, options?: WatchQueryOptionsAlone<GetSellerItemsExportDataQueryVariables>) {
      return this.getSellerItemsExportDataGql.watch(variables, options)
    }
    
    getBuyerItemsExportData(variables: GetBuyerItemsExportDataQueryVariables, options?: QueryOptionsAlone<GetBuyerItemsExportDataQueryVariables>) {
      return this.getBuyerItemsExportDataGql.fetch(variables, options)
    }
    
    getBuyerItemsExportDataWatch(variables: GetBuyerItemsExportDataQueryVariables, options?: WatchQueryOptionsAlone<GetBuyerItemsExportDataQueryVariables>) {
      return this.getBuyerItemsExportDataGql.watch(variables, options)
    }
    
    forgotPassword(variables?: ForgotPasswordMutationVariables, options?: MutationOptionsAlone<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
      return this.forgotPasswordGql.mutate(variables, options)
    }
    
    searchPayouts(variables: SearchPayoutsQueryVariables, options?: QueryOptionsAlone<SearchPayoutsQueryVariables>) {
      return this.searchPayoutsGql.fetch(variables, options)
    }
    
    searchPayoutsWatch(variables: SearchPayoutsQueryVariables, options?: WatchQueryOptionsAlone<SearchPayoutsQueryVariables>) {
      return this.searchPayoutsGql.watch(variables, options)
    }
    
    getPayoutDetails(variables: GetPayoutDetailsQueryVariables, options?: QueryOptionsAlone<GetPayoutDetailsQueryVariables>) {
      return this.getPayoutDetailsGql.fetch(variables, options)
    }
    
    getPayoutDetailsWatch(variables: GetPayoutDetailsQueryVariables, options?: WatchQueryOptionsAlone<GetPayoutDetailsQueryVariables>) {
      return this.getPayoutDetailsGql.watch(variables, options)
    }
    
    getPayoutFilters(variables: GetPayoutFiltersQueryVariables, options?: QueryOptionsAlone<GetPayoutFiltersQueryVariables>) {
      return this.getPayoutFiltersGql.fetch(variables, options)
    }
    
    getPayoutFiltersWatch(variables: GetPayoutFiltersQueryVariables, options?: WatchQueryOptionsAlone<GetPayoutFiltersQueryVariables>) {
      return this.getPayoutFiltersGql.watch(variables, options)
    }
    
    getBusinessCodes(variables?: GetBusinessCodesQueryVariables, options?: QueryOptionsAlone<GetBusinessCodesQueryVariables>) {
      return this.getBusinessCodesGql.fetch(variables, options)
    }
    
    getBusinessCodesWatch(variables?: GetBusinessCodesQueryVariables, options?: WatchQueryOptionsAlone<GetBusinessCodesQueryVariables>) {
      return this.getBusinessCodesGql.watch(variables, options)
    }
    
    geocode(variables: GeocodeQueryVariables, options?: QueryOptionsAlone<GeocodeQueryVariables>) {
      return this.geocodeGql.fetch(variables, options)
    }
    
    geocodeWatch(variables: GeocodeQueryVariables, options?: WatchQueryOptionsAlone<GeocodeQueryVariables>) {
      return this.geocodeGql.watch(variables, options)
    }
    
    register(variables: RegisterMutationVariables, options?: MutationOptionsAlone<RegisterMutation, RegisterMutationVariables>) {
      return this.registerGql.mutate(variables, options)
    }
    
    activateUser(variables: ActivateUserMutationVariables, options?: MutationOptionsAlone<ActivateUserMutation, ActivateUserMutationVariables>) {
      return this.activateUserGql.mutate(variables, options)
    }
    
    getCountries(variables?: GetCountriesQueryVariables, options?: QueryOptionsAlone<GetCountriesQueryVariables>) {
      return this.getCountriesGql.fetch(variables, options)
    }
    
    getCountriesWatch(variables?: GetCountriesQueryVariables, options?: WatchQueryOptionsAlone<GetCountriesQueryVariables>) {
      return this.getCountriesGql.watch(variables, options)
    }
    
    getContactInfo(variables?: GetContactInfoQueryVariables, options?: QueryOptionsAlone<GetContactInfoQueryVariables>) {
      return this.getContactInfoGql.fetch(variables, options)
    }
    
    getContactInfoWatch(variables?: GetContactInfoQueryVariables, options?: WatchQueryOptionsAlone<GetContactInfoQueryVariables>) {
      return this.getContactInfoGql.watch(variables, options)
    }
    
    sendContactUsMessageMutation(variables?: SendContactUsMessageMutationMutationVariables, options?: MutationOptionsAlone<SendContactUsMessageMutationMutation, SendContactUsMessageMutationMutationVariables>) {
      return this.sendContactUsMessageMutationGql.mutate(variables, options)
    }
    
    getDropdownValues(variables?: GetDropdownValuesQueryVariables, options?: QueryOptionsAlone<GetDropdownValuesQueryVariables>) {
      return this.getDropdownValuesGql.fetch(variables, options)
    }
    
    getDropdownValuesWatch(variables?: GetDropdownValuesQueryVariables, options?: WatchQueryOptionsAlone<GetDropdownValuesQueryVariables>) {
      return this.getDropdownValuesGql.watch(variables, options)
    }
    
    saveItem(variables: SaveItemMutationVariables, options?: MutationOptionsAlone<SaveItemMutation, SaveItemMutationVariables>) {
      return this.saveItemGql.mutate(variables, options)
    }
    
    draftItems(variables?: DraftItemsQueryVariables, options?: QueryOptionsAlone<DraftItemsQueryVariables>) {
      return this.draftItemsGql.fetch(variables, options)
    }
    
    draftItemsWatch(variables?: DraftItemsQueryVariables, options?: WatchQueryOptionsAlone<DraftItemsQueryVariables>) {
      return this.draftItemsGql.watch(variables, options)
    }
    
    draftFilterValues(variables?: DraftFilterValuesQueryVariables, options?: QueryOptionsAlone<DraftFilterValuesQueryVariables>) {
      return this.draftFilterValuesGql.fetch(variables, options)
    }
    
    draftFilterValuesWatch(variables?: DraftFilterValuesQueryVariables, options?: WatchQueryOptionsAlone<DraftFilterValuesQueryVariables>) {
      return this.draftFilterValuesGql.watch(variables, options)
    }
    
    saveDraftItems(variables?: SaveDraftItemsMutationVariables, options?: MutationOptionsAlone<SaveDraftItemsMutation, SaveDraftItemsMutationVariables>) {
      return this.saveDraftItemsGql.mutate(variables, options)
    }
    
    updateDraftItem(variables?: UpdateDraftItemMutationVariables, options?: MutationOptionsAlone<UpdateDraftItemMutation, UpdateDraftItemMutationVariables>) {
      return this.updateDraftItemGql.mutate(variables, options)
    }
    
    deleteDraftItem(variables?: DeleteDraftItemMutationVariables, options?: MutationOptionsAlone<DeleteDraftItemMutation, DeleteDraftItemMutationVariables>) {
      return this.deleteDraftItemGql.mutate(variables, options)
    }
  }
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthResponseData, AuthUserService} from '../../services/auth-user.service';
import {Router} from '@angular/router';
import {GetFaIcon} from '../../functions/icon-helpers';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {CompanySelectionService, UserAggregateCompany, UserCompany} from '../../services/company-selection.service';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../services/google-analytic.service';

@Component({
  selector: 'top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  public faBars = GetFaIcon('bars', 'fas');
  public faUser = GetFaIcon('user', 'fas');
  public faBuilding = GetFaIcon('building', 'fas');
  public mobileProfileCollapsed = true;
  public mobileCompanyCollapsed = true;

  public $user: BehaviorSubject<AuthResponseData>;

  @Input()
  isMobile: boolean;

  @Input()
  liteVersion: boolean;

  @Output()
  expandClicked: EventEmitter<null> = new EventEmitter();

  public companies: Array<(UserCompany | UserAggregateCompany)>;
  public selectedCompany: (UserCompany | UserAggregateCompany);

  constructor(private userAuth: AuthUserService, public companyService: CompanySelectionService, private googleAnalyticService: GoogleAnalyticService, private auth0Service: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.$user = this.userAuth.$currentUser;
    this.companyService.$userCompanies.subscribe(companies => {
      this.companies = companies;
    });
    this.companyService.$selectedCompany.subscribe(company => {
        this.selectedCompany = company;
      });
  }

  public swapCompany(company: UserCompany): void {
    this.companyService.selectCompany(company.name);

    const event: GoogleAnalyticEventObject = {
      category: 'Company Selector',
      action: 'Company Selected',
      label: 'Site'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {});
    window.location.reload();
  }

  public profileAction(action: string): void {
    if (action === 'logout') {
      this.auth0Service.logout({ returnTo: window.location.origin });
    } else if (action === 'viewProfile') {
      this.router.navigate(['/dashboard/account']);
    }
  }

  public toggleExpand(): void {
    this.expandClicked.emit();
  }

}

<div class="container-fluid">
  <div class="mt-3 mb-3">
    <p>GraphQL is configured to be at <a target="_blank" [href]="graphQlUrl">{{graphQlUrl}}</a>.</p>
    <p>Copy the key from textbox and paste into the http headers within graphql playground to test authenticated queries</p>

    <div>
      <textarea rows="10" cols="60">{{httpHeaders}}</textarea>
    </div>

    <br />
    <br />
    <h3>Auth0 Decoded Tokens</h3>
    <p>These are not needed to paste into Graphql but are useful for debugging Auth0</p>
    <h5>ID Token</h5>
    <div>
      <textarea rows="10" cols="60">{{idToken}}</textarea>
    </div>

    <h5>Access Token</h5>
    <div>
      <textarea rows="10" cols="60">{{accessToken}}</textarea>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorModalComponent} from '../components/error-modal/error-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  public handleError(error?: HttpErrorResponse): void {
    if (this.modalRef) {
      // prevent opening multiple error modals at the same time
      this.modalRef.dismiss();
    }
    this.modalRef = this.modalService.open(ErrorModalComponent, {backdrop: 'static', keyboard: false});
  }
}

<div class="container-fluid">
  <div class="row mt-4 mb-3">
    <div class="col-12">
      <div class="wizard-progress">
        <div class="progress">
          <div [style.width]="progressWidth" class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
      </div>
      <div class="wizard-nav">
        <div class="wizard-steps row">
          <div *ngFor="let step of navigationSteps; let index = index" class="col-2 wizard-step">
            <a class="wizard-step-number" (click)="setStep(index + 1)">
              <span [ngClass]="{'colored': selectedStep >= index + 1}">
                <fa-icon [icon]="step.icon"></fa-icon>
              </span>
            </a>
            <div class="wizard-step-info">
              <div class="wizard-step-title mt-2 d-none d-md-block">
                {{index + 1}}. {{step.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <ng-content></ng-content>
    <div class="m-3">
      <div class="col-12 btn-section">
        <div *ngIf="!parentForm.valid && submitted && selectedStep === stepCount" class="error-text">
          Submission Form is invalid. Please check your inputs.
        </div>
        <button class="btn text-light bg-altec float-right" *ngIf="selectedStep < stepCount" (click)="setStep(selectedStep + 1)">
          Continue
        </button>
        <button class="btn text-light bg-altec float-right" *ngIf="selectedStep === stepCount" (click)="submit()">
          Submit
        </button>
        <button class="btn btn-outline-dark float-right mr-3" (click)="saveAndExit()" [GA]="{event: {category: 'Save and Exit', action: 'Click', label: 'Submit Item'}}">
          Save and Exit
        </button>
        <button class="btn btn-outline-dark" [routerLink]="['/dashboard']">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>



import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationStepData} from '../../../Submit Items/models/navigation-step-data';
import {FormGroup} from '@angular/forms';
import { RegistrationService } from '../../services/registration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../../../Shared/components/error-modal/error-modal.component';
import { PersonalInfo, Registration, BusinessInfo } from '../../../generated/graphql';

@Component({
  selector: 'app-reg-base-step-wizard',
  templateUrl: './registration-base-step-wizard.component.html',
  styleUrls: ['./registration-base-step-wizard.component.scss']
})
export class RegistrationBaseStepWizardComponent implements OnInit {

  @Input()
  public navigationSteps: NavigationStepData[] = [];

  @Input()
  public parentForm: FormGroup;

  @Input()
  public personal: boolean;

  @Output()
  public selectStep: EventEmitter<number> = new EventEmitter<number>();

  public progressWidth = '0%';
  public selectedStep = 1;
  public stepCount;
  private singleSegmentWidth;
  public finished: boolean = false;
  public submitted: boolean = false;

  constructor(private registrationService: RegistrationService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.stepCount = this.navigationSteps.length;
    const totalSegments = this.stepCount - 1;
    this.singleSegmentWidth = 100 / totalSegments;
  }

  public setStep(step: number): void {
    this.selectedStep = step;
    this.selectStep.emit(this.selectedStep);

    const segmentsToShow = this.selectedStep - 1;
    this.progressWidth = (segmentsToShow * this.singleSegmentWidth) + '%';
  }

  private convertFormForSubmission(): Registration {
    this.submitted = false;

    let registration: Registration = {
      individual: false,
      personalInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        personalPhoneNumber: "",
        personalPhoneType: "",
        personalAddressLine1: "",
        personalAddressLine2: "",
        personalCity: "",
        personalState: "",
        personalZip: "",
        personalCounty: "",
        personalCountry: ""
      },
      businessInfo: {
        businessName: "",
        businessType: "",
        businessPhoneNumber: "",
        businessPhoneType: "",
        businessAddressLine1: "",
        businessAddressLine2: "",
        businessCity: "",
        businessState: "",
        businessZip: "",
        businessCounty: "",
        businessCountry: ""
      },
      password: "",
      recaptcha: ""
    };
    let person: PersonalInfo;
    let business: BusinessInfo;
    let password;

    if(!this.parentForm.valid) {
      this.parentForm.markAllAsTouched();
      this.submitted = true;
      return null;
    }

    if (this.parentForm.controls.personalGroup) {
      person = {...this.parentForm.controls.personalGroup.value};
    }

    if (this.parentForm.controls.businessGroup) {
      business = {...this.parentForm.controls.businessGroup.value};
    }

    if (this.parentForm.controls.passwordGroup) {
      password = {...this.parentForm.controls.passwordGroup.value};
    }

    registration.personalInfo.firstName = person.firstName;
    registration.personalInfo.middleName = person.middleName;
    registration.personalInfo.lastName = person.lastName;
    registration.personalInfo.email = person.email;
    registration.personalInfo.personalPhoneNumber = person.personalPhoneNumber;
    registration.personalInfo.personalPhoneType = person.personalPhoneType;
    registration.personalInfo.personalAddressLine1 = person.personalAddressLine1;
    registration.personalInfo.personalAddressLine2 = person.personalAddressLine2;
    registration.personalInfo.personalCity = person.personalCity;
    registration.personalInfo.personalState = person.personalState;
    registration.personalInfo.personalZip = person.personalZip;
    registration.personalInfo.personalCounty = person.personalCounty;
    registration.personalInfo.personalCountry = person.personalCountry;
    registration.individual = this.personal;
    if(this.personal){
      registration.businessInfo.businessName = person.lastName + ', ' + person.firstName;
      registration.businessInfo.businessType = 'REGIONAL OR PRIVATE BUYER/SELLER';
      registration.businessInfo.businessPhoneNumber = person.personalPhoneNumber;
      registration.businessInfo.businessPhoneType = person.personalPhoneType;
      registration.businessInfo.businessAddressLine1 = person.personalAddressLine1;
      registration.businessInfo.businessAddressLine2 = person.personalAddressLine2;
      registration.businessInfo.businessCity = person.personalCity;
      registration.businessInfo.businessState = person.personalState;
      registration.businessInfo.businessZip = person.personalZip;
      registration.businessInfo.businessCounty = person.personalCounty;
      registration.businessInfo.businessCountry = person.personalCountry;
    } else {
      registration.businessInfo.businessName = business.businessName;
      registration.businessInfo.businessType = business.businessType;
      registration.businessInfo.businessPhoneNumber = business.businessPhoneNumber;
      registration.businessInfo.businessPhoneType = business.businessPhoneType;
      registration.businessInfo.businessAddressLine1 = business.businessAddressLine1;
      registration.businessInfo.businessAddressLine2 = business.businessAddressLine2;
      registration.businessInfo.businessCity = business.businessCity;
      registration.businessInfo.businessState = business.businessState;
      registration.businessInfo.businessZip = business.businessZip;
      registration.businessInfo.businessCounty = business.businessCounty;
      registration.businessInfo.businessCountry = business.businessCountry;
    }

    registration.password = password.password;
    registration.recaptcha = password.recaptcha;

    return registration;
  }

  public submit(): void {
    const registration = this.convertFormForSubmission();
    if(registration) {
      this.registrationService.register(registration).subscribe(response => {
        this.finished = true;
        if (response){
          this.selectedStep = 99;
        } else {
          this.selectedStep = 100;
        }
        this.selectStep.emit(this.selectedStep);
      });
    }
  }
}

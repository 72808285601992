import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  GetInventoryManagementDetailsQuery,
  GetRecentAuctionsQuery,
  GetUpcomingAuctionsQuery,
  JjkIntegration,
  InvoiceSearchRequest,
} from "../../generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private jjkIntegration: JjkIntegration) {}

  getYearToDatePayouts(accountIds: number[]): Observable<number> {
    return this.jjkIntegration.ytdSales({accountIds})
    .pipe(map((x) => x.data.ytdSales));
  }

  getUpcomingAuctions(accountIds: number[]): Observable<GetUpcomingAuctionsQuery['upcomingAuctions']> {
    return this.jjkIntegration.getUpcomingAuctions({accountIds, limit: 5})
      .pipe(map(response => response.data.upcomingAuctions));
  }

  getRecentAuctions(buyerAccountIds: number[], sellerAccountIds: number[]): Observable<GetRecentAuctionsQuery['recentAuctions']> {
    return this.jjkIntegration.getRecentAuctions({buyerAccountIds, sellerAccountIds, limit: 5})
      .pipe(map(x => x.data.recentAuctions));
  }

  getInventoryManagementDetails(accountIds: number[]): Observable<GetInventoryManagementDetailsQuery['inventoryManagementDetails']> {
    return this.jjkIntegration.getInventoryManagementDetails({accountIds})
      .pipe(map(response => response.data.inventoryManagementDetails));
  }

  estimatedPurchaseBalance(accountIds: number[]): Observable<number> {
    return this.jjkIntegration.getEstimatedPurchaseBalance({accountIds})
      .pipe(map(response => response.data.estimatedPurchaseBalance));
  }

  currentRegisteredAuctions(searchObject: InvoiceSearchRequest): Observable<number> {
    return this.jjkIntegration.getCurrentRegisteredAuctions({searchObject})
      .pipe(map(response => response.data.currentRegisteredAuctions));
  }

  getNotOnSiteCount(accountIds: number[]): Observable<number> {
    return this.jjkIntegration.getNotOnSiteCount({accountIds})
      .pipe(map(response => response.data.notOnSiteCount));
  }

  getConditionPendingCount(accountIds: number[]): Observable<number> {
    return this.jjkIntegration.getConditionPendingCount({accountIds})
      .pipe(map(response => response.data.conditionPendingCount));
  }

  getAwaitingTitleCount(accountIds: number[]): Observable<number> {
    return this.jjkIntegration.getAwaitingTitleCount({accountIds})
      .pipe(map(response => response.data.awaitingTitleCount));
  }

  getAuctionReadyCount(accountIds: number[]): Observable<number> {
    return this.jjkIntegration.getAuctionReadyCount({accountIds})
      .pipe(map(response => response.data.auctionReadyCount));
  }

  getAssignedItemsCount(accountIds: number[]): Observable<number> {
    return this.jjkIntegration.getAssignedItemsCount({accountIds})
      .pipe(map(response => response.data.assignedItemsCount));
  }
}

export interface NotOnSiteCountResponse {
  notOnSiteCount: number;
}

export interface ConditionPendingCountResponse {
  conditionPendingCount: number;
}

export interface AwaitingTitleCountResponse {
  awaitingTitleCount: number;
}

export interface AuctionReadyCountResponse {
  auctionReadyCount: number;
}

export interface AssignedItemsCountResponse {
  assignedItemsCount: number;
}

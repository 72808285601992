// Function to determine if two input arrays are equal, regardless of order
// https://www.30secondsofcode.org/blog/s/javascript-array-comparison
export function equalsIgnoreOrder(a, b): boolean {
  // With the params being strings, this function was tripped up if there was only one company selected since
  // strings are still iterable this function would run and always return false, the fix is to check if the
  // params is an array, if not turn it into an array
  if (!Array.isArray(a)) {
    a = [a];
  }
  if (!Array.isArray(b)) {
    b = [b];
  }
  if (a.length !== b.length) {
    return false;
  }
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) {
      return false;
    }
  }
  return true;
}

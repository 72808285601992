<div class="modal-header">
  <h4 class="modal-title">An Error Occurred</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Some functionality may currently be unavailable. We apologize for the inconvenience. Try refreshing the page, or
    <a href="https://www.jjkane.com/contact-us/" target="_blank"  (click)="activeModal.dismiss()">Contact Us</a>.</p>
</div>

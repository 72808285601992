<div class="item" [class.odd]="odd">
  <div class="text-center">
    <div *ngIf="loading" class="image loading-image-fluid loading mb-1">&nbsp;</div>
    <img *ngIf="!loading" class="image" [src]="getImageUrl()"/>
  </div>
  <div>
    <label><strong>Unit #: </strong></label> <span *ngIf="!loading; else loadingSpan"><a [GA]="{event: {category: 'Item Select', action: 'Click', label: 'Payout Details'}, route: ['/dashboard/items/details', item.id]}"> {{item.unitNumber}}</a></span>
  </div>
  <div>
    <label><strong>Auction Price: </strong></label> <span *ngIf="!loading; else loadingSpan"> {{item.auctionPrice | currency}}</span>
  </div>
  <div>
    <label><strong>Seller Net: </strong></label><span class="{{ item.payoutFinalized && item.sellerNet > 0 ? 'finalized' : (item.sellerNet < 0 && item.payoutFinalized ? 'negative-seller-net' : 'not-finalized') }}" *ngIf="!loading; else loadingSpan"> {{ item.payoutFinalized ? (item.sellerNet | currency) : "Pending"}}</span>
  </div>
</div>

<ng-template #loadingSpan>
  <div class="loading-text-field loading ml-3 w-25 inline-block">&nbsp;</div>
</ng-template>

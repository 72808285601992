<div>
  <div class="row d-flex align-items-center">
    <!-- Image -->
    <div class="col-lg-3 d-none d-lg-block h-100">
      <div *ngIf="!imageExists" class="missing-image d-flex justify-content-center align-items-center">
        <fa-icon [icon]="faQuestion" size="3x" class="text-light"></fa-icon>
      </div>
      <div *ngIf="imageExists">
        <img src="{{ item.imagePath }}" class="image"/>
      </div>
    </div>
    <!-- Unit Details -->
    <div class="col-lg-9 col-12">
      <div class="row">
        <div class="col-6 col-lg-12">
          <h5 class="mb-1 alt-link" (click)="clickDetail(item.id)">
            <ng-container *ngIf="isBuyerItemSearch; else sellerHeader">
              Item #: {{item.id}}
            </ng-container>
            <ng-template #sellerHeader>
              Unit #: {{ item.unitNumber }}
            </ng-template>
          </h5>
        </div>
        <div class="col-6 d-lg-none text-right">
          <fa-icon (click)="showMobileModal('image')" [icon]="faImage" size="2x" class="alt-link"></fa-icon>
        </div>
        <div class="col-lg-4 col-12">
          <p class="mb-1"><b>Category:</b> {{ item.category }}</p>
          <p class="mb-1">
            <ng-container *ngIf="isBuyerItemSearch; else itemNumber">
              <b>Lot #:</b> {{ item.lotNumber }}
            </ng-container>
            <ng-template #itemNumber>
              <b>Item #:</b> {{ item.id  }}
            </ng-template>
          </p>
          <p class="mb-1"><b>VIN:</b> {{ item.vin }}</p>
          <p class="mb-1"><b>Year:</b> {{ item.year }}</p>
          <p class="mb-1"><b>Make:</b> {{ item.make }}</p>
          <p class="mb-1"><b>Model:</b> {{ item.model }}</p>
        </div>
        <div class="col-lg-4 col-12">
          <p class="mb-1"><b>{{isBuyerItemSearch ? 'Invoice' : 'Lot'}} #:</b> {{ isBuyerItemSearch ? item.invoiceId : item.lotNumber }}</p>
          <p class="mb-1"><b>Auction:</b> {{ item.itemIsHolding ? '': item.auction }}</p>
          <p class="mb-1"><b>Type:</b> {{ item.itemIsHolding ? '' : item.type === 'Timed Auction' || item.type === 'Public Auction' ? 'Auction' : item.type}}</p>
          <p class="mb-1"><b>Auction Date:</b> {{ item.itemIsHolding ? '' : item.auctionDate }}</p>
          <p class="mb-1"><b>{{isBuyerItemSearch ? 'Pick Up' : 'Auction'}} Location:</b> {{ item.itemIsHolding ? '' : item.location }}</p>
          <p class="mb-1">
            <b>Catalog Description:</b>
            <span class="d-none d-lg-block alt-link" (click)="toggleCatalogDescription()"> View</span>
            <span class="d-lg-none alt-link" (click)="showMobileModal('description')" [GA]="{event: {category: 'Show Description', action: 'Click', label: 'Purchased Items'}}"> View</span>
          </p>
        </div>
        <ng-container *ngIf="isBuyerItemSearch; else sellerStatusColumn">
          <div class="col-lg-4 col-12">
            <div class="d-flex flex-wrap justify-content-center row">
              <div class="col-lg-12 mb-3">
                <h5 class="mb-1 text-start text-lg-center inline">
                  <b>Item Total: </b>
                </h5>
                <p class="mb-1 text-start text-lg-center inline"><b>{{ item.buyersTotal | currency  }}</b></p>
              </div>
              <div class="col-lg-12">
                <h5 class="mb-1 text-start text-lg-center inline">
                  <b>Invoice: </b>
                </h5>
                <div class="text-center">
                  <div class="spinner-border mr-2" *ngIf="downloadingInvoicePdf; else downloadLink">
                    <span class="sr-only"></span>
                  </div>
                  <ng-template #downloadLink>
                    <div class="receipt cursor-pointer" (click)="downloadInvoicePdf(item.buyerAccountId, item.invoiceId)">
                      <fa-icon [icon]="faReceipt" size="lg" class="mr-2"></fa-icon>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #sellerStatusColumn>
          <div class="col-lg-4 col-12">
            <div class="d-flex flex-wrap h-100 justify-content-center align-content-center row">
              <div class="col-lg-12">
                <h5 class="mb-1 text-start text-lg-center inline">
                  <b>Status:</b>
                </h5>
                <p *ngIf="!item.payoutId" class="mb-1 ml-1 text-start text-lg-center d-lg-none inline">{{ item.status }}</p>
                <p *ngIf="item.payoutId" class="mb-1 ml-1 text-start text-lg-center d-lg-none inline alt-link" [GA]="{event: {category: 'Select Payout Status', action: 'Click', label: 'Items'}}" (click)="openPayout(item.payoutId)">{{ item.status }}</p>
              </div>
              <div class="col-lg-12 d-none d-lg-block">
                <p *ngIf="!item.payoutId" class="mb-1 text-start text-lg-center">{{ item.status }}</p>
                <p *ngIf="item.payoutId" class="mb-1 text-start text-lg-center alt-link" [GA]="{event: {category: 'Select Payout Status', action: 'Click', label: 'Items'}}" (click)="openPayout(item.payoutId)">{{ item.status }}</p>
              </div>

              <!-- If item is titled and is in status of submitted or active, show Title status (awaiting or submitted) -->
              <div class="col-12" *ngIf="item.titled && (item.status === 'Submitted' || item.status === 'Active')">
                <div class="mb-1 text-start text-lg-center w-100" *ngIf="item.haveTitle">
                  <fa-icon [icon]="faFileContract" size="md" class="success-text mr-2"></fa-icon>
                  <span>Submitted Title</span>
                </div>
                <div class="mb-1 text-start text-lg-center w-100" *ngIf="!item.haveTitle">
                  <fa-icon [icon]="faFileContract" size="md" class="error-text mr-2"></fa-icon>
                  <span>Awaiting Title</span>
                </div>
              </div>

              <ng-container *ngIf="item.status === 'Sold' && item.payoutsFinalized" class="mb-1">
                <div class="col-lg-12">
                  <h5 *ngIf="item.amountOwed != null" class="mb-1 text-start text-lg-center inline">
                    <b>Seller Net: </b>
                  </h5>
                  <ng-container *ngIf="item.salePrice !== 0; else noSaleTemplate">
                    <p *ngIf="item.amountOwed != null && item.amountOwed > 0" class="mb-1 text-start text-lg-center inline finalized"> {{ item.amountOwed | currency  }}</p>
                    <p *ngIf="item.amountOwed != null && item.amountOwed < 0" class="mb-1 text-start text-lg-center inline negative-seller-net"> {{ item.amountOwed | currency  }}</p>
                    <p *ngIf="item.amountOwed === null || item.amountOwed === 0" class="mb-1 text-start text-lg-center inline"><b>{{ item.amountOwed | currency  }}</b></p>
                  </ng-container>
                  <ng-template #noSaleTemplate>
                    <p class="mb-1 text-start text-lg-center inline"><b> No Sale</b></p>
                  </ng-template>

                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showCatalogDescription">
    <div class="my-2 col-9 offset-3">
      <p>{{ item.catalogDescription }}</p>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { RDSSiteMessage } from '../../dto/SiteMessage';
import { GetFaIcon } from 'src/app/Shared/functions/icon-helpers';

@Component({
  selector: 'site-message-alert',
  templateUrl: './site-message-alert.component.html',
  styleUrls: ['./site-message-alert.component.scss']
})
export class SiteMessageAlertComponent implements OnInit {
  
  public faInfoCircle = GetFaIcon('info-circle', 'fas');
  public faExclamationTriangle = GetFaIcon('exclamation-triangle', 'fas');
  
  @Input() message: RDSSiteMessage;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { GraphQLRequest } from '../../Shared/models/graphql-request';
import { map } from 'rxjs/operators';
import { GraphQLResponse } from '../../Shared/models/graphql-response';
import { Success } from '../../Shared/services/common.service';
import { JjkIntegration, ForgotPassword } from '../../generated/graphql';
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private jjkIntegration: JjkIntegration) { }

  public forgotPassword(forgotPasswordObject: ForgotPassword) {
    return this.jjkIntegration.forgotPassword({forgotPasswordObject}).pipe(map(response => {
      return response.data.forgotPassword.success
    }));
  }
}

export interface ForgotPWInput {
  username: String
}

export interface ForgotPWResponse {
  forgotPassword: Success;
}

export class ForgotPWVariables {
  forgotPasswordObject: ForgotPWInput;
}

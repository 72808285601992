import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { GetFaIcon } from '../../functions/icon-helpers';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../services/contact.service';
import {AuthResponseData, AuthUserService} from '../../services/auth-user.service';
import {Subscription} from 'rxjs';
import {CompanySelectionService} from '../../services/company-selection.service';
import {Title} from '@angular/platform-browser';
import { ContactUsMessage, AccountInfoFragment } from '../../../generated/graphql';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy, AfterViewInit {

  public wasSubmitted: boolean;
  public wasSuccess: boolean;
  public faUser = GetFaIcon('user', 'fas');
  public faEnvelope = GetFaIcon('envelope', 'fas');
  public faPhone = GetFaIcon('phone', 'fas');
  public $user: Subscription;
  public contactForm: FormGroup = this.fb.group({
    subject: new FormControl(
      '',
      [
        Validators.required
      ]
    ),
    comments: new FormControl(
      '',
      [
        Validators.required
      ]
    )
  });

  public accountInfo: AccountInfoFragment;

  public user: AuthResponseData;
  public subject = this.contactForm.get('subject');
  public comments = this.contactForm.get('comments');
  public loading: boolean;
  private $selectedCompanySubscription: Subscription;

  constructor(private fb: FormBuilder,
              private userAuth: AuthUserService,
              private contactService: ContactService,
              private companySelection: CompanySelectionService,
              private titleService: Title) {
    this.titleService.setTitle('Contact Us - JJKane');
  }

  ngOnInit(): void {
    this.$user = this.userAuth.$currentUser.subscribe( (usr: AuthResponseData) => {
      this.user = usr;
    });
    this.$selectedCompanySubscription = this.companySelection.$selectedCompany.subscribe(() => {
      this.accountInfo = null;
      this.loading = true;
      this.contactService.getContactInfo().subscribe(contactInfo => {
        this.accountInfo = contactInfo.accountInfo;
        this.loading = false;
      });
    });
  }

  ngAfterViewInit(): void {
    // Comments placeholder is set in TS file as putting directly in HTML causes the error message to show immediately on IE
    // See https://github.com/angular/angular/issues/15299 for more info
    // The solutions in the above issue did not actually solve the problem, resetting the form to clean after init seems to though
    setTimeout(() => {
      this.comments.markAsPristine();
      this.comments.markAsUntouched();
    });
  }

  ngOnDestroy(): void {
    this.$user.unsubscribe();
    this.$selectedCompanySubscription.unsubscribe();
  }

  send(): void {
    let variables: ContactUsMessage;
    variables = {
      customerComments: this.comments.value,
      email: this.user.email,
      firstName: this.user.firstName,
      formType: this.subject.value,
      lastName: this.user.lastName,
      leadSource: 'PORTAL',
      mailingPostalCode: this.accountInfo.zip,
      phone: this.accountInfo.phoneNumber,
      phoneType: this.accountInfo.phoneType
    };

    if (this.contactForm.valid) {
      this.contactService.sendContactUsMessage(variables).subscribe(success => {
        this.wasSubmitted = true;
        this.wasSuccess = success;
      });
    }
    else {
      this.contactForm.markAllAsTouched();
    }
  }

}

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {GraphQLRequest} from '../../Shared/models/graphql-request';
import {map} from 'rxjs/operators';
import {GraphQLResponse} from '../../Shared/models/graphql-response';
import {HttpClient} from '@angular/common/http';
import {Payout} from '../../Shared/models/auctions';
import {FilterItem} from '../../Items/services/item.service';
import { JjkIntegration, PayoutSearchRequest, SearchPayoutsQuery, GetPayoutDetailsQuery, GetPayoutFiltersQuery } from '../../generated/graphql';
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class PayoutService {

  constructor(private http: HttpClient, private jjkIntegration: JjkIntegration) { }

  public searchPayouts(searchRequest: PayoutSearchRequest): Observable<SearchPayoutsQuery['searchPayouts']> {
    return this.jjkIntegration.searchPayouts({searchRequest}).pipe(map(response => {
      return response.data.searchPayouts;
    }));
  }

  public getPayoutDetails(accountIds: number[], payoutId: number): Observable<GetPayoutDetailsQuery['payoutDetails']> {
    return this.jjkIntegration.getPayoutDetails({accountIds, payoutId}).pipe(map(response => {
      return response.data.payoutDetails
    }));
  }

  public getPayoutFilters(accountIds: number[]): Observable<GetPayoutFiltersQuery['payoutFilters']> {
    return this.jjkIntegration.getPayoutFilters({accountIds}).pipe(map(response => {
      return response.data.payoutFilters;
    }));
  }

  public downloadSellerTotalReport(accountId: number, payoutId: number): Observable<any> {
    return this.downloadPayoutReport(accountId, payoutId, 'SELLERTOTALSREPORTWITHOUTBUYER');
  }

  public downloadPayoutLetter(accountId: number, payoutId: number): Observable<any> {
    return this.downloadPayoutReport(accountId, payoutId, 'PayoutSummary');
  }

  public downloadPayoutDetailReport(accountId: number, payoutId: number): Observable<any> {
    return this.downloadPayoutReport(accountId, payoutId, 'PayoutDetail');
  }

  private downloadPayoutReport(accountId: number, payoutId: number, reportName: string): Observable<any> {
    let url = environment.graphQLConfig.downloadPayoutReportUrl;
    url = url.replace(':accountId', accountId?.toString());
    url = url.replace(':payoutId', payoutId?.toString());
    url = url.replace(':reportName', reportName);
    return this.http.get(url, {responseType: 'arraybuffer'});
  }

}

export class PayoutDetailRequest {
  accountIds: number[];
  payoutId: number;
}

export interface PayoutSearchResponse {
  number: number;
  pageSize: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  totalPages: number;
  numberOfElements: number;
  content: Payout[];
}

export interface SearchPayoutsResponseWrapper {
  searchPayouts: PayoutSearchResponse;
}

export interface PayoutDetailsResponseWrapper {
  payoutDetails: PayoutSearchResponse;
}

export interface PayoutFiltersRequest {
  accountIds: number[];
}

export interface PayoutFiltersResponse {
  sale: FilterItem[];
}

export interface PayoutFiltersResponseWrapper {
  payoutFilters: PayoutFiltersResponse;
}

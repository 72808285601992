import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {JjkIntegration, SearchItemsQuery, SearchItemsQueryVariables, ItemDetailsQuery, SellerFilterOptionsQuery, BuyerFilterOptionsQuery, GetBuyerItemsExportDataQuery, GetSellerItemsExportDataQuery} from "../../generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(private http: HttpClient, private jjkIntegration: JjkIntegration) {
  }

  public searchItems(searchItemVariables: SearchItemsQueryVariables['searchObject']): Observable<SearchItemsQuery['searchItems']> {
    return this.jjkIntegration.searchItems({searchObject: searchItemVariables})
      .pipe(map(x => {
        return x.data.searchItems;
      }));
  }

  public sellerItemFilters(filterItemInput: FiltersRequestInput): Observable<SellerFilterOptionsQuery['sellerFilterOptions']> {
    return this.jjkIntegration.sellerFilterOptions({accountIds: filterItemInput.accountIds}).pipe(map(response => {
      return response.data.sellerFilterOptions;
    }))
  }

  public buyerItemFilters(filterItemInput: FiltersRequestInput): Observable<BuyerFilterOptionsQuery['buyerFilterOptions']> {
    return this.jjkIntegration.buyerFilterOptions({accountIds: filterItemInput.accountIds}).pipe(map(response => {
      return response.data.buyerFilterOptions;
    }))
  }

  public getItemDetails(accountIds: number[], itemId: number, isBuyerItemDetail: boolean): Observable<ItemDetailsQuery['itemDetails']> {
    var sellerAccountIds = isBuyerItemDetail ? [] : accountIds;
    var buyerAccountIds = isBuyerItemDetail ? accountIds : [];
    return this.jjkIntegration.itemDetails({sellerAccountIds, buyerAccountIds, itemId}).pipe(map(response => {
      return response.data.itemDetails;
    }));
  }

  public downloadImages(itemId: string): Observable<any> {
    const url = environment.graphQLConfig.downloadItemDetailImagesUrl.replace(':itemId', itemId);
    return this.http.get(url, {responseType: 'arraybuffer'});
  }

  public getItemsExportData(searchItemVariables: SearchItemVariables, isBuyer: boolean): Observable<GetBuyerItemsExportDataQuery['exportItems'] | GetSellerItemsExportDataQuery['exportItems']> {
    if(isBuyer) {
      return this.jjkIntegration.getBuyerItemsExportData({searchObject: searchItemVariables.searchObject, exportStartPage: searchItemVariables.exportStartPage, exportEndPage: searchItemVariables.exportEndPage}).pipe(map(response => {
        return response.data.exportItems;
      }));
    } else {
      return this.jjkIntegration.getSellerItemsExportData({searchObject: searchItemVariables.searchObject, exportStartPage: searchItemVariables.exportStartPage, exportEndPage: searchItemVariables.exportEndPage}).pipe(map(response => {
        return response.data.exportItems;
      }));
    }
  }
}

export class FiltersRequestInput {
  accountIds: number[];
}

export interface SellerItemFiltersResponse {
  sellerFilterOptions: SellerFilterOptions;
}

export interface BuyerItemFiltersResponse {
  buyerFilterOptions: BuyerFilterOptions;
}

export interface SellerFilterOptions {
  saleInfo: Array<FilterItem>;
  offsitePhysical: Array<FilterItem>;
  category: Array<FilterItem>;
  make: Array<FilterItem>;
  model: Array<FilterItem>;
  upperMake: Array<FilterItem>;
}

export interface BuyerFilterOptions {
  saleInfo: Array<FilterItem>;
  saleLocation: Array<FilterItem>;
  category: Array<FilterItem>;
  make: Array<FilterItem>;
  model: Array<FilterItem>;
  upperMake: Array<FilterItem>;
  upperModel: Array<FilterItem>;
}

export interface FilterItem {
  name: string;
  description: string;
}

export class SearchItemInput {
  category?: string;
  cpStatus?: string;
  make?: string;
  model?: string;
  offSitePhysical?: string;
  pageSize: number;
  page: number;
  sellerAccountIds?: number[];
  buyerAccountIds?: number[];
  searchTerm: string;
  saleInfo?: string;
  sortField?: string;
  sortDirection?: string;
  upperMake?: string;
  upperModel?: string;
  payoutIds?: number[];
}

export class SearchItemVariables {
  searchObject: SearchItemInput;
  exportStartPage: number;
  exportEndPage: number;
}

export class GetItemDetailsVariables {
  sellerAccountIds?: number[];
  buyerAccountIds?: number[];
  itemId: number;
}

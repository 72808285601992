import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GetFaIcon} from 'src/app/Shared/functions/icon-helpers';
import {RDSDraftItem} from '../../../item-drafts/services/drafts.service';
import {ContactService} from '../../../Shared/services/contact.service';

@Component({
  selector: 'app-new-draft-item-modal',
  templateUrl: './new-draft-item-modal.component.html',
  styleUrls: ['./new-draft-item-modal.component.scss']
})
export class NewDraftItemModalComponent implements OnInit {
  public modalTitle = 'New Site Message';
  public submitButtonText = 'Submit';
  public newItemForm: FormGroup;
  public faCalendar = GetFaIcon('calendar', 'fas');

  public startTime = {hour: 13, minute: 30};
  public endTime = {hour: 13, minute: 30};
  public startDate: NgbDate;
  public endDate: NgbDate;
  public startingDate: NgbDateStruct;
  public endingDate: NgbDateStruct;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.newItemForm = this.formBuilder.group({
      userId: [this.getRandomInt(10, 1000)],
      submitTimestamp: [String(Date.now())],
      currentStep: ['1'],
      itemType: ['Titled'],
      category: ['Golf'],
      unitNumber: [this.getRandomInt(20, 200)],
      vin: [this.getRandomInt(2000, 9000)],
      year: [this.getRandomInt(1995, 2021)],
      make: ['International'],
      model: ['9200i'],
      airConditioning: [false],
      capacity: ['1000'],
      capacityUnit: ['lbs'],
      capacityType: ['Big'],
      engine: ['Volvo VE-D7-260 6.7L'],
      fuel: ['diesel'],
      itemNotes: ['Its a big red engine'],
      height: ['60'],
      hours: ['20'],
      mounted: ['center mounted on'],
      odometer: ['1065'],
      offSitePhysicalAddress: ['33 Inverness Center Drive'],
      offSitePhysicalCity: ['Birmingham'],
      offSitePhysicalState: ['AL'],
      offSitePhysicalZip: ['35126'],
      optionalEquipment: ['N/A'],
      parentCategory: ['Sporting Goods'],
      plateNumber: ['3f4i449'],
      sellerFleetNumber2: [''],
      taxCounty: ['Jefferson'],
      transmission: ['10-spd'],
      upperFuel: ['unleaded'],
      upperMake: ['International'],
      upperModel: ['9200i'],
      upperOptionalEquipment: ['Bucket and Claps'],
      upperSerialNumber: ['15rgt45']
    });
  }

  public submit(): void {

    const item: RDSDraftItem = {
      currentStep: this.newItemForm.get('currentStep').value,
      submitTimestamp: String(this.newItemForm.get('submitTimestamp').value),
      userId: String(this.newItemForm.get('userId').value),
      category: String(this.newItemForm.get('category').value),
      itemType: String(this.newItemForm.get('itemType').value),
      make: String(this.newItemForm.get('make').value),
      model: String(this.newItemForm.get('model').value),
      unitNumber: String(this.newItemForm.get('unitNumber').value),
      vin: String(this.newItemForm.get('vin').value),
      year: String(this.newItemForm.get('year').value),
      // new draft item properties
      airConditioning: Boolean(this.newItemForm.get('airConditioning').value),
      capacity: String(this.newItemForm.get('capacity').value),
      capacityUnit: String(this.newItemForm.get('capacityUnit').value),
      capacityType: String(this.newItemForm.get('capacityType').value),
      engine: String(this.newItemForm.get('engine').value),
      fuel: String(this.newItemForm.get('fuel').value),
      itemNotes: String(this.newItemForm.get('itemNotes').value),
      height: String(this.newItemForm.get('height').value),
      hours: String(this.newItemForm.get('hours').value),
      mounted: String(this.newItemForm.get('mounted').value),
      odometer: String(this.newItemForm.get('odometer').value),
      offSitePhysicalAddress: String(this.newItemForm.get('offSitePhysicalAddress').value),
      offSitePhysicalCity: String(this.newItemForm.get('offSitePhysicalCity').value),
      offSitePhysicalState: String(this.newItemForm.get('offSitePhysicalState').value),
      offSitePhysicalZip: String(this.newItemForm.get('offSitePhysicalZip').value),
      optionalEquipment: String(this.newItemForm.get('optionalEquipment').value),
      parentCategory: String(this.newItemForm.get('parentCategory').value),
      plateNumber: String(this.newItemForm.get('plateNumber').value),
      sellerFleetNumber2: String(this.newItemForm.get('sellerFleetNumber2').value),
      taxCounty: String(this.newItemForm.get('taxCounty').value),
      transmission: String(this.newItemForm.get('transmission').value),
      upperFuel: String(this.newItemForm.get('upperFuel').value),
      upperMake: String(this.newItemForm.get('upperMake').value),
      upperModel: String(this.newItemForm.get('upperModel').value),
      upperOptionalEquipment: String(this.newItemForm.get('upperOptionalEquipment').value),
      upperSerialNumber: String(this.newItemForm.get('upperSerialNumber').value),
      titled: false
    };

    this.activeModal.close(item);

  }

  public getRandomInt(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

}

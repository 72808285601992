import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetFaIcon } from '../../../../../src/app/Shared/functions/icon-helpers';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';
import { RDSSiteMessage, SiteMessageStatus, SiteMessageLevel, SiteMessagePlacement } from '../../dto/SiteMessage';
import { SiteMessagesService } from '../../services/site-messages.service';
import {HttpClient} from '@angular/common/http';
import {ContactService} from '../../../Shared/services/contact.service';
import {Observable, Subscription} from 'rxjs';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {SiteMessage} from '../../../generated/graphql';

@Component({
  selector: 'app-new-message-modal',
  templateUrl: './new-message-modal.component.html',
  styleUrls: ['./new-message-modal.component.scss']
})
export class NewMessageModalComponent implements OnInit {

  public modalTitle: string = 'New Site Message';
  public submitButtonText: string = 'Submit';
  public message: SiteMessage;
  public newMessageForm: FormGroup;
  public faCalendar = GetFaIcon('calendar', 'fas');

  public startTime = {hour: 13, minute: 30};
  public endTime = {hour: 13, minute: 30};
  public startDate: NgbDate;
  public endDate: NgbDate;
  public startingDate: NgbDateStruct;
  public endingDate: NgbDateStruct;
  public currentTimestamp: Number;

  public accountID: number;

  private $selectedCompanySubscription: Subscription;

  constructor(private http: HttpClient, private contactService: ContactService, private companySelection: CompanySelectionService, public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    // todo: we're using parentAccountId, but this.companySelection.getSelectedAccountIds() may be more appropriate
    // todo: consider removing entirely. Instead of keeping state here, use CompanySelectionService where needed as the source of truth
    this.$selectedCompanySubscription = this.companySelection.$selectedCompany.subscribe(() => {
      this.contactService.getContactInfo().subscribe(contactInfo => {
        this.accountID = contactInfo.parentAccountId;
      });
    });
  }

  submitted = false;

  ngOnInit(): void {
    this.newMessageForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      title: ['', Validators.required],
      message: ['', Validators.required],
      sitePlacement: ['', Validators.required],
      messageLevel: ['', Validators.required]
    });

    // Passed in when editing

    if(this.message) {
      this.modalTitle = 'Edit Message';
      this.submitButtonText = 'Save';
      this.newMessageForm.get('title').setValue(this.message.title);
      this.newMessageForm.get('message').setValue(this.message.message);
      this.newMessageForm.get('sitePlacement').setValue(this.message.sitePlacement);
      this.newMessageForm.get('messageLevel').setValue(this.message.level);

      let startDateToDate = new Date(this.message.startDate);
      let endDateToDate = new Date(this.message.endDate);

      this.startTime = {hour: startDateToDate.getHours(), minute: startDateToDate.getMinutes()};
      this.startingDate = {year: startDateToDate.getFullYear(), month: startDateToDate.getMonth()+1, day: startDateToDate.getDate()};
      this.endTime = {hour: endDateToDate.getHours(), minute: endDateToDate.getMinutes()};
      this.endingDate = {year: endDateToDate.getFullYear(), month: endDateToDate.getMonth()+1, day: endDateToDate.getDate()};

    }
  }

  get formControls() { return this.newMessageForm.controls; }

  public submit() {

    function sanitizeInput(val){
      val = val.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
      return val.trim();
    }

    this.submitted = true;

    let jsStartDate: Date = new Date();
    let jsEndDate: Date = new Date();
    let jsMessageLevel = this.newMessageForm.get('messageLevel').value;
    let jsSitePlacement = this.newMessageForm.get('sitePlacement').value;
    let jsMessage = sanitizeInput(this.newMessageForm.get('message').value);
    let jsTitle = sanitizeInput(this.newMessageForm.get('title').value);

    if(this.startingDate){
      jsStartDate = new Date(this.startingDate.year, this.startingDate.month-1, this.startingDate.day, this.startTime.hour, this.startTime.minute);
      this.newMessageForm.controls.startDate.setValue(jsStartDate);
    }

    if(this.endingDate){
      jsEndDate = new Date(this.endingDate.year, this.endingDate.month-1, this.endingDate.day, this.endTime.hour, this.endTime.minute);
      this.newMessageForm.controls.endDate.setValue(jsEndDate);
    }

    if(jsSitePlacement){
      this.newMessageForm.controls.sitePlacement.setValue(jsSitePlacement);
    }

    if(jsMessageLevel){
      this.newMessageForm.controls.messageLevel.setValue(jsMessageLevel);
    }

    if(this.message?.submitTimestamp){
      this.currentTimestamp = Number(this.message.submitTimestamp);
      this.accountID = Number(this.message.userId);
    } else {
      this.currentTimestamp = Date.now();
    }


    if (this.newMessageForm.valid) {
      let message: RDSSiteMessage = {
        id: this.message? Number(this.message.id) : Number(),
        userId: String(this.accountID),
        submitTimestamp: String(this.currentTimestamp),
        status: SiteMessageStatus.Active,
        level: jsMessageLevel,
        sitePlacement: jsSitePlacement,
        message: jsMessage,
        title: jsTitle,
        endDate: String(jsEndDate),
        startDate: String(jsStartDate)
      };
      this.activeModal.close(message);
    }
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StateUs} from '../models/state-us';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { JjkIntegration, GetDropdownValuesQuery, AuctionItem } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class SubmitItemService {

  public uploadQueue: File[] = [];
  YEAR_OF_THE_FIRST_AUTOMOBILE_PATENT = 1886;

  constructor(private http: HttpClient, private jjkIntegration: JjkIntegration) { }

  public getYearList(): string[] {
    const years = [];
    const nextYear = new Date().getFullYear() + 1;
    const numberOfYears = nextYear - this.YEAR_OF_THE_FIRST_AUTOMOBILE_PATENT;
    for (let i = 0; i <= numberOfYears; i++) {
      years.push((nextYear - i).toString());
    }
    return years;
  }

  public getStatesUSList(): Observable<StateUs[]> {
    return this.http.get('assets/states-US.json')
      .pipe(map (response => response as StateUs[]));
  }

  public getDropdownValues(): Observable<GetDropdownValuesQuery> {
    return this.jjkIntegration.getDropdownValues().pipe(map(response => {
      console.log(response.data.categories);
      return response.data;
    }))
  }

  saveAuctionItem(variables: SaveItemVariables): Observable<AuctionItem> {
    return this.jjkIntegration.saveItem({accountId: variables.accountId, contactId: variables.contactId, auctionItem: variables.auctionItem}).pipe(map(response => {
      return response.data.saveAuctionItem;
    }))
  }

  // Need to pass response.id, response.saleId here and set to form data on backend
  saveAuctionPhotos(id: string, saleId: string): Promise<any> {
    const url = environment.graphQLConfig.test;

    let uploads = [];
    this.uploadQueue.forEach((file, i) => {

      let formData: FormData = new FormData();
      formData.append('files', file, file.name);
      formData.append('id', id);
      formData.append('saleId', saleId);
      formData.append('sequence', i.toString());

      let upload = this.http.post(url + 'postImage', formData, {
        withCredentials: true
      }).toPromise();
      uploads.push(upload);
    });
    console.log('Photo uploads started');
    console.log(new Date(Date.now()).toLocaleString());
    return Promise.all(uploads);
  }
}

export class SaveItemVariables {
  accountId: number;
  contactId: number;
  auctionItem: AuctionItemInput;
}

export class AuctionItemInput {
  parentCategory: string;
  category: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  unitNumber: string;
  sellerFleetNumber2: string;
  plateNumber: string;

  engine: string;
  fuel: string;
  transmission: string;
  odometer: string;
  hours: string;
  optionalEquipment: string;
  airConditioning: boolean;


  upperSerialNumber: string;
  upperMake: string;
  upperModel: string;
  upperFuel: string;
  height: string;
  capacity: string;
  capacityType: string;
  mounted: string;
  upperOptionalEquipment: string;
  itemNotes: string;

  // todo: there doesn't appear to be a field for address line 2
  offSitePhysicalAddress: string;
  offSitePhysicalCity: string;
  offSitePhysicalState: string;
  offSitePhysicalZip: string;
  taxCounty: string;
}

export interface SubmitItemRequest{
  itemId: number;
}
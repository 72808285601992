<div class="modal-header">
  <h4 class="modal-title">
    Custom Date Range
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <input class="form-control date-picker" placeholder="yyyy-mm-dd" [(ngModel)]="startDate" [ngModelOptions]="{standalone: true}" ngbDatepicker #dStart="ngbDatepicker" [maxDate]="today" required (click)="dStart.toggle()">
    <div class="form-control range-divider">to</div>
    <input class="form-control date-picker" placeholder="yyyy-mm-dd" [(ngModel)]="endDate" [ngModelOptions]="{standalone: true}" ngbDatepicker #dEnd="ngbDatepicker" [maxDate]="today" required (click)="dEnd.toggle()">
    <button class="btn text-light bg-altec submit-button" [disabled]="!validDateEntries" (click)="set()">Set</button>
    <div *ngIf="!datesInOrder">
      <small class="error-text">Start date must come before end date</small>
    </div>
</div>

<div class="wrapper py-2">
  <div class="row px-3">
    <div class="col-6 h5 font-weight-bold text-left">Inventory Management</div>
  </div>
  <div class="col-12">
    <div class="row mb-2">
      <div class="col-xl-3 col-lg-6 col-xs-12 mt-2">
        <inventory-management-card id="not-on-site" [title]="'Not On Site'" [count]="inventoryManagementDetails?.notOnSite" (clicked)="quickFilterAction($event)"></inventory-management-card>
      </div>
      <div class="col-xl-3 col-lg-6 col-xs-12 mt-2">
        <inventory-management-card id="condition-pending" [title]="'Condition Pending'" [count]="inventoryManagementDetails?.conditionPending" (clicked)="quickFilterAction($event)"></inventory-management-card>
      </div>
      <div class="col-xl-3 col-lg-6 col-xs-12 mt-2">
        <inventory-management-card id="awaiting-title" [title]="'Awaiting Title'" [count]="inventoryManagementDetails?.awaitingTitle" (clicked)="quickFilterAction($event)"></inventory-management-card>
      </div>
      <div class="col-xl-3 col-lg-6 col-xs-12 mt-2">
        <inventory-management-card id="auction-ready" [title]="'Auction Ready'" [count]="inventoryManagementDetails?.auctionReady" (clicked)="quickFilterAction($event)"></inventory-management-card>
      </div>
    </div>
  </div>
</div>

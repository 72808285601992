<div class="my-3 mx-3">

    <ng-container *ngIf="!doneSubmitting">

        <div *ngIf="submittingItem" class="my-3">
            <h4 class="font-weight-bold text-center">Submitting Item</h4>
        </div>

        <div *ngIf="submittingPhotos" class="my-3">
            <h4 class="font-weight-bold text-center">Submitting {{ photoCount }} Photo {{ photoCount > 1 ? 's' : '' }}</h4>
        </div>

        <div class="col-8 offset-2 my-5">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

    </ng-container>

    <ng-container *ngIf="doneSubmitting">

        <ng-container *ngIf="!error">
            <h4 class="font-weight-bold text-center my-3">Item Submitted</h4>

            <div class="w-100 d-flex justify-content-center mb-3">
                <button class="btn bg-altec text-light w-mobile-100" (click)="activeModal.close()">View Item</button>
            </div>
        </ng-container>

        <ng-container *ngIf="error">
            <error-modal></error-modal>
        </ng-container>

    </ng-container>

</div>
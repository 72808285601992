import {Component, Input, OnInit} from '@angular/core';
import { Item } from '../../../generated/graphql';
import { ItemData } from '../payout-detail/payout-detail.component';

@Component({
  selector: 'payout-item-card',
  templateUrl: './payout-item-card.component.html',
  styleUrls: ['./payout-item-card.component.scss']
})
export class PayoutItemCardComponent implements OnInit {
  @Input()
  public item: ItemData;

  @Input()
  public loading = false;

  @Input()
  public odd: boolean;

  private missingImageUrl = './assets/images/300px-No_image_available.svg.png';

  constructor() { }

  ngOnInit(): void {
    
  }

  public getImageUrl(): string {
    if (this.item?.imageCount > 0) {
      return this.item.imagePath;
    }
    return this.missingImageUrl;
  }
}

import { Component, OnInit } from '@angular/core';
import { GetFaIcon } from '../../functions/icon-helpers';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public faFacebook = GetFaIcon('facebook', 'fab');
  public faInstagram = GetFaIcon('instagram', 'fab');
  public faLinkedin = GetFaIcon('linkedin', 'fab');
  public faRss = GetFaIcon('rss', 'fas');
  public faTwitter = GetFaIcon('twitter', 'fab');
  public faYoutube = GetFaIcon('youtube', 'fab');

  constructor() { }

  ngOnInit(): void {
  }

}
